export class DashBoardViewModel {

    constructor() {
        this.statistcs = new statistcs();
    }
    ordersRate: ordersRate[] | undefined;
    dailySalesRates: dailySalesRate[] | undefined;
    statistcs: statistcs;
}
export class
    ordersRate {
    year: number;
    month: string;
    day: string;
    numberofOrders: number = 0;
    revenue: number = 0;
    ordersPercentage: number = 0;
}
export class
    dailySalesRate {
    year: number;
    string: number;
    day: string;
    numberofOrders: number = 0;
    revenue: number = 0
}
;
export class
    statistcs {
    numberOfCustomers: number = 0;
    numberOfGroups: number = 0;

    availableProducts: number = 0;
    productsOnPromotion: number = 0;
    belowStock: number = 0;
    pendingOrders: number = 0;
    awaitingFulfillmentOrders : number = 0;
    last7dayRevenue: number = 0;
}

export class chartdata {
    labels: any[];
    series: any[];
    increased: boolean = true;
    percentage: number = 0;
    nochange: boolean;
    decreased: boolean;
}
export class DailyOrders {
    year: number;
    month: string;
    day: string;
    numberofOrders: number = 0;    
}

export class StockLevelModel {
    date: Date;
    stockLevelValue: number = 0;
}
export class MonthlyOrdersByStatusModel {
    year: number;
    month: string;
    status: string;
    percentage: number;
    numberofOrders: number = 0;    
}

export class MonthlyOrdersModel {
    groupedOrders: MonthlyOrdersByStatusModel[];    
    totalNumberOfOrders: number = 0;    
}

export class MonthlyOrdersByStatusChartData {
    kind: string;
    share: number;    
}
export enum Months {
    'Jan' = 1, 'Feb' = 2, 'Mar' = 3, 'Apr' = 4, 'May' = 5, 'Jun' = 6, 'Jul' = 7, 'Aug' = 8, 'Sept' = 9, 'Oct' = 10, 'Nov' = 11, 'Dec' = 12
}