import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[dateCompare][formControlName],[dateCompare][formControl],[dateCompare][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: DateCompareDirective, multi: true}]
})
export class DateCompareDirective implements Validator {
  @Input()
  dateCompare: Date;

  
  validate(c: FormControl): {[key: string]: any} {
      let v = c.value;
      return ( v < this.dateCompare )? {"dateCompare": true} : null;
  }
} 