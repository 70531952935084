<div class="row">
<div class="col-12 mb-5">
<p class="h3 font-weight-bold text-center">Invite People to Your Team</p>
</div>
<div class="col-12 input-group  input-group-lg mb-5">

  <input type="text" class="form-control" value="{{link}}" readonly aria-label="inviteLink" aria-describedby="copy">
 <div class="input-group-append">
  <div class='input-group-btn'>
  <button class="btn btn-primary" (click)="copyText(link)" id="copy">Copy</button>
</div>

</div>

</div>

</div>
<div class="p-3 mb-5 bg-secondary text-white font-weight-bold rounded text-center" >
  <span>Any one you share this link with will be able to join and access this team.</span>

</div>

  <form [formGroup]="myForm" (ngSubmit)="submit()" novalidate>
     <ng-container formArrayName="users">
      <ng-container
      *ngFor="let x of usersArray.controls; let i = index;"
      [formGroupName]="i"
      >
      <div class="row">
        <div class="col-12 mb-5">
          <input [id]="i+1" matInput class="form-control" type="text" placeholder="Email Address" formControlName="email"  />
        </div>
      
      </div>
        
          
      </ng-container>
    </ng-container>  
    <div class="text-center">
      <button  type="submit"  class="btn btn-primary btn-round" [mat-dialog-close]="true"   [disabled]="!myForm.valid" >Invite People to My Team</button>
    </div>
    
</form>