import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';
import { Message } from 'ng-chat';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { catchError } from 'rxjs/operators';
import { DashBoardViewModel } from '../models/dashboard/dashboardViewModel';
import { SellerService } from './Seller.Service';
import { SupplierTrustedNetworksViewModel } from '../models/promotions/marketing.model';
import { PageModel } from '../models/pageModel';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  headers: Headers;
  apiURl: string;

  private handleError: HandleError;
  private sellerService: SellerService;  


  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, sellerService: SellerService) {
    this.handleError = httpErrorHandler.createHandleError('ChatService');
    this.apiURl = environment.apibaseUrl;
    this.sellerService = sellerService;
    //this.sellerId = this.sellerService.sellerViewModel.id;
  }

  GetChatHistoryForBuyer(buyerId: number): Observable<any[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/NgChat/GetChatHistory?buyerId=${buyerId}`;
        return this.http.get<any[]>(url).pipe( map((data: any[]) => {
            for(var x in data)
            {
                data[x].dateSent = new Date(data[x].dateSent + 'Z');
            }
            return data;
          }),
      catchError(this.handleError<any>('GetChatHistoryForBuyer')))})
    );
  }

  GetChatHistoryForBuyerByPage(buyerId: number,pageNo:number,limit:number): Observable<any[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/NgChat/ChatHistoryPaginatedMessages?buyerId=${buyerId}&pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<any[]>(url).pipe( map((data: any[]) => {
            for(var x in data)
            {
                data[x].dateSent = new Date(data[x].dateSent + 'Z');
            }
            return data;
          }),
          catchError(this.handleError<any>('GetChatHistoryForBuyerByPage')))})
    );
  }
  
  GetChatContacts(): Observable<SupplierTrustedNetworksViewModel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
          const url = `${this.apiURl}/api/v1/${seller.id}/NgChat/Contacts?`;
          return this.http.get<SupplierTrustedNetworksViewModel>(url)}),
          catchError(this.handleError<any>('GetChatContacts'))
    );
  }

  GetChatHistory(ChatId: number): any {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/NgChat?ChatId=${ChatId}`;
        return this.http.get<any>(url)}),
      catchError(this.handleError<any>('GetChatHistory'))
    );
  }

  SendMessage(message: Message): any {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/NgChat/SendMessage`;    
        return this.http.post<any>(url,message)}),
      catchError(this.handleError<any>('SendMessage'))
    );
  }

  MarkMessagesRead(messages: Message[], buyerId: number): any {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/NgChat/MarkMessagesRead?buyerId=${buyerId}`;
        return this.http.post<any>(url,messages)}),
      catchError(this.handleError<any>('MarkMessagesRead'))
    );    
  }

}


