export class PermissionsConstants {
  public static CREATE = 'CREATE';
  public static UPDATE = 'UPDATE';
  public static DELETE = 'DELETE';
  public static READ = 'READ';
  public static DASHBOARD = 'DASHBOARD';
  public static INVENTORY = 'INVENTORY';
  public static ORDERS = 'ORDERS';
  public static APPROVALS = 'APPROVALS';
  public static CUSTOMERS = 'CUSTOMERS';
  public static MARKETING = 'MARKETING';
  public static INTEGRATION = 'INTEGRATION';
  public static PROFILE = 'PROFILE';
  public static SETTINGS = 'SETTINGS';
  public static USERS = 'USERS';
  public static SELLER = 'SELLER';
  public static GLOBAL = 'GLOBAL';
  public static PERMISSIONS = 'PERMISSIONS';
  public static CHAT = 'CHAT';
  public static SHOPPING_CART = 'SHOPPING_CART';
  public static BUYER_INFO = 'BUYER_INFO';
  public static SELLER_CONNECTIONS = 'SELLER_CONNECTIONS';
  public static MARKETPLACE_CATALOG = 'MARKETPLACE_CATALOG';
  public static MARKET_UPDATES = 'MARKET_UPDATES';
  public static BUYER_NOTIFICATIONS = 'BUYER_NOTIFICATIONS';
  public static POS = 'POS';
  public static BILLING = 'BILLING';
  public static SITE_MANAGEMENT = 'SITE_MANAGEMENT';
}