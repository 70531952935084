import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyobDialog } from 'app/integration/dialogs/myob/myob.dialog';
import { MarketingService } from 'app/shared/services/marketing.service';
import { IntegrationService } from 'app/shared/services/integration.service';
import { CompanyFileViewModel } from 'app/shared/models/integration/companyfile.model';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { XeroTenantViewModel } from 'app/shared/models/integration/xerotenant.model';
import { XeroDialog } from 'app/integration/dialogs/xero/xero.dialog';
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';

@Component({
  selector: 'app-payment-integration',
  templateUrl: './payment-integration.component.html',
  styleUrls: ['./payment-integration.component.css']
})
export class PaymentIntegrationComponent implements OnInit {

  apiURL: string;
  private handleError: HandleError;
  paymentIsActive: boolean;
  isLinkedToStripe: boolean;
  showIntegrations: boolean = true;
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    httpErrorHandler: HttpErrorHandler,
    private userService: UserService, private sellerService: SellerService
  ) {
    this.apiURL = environment.apibaseUrl;
    this.handleError = httpErrorHandler.createHandleError('ChatService');
  }

  loadPermissions(seller: SellerViewModel) {
    this.userService.hasPermissionFor(PermissionsConstants.INTEGRATION, PermissionsConstants.READ, seller.id).subscribe(x => {  this.showIntegrations = x ;this.spinner.hide('loading'); });
  }

  ngOnInit(): void {
    this.spinner.show('loading');
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
  }
  stripePageRedirect(): void {
    this.sellerService.stripeIntegration().subscribe(res => {
      window.open(res.data, '_blank');
    });
  }
}
