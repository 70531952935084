import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import {  ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SellerViewModel } from 'app/shared/models/seller';
import { ConfirmationDialog } from 'app/dialogs/confirm/confirm.dialog';
import { SellerService } from 'app/shared/services/Seller.Service';
import { NgxSpinnerService } from "ngx-spinner";
import { IntegrationService } from 'app/shared/services/integration.service';
import { IntegrationItemsDialog } from 'app/inventory/dialogs/integration-items/integration-items.dialog';
import { BatchDialog } from 'app/inventory/dialogs/batch/batch.dialog';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import {  DataBindingDirective, PageChangeEvent, RowArgs, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, GroupDescriptor, GroupResult, filterBy, groupBy } from '@progress/kendo-data-query';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import { SiteModel, StockTakeModel, StockTakeRecordModel } from 'app/shared/models/sites/site.model';

@Component({
  selector: 'app-stock-take-batches-dialog',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './stock-take-batches.component.html',
  styleUrls: ['./stock-take-batches.component.css']
})
export class StockTakeBatchesComponent implements OnInit {
  public gridView: any;
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  dropdownItems: SiteModel[] ;
  selectedOption: number;
  public pendingGridData: any[];
  public pendingGridView: any[];
  public pendingProductSelection: string[] = [];
  public pendingProductselectedUID:string  = '';
  decimalPattern = new RegExp('[0-9]+([.][0-9]+)?');
  Products: ProductViewMdel[];
  newstock: number = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  Product: ProductViewMdel;
  SupplierId: number;
  isLinkedToExternalSystem: boolean;
  ConfirmationdialogRef: MatDialogRef<ConfirmationDialog>;
  IntegrationDialogRef: MatDialogRef<IntegrationItemsDialog, any>;
  BatchDialogRef: MatDialogRef<BatchDialog, any>;
  showInventory: boolean = true;
  featurePermission: FeaturePermission  = new FeaturePermission();
  headerStyle: any;
  createInventory:boolean =false;
  public batchList: BatchViewModel[]=[];
  public selectedBatch:BatchViewModel;
  public groupRes:BatchViewModel[] | GroupResult[];
  public group: GroupDescriptor[] = [{ field: "product.category.categoryName" }];
  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(
    private ProductService: ProductService, public dialogRef: MatDialogRef<BatchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: {  site: SiteModel }, private snackbar: MatSnackBar, private sellerService: SellerService,
    private spinner: NgxSpinnerService, private integrationService: IntegrationService,private userService: UserService
  ) {

  }

  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.INVENTORY )[0];
    this.showInventory=this.featurePermission.read;   
    this.createInventory=this.featurePermission.create;  
    });
     
 }

  ngOnInit(): void {
    //this.spinner.show();
    this.loading = true;
    this.setSelectableSettings();
    this.getSites();
    this.initBtaches();

  }

  async initBtaches() {    
    this.sellerService.getSeller().subscribe(data => {
      this.loadPermissions(data);
      this.SupplierId = data.supplierId;
      this.getBatches(this.currentPage,this.pageSize,this.data.site.id);
      // this.imageSource = this.Seller.supplier.profileImageUrl_Small;
    });
  }

  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
     let filteredData= filterBy(this.gridData , this.filter);
     this.groupRes=groupBy(filteredData , this.group);
         this.gridView=[...this.groupRes];

  }

  getBatches(currentPage:number,pageSize:number,siteId:number=null): void {

    this.loading = true;
    
    let stockTakeId = this.data.site.currntStockTake.id;
    let x = this.ProductService.getBatchesInStockTakeforSupplier(currentPage,pageSize,siteId,stockTakeId).subscribe(
      (data) => {  
         this.gridData = filterBy(data.items, this.filter);
        this.groupRes=groupBy(this.gridData , this.group);
        this.gridView = {
          data: this.groupRes,
          total: data.totalItems,
        };
        
        this.loading = false;
      }
    );
  }
  getSites(): void {
    this.sellerService.getAllSites().subscribe(
      (data) => {  
        this.dropdownItems=data;
       
      }
    );
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getBatches(this.currentPage,this.pageSize,this.data.site.id);
  }

 
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }
  
  getBatch(batchId: number)
  {
    this.ProductService.getBatchForSeller(batchId).subscribe(result => {
      
      this.loading = true;  
      this.spinner.show();
      this.batchList=[];    
       this.batchList.push(result);
       this.gridView=[...this.batchList]; 
       this.loading = false;
       this.spinner.hide();
    });
  }
  isEditDisabled()
  {
    return (this.selectedUID == null || this.selectedUID.length == 0 || !this.featurePermission.update);
  }
  public rowCallback = (context: RowClassArgs) => {
    const now = new Date().toISOString();
    if (context.dataItem.expiryDate < now) {
      return { gold: true };
    } else {
      return '';
    }
  };
  createStockTake(batch: BatchViewModel, update = false)
  {
    this.spinner.show();
    let model= new StockTakeRecordModel();
    model.batchId=batch.id;
    model.inventoryStockTakeId=this.data.site.currntStockTake.id;
    model.actualStock=batch.stock;
    this.ProductService.createStockTakeRecord(model.inventoryStockTakeId,model,update).subscribe(
      (data) => {  
        this.snackbar.open('Stock updated Successfully', 'close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.initBtaches();
        this.spinner.hide();
      }
    );
  }
  completeStockTake()
  {
    this.spinner.show();
    let stockTakeId=this.data.site.currntStockTake.id;

    this.ProductService.completeStockTake(stockTakeId).subscribe(
      (data) => {  
        this.dialogRef.close();
      }
    );
  }
}