import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import {  ProductType } from 'app/shared/models/catalogue/lockups';
import { NewSizeDialog } from '../dialogs/new-size/new-size.dialog';
import Swal from 'sweetalert2';
import { NewTypeDialog } from '../dialogs/new-type/new-type.dialog';


@Component({
  selector: '[app-types-settings]',
  templateUrl: './types-settings.html',
  styleUrls: ['./types-settings.css']
})
export class TypesSetttingsComponent implements OnInit {
  public gridView: GridDataResult;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  featurePermission: FeaturePermission = new FeaturePermission();
  show: boolean = true;

  constructor(
    public dialog: MatDialog,
    private userService: UserService, private sellerService: SellerService
  ) {
  
  }

  ngOnInit(): void {
    this.loading = true;
    this.init();
  }
  
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SETTINGS )[0];  
  this.show = this.featurePermission.read;
  });
   
}
  async init() {
    this.loading = true;
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    
     this.sellerService.getTypesSettings(this.currentPage,this.pageSize).subscribe((data) => {
       this.gridView = {
         data: data.items,
         total: data.totalItems,
       };
       this.loading = false;
     });
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getGrid();
  }
  
  getGrid()
  {
    this.loading = true;
    this.sellerService.getTypesSettings(this.currentPage,this.pageSize).subscribe((data) => {
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;
    });
  }

  
   newType(editable:boolean=false,model:ProductType=null): void {
    var Data = { editable: editable, model: model }
     const dialogRef = this.dialog.open(NewTypeDialog,{data: Data ,width:'1000px'});
     dialogRef.afterClosed().subscribe(result => {
       this.loading = true;
       this.init();
     });
   }
   deleteType(id:number)
   {
    
    this.loading = true;
    this.sellerService.checkTypeIsExistInProducts(id).subscribe((isExist) => {
      if(!isExist)
      {
        this.sellerService.deleteType(id).subscribe((data) => {
          this.loading = true;
           this.init();
        });
      }
      else
      {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          html: "<strong> The selected Type can not be deleted, as it is being used by other products or batches, please delete any reference to this Size in order to be able to delete it  </strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        }).then((result) => {
          this.loading = true;
          this.init();
        })
      }
       
    });
   }
}

