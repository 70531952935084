import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyobDialog } from 'app/integration/dialogs/myob/myob.dialog';
import { IntegrationService } from 'app/shared/services/integration.service';
import { CompanyFileViewModel } from 'app/shared/models/integration/companyfile.model';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { XeroTenantViewModel } from 'app/shared/models/integration/xerotenant.model';
import { XeroDialog } from 'app/integration/dialogs/xero/xero.dialog';
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { NetsuiteDialog } from '../dialogs/netsuite/netsuite.dialog';

@Component({
  selector: 'app-accounting-integration',
  templateUrl: './accounting-integration.component.html',
  styleUrls: ['./accounting-integration.component.css']
})
export class AccountingIntegrationComponent implements OnInit {
  myobLoginURL: string;
  xeroLoginURL: string;
  myobCode: string;
  apiURL: string;
  isLinkedToMYOB: boolean;
  isLinkedToXero: boolean;
  isLinkedToNetsuite: boolean;
  private handleError: HandleError;
  MyobDialogRef: MatDialogRef<MyobDialog, any>;
  XeroDialogRef: MatDialogRef<XeroDialog, any>;
  NetsuiteDialogRef: MatDialogRef<NetsuiteDialog, any>;
  accountingIsActive: boolean = true;
  emailIsActive: boolean;
  paymentIsActive: boolean;
  isLinkedToMicrosoft: boolean;
  showIntegrations: boolean = true;
  constructor(
    private integrationService: IntegrationService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    httpErrorHandler: HttpErrorHandler,
    private userService: UserService, private sellerService: SellerService
  ) {
    this.myobLoginURL = environment.myobLoingUrl;
    this.xeroLoginURL = environment.xeroLoginUrl;
    this.apiURL = environment.apibaseUrl;
    this.handleError = httpErrorHandler.createHandleError('ChatService');
  }

  loadPermissions(seller: SellerViewModel) {
    this.userService.hasPermissionFor(PermissionsConstants.INTEGRATION, PermissionsConstants.READ, seller.id).subscribe(x => { this.showIntegrations = x });
  }

  ngOnInit(): void {
    this.spinner.show('loading');
    this.route.queryParams
      .subscribe(params => {
        this.myobCode = params.code;
      }
      );
    var code = localStorage.getItem("code");
    var xeroCode = localStorage.getItem("xeroCode");
    if (code) {
      this.authenticateToMyOB(code);
    } else if (xeroCode) {
      this.authenticateToXero(xeroCode);
    } else {
      this.getLinkedApps();
    }
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
  }

  authenticateToMyOB(code: string): any {
    this.integrationService.LinkMYOBAccount(code).subscribe((data) => {
      localStorage.removeItem("code");
      this.spinner.hide('loading');
      this.openCompanyFileDialog(data);
    }, (err: HttpErrorResponse) => {
      console.log(err);
      this.spinner.hide('loading');
      localStorage.removeItem("code");
    });
  }

  authenticateToXero(code: string): any {
    this.integrationService.LinkXeroAccount(code).subscribe((data) => {
      localStorage.removeItem("xeroCode");
      this.spinner.hide('loading');
      this.openXeroTenantDialog(data);
    }, (err: HttpErrorResponse) => {
      console.log(err);
      this.spinner.hide('loading');
      localStorage.removeItem("xeroCode");
    });
  }

  getLinkedApps(): any {
    this.sellerService.getSeller().subscribe((seller) => {
      this.integrationService.GetLinkedApps(seller.id).subscribe((data) => {
        if (data.indexOf("MYOB") >= 0) {
          this.isLinkedToMYOB = true;
        }
        if (data.indexOf("Xero") >= 0) {
          this.isLinkedToXero = true;
        }
        if (data.indexOf("Netsuite") >= 0) {
          this.isLinkedToNetsuite = true;
        }
      });
      this.spinner.hide('loading');
    });
  }

  openCompanyFileDialog(companyFiles: CompanyFileViewModel[]) {
    this.MyobDialogRef = this.dialog.open(MyobDialog, { data: companyFiles });
    this.MyobDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLinkedToMYOB = true;
        this.MyobDialogRef = null;
        this.spinner.show('syncing');
        this.integrationService.SyncMYOBAccount().subscribe((data) => {
          this.spinner.hide('syncing');
        });
      }
    });
  }

  openXeroTenantDialog(tenants: XeroTenantViewModel[]) {
    this.XeroDialogRef = this.dialog.open(XeroDialog, { data: tenants });
    this.XeroDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLinkedToXero = true;
        this.XeroDialogRef = null;
      }
    });
  }
  // create a new dialog for netsuite details AccountID, ConsumerKey, ConsumerSecret, TokenID, TokenSecret
  openNetsuiteDialog() {
    this.NetsuiteDialogRef = this.dialog.open(NetsuiteDialog);
    this.NetsuiteDialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.isLinkedToNetsuite = true;
        this.NetsuiteDialogRef = null;
      }
    });
  }
}
