<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
         
          <div class="card-body">
            <section class="d-flex">
              <mat-label class="mat-body-strong col-md-6">Filter by date:</mat-label>
              <mat-form-field appearance="fill" class="col-md-6">
                <mat-date-range-input [rangePicker]="rangePicker">
                  <input matStartDate placeholder="Start date" [(ngModel)]="startDate">
                  <input matEndDate placeholder="End date" [(ngModel)]="endDate">
                </mat-date-range-input>
                <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                <mat-date-range-picker #rangePicker color="primary">
                  <mat-date-range-picker-actions>
                    <button mat-button matDateRangePickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDateRangePickerApply
                      (click)="getOrders(1,pageSize)">Apply</button>
                  </mat-date-range-picker-actions>
                </mat-date-range-picker>
              </mat-form-field>
            </section>
            <section class="d-flex">
              <mat-label class="mat-body-strong col-md-6">Filter by Status:</mat-label>
              <mat-form-field appearance="fill" class="col-md-6">
                <mat-select [(ngModel)]="statusFilter" name="Region" (selectionChange)="getOrders(1,pageSize)">
                  <mat-option value="-1" selected>All</mat-option>
                  <mat-option value="2">Awating Confirmation</mat-option>
                  <mat-option value="4">Confirmed</mat-option>
                  <mat-option value="8">Rejected</mat-option>
                  <mat-option value="11">Fulfilled</mat-option>
                </mat-select>
              </mat-form-field>
            </section>

            <kendo-grid [data]="gridView" [loading]="loading"  [pageSize]="pageSize" [skip]="skip" [pageable]="true" [resizable]="true"
            [groupable]="false"
            [group]="groups"
            
            (groupChange)="groupChange($event)"
            (pageChange)="pageChange($event)">

            <kendo-grid-column [hidden]="true" field="orderDate" title="Order Date" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="150">                
              </kendo-grid-column>

              <kendo-grid-column field="data" title="Order Id" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button class="nav-link" matTooltip="Order details" (click)="opendialog(dataItem)">
                    {{dataItem.orderId}}
                    <div class="ripple-container"></div>
                  </a>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Printouts" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <button [class]="hasInvoice(dataItem.invoiceURL)" mat-raised-button type="button" matTooltip="Invoice" [matTooltipPosition]="'above'"
                    class="btn btn-primary btn-link btn-sm btn-just-icon" (click)="openInvoiceDialog(dataItem)">
                    <i class="material-icons">list</i>
                  </button>
                  <button [class]="hasInvoice(dataItem.pickupSlipURL)" mat-raised-button type="button" matTooltip="Pick slip" [matTooltipPosition]="'above'"
                    class="btn btn-success btn-link btn-sm btn-just-icon" (click)="openPickSlipdialog(dataItem)">
                    <i class="material-icons">view_in_ar</i>
                  </button>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Customer" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button class="nav-link" (click)="opendialog(dataItem)">{{dataItem.customerName}}</a>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Line Items" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button class="nav-link">
                    {{dataItem.orderItemsCount}}</a>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Total Price" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button class="nav-link">
                    {{dataItem.orderItemsTotalPrice? "$" + dataItem.orderItemsTotalPrice : "TBC"}}
                    <div class="ripple-container"></div>
                  </a>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Payment" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button class="nav-link">
                    {{dataItem.payment | titlecase}}
                    <div class="ripple-container"></div>
                  </a>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Delivery" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button class="nav-link">
                    {{dataItem.deliveryType | titlecase}}
                    <div class="ripple-container"></div>
                  </a>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Source" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button class="nav-link">
                    {{dataItem.source}}
                    <div class="ripple-container"></div>
                  </a>

                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Order Status" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="150">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <a mat-button [class]="getclass(dataItem.orderStatus)">
                    {{dataItem.orderStatus}}
                  </a>                  
                </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Actions" class='k-text-center font-weight-bold'
                headerClass="k-text-center font-weight-bold" [width]="100">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">                  
                  <a mat-button class="btn btn-success btn-sm" (click)="openFulfillDialog(dataItem)"
                    *ngIf="dataItem.orderStatus == 'Confirmed' || dataItem.orderStatus == 'Partially Fulfilled'">
                    Fulfill Order
                  </a>
                </ng-template>
              </kendo-grid-column>

            </kendo-grid>


            <div *ngIf="TrackingOrders?.length === 0">No records found</div>
            <div *ngIf="!showOrders">
              <img src="/assets/img/403.png">
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading ... </p>
</ngx-spinner>