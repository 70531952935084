<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-header card-header-tabs card-header-success">
                        
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">

                                <ul class="nav nav-tabs" data-tabs="tabs">

                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="active-customers" routerLinkActive="active"> <i
                                                class="material-icons">done_all</i> Active Customers</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="deactive-customers" routerLinkActive="active"><i
                                                class="material-icons"> visibility_off</i> Deactivated Customers</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="new-customers" routerLinkActive="active"><i
                                                class="material-icons">eco</i> New Customers</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="rejected-customers" routerLinkActive="active"><i
                                                class="material-icons">close</i> Rejected Customers</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="pending-import-customers"
                                            routerLinkActive="active"> <i class="material-icons">hourglass_empty</i>
                                            Pending Imports</a>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="card-body">          
                                <button mat-raised-button matTooltip="Add New Customer" [matTooltipPosition]="'above'"
                            class="btn btn-primary btn-round" (click)="opendialog()">
                            <i class="material-icons">note_add</i> Add New Customer

                        </button>
                            </div>
                          </div>
                        
                        <div class="tab-content">
                            <router-outlet></router-outlet>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading ... </p>
</ngx-spinner>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" name="pushing" type="square-jelly-box"
    [fullScreen]="true">
    <p style="color: white"> Pushing Customer ... </p>
</ngx-spinner>