<div class="tab-pane active" id="profile">
    <kendo-grid [data]="gridView" [loading]="loading"  [pageSize]="pageSize" [skip]="skip" [pageable]="true" [resizable]="true"
    [groupable]="{ showFooter: true }"
    [group]="groups"
    (pageChange)="pageChange($event)">

   
    
    <kendo-grid-column [hidden]="true" field="customerInOrder.customerName" title="Customer Name" class='k-text-center font-weight-bold'
        headerClass="k-text-center font-weight-bold" [width]="150">
        
      </kendo-grid-column>
      <kendo-grid-column field="lineItemInOrder.orderId" title="Order No" class='k-text-center font-weight-bold'
        headerClass="k-text-center font-weight-bold" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <strong>{{dataItem.lineItemInOrder.orderId}}</strong>

        </ng-template>
      </kendo-grid-column>
      
      <kendo-grid-column field="productInOrder" title="Product" class='k-text-center font-weight-bold'
        headerClass="k-text-center font-weight-bold" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
            <strong>{{dataItem.productInOrder.category}} - {{dataItem.productInOrder.varietal}}</strong>
        </ng-template>
      </kendo-grid-column>
      <kendo-grid-column field="productInOrder.grade" title="Grade" class='k-text-center font-weight-bold'
      headerClass="k-text-center font-weight-bold" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
           <strong>{{dataItem.productInOrder.grade}} </strong>
      </ng-template>
    </kendo-grid-column>
      <kendo-grid-column field="lineItemInOrder.orderDate " title="Date" class='k-text-center font-weight-bold'
      headerClass="k-text-center font-weight-bold" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <strong>{{dataItem.lineItemInOrder.orderDate | date: 'dd/MM/yyyy'}}</strong>
      </ng-template>
    </kendo-grid-column>
      
    <kendo-grid-column field="lineItemInOrder.soldPrice" title="Unit Price" class='k-text-center font-weight-bold'
    headerClass="k-text-center font-weight-bold" [width]="150">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <strong *ngIf="dataItem.lineItemInOrder.soldPrice">${{dataItem.lineItemInOrder.soldPrice}}</strong>
        <strong *ngIf="! dataItem.lineItemInOrder.soldPrice">TBC</strong>
    </ng-template>
    <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates">
        Total Price: {{ aggregates.orderItemTotalPrice.sum }}
      </ng-template>
  </kendo-grid-column>
  <kendo-grid-column field="productInOrder.stock" title="In Stock" class='k-text-center font-weight-bold'
  headerClass="k-text-center font-weight-bold" [width]="150">
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">                                
    <strong> {{dataItem.productInOrder.isInfinityStock ? '∞' : dataItem.productInOrder.stock}} </strong>
  </ng-template>
</kendo-grid-column>
<kendo-grid-column field="lineItemInOrder.approvedQuantity" title="Quantity" class='k-text-center font-weight-bold'
headerClass="k-text-center font-weight-bold" [width]="150">
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <strong>{{dataItem.lineItemInOrder.approvedQuantity}}</strong>
</ng-template>
</kendo-grid-column>

<kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
headerClass="k-text-center font-weight-bold" [width]="150">
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
<ng-template kendoGridGroupHeaderColumnTemplate>
   
        <button mat-raised-button matTooltip="Confirm All Order Items" [matTooltipPosition]="'above'" class="btn btn-success btn-link btn-sm"  (click)="ConfirmAllProductsforCustomer(dataItem.customerInOrder)">
<i class="material-icons">add_task </i> Confirm All

</button>
    
  </ng-template>
<div class="row">
    
    <div class="col-md-3">
        
        <button mat-raised-button type="button" [disabled]="!featurePermission.update" matTooltip="Set Unit Price" id="setUnitPrice" 
        [matTooltipPosition]="'above'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-primary btn-link btn-sm btn-just-icon">
            <i class="material-icons">attach_money</i>                                
        </button>
        <div class="dropdown-menu  price-dropdown-position " aria-labelledby="updateProduct">
            <div class="container text-center">
                <input matInput type="number" min="1" style="width:100px;left: -50px !important;"  [(ngModel)]="dataItem.lineItemInOrder.updatedSoldPrice">                                                                                            
                <button mat-raised-button type="button"  class="btn btn-success btn-link btn-sm btn-just-icon" [disabled]='! dataItem.lineItemInOrder.updatedSoldPrice || !featurePermission.update' (click)="setUnitPrice(dataItem,dataItem.lineItemInOrder.updatedSoldPrice)">
                    <i class="material-icons">done_all</i>
                </button>      
            </div>
        </div>
    </div>
    <div class="col-md-3">
        
        <button mat-raised-button type="button" [disabled]="!featurePermission.update" matTooltip="Update Units" id="updateProduct" [matTooltipPosition]="'above'"  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="btn btn-primary btn-link btn-sm btn-just-icon">
            <i class="material-icons">edit</i>
        </button>
        <div class="dropdown-menu  quantity-dropdown-position " aria-labelledby="updateProduct">
            <div class="container text-center">
                <input matInput type="number" min="1" style="width:100px;left: -60px !important;" [(ngModel)]="dataItem.lineItemInOrder.updatedQuantity">
                <button mat-raised-button type="button" class="btn btn-success btn-link btn-sm btn-just-icon" [disabled]='(lessThan(dataItem.productInOrder.stock,dataItem.lineItemInOrder.updatedQuantity) && ! dataItem.productInOrder.isInfinityStock  || !featurePermission.update)' (click)="ChangeRequestedQuantity(dataItem,dataItem.lineItemInOrder.updatedQuantity)">
                    <i class="material-icons">done_all</i>
                </button>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <button mat-raised-button type="button"  matTooltip="Confirm" [disabled]="(lessThan(dataItem.productInOrder.stock,dataItem.lineItemInOrder.approvedQuantity) && ! dataItem.productInOrder.isInfinityStock) || ! dataItem.lineItemInOrder.soldPrice || !featurePermission.update" [matTooltipPosition]="'above'" class="btn btn-success btn-link btn-sm btn-just-icon" (click)="updateBuyerOrder(true,dataItem)">
            <i class="material-icons">done_all</i>
        </button>
    </div>
    <div class="col-md-3">

        <button mat-raised-button type="button" [disabled]="!featurePermission.update" matTooltip="reject order item" [matTooltipPosition]="'above'" class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="updateBuyerOrder(false,dataItem)">
            <i class="material-icons">close</i>
        </button>
    </div>
    
</div>
    
    


    


</ng-template>
</kendo-grid-column>
    </kendo-grid>
</div>