<div mat-dialog-content *ngIf="isShow">
  <div>
    <a [href]="pdfSrc" class="btn btn-primary btn-lg active pdf-btn" role="button" aria-pressed="true">
      <i class="fa fa-download"></i>
    </a>
    <pdf-viewer [src]="pdfSrc"
  [render-text]="false"
  [original-size]="true"
  [fit-to-page]="true"

  ></pdf-viewer>
</div>


</div>