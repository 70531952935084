import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { IntegrationService } from 'app/shared/services/integration.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-webhook-api',
  templateUrl: './webhook-api.component.html',
  styleUrls: ['./webhook-api.component.css']
})
export class WebhookAPIComponent implements OnInit {
  hidePassword: boolean = true;
  password: string = '';

  constructor(
    public dialog: MatDialog, private route: ActivatedRoute, private router: Router,
    private snackbar: MatSnackBar,private integrationService:IntegrationService
    ,private spinner: NgxSpinnerService,
  ) {

  }

  ngOnInit(): void {
    this.spinner.show();
    this.integrationService.getApiKey().subscribe((result) => {
      this.password=result.data;
      this.spinner.hide();
  })

}
  togglePasswordVisibility() {
    this.hidePassword = !this.hidePassword;
  }

}

