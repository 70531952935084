import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import {  DataBindingDirective } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';


@Component({
  selector: 'app-new-subscription',
  templateUrl: './new-subscription.dialog.html',
  styleUrls: ['./new-subscription.dialog.css']
})
export class NewSubscriptionDialog implements OnInit {
  public opened = true;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(public dialogRef: MatDialogRef<NewSubscriptionDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public dialogdata: any,public dialog: MatDialog,
    private sellerService: SellerService
  ) {

  }

  ngOnInit(): void {
  
  }



  public newSubscribe(): void {
    this.sellerService.newSubscribe().subscribe(res => {
      Swal.fire({
        icon: 'success',
        title: 'Success',
        html: "Subscription has been done!",
        showCloseButton: false,
        showCancelButton: false,
        confirmButtonColor: "#0c6acb",
        timer:3000
      }).then(()=>{
        this.dialogRef.close();
        window.open(environment.sellerWebUrl,"_self");
      
      });
    });
  }
  
}
