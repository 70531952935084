import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { HttpClientModule } from '@angular/common/http';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';

import { RouterModule, Routes } from '@angular/router';


// import { AppRoutingModule } from 'app/app.routing';
import { ComponentsModule } from 'app/layouts/components/components.module';


import { AppComponent } from './app.component';
import { NgChatModule } from 'ng-chat';

import { SharedModule } from './shared/shared.module';

import { AdminLayoutModule } from './layouts/admin-layout/admin-layout.module';
import { httpInterceptorProviders } from 'app/base/http-interceptors/index'
import { HttpErrorHandler } from './base/http-error-handler.service';
import { MessageService } from './base/message.service';
import { AuthService } from './base/auth.service';
import { RequestCache, RequestCacheWithMap } from './base/request-cache.service';

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { b2cPolicies, apiConfig } from 'app/config/app-config';
import { BrowserModule } from '@angular/platform-browser';

// import { LoginComponent } from './login/login.component';
import { EditorModule } from '@progress/kendo-angular-editor';
import { MaterialdModule } from './shared/material/material.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';

import { NgxSpinnerModule } from "ngx-spinner";
import { DialogService } from '@progress/kendo-angular-dialog';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import 'hammerjs'
import { environment } from 'environments/environment';
import { SellerService } from './shared/services/Seller.Service';
import { DataService } from './shared/services/data.service';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {

}

export function MSALInstanceFactory(): IPublicClientApplication {
  // let myMSALObj= new PublicClientApplication({
  //   auth: {
  //     clientId: environment.clientId,
      
  //     authority: environment.signUpSignInAuthority,
  //     redirectUri: '/',
  //     postLogoutRedirectUri: '/',
  //     knownAuthorities: [environment.authorityDomain]
  //   },
  //   cache: {
  //     cacheLocation: BrowserCacheLocation.LocalStorage,
  //     storeAuthStateInCookie: isIE, 
       
  //   },
  //   system: {
  //     allowRedirectInIframe: true,
  //     loggerOptions: {
  //       loggerCallback,
  //       logLevel: LogLevel.Info,
  //       piiLoggingEnabled: false
  //     }
  //   }
  // });  
  // return myMSALObj;
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.signUpSignInAuthority,
      redirectUri: '/',
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
      knownAuthorities: [environment.authorityDomain]
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,//BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: true
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) { return; }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
          }
        }
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(environment.apibaseUrl, [environment.scope]);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  let invitationCode=localStorage.getItem("invitationCode");
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      // scopes: [...apiConfig.b2cScopes],
      scopes: [environment.scope],
      state:invitationCode
    },
  };
}

@NgModule({
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'My-Xsrf-Cookie',
      headerName: 'My-Xsrf-Header',
    }),
    RouterModule,
    MsalModule,
    ComponentsModule,
    NgChatModule,
    CoreModule,
    AdminLayoutModule,
    // ReactiveFormsModule,
    SharedModule.forRoot()


  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    { provide: RequestCache, useClass: RequestCacheWithMap },
    httpInterceptorProviders,
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
      // deps: [environment]
    },
    AuthService,
    HttpErrorHandler,
    DialogService,
    ChartsModule,
    ButtonsModule,
    MessageService,
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DataService
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
