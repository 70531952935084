<mat-card>
  <mat-card-header class="mb-3">        
    <mat-card-title class="cardHeader"> Manage Permissions for {{user.givenName}} {{user.surname}}</mat-card-title>
  </mat-card-header>       
  <mat-dialog-content>
    <section class="d-flex">
      <div class="col-md-12">
        <span>Tick all</span>
        <kendo-switch  [(ngModel)]="tickAll" (click)="handleTickAllSwitch()" class="pl-5" onLabel="Yes" offLabel="No">
        </kendo-switch>
      </div>
    </section>
    <section class="d-flex">
      <div class="col-md-12">
          <kendo-grid
            [kendoGridBinding]="gridView"
            [loading]="loading" 
            [pageSize]="20"
            [pageable]="true"
            [sortable]="true"
            [groupable]="false"
            [reorderable]="true"
            [resizable]="true"
            [height]="500"
            [columnMenu]="{ filter: true }"        
            >            
                
            <kendo-grid-column field="feature" title="Feature" [width]="220" visibility="false">
            </kendo-grid-column>        
            <kendo-grid-column field="create" title="Create" [width]="220" >          
              <ng-template kendoGridCellTemplate let-dataItem>
                <div><mat-checkbox class="pt-3" labelPosition="after" (change)="handlePermissionChange()" [(ngModel)]="dataItem.create" color="primary"></mat-checkbox></div>
              </ng-template>
            </kendo-grid-column>     
            <kendo-grid-column field="read" title="Read" [width]="220">          
              <ng-template kendoGridCellTemplate let-dataItem>
                <div><mat-checkbox class="pt-3" labelPosition="after" (change)="handlePermissionChange()" [(ngModel)]="dataItem.read" color="primary"></mat-checkbox></div>
              </ng-template>
            </kendo-grid-column>                         
            <kendo-grid-column field="update" title="Update" [width]="220">   
              <ng-template kendoGridCellTemplate let-dataItem>
                <div><mat-checkbox class="pt-3" labelPosition="after" (change)="handlePermissionChange()" [(ngModel)]="dataItem.update" color="primary"></mat-checkbox></div>
              </ng-template>       
            </kendo-grid-column>        
            <kendo-grid-column field="delete" title="Delete" [width]="220">  
              <ng-template kendoGridCellTemplate let-dataItem>
                <div><mat-checkbox class="pt-3" labelPosition="after" (change)="handlePermissionChange()" [(ngModel)]="dataItem.delete" color="primary"></mat-checkbox></div>
              </ng-template>        
            </kendo-grid-column>                      
          </kendo-grid> 
      </div>
    </section>
  </mat-dialog-content>
  <mat-card-actions >
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="saveSettings()" cdkFocusInitial>Save</button>
  </mat-card-actions>
</mat-card>