<div class="scrollable-content">

<kendo-grid id="my-grid" [data]="gridView" [rowClass]="rowCallback" [loading]="loading"  [pageSize]="pageSize" [skip]="skip" [pageable]="true" [resizable]="true"
[groupable]="{ showFooter: true }" 
[selectable]="selectableSettings"
kendoGridSelectBy="id"
[kendoGridSelectBy]="mySelectionKey"
(selectedKeysChange)="keyChange($event)"
[group]="group"
[filterable]="true"
      [filter]="filter"
      (filterChange)="filterChange($event)"
 
(pageChange)="pageChange($event)">
  

<kendo-grid-column [hidden]="true" field="product.category.categoryName" title="Product" class='k-text-center font-weight-bold'
    headerClass="k-text-center font-weight-bold" [width]="50">
    
  </kendo-grid-column>
 
  <kendo-grid-column [hidden]="true"  field="id" title="ID" [width]="220" visibility="false">
  </kendo-grid-column>       
  <kendo-grid-column title="#" [width]="100">
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
      {{ rowIndex+1 }}
    </ng-template>
  </kendo-grid-column>         
  <kendo-grid-column field="number" title="Number" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">
  </kendo-grid-column>        
  <kendo-grid-column field="date" title="Date" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold" >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.date | date: 'dd/MM/yyyy'}} 
    </span>
  </ng-template>
</kendo-grid-column>   
<kendo-grid-column field="expiryDate" title="Expiry Date" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold" >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.expiryDate | date: 'dd/MM/yyyy'}} 
    </span>
  </ng-template>
</kendo-grid-column>  
  <kendo-grid-column field="format" title="Unit of Measure" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold" >          
  </kendo-grid-column>     
  <kendo-grid-column field="grade.grade" title="Grade" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>                         
  <kendo-grid-column field="grower.growerName" title="Grower" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>                                             
  <kendo-grid-column field="region" title="Region" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="siteName" title="Site" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="zoneName" title="Zone" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="siteName" title="Ripeness" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.productRipeness?.ripeness}}
    </span>
  </ng-template>  
  </kendo-grid-column>
  <kendo-grid-column field="siteName" title="Size" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.productSize?.size}}
    </span>
  </ng-template>  
  </kendo-grid-column>
  <kendo-grid-column field="siteName" title="UoM" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.productUom?.uom}}
    </span>
  </ng-template>  
  </kendo-grid-column>
  <kendo-grid-column field="unitPrice" title="Unit Price" format="{0:c2}" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
 
  <kendo-grid-column field="stockTakeCompletionDate" title="Completion Date" [sticky]="true" [filterable]="false" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <span>
        {{dataItem.stockTakeCompletionDate | date: 'dd/MM/yyyy H:mm'}} 
      </span>
    </ng-template>         
  </kendo-grid-column>   

  <kendo-grid-column field="stock" title="Quantity" [sticky]="true" [filterable]="false" [width]="200">
    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
      <kendo-numerictextbox [disabled]="dataItem.infinityStock" [(ngModel)]="dataItem.stock" format="##"
       [min]="0" 
        class="form-control">
      </kendo-numerictextbox>

    </ng-template>
  </kendo-grid-column>   

  
<kendo-grid-column field="data" title="" class='k-text-center font-weight-bold' [sticky]="true" [filterable]="false"
headerClass="k-text-center font-weight-bold" [width]="150">
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  <button mat-raised-button type="button" matTooltip="Stock Take" [matTooltipPosition]="'above'" *ngIf="! dataItem.stockTakeCompletionDate"
     class="btn btn-primary " (click)="createStockTake(dataItem)" [disabled]="dataItem.infinityStock" 
    >
    Update Stock
  </button>
  <button mat-raised-button type="button" matTooltip="Stock Take" [matTooltipPosition]="'above'" *ngIf="dataItem.stockTakeCompletionDate"
     class="btn btn-primary " (click)="createStockTake(dataItem, true)"
    >
    Stock Updated
  </button>
  
</ng-template>
</kendo-grid-column>

</kendo-grid>
<mat-card-actions align="end">
  <button mat-raised-button type="button" matTooltip="Complete Stock Take" [matTooltipPosition]="'above'"
     class="btn btn-success " (click)="completeStockTake()"
    >
    Complete Stock Take
  </button>
</mat-card-actions>
</div>