import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductLineItemDetails } from 'app/shared/models/order/PlainProductInOrders.Model';
import { OrderService } from 'app/shared/services/order.service';
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { aggregateBy } from "@progress/kendo-data-query";
import { SellerService } from 'app/shared/services/Seller.Service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller';
import { BuyerViewModel } from 'app/shared/models/promotions/marketing.model';
import { DecimalPipe } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerModel } from 'app/shared/models/customer/customer.model';

@Component({
  selector: 'invoice-dialog',
  templateUrl: './invoice.dialog.html',
  styleUrls: ['./invoice.dialog.css']

})
export class InvoiceDialog implements OnInit {
  ProductLineItemsDetails: ProductLineItemDetails[] = [];
  Seller: SellerViewModel = null;
  Customer: CustomerModel = null;
  Supplier: SupplierViewModel = null;
  currentDate: Date = new Date();
  invoiceTotal: number = 0;
  pdfSrc: string;
  isShow: boolean = false;


  constructor(public dialogRef: MatDialogRef<InvoiceDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService, private spinner: NgxSpinnerService,
    private sellerservice: SellerService
  ) {
  }
  ngOnInit(): void {
    this.spinner.show();
    this.orderService.GetOrderLineItemsDetails(this.data.orderId).
      subscribe((result) => {
        this.pdfSrc = result[0].lineItemInOrder.invoiceUrl;
        this.isShow = true;
        this.spinner.hide();
      }
      );
  }

  onLoadComplete(): void {
    this.isShow = true;
  }
}
