import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyFileViewModel } from 'app/shared/models/integration/companyfile.model';
import { IntegrationService } from 'app/shared/services/integration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { XeroTenantViewModel } from 'app/shared/models/integration/xerotenant.model';
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';

@Component({
  selector: 'xero-dialog',
  templateUrl: './xero.dialog.html',
  styleUrls: ['./xero.dialog.css']

})
export class XeroDialog implements OnInit {
  tenants: XeroTenantViewModel[];
  selectedTenantId: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  featurePermission: FeaturePermission;
  

  constructor(public dialogRef: MatDialogRef<XeroDialog>,
    @Inject(MAT_DIALOG_DATA) public data: [XeroTenantViewModel],
    private integrationService: IntegrationService,    
    private snackbar: MatSnackBar,
    private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    private userService: UserService, private sellerService: SellerService
  ) { 
    this.tenants = data;
    this.selectedTenantId = data[0].tenantId;
  }
  ngOnInit(): void {
    //this.loadBuyer();
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
  }
  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission=userPermissions.filter(x => x.feature == PermissionsConstants.INTEGRATION )[0];
  
    });
  }
  saveSettings() {
    this.integrationService.UpdateXeroAccount(this.selectedTenantId).subscribe((response) => {       
      this.snackbar.open('Company file updated successfully', 'close', {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.dialogRef.close({ data: response});
    });

  }

}
