<mat-card>
  <mat-card-header class="mb-3">


    <mat-card-title class="cardHeader"> Transfer Batch</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex">
      <mat-form-field class="col-md-6">
        <mat-label>Current Site</mat-label>
        <input matInput type="text" disabled [value]="batch.siteName"  />
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>New Site</mat-label>
        <mat-select [(ngModel)]="batch.siteId" name="Site" required (selectionChange)="loadZones($event.value)">
          <mat-option value="-1" selected>Please select Site</mat-option>
          <mat-option *ngFor="let s of Sites" [value]="s.id">
            {{s.name}}
          </mat-option>
        </mat-select>
     
      </mat-form-field>
    </section>    
    <section class="d-flex">
      <mat-form-field class="col-md-6">
        <mat-label>Current Zone</mat-label>
        <input matInput type="text" disabled [value]="batch.zoneName">
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>New Zone</mat-label>
        <mat-select [(ngModel)]="this.batchTransferModel.destinationZoneId" required name="Zone">
          <mat-option value="-1" selected>Please select Zone</mat-option>
          <mat-option *ngFor="let z of Zones" [value]="z.id">
            {{z.name}}
          </mat-option>
        </mat-select>
  
      </mat-form-field>       
    </section>
  

  </mat-card-content>
  <mat-card-actions align="end">
    <mat-error *ngIf="showErrors">
      Please enter all mandatory fields
    </mat-error>
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="saveOrUpdateBatch()" [disabled]="!formIsValid()"  cdkFocusInitial>Save</button>
  </mat-card-actions>
</mat-card>
