import { Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { DataBindingDirective, RowArgs, RowClassArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { BatchDialog } from 'app/inventory/dialogs/batch/batch.dialog';
import { UploadService } from 'app/shared/services/upload.service';
import { NgxSpinnerService } from "ngx-spinner";
import { WasteBatchDialog } from '../dialogs/waste-batch/waste-batch.dialog';
import { ProductRipeness, ProductSize, ProductType, ProductUoM } from 'app/shared/models/catalogue/lockups';
import { ChildBatchProductsDialog } from '../dialogs/child-batch-products/child-batch-products.dialog';
import { ChildBatchesDialog } from '../dialogs/child-batches/child-batches.dialog';
import Swal from 'sweetalert2';
import { SellerService } from 'app/shared/services/Seller.Service';
import { DashBoardService } from 'app/shared/services/dashboard.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'dialogs-product',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './product.dialog.html',
  styleUrls: ['./product.dialog.css']
})
export class ProductDialog implements OnInit {
  // @ViewChild('productForm') productForm!: NgForm;
  loadingPanelVisible = true;
  productname: FormControl;
  region: FormControl;
  threshold: number;
  minDate = new Date();
  onpromotion: boolean = false;
  showSizeMessage: boolean = false;
  ProductCategories: ProductCategory[];
  ProductGrades: ProductGrade[];
  ProductGrowers: ProductGrower[];
  ProductSizes: ProductSize[];
  ProductRipness: ProductRipeness[];
  ProductUoM: ProductUoM[];
  ProductVaritals: ProductVarital[];
  ProductTypes: ProductType[];
  Products: ProductViewMdel[];
  BatchDialogRef: MatDialogRef<BatchDialog, any>;

  public stockLevels: number[];
  public months: string[];
  public gridView: any[];
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID: string = '';
  public loading: boolean = false;
  public showErrors: boolean = false;
  public NewVarietalName = "";
  public isSingleTrackDisabled: boolean;
  public selectableSettings: SelectableSettings;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('productForm', { static: true }) productForm: NgForm;
  constructor(
    public dialogRef: MatDialogRef<ProductDialog>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: { editmode: boolean, Product: ProductViewMdel },
    private ProductService: ProductService, private SellerService: SellerService, private uploadService: UploadService,
    public dialog: MatDialog, private dashBoardService: DashBoardService, private snackbar: MatSnackBar,
  ) { }


  ngOnInit(): void {
    this.productname = new FormControl(this.dialogdata?.Product?.category?.categoryName, [Validators.required]);
    //grade = new FormControl(this.dialogdata.Product.grade.grade, [Validators.required]);
    this.region = new FormControl(this.dialogdata?.Product?.region, [Validators.required]);
    //Varietal = new FormControl(this.dialogdata.Product.varietal?.varietalName, [Validators.required]);
    if (this.dialogdata.editmode) {
      this.isSingleTrackDisabled = true;
    }
    this.threshold = this.dialogdata?.Product?.threshold;
    this.loadLockups();
    this.setSelectableSettings();
    this.loadBatches();
    this.calculateStock();
    if (this.dialogdata?.Product?.id > 0) {
      console.log(this.dialogdata);
      this.loadProjectedStockLevelChart();
    }
  }

  loadProjectedStockLevelChart() {
    this.SellerService.getSeller().subscribe(seller => {
      this.dashBoardService.GetFutureProjectedStockLevelValues(seller.id, this.dialogdata?.Product?.id).subscribe(stockLevelModel => {
        this.stockLevels = stockLevelModel.map(item => item.stockLevelValue);
        this.months = stockLevelModel.map(item => { console.log(JSON.stringify(item.date)); return item.date.toString().slice(4, 6) });
        this.loadingPanelVisible = false;
      });
    });
  }

  loadBatches() {
    console.log(this.dialogdata.Product.batches)
    //this.dialogdata.Product.batches.forEach(x => x.stockFormatted = x.stock == -1 ?  '∞' : x.stock.toString());
    this.gridData = this.dialogdata.Product.batches;
    this.gridView = this.gridData;
  }
  loadLockups(): void {

    this.ProductService.getProductGrowers().subscribe((result) => {
      this.ProductGrowers = result;

    });
    this.ProductService.getProductSizes().subscribe((result) => {
      this.ProductSizes = result;

    });
    this.ProductService.getProductRipeness().subscribe((result) => {
      this.ProductRipness = result;

    });
    this.ProductService.getProductUoM().subscribe((result) => {
      this.ProductUoM = result;

    });
    this.ProductService.getProductGrades().subscribe((result) => {
      this.ProductGrades = result;
    });
    this.ProductService.getProductCategories().subscribe((result) => {
      this.ProductCategories = result;
      if (this.dialogdata.Product.productCategoryId != null) {
        this.loadvarital(this.dialogdata.Product.productCategoryId);
        this.loadTypes(this.dialogdata.Product.productCategoryId);
      }
    });

  }
  loadvarital(categoryId: any) {
    this.ProductService.GetProductVarietals(categoryId).subscribe((result) => {
      this.ProductVaritals = result;
    });
  }

  addNewVarietal() {
  }

  loadTypes(categoryId: any) {
    this.ProductService.GetProductTypes(categoryId).subscribe((result) => {
      this.ProductTypes = result;
    });
  }

  onSelectValueChange() {

  }
  calculateStock() {
    var totalStock = 0;
    this.dialogdata.Product.batches?.forEach(x => totalStock += parseInt(x.stock.toString()));
    var infinityStockBatch = this.dialogdata.Product.batches?.find(x => x.infinityStock);
    this.dialogdata.Product.stockFormatted = infinityStockBatch ? '∞' : totalStock.toString();
  }
  isEditDisabled() {
    return (this.selectedUID == null || this.selectedUID.length == 0 || this.dialogdata.Product.batches.filter(x => x.id == parseInt(this.selectedUID[0]))[0].infinityStock || this.dialogdata.Product.tradedBySingleBatch);
  }

  isAddingNewDisabled() {
    return this.dialogdata.Product.batches.find(x => x.infinityStock) || this.dialogdata.Product.tradedBySingleBatch;
  }
  formIsValid() {
    if (!this.dialogdata.Product.productCode ||
      !this.dialogdata.Product.discription ||
      !this.dialogdata.Product.productCategoryId || this.dialogdata.Product.productCategoryId == -1 ||
      !this.dialogdata.Product.productUoMId || this.dialogdata.Product.productUoMId == -1 || Number(this.dialogdata.Product.minQuantity) > Number(this.dialogdata.Product.maxQuantity)
      || (Number(this.dialogdata.Product.stock) < 1 && this.dialogdata.Product.tradedBySingleBatch)
    ) {
      return false;
    }
    return true;
  }
  saveOrUpdateProduct() {

    if (!this.dialogdata.Product.productCode ||
      !this.dialogdata.Product.discription ||
      !this.dialogdata.Product.productCategoryId || this.dialogdata.Product.productCategoryId == -1 ||
      !this.dialogdata.Product.productUoMId || this.dialogdata.Product.productUoMId == -1
      || this.dialogdata.Product.minQuantity > this.dialogdata.Product.maxQuantity
      || (this.dialogdata.Product.stock < 1 && this.dialogdata.Product.tradedBySingleBatch)
    ) {
      this.showErrors = true;
      return;
    }
    this.spinner.show();
    if (this.dialogdata.editmode) {
      this.ProductService.UpdateProduct(this.dialogdata.Product).subscribe((result) => {
        if (result != null) {
          this.snackbar.open('Product Updated Successfully', 'close', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
          this.dialogRef.close(true);
        }
        else {
          this.snackbar.open('Failed to update Product ', 'close', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
          this.dialogRef.close(true);
        }

      }, (error) => {
        this.snackbar.open(`Failed to update product due to error ${error.error}`, 'close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.dialogRef.close(true);

      });
    }
    else {
      this.ProductService.CreateProuctForSupplier(this.dialogdata.Product).subscribe((result) => {
        if (result != null) {
          this.snackbar.open('Product Created Successfully', 'close', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
          this.dialogRef.close(true);
        }
        else {
          this.snackbar.open('Failed to create Product ', 'close', {
            duration: 5000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
          this.dialogRef.close(true);
        }

      }, (error) => {
        this.snackbar.open(`Failed to create product due to error ${error.error}`, 'close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.dialogRef.close(true);
      });
    }
  }

  getErrorMessage() {
    if (this.productname.hasError('required')) {
      return 'You must enter a value';

    }
  }
  formatLabel(value: number) {
    if (value <= 100) {
      return value + '%';
    }

    return value;
  }
  updateSetting(event) {

    this.dialogdata.Product.stockThreshold = Math.round((this.dialogdata.Product.threshold * this.dialogdata.Product.stock) / 100)
  }

  addNewBatch(): any {
    var Data = { editmode: false, Product: this.dialogdata.Product }
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
      this.loading = true;
      setTimeout(() => this.ProductService.getProductBatches(this.dialogdata.Product.id).subscribe(
        (data) => {
          this.gridData = data;
          this.gridView = this.gridData;
          this.loading = false;
          this.dialogdata.Product.batches = this.gridView;
          this.calculateStock();
        }
      ), 1000);
    });
  }

  editBatch(): any {
    var selectedBatch = this.dialogdata.Product.batches.filter(x => x.id == parseInt(this.selectedUID[0]))[0];
    var Data = { editmode: true, Product: this.dialogdata.Product, Batch: selectedBatch }
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
      this.loading = true;
      this.ProductService.getProductBatches(this.dialogdata.Product.id).subscribe(
        (data) => {
          this.gridData = data;
          this.gridView = [...this.gridData];
          this.loading = false;
          this.calculateStock();
        }
      );
    });
  }

  wasteBatch(): any {
    var selectedBatch = this.dialogdata.Product.batches.filter(x => x.id == parseInt(this.selectedUID[0]))[0];
    var Data = { Batch: selectedBatch }
    const batchDialog = this.dialog.open(WasteBatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
      this.loading = true;
      this.ProductService.getProductBatches(this.dialogdata.Product.id).subscribe(
        (data) => {
          this.gridData = data;
          this.gridView = this.gridData;
          this.loading = false;
          this.calculateStock();
        }
      );
    });
  }

  packChildBatch(): any {
    var selectedBatch = this.dialogdata.Product.batches.filter(x => x.id == parseInt(this.selectedUID[0]))[0];
    const newObject = { ...selectedBatch };
    this.ProductService.getSharedProducts(newObject.productId).subscribe((result) => {
      if (result.length <= 0) {
        Swal.fire({
          icon: 'error',
          title: 'No products',
          html: "<strong> No products have the same type and category  </strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        })
      }
      else {

        var Data = { Batch: newObject }
        const batchDialog = this.dialog.open(ChildBatchesDialog, { data: Data });
        batchDialog.afterClosed().subscribe(result => {
          this.loading = true;
          this.spinner.show();
          this.ProductService.getProductBatches(this.dialogdata.Product.id).subscribe(
            (data) => {
              this.gridData = data;
              this.gridView = [...this.gridData];
              this.loading = false;
              this.spinner.hide();
              this.calculateStock();
            }
          );
        });
      }
    });

  }

  upload(files) {
    const formData = new FormData();
    this.spinner.show();
    if (files[0]) {
      // Todod Append Product Id
      formData.append('550c0516-3dac-40e7-95e6-ba2e9d3a40a7/' + files[0].name, files[0]);
    }

    this.uploadService
      .uploadImage(formData)
      .subscribe(
        (data) => {
          this.dialogdata.Product.primaryImageUrl = data[0].absoluteUri;
          this.dialogdata.Product.thumbnailUrl = data[1].absoluteUri;
          this.spinner.hide();
          //this.imageSource = data[1].absoluteUri;
        }
      );
  }

  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.id;
  }
  keyChange(e) {
    this.selectedUID = e;
  }

  public onFilter(inputValue: string): void {
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "uid",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "name",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  wrtiteOffBatch(): any {
    var selectedBatch = this.dialogdata.Product.batches.filter(x => x.id == parseInt(this.selectedUID[0]))[0];
    var Data = { Batch: selectedBatch }
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
      this.loading = true;
      this.ProductService.getProductBatches(this.dialogdata.Product.id).subscribe(
        (data) => {
          this.gridData = data;
          this.gridView = this.gridData;
          this.loading = false;
          this.calculateStock();
        }
      );
    });
  }

  quantityIsValid(): boolean {
    if (Number(this.dialogdata.Product.minQuantity) > Number(this.dialogdata.Product.maxQuantity) && this.dialogdata.Product.maxQuantity != null && this.dialogdata.Product.minQuantity != null) {
      this.productForm.form.controls["minQuantity"]?.setErrors({ 'invalid': true });
      this.productForm.form.controls["maxQuantity"]?.setErrors({ 'invalid': true });
      return false;
    }
    else if (Number(this.dialogdata.Product.minQuantity) <= Number(this.dialogdata.Product.maxQuantity) && this.dialogdata.Product.maxQuantity != null && this.dialogdata.Product.minQuantity != null) {
      this.productForm.form.controls["minQuantity"]?.setErrors(null);
      this.productForm.form.controls["maxQuantity"]?.setErrors(null);
    }

    return true;
  }
  public rowCallback = (context: RowClassArgs) => {
    const now = new Date().toISOString();
    if (context.dataItem.expiryDate < now) {
      return { gold: true };
    } else {
      return '';
    }
  };
}
