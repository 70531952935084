export const environment = {
  production: true,
  apibaseUrl: 'https://my.omniorder.com.au',
  sellerWebUrl: 'https://my.omniorder.com.au/dashboard',
  myobLoingUrl: 'https://secure.myob.com/oauth2/account/authorize?client_id=30f1c94d-5c93-4abc-ab16-4a4bb9407918&redirect_uri=https://my.omniorder.com.au/integration&response_type=code&scope=CompanyFile',
  xeroLoginUrl: 'https://login.xero.com/identity/connect/authorize?response_type=code&client_id=A7E19EDCAA1F46E6AA007575ABF14653&redirect_uri=https://my.omniorder.com.au/integration&scope=accounting.transactions%20offline_access%20accounting.settings%20accounting.contacts&state=123',
  googleOauthUrl: 'https://accounts.google.com/o/oauth2/v2/auth?access_type=offline&response_type=code&client_id=660379109232-b3n99hu2f0luo17n8t2ofdlmu8u32tvo.apps.googleusercontent.com&redirect_uri=https%3A%2F%2Fmy.omniorder.com.au%2Fintegration&scope=https%3A%2F%2Fmail.google.com%2F https%3A%2F%2Fwww.googleapis.com%2Fauth%2Fuserinfo.email',
  exchangeOauthUrl: 'https://login.microsoftonline.com/common/oauth2/v2.0/authorize?scope=offline_access+email+openid+profile+User.Read+Mail.Read&response_type=code&client_id=0f8300fa-087b-44e9-8914-23ae49f5e252&redirect_uri=https%3A%2F%2Fmy.omniorder.com.au%2Fintegration&client-request-id=4a5c3ed6-9942-4360-8a65-e828b7f7c994&x-client-SKU=MSAL.NetCore&x-client-Ver=4.43.1.0&x-client-OS=Darwin+21.3.0+Darwin+Kernel+Version+21.3.0%3A+Wed+Jan++5+21%3A37%3A58+PST+2022%3B+root%3Axnu-8019.80.24~20%2FRELEASE_X86_64&prompt=select_account&client_info=1',
  outlookOauthUrl: 'https://login.microsoftonline.com/consumers/oauth2/v2.0/authorize?scope=offline_access+email+https%3A%2F%2Foutlook.office.com%2FIMAP.AccessAsUser.All+https%3A%2F%2Foutlook.office.com%2FPOP.AccessAsUser.All+https%3A%2F%2Foutlook.office.com%2FSMTP.Send+openid+profile&response_type=code&client_id=0f8300fa-087b-44e9-8914-23ae49f5e252&redirect_uri=https%3A%2F%2Fmy.omniorder.com.au%2Fintegration&client-request-id=72a58f37-deec-45d1-846e-d20d62e9b6d0&x-client-SKU=MSAL.NetCore&x-client-Ver=4.43.1.0&x-client-OS=Darwin+21.3.0+Darwin+Kernel+Version+21.3.0%3A+Wed+Jan++5+21%3A37%3A58+PST+2022%3B+root%3Axnu-8019.80.24~20%2FRELEASE_X86_64&prompt=select_account&client_info=1',
  signUpSignInAuthority:"https://omniorderprod.b2clogin.com/omniorderprod.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_PRD",
  forgotPasswordAuthority:"https://omniorderprod.b2clogin.com/omniorderprod.onmicrosoft.com/B2C_1A_PASSWORDRESET_PRD",
  editProfileAuthority:"https://omniorderprod.b2clogin.com/omniorderprod.onmicrosoft.com/B2C_1A_PROFILEEDIT_PRD",
  authorityDomain:"omniorderprod.b2clogin.com",
  clientId:"cb5dd606-5ae9-4d46-9a87-23c9c63f9c54",
  scope:"https://omniorderprod.onmicrosoft.com/OmniOrderAPI/access_as_user",
  stripePublishableKey:"pk_live_51JcB9PHe3v42e1SBFsursqhQz33lgh6Hx6eKIVivrHyFS6k58983u6aibJ2wqiaiof22RAp5zfQ4BpscW8VYmraN00uP8m1xB8",
  postLogoutRedirectUri:"https://www.omniorder.com.au",
};
