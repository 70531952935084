import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { IntegrationCustomersDialog } from 'app/customers/dialogs/integration-customers/integration-customers.dialog';
import { SupplierTrustedNetworks, SupplierTrustedNetworksViewModel } from 'app/shared/models/promotions/marketing.model';
import { MarketingService } from 'app/shared/services/marketing.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ConnectionStatus } from 'app/shared/models/seller/connectionStatusEnum';
import { CustomerDialog } from 'app/dialogs/customer/customer.dialog';
import { ExternalCustomerModel } from 'app/shared/models/integration/external.customer.model';
import { CustomerDetailsDialog } from '../dialogs/customer-details/customer-details.dialog';

@Component({
  selector: 'app-deactive-customers',
  templateUrl: './deactive-customers.component.html',
  styleUrls: ['./deactive-customers.component.css']
})
export class DeactiveCustomersComponent implements OnInit {
  public gridView: GridDataResult;
  public inActiveGridView: GridDataResult;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage: number = 1;
  public approveStatus: number = ConnectionStatus.Approved;
  public currentStatus: number = ConnectionStatus.Approved;
  public isActive: boolean = false;
  supplierTrustedNetworksViewModel: SupplierTrustedNetworksViewModel;
  activetrustedsupplierNetworks: SupplierTrustedNetworks[];
  featurePermission: FeaturePermission = new FeaturePermission();
  integrationPermission: FeaturePermission = new FeaturePermission();
  showCustomers: boolean = true;
  CustomerDialogRef: MatDialogRef<CustomerDetailsDialog, any>;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private marketingService: MarketingService, public dialog: MatDialog,
    private spinner: NgxSpinnerService,private snackbar: MatSnackBar,
    private userService: UserService, private sellerService: SellerService
  ) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.initCutomers();
  }
  loadPermissions(seller: SellerViewModel) {
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {
      this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.CUSTOMERS)[0];
      this.integrationPermission = userPermissions.filter(x => x.feature == PermissionsConstants.INTEGRATION)[0];
      this.showCustomers = this.featurePermission.read;
    });

  }
  async initCutomers() {
    this.supplierTrustedNetworksViewModel = new SupplierTrustedNetworksViewModel();
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.reloadSuppliersNetwork(this.supplierTrustedNetworksViewModel);

  }
  activateDeactivateProduct(supplierTrustedNetworks: SupplierTrustedNetworks): void {
    this.loading=true;
    this.marketingService.ActivateOrDeactivateCustomer(supplierTrustedNetworks).subscribe((data) => {
      this.supplierTrustedNetworksViewModel = data
      this.reloadSuppliersNetwork(this.supplierTrustedNetworksViewModel);
    });
  }
  reloadSuppliersNetwork(Collection: SupplierTrustedNetworksViewModel) {

    this.getGrid(this.currentStatus, this.isActive);
  }
  openCustomerDialog(CustomerId: number) {
    var model = this.gridView.data.filter(x => x.customerId == CustomerId);
    this.CustomerDialogRef = this.dialog.open(CustomerDetailsDialog, { data: { Customer:model[0].customer, editmode:true }, width: '1200px', });
    this.CustomerDialogRef.afterClosed().subscribe(result => {
      if(result!=null && result!="")
      {
        this.snackbar.open('Customer updated successfully', 'close', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.CustomerDialogRef = null;
        this.getGrid(this.currentStatus, this.isActive);
      }
      
    }, (error) => {
      this.snackbar.open(`Failed to update customer due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide();
    });
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number(event.skip) + this.pageSize) / this.pageSize;
    this.skip = event.skip;
    this.getGrid(this.currentStatus, this.isActive);
  }
  getGrid(status: number, isActive: boolean = true) {
    this.currentStatus = status ?? this.approveStatus;
    this.isActive = isActive;
    this.loading = true;
    this.marketingService.GetBuyersInSupllierNetworkByStatus(this.currentStatus, this.currentPage, this.pageSize, this.isActive).subscribe((data) => {
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;

    });
  }

}

