import { Component, Inject, OnInit, SystemJsNgModuleLoader, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital} from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DataBindingDirective, RowArgs, SelectableSettings} from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { UploadService } from 'app/shared/services/upload.service';
import { NgxSpinnerService } from "ngx-spinner";
import { SupplierTrustedNetworks } from 'app/shared/models/promotions/marketing.model';
import { OrderService } from 'app/shared/services/order.service';
import { ManualOrderItemModel } from 'app/shared/models/order/order.model';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import {ChangeDetectorRef } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import {AddEvent,CancelEvent,CellClickEvent,CellCloseEvent,GridComponent,GridDataResult,RemoveEvent,SaveEvent,} from "@progress/kendo-angular-grid";
import { State} from "@progress/kendo-data-query";
import { Keys } from "@progress/kendo-angular-common";
import { OrderItemsEditService } from "./manual.order.items.edit.service";
import { map } from "rxjs/operators";
import { Observable } from 'rxjs';
import Swal from 'sweetalert2'
import { PaymentType } from 'app/shared/models/order/paymentTypeEnum';
import { MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'dialogs-order',
  templateUrl: './manual.order.dialog.html',
  styleUrls: ['./manual.order.dialog.css']
})
export class OrderDialog implements OnInit {

  buyer = new FormControl(this.dialogdata.Order.buyerId, [Validators.required]);
  unitprice = new FormControl(this.dialogdata.Order.unitPrice, [Validators.required]);
  quantity = new FormControl(this.dialogdata.Order.quantity, [Validators.required]);
  ProductCategories: ProductCategory[];
  Customers: SupplierTrustedNetworks[];
  ProductVaritals: ProductVarital[];
  ProductGrades: ProductGrade[];
  ProductGrowers: ProductGrower[];
  Total: number = 0;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  public isCustomerSelectionDisabled: boolean = false;
  public batchesGridView: BatchViewModel[];
  public batchesGridData: BatchViewModel[];
  public orderItemsGridView: ManualOrderItemModel[] = [];
  public orderItemsGridData: ManualOrderItemModel[] = [];
  public batchSelection: string[] = [];
  public selectedBatchUID:number[]  = [];
  public selectedOrderItemUID:number[]  = [];
  public orderItemSelection: string[] = [];
  public orderItemUID:string  = '';
  public deliveryType: number = 1;
  public deliveryTypePickup: boolean = false;
  public deliveryTypeAddress: boolean = false;
  public isBusinessTradeCredit: boolean = false;
  public isMarketTradeCredit: boolean = false;
  public paymentTypeId: number=-1;
  public deliveryTypeId: number=-1;
  public deliveryBay: string;
  public deliveryAddress: string;
  public deliveryCity: string;
  public deliveryState: string;
  public deliveryPostalCode: number;
  public loading: boolean = false;
  public showErrors: boolean = false;
  public showBatchErrors: boolean = false;  
  public showItemAlreadyExists: boolean = false;
  public errorMessage: string;
  public selectableSettings: SelectableSettings;
  public changes = {};
  public tradeClass :string ="";
  public marketClass :string ="";
  public addressClass :string ="";
  public pickClass :string ="";
  public businessTradeCredit=PaymentType.BusinessTradeCredit;
  public marketTradeCredit=PaymentType.MarketTradeCredit;
  public view: Observable<GridDataResult>;
  public isLinear = false;
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 10,
  };  
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
   @ViewChild('stepper') stepper: MatStepper;

  constructor(
    public dialogRef: MatDialogRef<OrderDialog>,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public dialogdata: { editmode: boolean, Order: ManualOrderItemModel },
    private ProductService: ProductService, private uploadService: UploadService,  private orderService: OrderService,
    public dialog: MatDialog,
    private cdref: ChangeDetectorRef,
    private snackbar: MatSnackBar,
    private formBuilder: FormBuilder,
    public editService: OrderItemsEditService 
  ) { }
  
  ngOnInit(): void {
    this.loadLockups();
    this.setSelectableSettings(); 
    this.view = this.editService.pipe(
      map((data) => process(data, this.gridState))
    );   
  }
    
  loadLockups(): void {  
    this.ProductService.getProductGrowers().subscribe((result) => {      
      this.ProductGrowers = result;
      this.cdref.detectChanges();
    });    
    this.ProductService.getProductGrades().subscribe((result) => {
      this.ProductGrades = result;      
      this.cdref.detectChanges();
    });
    this.orderService.GetProductCategoriesOrderCreation().subscribe((result) => {
      this.ProductCategories = result;      
      this.cdref.detectChanges();
    });
    this.orderService.GetCustomersForOrderCreation().subscribe((result) => {
      this.Customers = result;   
    });
  }
  loadBatchesForCustomer(customerId: number) {
    if(customerId > 0)
    {
      this.clearOrderData();
      var selectedTrustedNetwork = this.Customers.filter(x => x.customerId == customerId)[0];
      if(selectedTrustedNetwork.customer.addresses.length>0)
      {
        this.deliveryAddress = selectedTrustedNetwork.customer.addresses[0].addressLine1;
        this.deliveryCity = selectedTrustedNetwork.customer.addresses[0].city;
        this.deliveryState = selectedTrustedNetwork.customer.addresses[0].state?.name;
        this.deliveryPostalCode = parseInt(selectedTrustedNetwork.customer.addresses[0].postalCode);
      }
      this.deliveryBay = selectedTrustedNetwork.customer.bay;
      
      this.ProductService.GetAllBatchesForCustomer(customerId).subscribe((result) => {
        console.log(result)
        this.batchesGridView = result;
        this.batchesGridData = result;    
      });
      this.dialogdata.Order.categoryId = -1;
      this.dialogdata.Order.varietalId = -1;
      this.dialogdata.Order.productGradeId = -1;
      this.dialogdata.Order.productGrowerId = -1;
      this.dialogdata.Order.region = "-1";
    }
  }

  filterBatches()
  {
    var categoryFilter = false, varietlaFilter = false, typeFilter = false, gradeFilter = false, growerFilter = false, regionFilter = false;
    if(this.dialogdata.Order.categoryId > 0)
    {
      categoryFilter = true;
    }
    if(this.dialogdata.Order.varietalId > 0)
    {
      varietlaFilter = true;
    }
    if(this.dialogdata.Order.typeId > 0)
    {
      typeFilter = true;
    }
    if(this.dialogdata.Order.productGradeId > 0)
    {
      gradeFilter = true;
    }
    if(this.dialogdata.Order.productGrowerId > 0)
    {
      growerFilter = true;
    }        
    if(this.dialogdata.Order.region?.length > 2)
    {
      regionFilter = true;
    }
    this.batchesGridView = this.batchesGridData.filter(x => {
        if(categoryFilter && x.productCategoryId != this.dialogdata.Order.categoryId){
          return null;
        }
        if(varietlaFilter && x.productVarietalId != this.dialogdata.Order.varietalId){
          return null;
        }
        if(typeFilter && x.productTypeId != this.dialogdata.Order.typeId){
          return null;
        }
        if(gradeFilter && x.productGradeId != this.dialogdata.Order.productGradeId){          
          return null;
        }
        if(growerFilter && x.productGrowerId != this.dialogdata.Order.productGrowerId){
          return null;
        }
        if(regionFilter && x.region != this.dialogdata.Order.region){
          return null;
        }
        return x;
      }
    );
  }

  loadVarietlasAndFilterBatches(categoryId : number)
  {
    this.ProductService.GetProductVarietals(categoryId).subscribe((result) => {
      this.ProductVaritals = result;
      this.filterBatches();
    });  
  }

  AddItemIsValid(){
    if( this.selectedBatchUID.length == 0 || ! this.dialogdata.Order.unitPrice || !this.dialogdata.Order.quantity){
     return false;
    }
    else{
      return true;
    }
  }
  AddOrderItem(){   
    if( this.selectedBatchUID.length == 0 || ! this.dialogdata.Order.unitPrice || !this.dialogdata.Order.quantity){
      this.errorMessage = "Please enter all required fields";
      this.showBatchErrors = true;
      return;
    }
    var batch = this.batchesGridView.filter(x => x.id == this.selectedBatchUID[0])[0];
    var availableStock = this.batchesGridView.filter(x => x.id == this.selectedBatchUID[0])[0].stock;
    if( this.dialogdata.Order.quantity > availableStock && !batch.infinityStock ){
      this.errorMessage = "Quantity is more than available stock, please reduce quantity";
      this.showBatchErrors = true;
      return;
    }
    if(this.editService.getData().filter(x => x.id == this.selectedBatchUID[0]).length > 0 ){
      this.showItemAlreadyExists = true;
      return;
    }
    this.errorMessage = "";
    this.showBatchErrors = false;
    this.showItemAlreadyExists = false;
    var lineTotal  = this.dialogdata.Order.unitPrice * this.dialogdata.Order.quantity;    
    var newOrderItem = {
      "id": this.batchesGridView.filter(x => x.id == this.selectedBatchUID[0])[0].id,
      "batchId": this.batchesGridView.filter(x => x.id == this.selectedBatchUID[0])[0].id,
      "quantity": parseInt(this.dialogdata.Order.quantity.toString()),
      "unitPrice": parseFloat(this.dialogdata.Order.unitPrice.toString()),
      "total": lineTotal,
      "varietalId":this.dialogdata.Order.varietalId,
      "typeId":this.dialogdata.Order.typeId,
      "batch": this.batchesGridView.filter(x => x.id == this.selectedBatchUID[0])[0],
      "buyerId": this.dialogdata.Order.buyerId,
      "categoryId" : this.dialogdata.Order.categoryId,
      "productGradeId": this.dialogdata.Order.productGradeId,
      "productGrowerId": this.dialogdata.Order.productGrowerId,
      "region": this.dialogdata.Order.region,
      "item": this.batchesGridView.filter(x => x.id == this.selectedBatchUID[0])[0].productName,
      "maxqty": availableStock
    };
    this.editService.create(newOrderItem);
    this.Total += lineTotal;    
    this.dialogdata.Order.quantity = 0;
    this.dialogdata.Order.unitPrice = 0;
    this.batchSelection = [];
    this.isCustomerSelectionDisabled = true;
  }

  public onStateChange(state: State): void {
    this.gridState = state;
    this.editService.read();
  }

  clearOrderData(){
    this.editService.clear();
    this.view = this.editService.pipe(
      map((data) => process(data, this.gridState))
    );   
    this.Total = 0;    
  }
  onSelectValueChange() {}

  createNewOrder() {
    var manualOrder = {
      customerId: this.dialogdata.Order.customerId,
      buyerId: this.dialogdata.Order.buyerId,
      orderItems : this.editService.getData(),
      paymentTypeId:this.paymentTypeId,
      delivery: {        
        deliveryTypePickup: this.deliveryTypePickup,
        deliveryTypeAddress: this.deliveryTypeAddress,
        deliveryBay: this.deliveryBay,
        deliveryAddress: this.deliveryAddress,
        deliveryCity: this.deliveryCity,
        deliveryState: this.deliveryState,
        deliveryPostalCode: this.deliveryPostalCode,
      }
    }
    this.spinner.show();
    let isStockTake=this.editService.getData().filter(x => x.batch.isStockTake).length>0 
    this.orderService.CreateManualOrder(manualOrder).subscribe((result) => {
      if(result)
      {
        if(isStockTake)
        {
          this.spinner.hide();
        this.clearOrderData();
        Swal.fire({
          icon: 'success',
          title: 'Success',
          html: "<strong> here is an active stock take in the site where the batch belongs to, hence order will not be auto confirmed, you can confirm the order from “Pending Orders” screen </strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        })      
        this.dialogRef.close();  
        }
        else{
          this.spinner.hide();
          this.clearOrderData();
          Swal.fire({
            icon: 'success',
            title: 'Success',
            html: "<strong> You have successfully created an order for your customer. To view the order navigate to the 'Orders' tab. Thank you!  </strong>",
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonColor: "#0c6acb"
          })      
          this.dialogRef.close();   
        }
        
      }
      else
      {
        this.snackbar.open(`Failed to create order `, 'close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.spinner.hide();
      }
         
    }, (error) => {
      this.snackbar.open(`Failed to create order due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide();
    });    
  } 

  deleteOrderItem() {  
    this.editService.remove(this.orderItemsGridView.find(x => x.id == this.selectedOrderItemUID[0]));
  } 

  getErrorMessage() {
    if (this.buyer.hasError('required')) {
      return 'You must enter a value';
    }
  }

  formatLabel(value: number) {
    if (value <= 100) {
      return value + '%';
    }

    return value;
  }    

  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }

  public batchSelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }

  batchKeyChange(e){    
    this.selectedBatchUID = e;
    this.dialogdata.Order.unitPrice = this.batchesGridView.filter(x => x.id == this.selectedBatchUID[0])[0].bestPrice;
  }

  public onBatchFilter(inputValue: string): void {
    this.batchesGridView = process(this.batchesGridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "uid",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "name",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  public orderItemSelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  
  orderItemKeyChange(e){    
    this.selectedOrderItemUID = e;
  }

  public onOrderItemFilter(inputValue: string): void {
    this.orderItemsGridView = process(this.orderItemsGridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "uid",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "name",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;
    this.dataBinding.skip = 0;
  }
  

  public cellClickHandler({
    sender,
    column,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    if (!isEdited && (column.field != 'id' && column.field != 'total')) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }

  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }
      console.log(formGroup.value.quantity, formGroup.value, dataItem);
      if(formGroup.value.quantity > dataItem.maxqty){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          html: "<strong> Entered Quantity is more than available stock  </strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        })
        return;
      }
      this.editService.assignValues(dataItem, formGroup.value);
      this.editService.update(dataItem);
      this.Total = this.editService.getData().reduce((partialSum, a) => partialSum + a.total, 0);
    }
  }

  public addHandler({ sender }: AddEvent): void {
    sender.addRow(this.createFormGroup(new ManualOrderItemModel()));
  }

  public cancelHandler({ sender, rowIndex }: CancelEvent): void {
    sender.closeRow(rowIndex);
  }

  public saveHandler({ sender, formGroup, rowIndex }: SaveEvent): void {
    if (formGroup.valid) {
      this.editService.create(formGroup.value);
      sender.closeRow(rowIndex);
    }
  }

  public removeHandler({ sender, dataItem }: RemoveEvent): void {
    this.editService.remove(dataItem);
    this.Total -= dataItem.total;
    sender.cancelCell();
  }

  public saveChanges(grid: GridComponent): void {
    grid.closeCell();
    grid.cancelCell();
    this.editService.saveChanges();
  }

  public cancelChanges(grid: GridComponent): void {
    grid.cancelCell();
    this.editService.cancelChanges();
  }

  public createFormGroup(dataItem: ManualOrderItemModel): FormGroup {
    return this.formBuilder.group({
      id: dataItem.id,
      quantity: [dataItem.quantity, Validators.required],
      unitPrice: [dataItem.unitPrice, Validators.required],
      total: dataItem.total
    });
  }
  public isOrderReadyForSubmit(){
    return this.editService.getData().length > 0  && (this.deliveryTypeAddress || this.deliveryTypePickup) && this.paymentTypeId>0;    
  }
  public handleDeliveryTypePickup()
  {
    this.deliveryTypeAddress = false;
    this.pickClass = 'border border-primary';
        this.addressClass = '';
  }
  public handleDeliveryTypeAddress()
  {
    this.deliveryTypePickup = false;
    this.addressClass = 'border border-primary';
        this.pickClass = '';
  }
  
  updateClasses( paymentVal: number) {

    switch (paymentVal) {
      
      case PaymentType.BusinessTradeCredit:
        this.paymentTypeId=PaymentType.BusinessTradeCredit;
        this.tradeClass = 'border border-primary';
        this.marketClass = '';
        break;
      case PaymentType.MarketTradeCredit:
        this.paymentTypeId=PaymentType.MarketTradeCredit;
        this.marketClass = 'border border-primary';
        this.tradeClass = '';
        break;

      default:
        this.paymentTypeId=-1;
        this.tradeClass = '';
        this.marketClass = '';
    }
  }
  
}
