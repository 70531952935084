<mat-card>
  <mat-card-header class="mb-3 ">
    
    <mat-card-title class=" text-danger  text-center">Ooops you are not linked to a workspace</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex" >
      <button  class="text-center col-6 btn btn-primary" (click)="openSellerDetails()">Create New workspace</button>
      <button  class="text-center col-6 btn btn-primary "(click)="openJoinDialog()">Join workspace</button>
    </section>
  </mat-card-content>
  </mat-card>