import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
  } from '@angular/forms';
  import { Observable, of } from 'rxjs';
  import { map } from 'rxjs/operators';
import { SellerService } from '../services/Seller.Service';

  
  export class PostalCodeValidator {
    static createValidator(sellerService: SellerService): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {        
        let pattern = /^[0-9]{4}$/;
        let result = pattern.test(control.value);
        return of(result? null : { isValid: false });
      };
    }
  }