<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-header card-header-success">
            <h4 class="card-title">Billing</h4>
            <!-- <p class="card-category">Complete your profile</p> -->
          </div>
          <div class="card-body" >
            <div class="row">
              <div class="col-md-12 ">
                <mat-card-content>
                  <h3>
                    You can view all invoices and transactions by clicking on the below button
                  </h3>
                </mat-card-content>
                <mat-card-actions>
                  <button mat-button class="btn btn-primary" (click)="billingPageRedirect()">Billing</button>
                  
                </mat-card-actions>
              </div>
              
            </div>
            


          </div>
          
        </div>
      </div>

    </div>
  </div>
</div>
