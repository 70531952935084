import { Component, EventEmitter, Inject, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DataBindingDirective, GridDataResult, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerABNValidator } from 'app/shared/validators/CustomerABNValidator';
import { ExternalCustomerModel } from 'app/shared/models/integration/external.customer.model';
import { SiteModel, ZoneModel } from 'app/shared/models/sites/site.model';
import { NewZoneDialog } from './dialogs/new-zone.dialog';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { ProductService } from 'app/shared/services/product.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})
export class ZonesComponent implements OnInit {
  public opened = true;
  public siteForm: FormGroup;
  public gridView: ZoneModel[]=[];
  public zones: ZoneModel[]=[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public loading: boolean = false;
  public showErrors: boolean = false;
  public NewVarietalName = "";
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public editableDialog:boolean = false;
  public selectableSettings: SelectableSettings;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @Input() siteId:number;  
  @Input() zonesList: ZoneModel[];
  @Input() featurePermission: FeaturePermission;
  @Input() editable: boolean;
  @Output() zonesDataEvent: EventEmitter<ZoneModel[]> = new EventEmitter<ZoneModel[]>();

  constructor(public dialogRef: MatDialogRef<ZonesComponent>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: { siterrId: number}
    ,public zoneDialog: MatDialog
    ,private sellerservice: SellerService,
    public fb: FormBuilder,private snackbar: MatSnackBar,private productService: ProductService

  ) {
  }


   ngOnInit(): void {
    this.zones= this.zonesList;
    this.gridView=[...this.zones]
     this.setSelectableSettings();
    //  let zz: ZoneModel={id:1,name:"aaaa",siteId:1};
    //  this.zones.push(zz);
    //  this.gridView=[...this.zones]  ;
//     this.loading = true;
// this.sellerservice.getZonesBySiteId(this.siteId, this.currentPage,this.pageSize).subscribe((data) => {
//   this.gridView = {
//     data: data.items,
//     total: data.totalItems,
//   };
//   this.loading = false;
// });
  }
  ngOnChanges(changes: SimpleChanges) {
    this.setSelectableSettings();
    this.gridView= changes['zonesList'].currentValue;
  }
  get f() { return this.siteForm.controls; }

  public close(status: string): void {
   
    this.opened = false;
  }
  openSellerDetails()
  {
   
    
  }


 public setSelectableSettings(): void {  
  this.selectableSettings = {
    checkboxOnly: false,
    mode: "single",
    drag: false,
  };
}
public mySelectionKey(context: RowArgs): number {   
  return context.index;
}
keyChange(e){    
  this.selectedUID = e;
  console.log('key change is called and selectedUID is ', this.selectedUID);
}
add(){     
  if(this.zones=== undefined)
  {
    this.zones=[];
  }
  this.editableDialog=false;
  var Data = { zone: null, editable: this.editableDialog}
   const dialogRef = this.zoneDialog.open(NewZoneDialog,{width:'1000px', data : Data});
   dialogRef.afterClosed().subscribe(result => {
    this.loading=true;
    this.spinner.show();
    if(result!=null )
    {
      
      this.zones.push(result);
      this.gridView=[...this.zones]  ;
      this.zonesDataEvent.emit(this.gridView);
      
    }
    this.spinner.hide();
    this.loading=false;
    });
}

delete(){
  this.spinner.show();
  let index=parseInt(this.selectedUID[0]);
  let zone=this.zones[index];
  if(zone.id===undefined)
  {
    this.zones.splice(index, 1);
      this.gridView=[...this.zones]  ;
      this.zonesDataEvent.emit(this.gridView);
      this.spinner.hide();
  }
  else
  {
    this.productService.hasZoneBatches(zone.id).subscribe(hasBatches => {
      if(hasBatches)
      {
        this.spinner.hide();
        Swal.fire({
          title: 'Delete Zone Alert',
          text: 'You need to transfer all the baches related to this zone before deleted it!',
          icon: 'error',
          showCloseButton: true,
          showCancelButton: true,
          showConfirmButton: false,
          cancelButtonColor: '#d33',
          cancelButtonText: 'Cancel',
        });
      }
      else
      {
        this.zones.splice(index, 1);
        this.gridView=[...this.zones]  ;
        this.zonesDataEvent.emit(this.gridView);
        this.spinner.hide();
      }
  
    });
  }
  
  
  
}

edit(){  
  let index=parseInt(this.selectedUID[0]);
  console.log('edit is called and index is ', index, ' this.selectedUID lengs is ', this.selectedUID.length);  
  this.editableDialog=true;
  var Data = { zone: null, editable: this.editableDialog}
  if(this.selectedUID.length > 0){
    Data.zone = this.zones[index];
    console.log('data.zone is', Data.zone);    
  }
  const dialogRef = this.zoneDialog.open(NewZoneDialog,{width:'1000px', data : Data});
   dialogRef.afterClosed().subscribe(result => {
    if(result)
    {
        this.spinner.show();
        this.zones[index]=result;
        this.gridView=[...this.zones]  ;
        this.zonesDataEvent.emit(this.zonesList);
        this.spinner.hide();
      }
  });
}

}
