import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from 'app/shared/services/upload.service';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import { ProductRipeness, ProductSize, ProductType, Site, Zone } from 'app/shared/models/catalogue/lockups';
import { SiteModel, ZoneModel } from 'app/shared/models/sites/site.model';
import { SellerService } from 'app/shared/services/Seller.Service';
import Swal from 'sweetalert2';


@Component({
  selector: 'dialogs-batch',
  templateUrl: './batch.dialog.html',
  styleUrls: ['./batch.dialog.css']
})
export class BatchDialog implements OnInit {

  // productname = new FormControl(this.dialogdata.Product.category.categoryName, [Validators.required]);
  // grade = new FormControl(this.dialogdata.Product.grade?.grade, [Validators.required]);
  // region = new FormControl(this.dialogdata.Product.region, [Validators.required]);
  //Varietal = new FormControl(this.dialogdata.Product.varietal.varietalName, [Validators.required]);
  treshold: number = this.dialogdata.Product?.threshold;
  minDate = new Date();
  onpromotion: boolean = false;  
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  ProductCategories: ProductCategory[];
  ProductGrades: ProductGrade[];
  ProductGrowers: ProductGrower[];
  Sites: SiteModel[];
  Zones: ZoneModel[];
  ProductVaritals: ProductVarital[];
  ProductTypes: ProductType[];
  calbackcompleted: boolean = false;
  Products: ProductViewMdel[];
  currentZoneId: number;
  currentSite: string;
  currentZone: string;
  public batch = new BatchViewModel();
  public gridView: any[];
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public loading: boolean = false;
  public selectableSettings: SelectableSettings;
  public showErrors: boolean = false;
  public batchModel:BatchViewModel;
  selectedValue: number ;
  ProductSizes: ProductSize[];
  ProductRipness: ProductRipeness[];
  uom: string="";
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('batchForm', {static: true}) batchForm: NgForm;

  constructor(
    public dialogRef: MatDialogRef<BatchDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: { editmode: boolean, Product?: ProductViewMdel, Batch: BatchViewModel,childmode: boolean },
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private ProductService: ProductService, 
    private uploadService: UploadService,
    private sellerService: SellerService,
  ) { 

    this.spinner.show();
    this.uom=this.dialogdata.Product?.productUoM?.uoM;
    if(this.dialogdata.editmode || this.dialogdata.childmode ) {
      this.batch = this.dialogdata.Batch;

      this.currentZoneId=this.batch.zoneId;
      this.currentSite=this.batch.siteName;
      this.currentZone=this.batch.zoneName;
    } else {
      this.batch.productId = this.dialogdata.Product.id;
      //this.batch.format = this.dialogdata.Product.format;
      this.batch.grade = this.dialogdata.Product.grade;
      this.batch.grower = this.dialogdata.Product.grower;
      this.batch.productSizeId = this.dialogdata.Product.productSizeId;
      this.batch.productUoMId = this.dialogdata.Product.productUoMId;
      this.batch.productRipenessId = this.dialogdata.Product.productRipenessId;
      this.batch.primaryImageUrl = this.dialogdata.Product.primaryImageUrl;
      this.batch.thumbnailUrl = this.dialogdata.Product.thumbnailUrl;
      this.batch.region = this.dialogdata.Product.region;
      this.batch.productGradeId = this.dialogdata.Product.productGradeId;
      this.batch.productGrowerId = this.dialogdata.Product.productGrowerId;
      this.batch.unitPrice = this.dialogdata.Product.unitPrice;
    }
  }


  ngOnInit(): void {
    this.loadLockups();    
  }  
  loadLockups(): void {
   this.spinner.show();
   
    if(this.dialogdata.childmode)
    {
      if(this.dialogdata.editmode)
      {
        this.loadSharedProducts(this.dialogdata.Product.id);
      }
      else{

        this.loadSharedProducts(this.dialogdata.Batch.productId);
      }
    
    }
    this.ProductService.getProductSizes().subscribe((result) => {      
      this.ProductSizes = result;
      
    }); 
    this.ProductService.getProductRipeness().subscribe((result) => {      
      this.ProductRipness = result;
      
    }); 
    this.ProductService.getProductGrowers().subscribe((result) => {
      this.ProductGrowers = result;      
    });

    this.ProductService.getProductGrades().subscribe((result) => {
      this.ProductGrades = result;      
    });

    this.sellerService.getSellerSites().subscribe((data) => {
      this.Sites = data;   
      this.loadZones(this.batch?.siteId, false);
    });

    this.ProductService.getProductCategories().subscribe((result) => {
      this.ProductCategories = result;
      if (this.dialogdata.Product?.productCategoryId != null) {
        this.loadvarital(this.dialogdata.Product.productCategoryId);
        this.loadTypes(this.dialogdata.Product.productCategoryId);
        
      }
      this.spinner.hide();
    });
  }
  loadSharedProducts(productId: any) {
   
    this.ProductService.getSharedProducts(productId).subscribe((result) => {
      this.Products = result;
      let selectedProduct= new ProductViewMdel();
      selectedProduct= this.Products.find(s=>s.id== this.batch.productId);
      
      if(selectedProduct===undefined)
      {
         selectedProduct=this.Products[0];
      }    
      console.log(selectedProduct)
     this.dialogdata.Product={...selectedProduct};
     this.selectedValue=selectedProduct.id;
     this.batch.product=selectedProduct;
     this.batch.productId=selectedProduct.id;
    
    });
  }
  loadvarital(categoryId: any) {
    this.spinner.show();
    this.ProductService.GetProductVarietals(categoryId).subscribe((result) => {
      this.ProductVaritals = result;
      this.spinner.hide();
    });
  }
  loadTypes(categoryId: any) {
    this.spinner.show();
    this.ProductService.GetProductTypes(categoryId).subscribe((result) => {
      this.ProductTypes = result;
      this.spinner.hide();
    });
  }
  loadZones(siteId: any, resetCurrentZone: boolean = true) {
    this.Zones = this.Sites.filter(x => x.id == siteId)[0].zones; 
    if(resetCurrentZone)
      this.batch.zoneId = -1;   
  }  
  formIsValid() {        
    if( !this.batch.number || !this.batch.date ||  ! this.batch.stock  || this.batch.stock < 0
      || this.batch.siteId <= 0 || this.batch.zoneId <= 0 || !this.batch.zoneId || !this.batch.siteId || this.batch.productId <= 0 || !this.dateIsValid()){
      return false;
    }
    return true;
  }
  saveOrUpdateBatch() {      

    if( !this.batch.number || !this.batch.date ||  ! this.batch.stock 
      || this.batch.stock < 0 || this.batch.siteId <= 0|| this.batch.zoneId <= 0 || !this.batch.zoneId || !this.batch.siteId || !this.dateIsValid() ){
      this.showErrors = true;
     
      return;
    }
    
    this.batch.productGrowerId=this.batch.productGrowerId==-1?null:this.batch.productGrowerId;
    this.batch.productGradeId=this.batch.productGradeId==-1?null:this.batch.productGradeId;
    this.batch.productSizeId=this.batch.productSizeId==-1?null:this.batch.productSizeId;
    this.batch.productRipenessId=this.batch.productRipenessId==-1?null:this.batch.productRipenessId;
    this.batch.productUoMId=this.dialogdata.Product.productUoMId==-1?null:this.dialogdata.Product.productUoMId;
    this.batch.grade=this.batch.productGradeId==-1?null:this.ProductGrades.filter(s=>s.id==this.batch.productGradeId)[0] 
    this.batch.grower=this.batch.productGrowerId==-1?null:this.ProductGrowers.filter(s=>s.id==this.batch.productGrowerId)[0] 
    this.batch.productVarietalId=this.batch.productVarietalId==-1?null:this.batch.productVarietalId;
    this.calbackcompleted = true;    
    if(this.dialogdata.childmode )
    {
      this.batch.productSize=this.batch.productSizeId==-1?null:this.ProductSizes.filter(s=>s.id==this.batch.productSizeId)[0] ;
      this.batch.productRipeness=this.batch.productRipenessId==-1?null:this.ProductRipness.filter(s=>s.id==this.batch.productRipenessId)[0] ;
      this.batch.productUoM=this.dialogdata.Product.productUoM;
      console.log(this.batch);
      let batchModel=this.batch;
      this.dialogRef.close(batchModel);
      return;
    }
    if(!this.dialogdata.editmode || this.currentZoneId== this.batch.zoneId)
    {
      this.batch.productRipeness=null;
    this.batch.productSize=null;
    this.batch.productUoM=null;
      this.ProductService.AddBatchToProduct(this.batch).subscribe((result) => {
        this.batchModel= result;
        var message = "Batch successfully added";
        if(this.dialogdata.editmode) {
          message = "Batch successfully updated";
        }
        this.snackbar.open(message, 'close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.waites();
  
      this.dialogRef.close(this.batchModel);
      });
      
    }
    else
    {
      let zone=this.Zones.filter(s=>s.id==this.batch.zoneId)[0];
      let site=this.Sites.filter(x => x.id == zone.siteId)[0];
      Swal.fire({
        title: 'Transfer Batch Confirmation',
        text: 'Are you sure you want to transfer batch.no: '+this.batch.number+' from site:'+this.currentSite+' - zone:'+this.currentZone+' to site:'+site.name+' - zone:'+zone.name+' ?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.ProductService.AddBatchToProduct(this.batch).subscribe((result) => {
            var message = "Batch successfully added";
            if(this.dialogdata.editmode) {
              message = "Batch successfully updated";
            }
            this.snackbar.open(message, 'close', {
              duration: 5000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition
            });
          });
          this.waites();
      
          this.dialogRef.close(true);
        } else {
          // User clicked "Cancel" or closed the dialog
          // Put your logic for canceling the action here
        }
      });
    }
    
  }

  waites() {
    setTimeout(() => {
      if (!this.calbackcompleted) {
        this.waites();
      }
      //<<<---using ()=> syntax
    }, 3000);
  }

  // getErrorMessage() {
  //   if (this.productname.hasError('required')) {
  //     return 'You must enter a value';

  //   }
  // }

  upload(files) {
    const formData = new FormData();

    if (files[0]) {
      // Todod Append Product Id
      formData.append('550c0516-3dac-40e7-95e6-ba2e9d3a40a7/' + files[0].name, files[0]);
    }
    this.spinner.show();
    this.uploadService
      .uploadImage(formData)
      .subscribe(
        (data) => {
          this.batch.primaryImageUrl = data[0].absoluteUri;
          this.batch.thumbnailUrl = data[1].absoluteUri;
          this.spinner.hide();
          //this.imageSource = data[1].absoluteUri;
        }
      );
  }
  dateIsValid():boolean
  {
    let batchDate = new Date(this.batch.date);
    let expiryDate = new Date(this.batch.expiryDate);
     if(this.batch.expiryDate==null || expiryDate>=batchDate)
     {
      this.batchForm.form.controls["batchDate"]?.setErrors(null);
      this.batchForm.form.controls["expiryDate"]?.setErrors(null);
      return true;
     }
     else
     {
      this.batchForm.form.controls["batchDate"]?.setErrors({ 'invalid': true });
      this.batchForm.form.controls["expiryDate"]?.setErrors({ 'invalid': true });
      return false;
     }
      
  }
  loadData(productId: any) {
  
   var product=this.Products.filter(s=>s.id==productId)[0];
   if(product===undefined)
   {
    this.ProductVaritals = [];
    this.ProductTypes = [];
    this.dialogdata.Product=new ProductViewMdel();
    this.batch.productId=0;
   }
   else{
    this.batch.product=product;
    this.batch.productId=product.id;
    this.dialogdata.Product=product;
    this.loadvarital(this.dialogdata.Product.productCategoryId);
    this.loadTypes(this.dialogdata.Product.productCategoryId);
   }
   
  }
}
