import { Component, OnInit, ViewChild } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { SignalRPaginatedAdapter } from 'app/chat/adapter/signalr-paginated-adapter';
import { SellerViewModel, SubscriptionStatus } from 'app/shared/models/seller/sellerViewModel';
import { AuthorizeService } from 'app/shared/services/authorize.service';
import { ChatService } from 'app/shared/services/chat.service';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { MarketingService } from 'app/shared/services/marketing.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { ChatAdapter, IChatController, IChatParticipant, Message } from 'ng-chat';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/filter';
import { filter, takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'app/shared/services/user.service';
import { MatDialog } from '@angular/material/dialog';
import { SubscriptionDialog } from 'app/errors/dialogs/workspace/subscription/subscription.dialog';
import { NewSubscriptionDialog } from 'app/errors/dialogs/workspace/new-subscription/new-subscription.dialog';
import { UnderApproveDialog } from 'app/errors/dialogs/workspace/under-approve/under-approve.dialog';
import { PaymentCartDialog } from 'app/errors/dialogs/workspace/paymentCart/paymentCart.dialog';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();

  currentTheme = 'dark-theme';
  public adapter: ChatAdapter;
  public userId: string = '1';
  public showchat: boolean = false;
  @ViewChild('ngChatInstance')
  protected ngChatInstance: IChatController;
  constructor(private eventEmitterService: EventEmitterService,
    private msalBroadcastService: MsalBroadcastService,
    private marketingService: MarketingService, private chatService: ChatService,public dialog: MatDialog,
    private sellerservice: SellerService, private AuthService: AuthorizeService, private userService: UserService,
    private spinner: NgxSpinnerService,
    private sellerService: SellerService) {
  }
  ngOnInit() {

    this.eventEmitterService.invokeShowSpinnerfunction
      .subscribe(() => {
        this.ShowSpinner();
      });
    this.eventEmitterService.invokeHideSpinnerfunction
      .subscribe(() => {
        this.HideSpinner();
      });
      this.adapter = new SignalRPaginatedAdapter(this.marketingService, this.chatService, this.sellerservice, this.AuthService);

    if (this.AuthService.isloggedIn()) {
      
      this.sellerservice.getSeller().subscribe((result) => {
        if(Object.keys(result).length != 0)
        {
          if(result?.subscriptionStatus==SubscriptionStatus.PendingApproval)
          {
            this.dialog.open(UnderApproveDialog, { disableClose: true });
          }
           else if(result?.subscriptionStatus==SubscriptionStatus.Deleted)
          {
            this.dialog.open(NewSubscriptionDialog, { disableClose: true });
          }
          else if(result?.subscriptionStatus==SubscriptionStatus.Cancelled)
              {
                this.dialog.open(SubscriptionDialog);
              }
              else if(result?.subscriptionStatus==SubscriptionStatus.PaymentMethodNotSet)
              {
                this.dialog.open(PaymentCartDialog,{data:{hasProfile:true},width:'1000px', disableClose: true });
              }
          }
        this.userId = result.supplierId.toString();
        //this.userService.hasPermissionFor(PermissionsConstants.CHAT, PermissionsConstants.READ, result.id).subscribe(x => {this.showchat = true});  
        //this.showchat = true
      })
    }
    else {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          takeUntil(this._destroying$)
        )
        .subscribe((result) => {
          this.sellerservice.getSeller().subscribe((result) => {
            if(Object.keys(result).length != 0)
            {
              if(result?.subscriptionStatus==SubscriptionStatus.PendingApproval)
              {
                this.dialog.open(UnderApproveDialog, { disableClose: true });
              }
               else if(result?.subscriptionStatus==SubscriptionStatus.Deleted)
              {
                this.dialog.open(NewSubscriptionDialog, { disableClose: true });
              }
              else if(result?.subscriptionStatus==SubscriptionStatus.Cancelled)
                  {
                    this.dialog.open(SubscriptionDialog);
                  }
                  else if(result?.subscriptionStatus==SubscriptionStatus.PaymentMethodNotSet)
              {
                this.dialog.open(PaymentCartDialog,{data:{hasProfile:true}, width:'1000px', disableClose: true });
              }
          }
            this.userId = result.supplierId.toString();
            //this.userService.hasPermissionFor(PermissionsConstants.CHAT, PermissionsConstants.READ, result.id).subscribe(x => {this.showchat = true});    
           // this.showchat = true
          })
        });
    }
  }
  ngAfterViewInit() {
    if (this.eventEmitterService.subsVar == undefined) {
      this.eventEmitterService.subsVar = this.eventEmitterService.
        invokeOpenChatFunction.subscribe((user: IChatParticipant) => {
          this.openchat(user);
        });
    }
  }
  HideSpinner() {
    this.spinner.hide();

  }
  ShowSpinner() {
    this.spinner.show();

  }
  openchat(user: IChatParticipant) {
    console.info("open chat in Layout:", [user])
    this.ngChatInstance.triggerOpenChatWindow(user);
  }
  public messageSeen(messages: any) {
    this.sellerService.getSeller().subscribe((seller: SellerViewModel) => {
      var message = messages.filter(message => message.toId == seller.id)[0];
      if(message){
        this.chatService.MarkMessagesRead(messages, message.fromId).subscribe(result => {
        });    
      }
    });
  }
}
