
<div class="main-content scrollable-content">
<form [formGroup]="profileForm" (ngSubmit)="onSubmit()" novalidate>
  
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">Profile</h4>
              <!-- <p class="card-category">Complete your profile</p> -->
            </div>
            <div class="card-body mt-5 " *ngIf="showProfile">
              <div class="row">
                <div class="col-md-12 mb-5 " >
                  <div *ngIf="profileImageUrl!='' && profileImageUrl != null" class="image-container col-md-4 offset-md-4" (click)="toggleFullImage()" (mouseover)="showFullImage()" (mouseout)="hideFullImage()">
   
                  <img  [src]="profileImageUrl" alt="Image Description" class=" rounded-circle  "[ngClass]="{'full-image': isFullImage}" >
                  <button type="button" [disabled]="this.isMarketPlaceSource" class=" span-button" (click)="openFileDialog($event)" ><span class="camera material-icons">camera_alt</span></button>
                  <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" accept="image/*" />
                </div>
                <div *ngIf="profileImageUrl =='' || profileImageUrl == null" class="image-container col-md-4 offset-md-4 d-flex align-items-center justify-content-center" (click)="toggleFullImage()" (mouseover)="showFullImage()" (mouseout)="hideFullImage()"style="background-image: url(./assets/img/oba-leaf.jpg)">
   
                  <button id="camera" [disabled]="this.isMarketPlaceSource" class="btn span-button" (click)="openFileDialog($event)"><span  class="camera material-icons">camera_alt</span></button>
                  <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" accept="image/*" />
                </div>
                </div>
                <!-- <div class="col-md-12  mb-5  d-flex">
                      <img  [src]="profileImageUrl" alt="Image Description" class="col-md-4 offset-md-4 rounded" width="100" height="100" (click)="fileInput.click()">

                     <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
                      style="background-image: url(./assets/img/oba-leaf.jpg)" *ngIf="profileImageUrl =='' || profileImageUrl == null"
                      class="bg-style"
                      >
                      <span class="material-icons">camera_alt</span>
                      <span>Edit</span>
                    </div>
                    <div mat-card-avatar class=" col-md-12 product-header-image profile-pic" (click)="fileInput.click()" class="bg-style"
                      [ngStyle]="{ 'background-image': 'url(' +profileImageUrl + ')'}"
                      *ngIf="profileImageUrl!='' && profileImageUrl != null">
                      <span class="material-icons">camera_alt</span>
                      <span>Edit</span>
                    </div>
                    <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" accept="image/*" />
                
                    
                </div>  -->
                <div class="col-md-12">
                  <div class="row">
                    <!-- <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Account ID" name="AccountID" [(ngModel)]="Seller.supplier.accountId">
                      </mat-form-field>
                    </div> -->
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="ABN*"  formControlName="abn">
                        <mat-error
                        *ngIf="profileForm.get('abn').hasError('isValid')"
                        > abn already <strong>exists</strong></mat-error>
                        <mat-error >Invalid ABN</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Business Name*" formControlName="businessName">
                        <mat-error >Invalid Business Name</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">                    
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Business Email address*"  type="email" formControlName="businessEmail">
                        <mat-error >Invalid Email address</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <mat-label>Mobile*</mat-label>
                        <span matPrefix>+61 &nbsp;</span>
                        <input type="tel" matInput placeholder="000-000-000" appDigitOnly formControlName="mobile">
                        <mat-icon matSuffix>mode_edit</mat-icon>
                        <mat-error >Invalid Mobile Number</mat-error>
                      </mat-form-field>                      
                    </div>
                  </div>
                  <div class="row">                    
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <mat-label>Additional Number</mat-label>
                        <span matPrefix>+61 &nbsp;</span>
                        <input type="tel" matInput placeholder="000-000-000" appDigitOnly formControlName="additionalNumber">
                        <mat-icon matSuffix>mode_edit</mat-icon>
                        <mat-error >Invalid Additional Number</mat-error>  
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <mat-label>Phone</mat-label>
                        <span matPrefix>+61 &nbsp;</span>
                        <input type="tel" matInput placeholder="000-000-000" appDigitOnly formControlName="phone">
                        <mat-icon matSuffix>mode_edit</mat-icon>
                        <mat-error >Invalid Phone Number</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row"> 
                                     
                    <div class="col-md-12">
                      <mat-form-field class="example-full-width">
                        <textarea matInput placeholder="Address*" formControlName="address"> </textarea>
                        <mat-error >Invalid Street Address</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="City*" type="text" formControlName="city">
                        <mat-error >Invalid City</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-2">
                      <mat-form-field class="example-full-width">
  
                        <mat-label>ٍState*</mat-label>
                        <mat-select  formControlName="stateId">
                          <mat-option *ngFor="let stateObj of states" [value]="stateObj.id">{{stateObj.name}}</mat-option>
                        </mat-select>
                        <mat-error >Invalid State</mat-error>
                      </mat-form-field>
                   
                    </div>
                    <div class="col-md-2">
                      <mat-form-field class="example-full-width">
                        <mat-label>Postal Code*</mat-label>
                        <input matInput #postalCode maxlength="4" appDigitOnly placeholder="Ex. 9410" formControlName="postalCode">
                        <mat-hint align="end">{{postalCode.value.length}} / 4</mat-hint>
                        <mat-error >Invalid Postal Code</mat-error>
                      </mat-form-field>
                    </div>
  
                    <div class="col-md-3">
                      <mat-form-field class="example-full-width">
                        <mat-label>Market location</mat-label>
                        <mat-select name="Marketlocation" formControlName="marketLocation" >
                          <mat-option value="" selected>Choose here</mat-option>
                          <mat-option value="Adelaide (Pooraka)">Adelaide (Pooraka)</mat-option>
                          <mat-option value="Epping (Melbourne)">Epping (Melbourne)</mat-option>
                          <mat-option value="Sydney (Homebush)">Sydney (Homebush)</mat-option>
                          <mat-option value="Brisbane (Rocklea)">Brisbane (Rocklea)</mat-option>
                          <mat-option value="Perth (Market City)">Perth (Market City)</mat-option>
                          <mat-option value="Newcastle (Sandgate)">Newcastle (Sandgate)</mat-option>
                        </mat-select>
                        <mat-error >Invalid Market location</mat-error>
  
                      </mat-form-field>
                    </div>
                    <div class="col-md-3">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Bay" type="text" formControlName="bay" >
                        <mat-error >Invalid Bay</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                 <div class="row mt-5">
                  <div class="col-md-12"> 
                    <p style="text-align: center;">Select payment terms for this Customer</p>            
                </div>
                <div class="col-md-12"> 
                  <p style="text-align: center;">Payment terms can be changed in the future</p>            
              </div>
              <mat-form-field class="col-md-8 offset-md-2">            
                <mat-select formControlName="paymentTermsId" name="PaymentTerms">
             
                  <mat-option *ngFor="let c of paymentTerms" [value]="c.id">
                    {{c.name}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
                 </div>
                </div>
              </div>
              
              <div class="row">
                
                <div class="col-md-3 mr-3 text-center">
                  <button mat-button  type="submit" [disabled]="!profileForm.valid" class="btn btn-primary"  >
                    Submit
                </button>
                </div>
                <div class="col-md-2 text-center">
                  <button mat-button class="btn btn-danger" [mat-dialog-close]="">
                    Cancel
                </button>
                </div>    
            </div>

            </div>
            <div *ngIf="! showProfile">
              <img src="/assets/img/403.png">
            </div>
          </div>
        </div>
  
      </div>

      
    </div>
  </form>
  </div>

