import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { CellClickEvent, CellCloseEvent, DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { UnderApproveDialog } from '../under-approve/under-approve.dialog';
import { UploadService } from 'app/shared/services/upload.service';
import { ABNValidator } from 'app/shared/validators/ABNValidator';
import { MatStepper } from '@angular/material/stepper';
import { environment } from 'environments/environment';
import { SellerCustomerModel } from 'app/shared/models/seller/sellerCustomerModel';
import { SellerAddressModel } from 'app/shared/models/address/seller_address.model';
import { StateModel } from 'app/shared/models/sites/site.model';

@Component({
  selector: 'app-details',
  templateUrl: './details.dialog.html',
  styleUrls: ['./details.dialog.css']
})
export class DetailsDialog implements OnInit {
  public opened = true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  imageSource = '';
  profileImageUrl='';
  Seller: SellerViewModel;
  showProfile: boolean = true;
  displayMessage: string;
  firstStepCompleted:boolean=false;
  firstStepIsEditable:boolean=true;
  public profileForm: FormGroup;
  public paymentForm: FormGroup;
  public sellerCustomer: SellerCustomerModel;
  isLinear = false;
  states: StateModel[];
  public isLoading : boolean=true;
public selectedIndex:number=0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('stepper') stepper: MatStepper;
  myScriptElement: HTMLScriptElement;
  constructor(public dialogRef: MatDialogRef<DetailsDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: { hasProfile: boolean},public dialog: MatDialog,private sellerservice: SellerService,
    private userService: UserService,private uploadService: UploadService,
    private SellerService: SellerService,
    public fb: FormBuilder,private snackbar: MatSnackBar

  ) {

  }


   ngOnInit(): void {
    this.spinner.show(); 
    this.sellerservice.getStates().subscribe((data) => {
      this.states=data;
    });
    if( this.data.hasProfile)
    {
      this.SellerService.getSeller().subscribe(result => {

        this.SellerService.getSellerCustomer(result.id).subscribe(res => {
         
          this.firstStepCompleted=true;
          this.firstStepIsEditable=false;
          this.selectedIndex=1;
          this.sellerCustomer=res;
          this.spinner.hide(); 
          this.isLoading = false;
           
      });
      });
      
    }
    else
    {
      this.spinner.hide(); 
        this.isLoading = false;
    }

    this.profileForm=this.fb.group(
      {
        businessName:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        abn:["",[Validators.maxLength(250),Validators.minLength(11),Validators.maxLength(11),Validators.required,Validators.pattern('[a-zA-Z0-9]*')],[ABNValidator.createValidator(this.sellerservice)]],
        address:["",[Validators.minLength(3),Validators.maxLength(250),Validators.required]],
        city:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        stateId:[null,[Validators.required]],
        postalCode:["",[Validators.maxLength(4),Validators.minLength(4),Validators.required]],
        marketLocation:[""],
        bay:["",[Validators.maxLength(250)]],
        ownerName:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        businessEmail:[""],
        ownerEmail:["",[Validators.email,Validators.required]],
        mobile:["",[Validators.minLength(9),Validators.maxLength(10),Validators.required]],
        phone:[""],
        additionalNumber:[""]
      }
    
    )

  }

  get f() { return this.profileForm.controls; }

  public close(status: string): void {
   
    this.opened = false;
  }
  openSellerDetails()
  {
   
    
  }


  onSubmit() {
    this.spinner.show(); 
  let profile: SupplierViewModel= this.profileForm.value;
  profile.timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
  profile.businessEmail = profile.ownerEmail;
  profile.profileImageUrl_Normal=this.profileImageUrl;
  profile.profileImageUrl_Small=this.profileImageUrl;
  let address = new SellerAddressModel();
         address.addressLine1= this.profileForm.controls['address'].value;
        address.city= this.profileForm.controls['city'].value;
        address.postalCode= this.profileForm.controls['postalCode'].value;
        address.mobile= this.profileForm.controls['mobile'].value;
        address.additionalNumber= this.profileForm.controls['additionalNumber'].value;
        address.phone= this.profileForm.controls['phone'].value;
        address.stateId= this.profileForm.controls['stateId'].value;
        address.isDefault=true;
        let addresses: SellerAddressModel[]=[];
        addresses.push(address);
        profile.addresses=addresses;
this.SellerService.addSupplier(profile).subscribe((data) => {
  this.spinner.hide(); 
  Swal.fire({
    icon: 'success',
    title: 'Success',
    html: "Your account should get approved within 24 hours",
    showCloseButton: false,
    showCancelButton: false,
    confirmButtonColor: "#0c6acb",
    timer:3000
  }).then(()=>{
    window.location.reload();
  
  });
},
error => {

  this.snackbar.open(error, 'close', {
    duration: 1000,
    horizontalPosition: this.horizontalPosition,
    verticalPosition: this.verticalPosition
  });
}
);

 }

 upload(files) {
  this.spinner.show(); 
  console.log(files[0]);
    const formData = new FormData();
    // formData.set("json", JSON.stringify(this.Seller.supplier));
    if (files[0]) {
      ;
      formData.append(`${files[0].name}`, files[0]);
      // formData.append('image', files[0]);
 
    }
    this.spinner.show();
    this.uploadService
      .uploadSellerImage(formData)
      .subscribe(
        (data) => {
          this.profileImageUrl = data[0].absoluteUri;
          this.imageSource = data[1].absoluteUri;
          this.spinner.hide(); 

        },
        error => {
          this.spinner.hide(); 
          this.snackbar.open("failed to upload image", 'close', {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        }
      );
  }
  loadPaymentStep()
  {
    this.spinner.show(); 
    this.SellerService.getSellerCustomer().subscribe(res => {
        this.firstStepCompleted=true;
        this.firstStepIsEditable=false;
        this.sellerCustomer=res;
        this.spinner.hide(); 
        this.isLoading = false;
     
      });
  }

}
