<mat-card>
  <form [formGroup]="form" (ngSubmit)="addWasteBatch()">
  <mat-card-content>

    

   
    <section class="d-flex">
     <mat-form-field class="col-md-4">
        <mat-label>Quantity</mat-label>
        <input matInput type="number" formControlName="quantity" [(ngModel)]="wasteBatch.quantity" required id="quantity" />
        <mat-error *ngIf="hasError('quantity', 'required')">Quantity is required</mat-error>
        <mat-error *ngIf="hasError('quantity', 'min')">Minimum required quantity is 1.</mat-error>
        <mat-error *ngIf="hasError('quantity', 'max')">Maximum required quantity is {{stockMax}}.</mat-error>
     </mat-form-field>
   
     <mat-form-field class="col-md-4">
        <mat-label>Reason</mat-label>
        <input matInput type="text"  formControlName="reason" [(ngModel)]="wasteBatch.reason" required  id="reason"/>
        <mat-error *ngIf="hasError('reason', 'required')">Reason is required</mat-error>
        <mat-error *ngIf="hasError('reason', 'maxLength')">Maximum length  is 250.</mat-error>
     </mat-form-field>
     
    </section>
  
  </mat-card-content>
  <mat-card-actions align="end">
    <!-- <mat-error *ngIf="showErrors">
      Please enter all mandatory fields
    </mat-error> -->
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit"  [disabled]="!form.valid">Save</button>
  </mat-card-actions> 
</form>
</mat-card>
