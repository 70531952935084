import { Component, OnInit } from '@angular/core';
import { MsalService} from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { chartdata, DailyOrders, DashBoardViewModel, MonthlyOrdersByStatusChartData, Months } from 'app/shared/models/dashboard/dashboardViewModel';
import { DashBoardService } from 'app/shared/services/dashboard.service';
import { UtilsService } from 'app/shared/services/utils.service';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from "ngx-spinner";
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { SellerViewModel } from 'app/shared/models/seller';
import { SeriesLabelsComponent } from "@progress/kendo-angular-charts";
import { share } from 'rxjs/operators';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  public colors: string[] = [];
  private categoryColorMap: { [key: string]: string } = {
    'Submitted': '#5fa136',
    'Rejected': '#f44336',
    'Awaiting Confirmation': '#ff9800',
    'Confirmed': '#0c6acb',
    'Fulfilled': '#5fa136',
  };
  loadingPanelVisible = true;
  SupplierId: number;
  dashBoardViewModel: DashBoardViewModel;
  dashBoardStatisticsModel: DashBoardViewModel;
  dataCompletedTasksChart: chartdata;
  dataDailySalesChart: chartdata;
  dailyOrdersModel: DailyOrders[];
  noOfOrders: number[];
  dailyOrderDays: string[];
  showDashboard: boolean = true;
  ordersData: MonthlyOrdersByStatusChartData[];
  monthlyOrdersTotal: number;
  weeklyOrdersTotal: number;
  private readonly _destroying$ = new Subject<void>();
  type: string = 'Line';

  constructor(
    private authService: MsalService,
    private dashBoardService: DashBoardService, private utilities: UtilsService,
    private spinner: NgxSpinnerService,
    private sellerService: SellerService,
    private userService: UserService) {
  }
  ngOnInit() {
    this.initDashBoard();       
  }
  public labelContent(e:any): string {    
    return e.category;
  }
  loadPermissions(seller: SellerViewModel) {   
     this.userService.hasPermissionFor(PermissionsConstants.DASHBOARD, PermissionsConstants.READ, seller.id).subscribe(x => {this.showDashboard = x});    
  }

  initDashBoard() {
    this.sellerService.getSeller().subscribe(seller => {      
      this.userService.hasPermissionFor(PermissionsConstants.DASHBOARD, PermissionsConstants.READ, seller.id)
      .subscribe(x => {
        this.showDashboard = x  ;
        if(this.showDashboard)
        {
          this.dashBoardService.GetNumberOfDailyOrdersForTheCurrentWeek(seller.id).subscribe(dailyOrders => {
            this.noOfOrders = dailyOrders.map(item => item.numberofOrders);
            this.dailyOrderDays = dailyOrders.map(item => item.day);
            this.weeklyOrdersTotal = this.noOfOrders.reduce(function(a, b){
              return a + b;
            });
          });        

          this.dashBoardService.GetMonthlyOrdersByStatus(seller.id).subscribe(monthlyOrders => {
            this.ordersData = monthlyOrders.groupedOrders.map((item) => {return {kind : item.status, share: item.percentage};});
            this.ordersData.forEach(x => this.colors.push(this.categoryColorMap[x.kind]));
            this.monthlyOrdersTotal = monthlyOrders.totalNumberOfOrders;
          });        

          this.dashBoardService.GetDashBoardStatistics(seller.id).subscribe((data) => {
            this.dashBoardStatisticsModel = data;
          });

        this.dashBoardService.GetSupplierDashBoard(seller.id).subscribe((data) => {
          this.dashBoardViewModel = data
          this.dataCompletedTasksChart = new chartdata();
          this.dataDailySalesChart = new chartdata();
          this.dataDailySalesChart.labels = [];
          this.dataDailySalesChart.series = [];
          this.dataDailySalesChart.series[0] = [];
          this.dataCompletedTasksChart.labels = [];
          this.dataCompletedTasksChart.series = [];
          this.dataCompletedTasksChart.series[0] = [];
          this.dashBoardViewModel.ordersRate.forEach(x => {
            this.dataCompletedTasksChart.labels.push(x.day);
            this.dataCompletedTasksChart.series[0].push(x.ordersPercentage);
          });
          this.dashBoardViewModel.dailySalesRates.forEach(x => {
            this.dataDailySalesChart.labels.push(x.day);
            this.dataDailySalesChart.series[0].push(x.revenue);
          });
          if (this.dashBoardViewModel.ordersRate.length >= 2) {
            this.getPercentageChange(this.dashBoardViewModel.ordersRate[this.dashBoardViewModel.ordersRate.length - 2].ordersPercentage, this.dashBoardViewModel.ordersRate[this.dashBoardViewModel.ordersRate.length - 1].ordersPercentage);
          }
          else {
            this.getPercentageChange(1, 1);
          }
          if (this.dashBoardViewModel.dailySalesRates.length >= 2) {
    
            this.getdailyPercentageChange(this.dashBoardViewModel.dailySalesRates[this.dashBoardViewModel.dailySalesRates.length - 2].revenue, this.dashBoardViewModel.dailySalesRates[this.dashBoardViewModel.dailySalesRates.length - 1].revenue);
    
          }
          else {
            this.getdailyPercentageChange(1, 1);
    
          }
    
          this.spinner.hide();
          this.loadingPanelVisible = false;
          //this.loadingPanelVisible2 = false;
          // this.eventEmitterService.invokeHideSpinnerfunction.emit();
    
        });
      }
      else{
       
        this.dataCompletedTasksChart = new chartdata();
          this.dataDailySalesChart = new chartdata();
          this.dataDailySalesChart.labels = [];
          this.dataDailySalesChart.series = [];
          this.dataDailySalesChart.series[0] = [];
          this.dataCompletedTasksChart.labels = [];
          this.dataCompletedTasksChart.series = [];
          this.dataCompletedTasksChart.series[0] = [];
          this.spinner.hide();
          this.loadingPanelVisible = false;         
      }
    
  })}); 
  }


  getPercentageChange(oldNumber, newNumber) {

    //debugger;
    var deff = newNumber - oldNumber;
    this.dataCompletedTasksChart.increased = newNumber > oldNumber
    this.dataCompletedTasksChart.percentage = Math.round(((deff / oldNumber) * 100));
    
  }
  getdailyPercentageChange(oldNumber, newNumber) {

    //debugger;
    var deff = newNumber - oldNumber;
    this.dataDailySalesChart.increased = newNumber > oldNumber;
    this.dataDailySalesChart.nochange = deff == 0;
    this.dataDailySalesChart.decreased = newNumber < oldNumber;
    if(! this.dataCompletedTasksChart.nochange){
    this.dataDailySalesChart.percentage = Math.round(((deff / oldNumber) * 100));
    } else {
      this.dataDailySalesChart.percentage = 0;
    }
  }
}
