import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { UserService } from 'app/shared/services/user.service';
import { InviteUserModel, UserModel } from 'app/shared/models/user/user.model';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import Swal from 'sweetalert2'
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Clipboard } from '@angular/cdk/clipboard';

@Component({
  selector: 'invitations-dialog',
  templateUrl: './invitations.dialog.html',
  styleUrls: ['./invitations.dialog.css']

})
export class InvitationsDialog implements OnInit {  
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';  
 @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public userModel: UserModel[]=[new UserModel(),new UserModel(),new UserModel()];
  public givenName: string;
  public email: string;
  public link: string;
  public loading: boolean = false;
 
  public myForm: FormGroup;

  constructor(public dialogRef: MatDialogRef<InvitationsDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userService: UserService,    
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private clipboard: Clipboard,
    private http: HttpClient, httpErrorHandler: HttpErrorHandler
  ) { 
    
    //this.setSelectableSettings();
  }
  
  ngOnInit(): void { 
   this.myForm=this.fb.group({
    users:this.fb.array(
      [
        this.fb.group({
          email: ["", [Validators.required,Validators.email]]
        }),
        this.fb.group({
          email: ["", [Validators.email]]
        }),
        this.fb.group({
          email: ["", [Validators.email]]
        })
      ]
    )
    }
   )
    const s=this.userService.getInvitationLink().subscribe((res: any) => {   
      this.link=res.data;
    }
    );

  }
  
  submit() {      
    let data:  any = this.myForm.value;
     this.userService.InviteUser(data.users).subscribe((response) => {   
       Swal.fire({
         icon: 'success',
         title: 'Success',
         html: "<strong> Invitation was sent successfully  </strong>",
         showCloseButton: false,
         showCancelButton: false,
         confirmButtonColor: "#0c6acb"
       })         
         this.dialogRef.close();
     });
  }

  trackByIndex(index: number, obj: any): any {
    return index;
  }
  copyText(textToCopy: string) {
    this.clipboard.copy(textToCopy);
}

get usersArray() {
  return <FormArray>this.myForm.get("users");
  }
  track(index, track) {
    return track.email;
   }
}
