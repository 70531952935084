import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
  } from '@angular/forms';
  import { Observable } from 'rxjs';
  import { map } from 'rxjs/operators';
import { SellerService } from '../services/Seller.Service';

  
  export class ABNValidator {
    static createValidator(sellerService: SellerService): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {
        return sellerService
          .IsValidABN(control.value)
          .pipe(
            map((result: boolean) =>
              !result ? { isValid: true } : null
            )
          );
      };
    }
  }