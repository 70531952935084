import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { UtilsService } from 'app/shared/services/utils.service';

import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';


import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { GroupedOrdersSummaryResponse } from '../models/buyer/buyer-orders.model';
import { OrderRequest, OrderUpdateRequest, PendingOrdersbyBuyer, PendingOrdersbyProduct, PlainProductInOrders, ProductLineItemDetails } from '../models/order/PlainProductInOrders.Model';
import { ManualOrderModel, OrderSummaryResponse } from '../models/order/order.model';
import { SellerService } from './Seller.Service';
import { PageModel } from '../models/pageModel';



const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  headers: Headers;
  apiURl: string;
  private sellerService: SellerService;
  private handleError: HandleError;



  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, sellerService: SellerService) {
    this.handleError = httpErrorHandler.createHandleError('SellerService');
    this.apiURl = environment.apibaseUrl;
    this.sellerService = sellerService;
    //this.sellerId = this.sellerService.sellerViewModel.id;
  }
  GetPendingOrdersGroupbyBuyer(pageNo:number,limit:number): Observable<PageModel<PlainProductInOrders>> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/GetPendingOrdersGroupbyBuyer?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<PlainProductInOrders>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<PlainProductInOrders>>('GetPendingOrdersGroupbyBuyer'))
    );
  }

  GetPendingOrdersGroupbyProduct(): Observable<PendingOrdersbyProduct[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/GetPendingOrdersGroupbyProduct`;
        return this.http.get<PendingOrdersbyProduct[]>(url, httpOptions)}),
      catchError(this.handleError<PendingOrdersbyProduct[]>('GetPendingOrdersGroupbyProduct'))
    );
  }

  ConfirmItem(OrderUpdateRequest: OrderUpdateRequest): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/ConfirmItem`;
        return this.http.put(url, OrderUpdateRequest, httpOptions)}),
      catchError(this.handleError<any>('ConfirmItem'))
    );
  }

  RejectItem(OrderUpdateRequest: OrderUpdateRequest): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/RejectItem`;
        return this.http.put(url, OrderUpdateRequest, httpOptions)}),
      catchError(this.handleError<any>('RejectItem'))
    );
  }

  GetProductCategoriesOrderCreation(): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/Categories`;
        return this.http.get(url)}),
      catchError(this.handleError<any>('GetBuyersForOrderCreation'))
    );
  }

  GetBuyersForOrderCreation(): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/Buyers`;
        return this.http.get(url)}),
      catchError(this.handleError<any>('GetBuyersForOrderCreation'))
    );
  }
  GetCustomersForOrderCreation(): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/Customers`;
        return this.http.get(url)}),
      catchError(this.handleError<any>('GetCustomersForOrderCreation'))
    );
  }
  CreateManualOrder(manualOrderModel: ManualOrderModel): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/ManualOrder`;
        return this.http.post(url, manualOrderModel, httpOptions)}),
      catchError(this.handleError<any>('CreateManualOrder'))
    );
  }

  MarkOrderAsFulfilled(OrderUpdateRequest: OrderUpdateRequest): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/FulfillOrder`;
        return this.http.put(url, OrderUpdateRequest, httpOptions)}),      
      catchError(this.handleError<any>('FulfillOrder'))
    );
  }
  ConfirmAllProductForCustomer(OrderUpdateRequest: OrderUpdateRequest): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/ConfirmAllProductForBuyer`;
        return this.http.put(url, OrderUpdateRequest, httpOptions)}),
      catchError(this.handleError<any>('ConfirmAll'))
    );
  }
  ConfirmProductInAllOrders(OrderUpdateRequest: OrderUpdateRequest): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/ConfirmProductInAllOrders`;
        return this.http.put(url, OrderUpdateRequest, httpOptions)}),
      catchError(this.handleError<any>('ConfirmAll'))
    );
  }
  ChangeRequestedQuantity(OrderUpdateRequest: OrderUpdateRequest): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {        
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/ChangeRequestedQuantity`;
        return this.http.put(url, OrderUpdateRequest, httpOptions)}),
      catchError(this.handleError<any>('ChangeRequestedQuantity'))
    );
  }
  ChangeOrderItemQuantity(orderRequest: OrderRequest): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {        
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/ChangeOrderItemQuantity`;
        return this.http.put(url, orderRequest, httpOptions)}),
      catchError(this.handleError<any>('ChangeOrderItemQuantity'))
    );
  }

  SetUnitPrice(OrderUpdateRequest: OrderUpdateRequest): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/SetUnitPrice`;
        return this.http.put(url, OrderUpdateRequest, httpOptions)}),
      catchError(this.handleError<any>('SetUnitPrice'))
    );
  }

  GetOrdersforSupplier(): Observable<GroupedOrdersSummaryResponse[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/GetOrdersforSupplier`;
        return this.http.get<GroupedOrdersSummaryResponse[]>(url, httpOptions)}),
      catchError(this.handleError<GroupedOrdersSummaryResponse[]>('GetOrdersforSupplier'))
    );
  }
  FilterOrdersforSupplier(startDate: Date, endDate: Date, status: number,pageNo:number,limit:number): Observable<PageModel<OrderSummaryResponse>> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        var url = `${this.apiURl}/api/v1/${seller.id}/Ordering/FilterOrdersforSupplier?status=${status}`;
        if(startDate && endDate) {
          url = `${this.apiURl}/api/v1/${seller.id}/Ordering/FilterOrdersforSupplier?startDate=${startDate?.toISOString()}&endDate=${endDate?.toISOString()}&status=${status}&pageNo=${pageNo}&limit=${limit}`;
        }
        return this.http.get<PageModel<OrderSummaryResponse>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<OrderSummaryResponse>>('GetOrdersforSupplier'))
    );
  }
  GetOrderLineItemsDetails(OrderId: number): Observable<ProductLineItemDetails[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/GetOrderLineItemsDetails?OrderId=${OrderId}`;
        return this.http.get<ProductLineItemDetails[]>(url, httpOptions)}),
      catchError(this.handleError<ProductLineItemDetails[]>('GetOrderLineItemsDetails'))
    );
  }
  RemoveItem(OrderItemId: number): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/RemoveItem?OrderItemId=${OrderItemId}`;
        return this.http.get(url, httpOptions)}),
      catchError(this.handleError<any>('ConfirmItem'))
    );
  }
  FulfillOrderItems(orderItemsIds: number[]): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Ordering/FulfillOrderItem`;
        return this.http.put(url, orderItemsIds, httpOptions)}),
      catchError(this.handleError<any>('RejectItem'))
    );
  }
}
