import { SellerAddressModel } from "../address/seller_address.model";


export class SupplierViewModel {


    id: number;
    businessName: string;
    accountId: string;
    abn: string;
    profileImageUrl_Normal: string;
    profileImageUrl_Small: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    marketLocation: string;
    bay: string;
    ownerName: string;
    businessEmail: string;
    ownerEmail: string;
    mobile: string;
    phone: string;
    additionalNumber: string;
    active: boolean;
    cardInfo: CardInfoModel;
    timeZone: string;
    addresses:SellerAddressModel[]
}
export class CardInfoModel {

    nameOnCard: string;
    cardNumber: string;
    expirationMonth: number;
    expirationYear: number;
    cardCVVNumber: number;

}