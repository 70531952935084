<div class="card" >
    
<div class="card-body" *ngIf="showSites">
    <div class="row">
        <div class="card-body">          
            <button mat-raised-button matTooltip="Add New Site" [disabled]="!this.featurePermission.create" (click)="newSite(true,null)" [matTooltipPosition]="'above'"
            class="btn btn-primary btn-round"  >
            <i class="material-icons">note_add</i> Add New Site

        </button>
        </div>
      </div>
    <div class="tab-content">
        <div class="tab-pane active" id="active">
            <kendo-grid [data]="gridView" [loading]="loading" [pageSize]="pageSize" [skip]="skip"
                [pageable]="true" [resizable]="true" (pageChange)="pageChange($event)">
    
                <kendo-grid-column field="data" title="Site Name"
                    class='k-text-center font-weight-bold'
                    headerClass="k-text-center font-weight-bold" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <a mat-button class="nav-link" matTooltip="Site Name" (click)="newSite(true,dataItem)" >
                            {{dataItem.name}}
                            <div class="ripple-container"></div>
                        </a>

                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="data" title="Code" class='k-text-center font-weight-bold'
                    headerClass="k-text-center font-weight-bold" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <a mat-button class="nav-link" matTooltip="Code">
                            {{dataItem.code}}
                            <div class="ripple-container"></div>
                        </a>

                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="data" title="Adress" class='k-text-center font-weight-bold'
                    headerClass="k-text-center font-weight-bold" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                        <a mat-button class="nav-link" matTooltip="Code">
                            {{dataItem.addressLine1}} - {{dataItem.city}} - {{dataItem.postalCode}}
                            <div class="ripple-container"></div>
                        </a>

                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
       
        
    </div>

</div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading ... </p>
</ngx-spinner>
<div *ngIf="! showSites">
    <img src="/assets/img/403.png">
  </div>