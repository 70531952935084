<mat-card>
  <mat-card-header class="mb-3">
    <mat-card-title class="cardHeader"> Product Catgeories for : {{MarketingGroup.groupName}} </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex">
      <div class="row">
        <div class="col-md-5 col-sm-6" *ngFor="let cat of categories">
          <mat-checkbox class="pt-3" labelPosition="after" [(ngModel)]="cat.selected" color="primary">
            {{cat.categoryName}} </mat-checkbox>
        </div>
      </div>
    </section>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()" [mat-dialog-close]="MarketingGroup" cdkFocusInitial>Save</button>
  </mat-card-actions>
</mat-card>
