import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MarketingGroupViewModel, SupplierGroupsViewModel } from 'app/shared/models/promotions/marketing.model';
import { MarketingService } from 'app/shared/services/marketing.service';


@Component({
  selector: 'app-marketing-group',
  templateUrl: './marketing-group.dialog.html',
  styleUrls: ['./marketing-group.dialog.css']
})
export class MarketingGroupDialog {
  MarketingGroup: MarketingGroupViewModel
  calbackcompleted: boolean;
  supplierGroupsViewModel: SupplierGroupsViewModel;
  rateControl:FormControl;


  constructor(
    public dialogRef: MatDialogRef<MarketingGroupDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { editmode: boolean, marketingGroup: MarketingGroupViewModel }, private marketingService: MarketingService) {
  
    this.MarketingGroup = data.marketingGroup;
  }

  ngOnInit(): void {
    this.rateControl = new FormControl("", [Validators.max(100), Validators.min(0)]);
  }

  activateDeactivate(): void {
    this.marketingService.ActivateOrDeactivateMarketingGroup(this.MarketingGroup);
  }
  save(): void {
    this.supplierGroupsViewModel = new SupplierGroupsViewModel();

    this.calbackcompleted = false;

    if (this.data.editmode) {
      this.marketingService.UpdateMarketingGroup(this.MarketingGroup).subscribe((result) => {

        this.marketingService.GetSupplierGroups().subscribe((data) => {
          this.supplierGroupsViewModel = data
          this.calbackcompleted = true;

          
        });

        
      },
        error => {
       


        }
      );

    }
    else {

      this.marketingService.CreateMarketingGroup(this.MarketingGroup).subscribe((result) => {

        this.marketingService.GetSupplierGroups().subscribe((data) => {
          this.supplierGroupsViewModel = data
          this.calbackcompleted = true;

          
        });

        
      },
        error => {
          


        }
      );
      
    }

    this.waites();

    this.dialogRef.close(true);
  }



  waites() {
    setTimeout(() => {
      if (!this.calbackcompleted) {
        this.waites();
      }
      //<<<---using ()=> syntax
    }, 3000);
  }

}
