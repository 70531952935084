<form [formGroup]="zoneForm"  novalidate>
  <div class="row">
            
    <div class="col-md-6">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Zone Name*" formControlName="name">
        <mat-error >Invalid Zone Name</mat-error>
      </mat-form-field>
    </div>

  </div>
  <div class="row">
                
    <div class="col-md-3 mr-3 text-center">
      <button mat-button  (click)="save()" [disabled]="!zoneForm.valid" class="btn btn-primary"  >
        Save
    </button>
    </div>
    <div class="col-md-2 text-center">
      <button mat-button class="btn btn-danger" (click)="close()">
        Cancel
    </button>
    </div>    
</div>
 
</form>