import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import {  DataBindingDirective } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerCustomerModel } from 'app/shared/models/seller/sellerCustomerModel';



@Component({
  selector: 'app-paymentCart',
  templateUrl: './paymentCart.dialog.html',
  styleUrls: ['./paymentCart.dialog.css']
})
export class PaymentCartDialog implements OnInit {
  public sellerCustomer: SellerCustomerModel;
  public isLoading : boolean=true;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(public dialogRef: MatDialogRef<PaymentCartDialog>,private spinner: NgxSpinnerService, private sellerService: SellerService, 
    @Inject(MAT_DIALOG_DATA) public data: { hasProfile: boolean},public dialog: MatDialog,
  ) {

  }

  ngOnInit(): void {
    this.spinner.show(); 
    
    if( this.data.hasProfile)
    {
      this.sellerService.getSeller().subscribe(result => {

        this.sellerService.getSellerCustomer(result.id).subscribe(res => {
         
          this.sellerCustomer=res;
          this.spinner.hide(); 
          this.isLoading = false;
           
      });
      });
      
    }
    else
    {
      this.spinner.hide(); 
        this.isLoading = false;
    }
  }



}
