import { CustomerAddressModel } from "../address/customer_address.model"
import { BuyerViewModel } from "../promotions/marketing.model"

export class CustomerModel {
        id:number
        businessName:string
        businessEmail :string
        abn :string
        marketLocation :string
        bay :string
        profileImage :string
        profileThumbnail :string
        sellerId :number
        customerSourceId: number
        customerSourceValue:string
        externalId:string
        buyerId? :number
        paymentTermsId: number
        isActive:boolean
        connectionStatusId:number
        buyer:BuyerViewModel
        addresses:CustomerAddressModel[]
}
export enum CustomerSource {
        'Buyer' = 1, 'MYOB' = 2, 'Xero' = 3, 'Manual' = 4
    }
