import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { CellClickEvent, CellCloseEvent, DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UploadService } from 'app/shared/services/upload.service';
import { MatStepper } from '@angular/material/stepper';
import { SellerCustomerModel } from 'app/shared/models/seller/sellerCustomerModel';
import { MarketingService } from 'app/shared/services/marketing.service';
import { CustomerModel, CustomerSource } from 'app/shared/models/customer/customer.model';
import { CustomerABNValidator } from 'app/shared/validators/CustomerABNValidator';
import { ExternalCustomerModel } from 'app/shared/models/integration/external.customer.model';
import { CustomerAddressModel } from 'app/shared/models/address/customer_address.model';
import { StateModel } from 'app/shared/models/sites/site.model';
import { LookupViewModel } from 'app/shared/models/LookupViewModel';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-customer-details',
  templateUrl: './customer-details.dialog.html',
  styleUrls: ['./customer-details.dialog.css']
})
export class CustomerDetailsDialog implements OnInit {
  public opened = true;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  imageSource = '';
  profileImageUrl='';
  Seller: SellerViewModel;
  showProfile: boolean = true;
  displayMessage: string;
  firstStepCompleted:boolean=false;
  firstStepIsEditable:boolean=true;
  isMarketPlaceSource:boolean;
  public profileForm: FormGroup;
  public paymentForm: FormGroup;
  public sellerCustomer: SellerCustomerModel;
  isLinear = false;
  states: StateModel[];
  paymentTerms: LookupViewModel[];
  public isLoading : boolean=true;
public selectedIndex:number=0;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('fileInput') fileInput: ElementRef;
  myScriptElement: HTMLScriptElement;
  constructor(public dialogRef: MatDialogRef<CustomerDetailsDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: { hasProfile: boolean, externalCustomer: ExternalCustomerModel,Customer: CustomerModel,editmode: boolean},public dialog: MatDialog,private sellerservice: SellerService,
    private userService: UserService,private uploadService: UploadService,
    private SellerService: SellerService,
    public fb: FormBuilder,private snackbar: MatSnackBar,
    private marketingService: MarketingService

  ) {
  }


   ngOnInit(): void {
    this.spinner.show();
   this.isMarketPlaceSource= this.data?.Customer?.customerSourceId==CustomerSource.Buyer;
    this.sellerservice.getAllPaymentTerms().subscribe(res => {
 
      this.paymentTerms = res;
    });
    this.sellerservice.getStates().subscribe((data) => {
      this.states=data;
      this.spinner.hide();
    });
    
if( this.isMarketPlaceSource)
{
  
  this.createBuyerFormGroup();
}
else
{
  this.createCustomerFormGroup();
}
    
    
    if(this.data!= null && this.data?.externalCustomer) {      
      console.log(this.data.externalCustomer.paymentTerms?.id);
      this.profileForm.controls['businessName'].setValue(this.data.externalCustomer.name);
      this.profileForm.controls['abn'].setValue(this.data.externalCustomer.abn);
      this.profileForm.controls['businessEmail'].setValue(this.data.externalCustomer.email);      
      this.profileForm.controls['paymentTermsId'].setValue(this.data.externalCustomer.paymentTerms?.id);
    }
  }
  createCustomerFormGroup()
  {
    let customerId=this.data==null?null:this.data?.Customer?.id;
    this.profileForm=this.fb.group(
      {
        businessName:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        abn:["",[Validators.maxLength(250),Validators.minLength(11),Validators.maxLength(11),Validators.required,Validators.pattern('[a-zA-Z0-9]*')],[CustomerABNValidator.createValidator(this.marketingService,customerId)]],
        address:["",[Validators.minLength(3),Validators.maxLength(250),Validators.required]],
        city:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        stateId:[null,[Validators.required]],
        postalCode:["",[Validators.maxLength(4),Validators.minLength(4),Validators.required]],
        marketLocation:[""],
        bay:["",[Validators.maxLength(250)]],
        businessEmail:["",[Validators.email,Validators.required]],
        mobile:["",[Validators.minLength(9),Validators.maxLength(10),Validators.required]],
        phone:[""],
        additionalNumber:[""],
        paymentTermsId:[null,[Validators.required]]
      }      
    );
    if(this.data.editmode)
    {
      this.profileImageUrl=this.data.Customer?.profileImage;
      let address=this.data.Customer?.addresses.filter(s=>s.isDefault)[0];
      this.profileForm.patchValue({
        businessName: this.data.Customer.businessName,
        abn: this.data.Customer.abn,
        address: address.addressLine1,
        city: address.city,
        stateId: address.stateId,
        postalCode: address.postalCode,
        marketLocation: this.data.Customer.marketLocation,
        bay: this.data.Customer.bay,
        businessEmail: this.data.Customer.businessEmail,
        mobile:address.mobile,
        phone: address.phone,
        additionalNumber: address.additionalNumber,
        paymentTermsId:this.data.Customer.paymentTermsId
      });
    }
  }
  createBuyerFormGroup()
  {
    let customerId=this.data==null?null:this.data?.Customer?.id;
   
    this.profileForm=this.fb.group(
      {
        businessName:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        abn:["",[Validators.maxLength(250),Validators.minLength(11),Validators.maxLength(11),Validators.required,Validators.pattern('[a-zA-Z0-9]*')],[CustomerABNValidator.createValidator(this.marketingService,customerId)]],
        address:["",[Validators.minLength(3),Validators.maxLength(250),Validators.required]],
        city:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        stateId:[null,[Validators.required]],
        postalCode:["",[Validators.maxLength(4),Validators.minLength(4),Validators.required]],
        marketLocation:[""],
        bay:["",[Validators.maxLength(250)]],
        businessEmail:["",[Validators.email,Validators.required]],
        mobile:["",[Validators.minLength(9),Validators.maxLength(10),Validators.required]],
        phone:[""],
        additionalNumber:[""],
        paymentTermsId:[null,[Validators.required]]
      }      
    );
    if(this.data.editmode)
    {
      this.profileImageUrl=this.data.Customer?.profileImage;
      let address=this.data.Customer?.buyer?.addresses.filter(s=>s.isDefault)[0];
      this.profileForm.patchValue({
        businessName: this.data.Customer.businessName,
        abn: this.data.Customer.abn,
        address: address.addressLine1,
        city: address.city,
        stateId: address.stateId,
        postalCode: address.postalCode,
        marketLocation: this.data.Customer.marketLocation,
        bay: this.data.Customer.bay,
        businessEmail: this.data.Customer.businessEmail,
        mobile: address.mobile,
        phone: address.phone,
        additionalNumber: address.additionalNumber,
        paymentTermsId:this.data.Customer.paymentTermsId
      });
      this.disableForm();
    }
  }
  get f() { return this.profileForm.controls; }

  public close(status: string): void {
   
    this.opened = false;
  }
  openSellerDetails()
  {
   
    
  }


  onSubmit() {
    Swal.fire({  
      title: 'Changing payment terms will result in all future invoices being generated with the new payment terms from now. Are you sure you want to change the payment terms?',  
      showDenyButton: true,  showCancelButton: false,  
      confirmButtonText: `Yes`,  
      denyButtonText: `No`,
    }).then((result) => {  
      if (result.isConfirmed)
      {
        this.spinner.show(); 
        let profile: CustomerModel= this.profileForm.value;
        let address = new CustomerAddressModel();
        address.id= this.isMarketPlaceSource?this.data?.Customer?.buyer?.addresses.filter(s=>s.isDefault)[0].id:this.data?.Customer?.addresses.filter(s=>s.isDefault)[0].id;
        address.addressLine1= this.profileForm.controls['address'].value;
        address.city= this.profileForm.controls['city'].value;
        address.postalCode= this.profileForm.controls['postalCode'].value;
        address.mobile= this.profileForm.controls['mobile'].value;
        address.additionalNumber= this.profileForm.controls['additionalNumber'].value;
        address.phone= this.profileForm.controls['phone'].value;
        address.stateId= this.profileForm.controls['stateId'].value;
        address.isDefault=true;
        let addresses: CustomerAddressModel[]=[];
        addresses.push(address);
        profile.addresses=addresses;
        profile.paymentTermsId = this.profileForm.controls['paymentTermsId'].value;
        profile.profileImage=this.profileImageUrl;
        profile.profileThumbnail=this.profileImageUrl;
        profile.customerSourceId=CustomerSource.Manual;
        if(this.data!= null && this.data?.externalCustomer)  {
          profile.externalId = this.data.externalCustomer.uid;
          profile.customerSourceId = this.data.externalCustomer.customerSourceId;
        }
      
        if(this.data!= null && this.data.editmode)
        {
          profile.id=this.data.Customer?.id;
          profile.isActive=this.data.Customer.isActive;
          profile.connectionStatusId=this.data.Customer.connectionStatusId;
          this.marketingService.updateCustomer(profile).subscribe((data) => {
            this.spinner.hide(); 
            this.dialogRef.close(profile);
          });
        }
        else
        {
          this.marketingService.addCustomer(profile).subscribe((data) => {
            this.spinner.hide(); 
            this.dialogRef.close(profile);
          });
        }
      }
      
    });    
    


 }
 disableForm() {
  this.profileForm.disable();
  const termsControl = this.profileForm.get('paymentTermsId') as FormControl;
  termsControl.enable();
}
 upload(files) {
  this.spinner.show();
    const formData = new FormData();
    // formData.set("json", JSON.stringify(this.Seller.supplier));
    if (files[0]) {
      ;
      formData.append(`${files[0].name}`, files[0]);
      // formData.append('image', files[0]);

    }

    this.uploadService
      .uploadImage(formData)
      .subscribe(
        (data) => {
          this.profileImageUrl = data[0].absoluteUri;
          this.imageSource = data[1].absoluteUri;
          this.spinner.hide();

        }
      );
  }
  loadPaymentStep()
  {
    this.spinner.show(); 
    this.SellerService.getSellerCustomer().subscribe(res => {
        this.firstStepCompleted=true;
        this.firstStepIsEditable=false;
        this.sellerCustomer=res;
        this.spinner.hide(); 
        this.isLoading = false;     
      });
  }
  showFull = false;

  isFullImage = false;

  toggleFullImage() {
    this.isFullImage = !this.isFullImage;
  }

  showFullImage() {
    this.isFullImage = true;
  }

  hideFullImage() {
    this.isFullImage = false;
  }
 openFileDialog(event: Event) {
  event.preventDefault();
  this.fileInput.nativeElement.click();
  }
}
