<mat-card>
  <mat-card-header class="mb-3">        
    <mat-card-title class="cardHeader"> {{System}} Customers</mat-card-title>
  </mat-card-header>
   
       
        <kendo-grid
          [kendoGridBinding]="gridView"
          kendoGridSelectBy="uid"
          [selectedKeys]="mySelection"
          [loading]="loading" 
          [pageSize]="20"
          [pageable]="true"
          [sortable]="true"
          [groupable]="false"
          [reorderable]="true"
          [resizable]="true"
          [height]="500"
          [columnMenu]="{ filter: true }"
          [selectable]="selectableSettings"
          kendoGridSelectBy="uid"
          [kendoGridSelectBy]="mySelectionKey"
          (selectedKeysChange)="keyChange($event)"
          >            
        <ng-template kendoGridToolbarTemplate>
          <input
            placeholder="Search in all columns..."
            kendoTextBox
            (input)="onFilter($event.target.value)"
          />
          <kendo-grid-spacer></kendo-grid-spacer>        
        </ng-template>        
        <kendo-grid-checkbox-column
          [width]="45"
          [headerClass]="{ 'text-center': true }"
          [class]="{ 'text-center': true }"
          [resizable]="false"
          [columnMenu]="false"
          showSelectAll="false"          
          ></kendo-grid-checkbox-column>
        <kendo-grid-column field="uid" title="UID" [width]="220" visibility="false">
        </kendo-grid-column>        
        <kendo-grid-column field="displayID" title="DisplayId" [width]="220" >          
        </kendo-grid-column>     
        <kendo-grid-column field="name" title="Company Name" [width]="220">          
        </kendo-grid-column>                         
        <kendo-grid-column field="firstName" title="First Name" [width]="220">          
        </kendo-grid-column>        
        <kendo-grid-column field="lastName" title="Last Name" [width]="220">          
        </kendo-grid-column>                
        <kendo-grid-column field="currentBalance" title="Current Balance" [width]="220">          
        </kendo-grid-column>                
        <kendo-grid-column field="notes" title="Notes" [width]="220">          
        </kendo-grid-column>                
            
    </kendo-grid> 


  
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="saveSettings()" cdkFocusInitial>Save</button>
  
</mat-card>