import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { UtilsService } from 'app/shared/services/utils.service';

import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';


import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { PromotionViewMdel } from '../models/catalogue/promotionViewModel';
import { BatchViewModel, ParentChildBatchModel } from '../models/catalogue/batchViewModel';
import { SellerService } from './Seller.Service';
import { WasteBatchViewModel } from '../models/catalogue/WasteBatchViewModel';
import { PageModel } from '../models/pageModel';
import { ProductRipeness, ProductSize, ProductType, ProductUoM } from '../models/catalogue/lockups';

import { BatchTransferModel, StockTakeModel, StockTakeRecordModel } from '../models/sites/site.model';




const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  //  options: RequestOptions;
  headers: Headers;
  apiURl: string;
  private handleError: HandleError;
  private sellerService: SellerService;
  
  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, sellerService: SellerService) {
    this.handleError = httpErrorHandler.createHandleError('ProductService');
    this.apiURl = environment.apibaseUrl;
    this.sellerService = sellerService;
  }

  getProductsforSupplier(pageNo:number,limit:number): Observable<PageModel<ProductViewMdel>> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductsListedforSupplier?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductViewMdel>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductViewMdel>>('getProductsforSupplier'))
    );
  }

  getPendingProductImportsforSupplier(): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/PendingImports`;
        return this.http.get<any>(url, httpOptions)}),
      catchError(this.handleError<any>('getPendingProductImportsforSupplier'))
    );
  }

  getProductBatches(productId: number): Observable<BatchViewModel[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductBatches?productId=${productId}`;
        return this.http.get<BatchViewModel[]>(url, httpOptions).pipe(
          tap(results => {
            results.sort(function (a, b) { return a.id - b.id })
          }))}),
      catchError(this.handleError<BatchViewModel[]>('getProductsforSupplier'))
    );
  }

  getProductCategories(): Observable<ProductCategory[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductCategories`;
        return this.http.get<ProductCategory[]>(url, httpOptions)}),
      catchError(this.handleError<ProductCategory[]>('GetProductCategories'))
    );
  }

  getSellerProductCategories(): Observable<ProductCategory[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetSellerProductCategories`;
        return this.http.get<ProductCategory[]>(url, httpOptions)}),
      catchError(this.handleError<ProductCategory[]>('GetSellerProductCategories'))
    );
  }

  getSellerProductCategoriesInGroup(groupId: number): Observable<ProductCategory[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/ProductCategoriesInGroup?groupId=${groupId}`;
        return this.http.get<ProductCategory[]>(url, httpOptions)}),
      catchError(this.handleError<ProductCategory[]>('GetSellerProductCategories'))
    );
  }

  getProductGrades(): Observable<ProductGrade[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductGrades`;
        return this.http.get<ProductGrade[]>(url, httpOptions)}),
      catchError(this.handleError<ProductGrade[]>('getProduGrades'))
    );
  }
  getProductGrowers(): Observable<ProductGrower[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductGrowers`;
        return this.http.get<ProductGrower[]>(url, httpOptions)}),
      catchError(this.handleError<ProductGrower[]>('getProductGrowers'))
    );
  }
  getProductSizes(): Observable<ProductSize[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductSizes`;
        return this.http.get<ProductSize[]>(url, httpOptions)}),
      catchError(this.handleError<ProductSize[]>('getProductSizes'))
    );
  }
  getProductRipeness(): Observable<ProductRipeness[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductRipeness`;
        return this.http.get<ProductRipeness[]>(url, httpOptions)}),
      catchError(this.handleError<ProductRipeness[]>('getProductRipeness'))
    );
  }
  getProductUoM(): Observable<ProductUoM[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductUoM`;
        return this.http.get<ProductUoM[]>(url, httpOptions)}),
      catchError(this.handleError<ProductUoM[]>('getProductUoM  '))
    );
  }
  GetProductVarietals(categoryId): Observable<ProductVarital[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetProductVarietals?ProductCategoryId=${categoryId}`;
        return this.http.get<ProductVarital[]>(url, httpOptions)}),
      catchError(this.handleError<ProductVarital[]>('GetProductVarietals'))
    );
  }
  GetProductTypes(categoryId): Observable<ProductType[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/ProductTypes?ProductCategoryId=${categoryId}`;
        return this.http.get<ProductType[]>(url, httpOptions)}),
      catchError(this.handleError<ProductType[]>('GetProducTypes'))
    );
  }

  GetAllBatchesForBuyer(BuyerId: number): Observable<BatchViewModel[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetAllBatchesForBuyer?BuyerId=${BuyerId}`;
        return this.http.get<BatchViewModel[]>(url, httpOptions)}),
      catchError(this.handleError<BatchViewModel[]>('GetProductVarietals'))
    );
  }
  GetAllBatchesForCustomer(customerId: number): Observable<BatchViewModel[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetAllBatchesForCustomer?customerId=${customerId}`;
        return this.http.get<BatchViewModel[]>(url, httpOptions)}),
      catchError(this.handleError<BatchViewModel[]>('GetAllBatchesForCustomer'))
    );
  }
  CreateProuctForSupplier(Product: ProductViewMdel): Observable<ProductViewMdel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/CreateProuctForSupplier`;
        return this.http.post<ProductViewMdel>(url, Product, httpOptions)}),
      catchError(this.handleError<ProductViewMdel>('CreateProuctForSupplier'))
    );
  }

  AddBatchToProduct(Batch: BatchViewModel): Observable<BatchViewModel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/CreateNewBatch`;
        return this.http.post<BatchViewModel>(url, Batch, httpOptions)}),
      catchError(this.handleError<BatchViewModel>('CreateProuctForSupplier'))
    );
  }

  UpdateProduct(Product: ProductViewMdel): Observable<ProductViewMdel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/UpdateProduct`;
        return this.http.put<ProductViewMdel>(url, Product, httpOptions)}),
      catchError(this.handleError<ProductViewMdel>('UpdateProduct'))
    );
  }
  UpdateProductUnitPrice(Product: ProductViewMdel): Observable<ProductViewMdel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/UpdateProductUnitPrice`;
        return this.http.put<ProductViewMdel>(url, Product, httpOptions)}),
      catchError(this.handleError<ProductViewMdel>('UpdateProductUnitPrice'))
    );
  }
  activateDeactivateProduct(ProductId: number): Observable<ProductViewMdel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/ActivateDeActivateProduct?Id=${ProductId}`;
        return this.http.put<ProductViewMdel>(url, httpOptions)}),
      catchError(this.handleError<ProductViewMdel>('activateDeactivateProduct'))
    );
  }
  ActivatePromotion(Promotion: PromotionViewMdel): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/ActivatePromotion`;
        return this.http.put<boolean>(url, Promotion, httpOptions)}),
      catchError(this.handleError<boolean>('ActivatePromotion'))
    );
  }
  DEActivatePromotion(ProductId: number): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/DeActivatePromotion?Id=${ProductId}`;
        return this.http.put<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('DEActivatePromotion'))
    );
  }

  UpdateBatchPromotions(batches: any[]): Observable<any[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/UpdateBatchPromotions`;    
        return this.http.put<any[]>(url, batches, httpOptions)}),
      catchError(this.handleError<any[]>('UpdateBatchPromotions'))
    );
  }

  AddWasteBatch(WasteBatch: WasteBatchViewModel): Observable<WasteBatchViewModel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/WriteOffBatch`;
        return this.http.post<WasteBatchViewModel>(url, WasteBatch, httpOptions)}),
      catchError(this.handleError<WasteBatchViewModel>('WriteOffBatch'))
    );
  }
  getBatchesforSupplier(pageNo:number,limit:number,siteId:number=null): Observable<PageModel<BatchViewModel>> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        let url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetBatchesforSupplier?pageNo=${pageNo}&limit=${limit}&siteId=${siteId}`;
        if(siteId==null)
        {
          url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetBatchesforSupplier?pageNo=${pageNo}&limit=${limit}`;
        }        
        return this.http.get<PageModel<BatchViewModel>>(url, httpOptions).pipe(
          tap(results => {
            results.items.sort(function (a, b) { return a.id - b.id })
          }))}),
      catchError(this.handleError<PageModel<BatchViewModel>>('getBatchesforSupplier'))
    );
  }

  getBatchesInStockTakeforSupplier(pageNo:number,limit:number,siteId:number, stockTakeId:number): Observable<PageModel<BatchViewModel>> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        let url = `${this.apiURl}/api/v1/${seller.id}/Inventory/GetBatchesInStockTakeforSupplier?siteId=${siteId}&stockTakeId=${stockTakeId}&pageNo=${pageNo}&limit=${limit}`;                
        return this.http.get<PageModel<BatchViewModel>>(url, httpOptions).pipe(
          tap(results => {
            results.items.sort(function (a, b) { return a.id - b.id })
          }))}),
      catchError(this.handleError<PageModel<BatchViewModel>>('getBatchesforSupplier'))
    );
  }

  
  transferBatches(batchTransferModel: BatchTransferModel): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Inventory/batches/${batchTransferModel.batchId}/transfer`;
        return this.http.put(url, batchTransferModel, httpOptions)}),
      catchError(this.handleError<any>('transferBatches'))
    );
  } 
  getBatchForSeller(batchId: number): Observable<BatchViewModel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetBatchforSupplier?batchId=${batchId}`;
        return this.http.get<BatchViewModel>(url, httpOptions)}),
      catchError(this.handleError<BatchViewModel>('getBatchForSeller'))
    );
  }
  hasZoneBatches(zoneId: number): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Sites/HasZoneBatches?zoneId=${zoneId}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('hasZoneBatches'))
    );
  }
  startStockTake(siteId: number): Observable<StockTakeModel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Inventory/Sites/${siteId}/StockTake`;
        return this.http.post<StockTakeModel>(url, null, httpOptions)}),
      catchError(this.handleError<StockTakeModel>('startStockTake'))
    );
    
  }
  createStockTakeRecord(stokeTakeId: number,stockTakeRecordModel:StockTakeRecordModel, update = false): Observable<Boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Inventory/StockTake/${stokeTakeId}/StockTakeRecord`;
    return update ? this.http.post<Boolean>(url, stockTakeRecordModel, httpOptions) : this.http.put<Boolean>(url, stockTakeRecordModel, httpOptions)}),
      catchError(this.handleError<Boolean>('createStockTakeRecord'))
    );
  }
  completeStockTake(stokeTakeId: number): Observable<StockTakeModel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Inventory/StockTake/${stokeTakeId}/Complete`;
    return this.http.put<StockTakeModel>(url, null, httpOptions)}),
      catchError(this.handleError<StockTakeModel>('completeStockTake'))
    );
   
  }
  getSharedProducts(productId:number): Observable<ProductViewMdel[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/GetSharedProducts?productId=${productId}`;
        return this.http.get<ProductViewMdel[]>(url, httpOptions)}),
      catchError(this.handleError<ProductViewMdel[]>('getSharedProducts'))
    );
  }

  AddBatchesToProduct(parentChildBatchModel: ParentChildBatchModel): Observable<BatchViewModel> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Catalogue/CreateNewBatches`;
        return this.http.post<BatchViewModel>(url, parentChildBatchModel, httpOptions)}),
      catchError(this.handleError<BatchViewModel>('AddBatchesToProduct'))
    );
  }
}

