<div class="tab-pane active" id="active">
    <kendo-grid [data]="gridView" [loading]="loading" [pageSize]="pageSize" [skip]="skip"
        [pageable]="true" [resizable]="true" (pageChange)="pageChange($event)">
        <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button mat-raised-button type="button"
                    [disabled]="!featurePermission.update"
                    matTooltip="set users's permissions" [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon"
                    (click)="managePermissions(dataItem, false)">
                    <i class="material-icons">settings</i>
                </button>

            </ng-template>
        </kendo-grid-column>

        <kendo-grid-column field="data" title="User Name"
            class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a mat-button class="nav-link">
                    {{dataItem.givenName}} {{dataItem.surname}}</a>

            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="Email" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <strong>{{dataItem.email}}</strong>

            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button *ngIf="!dataItem.isActive" mat-raised-button type="button"
                    [disabled]="!featurePermission.update" matTooltip="Activate User"
                    [matTooltipPosition]="'above'"
                    class="btn btn-success btn-link btn-sm btn-just-icon"
                    (click)="activateDeactivateUser(dataItem, true)">
                    <i class="material-icons">visibility</i>
                </button>
                <button *ngIf="dataItem.isActive" mat-raised-button type="button"
                    [disabled]="!featurePermission.update" matTooltip="Deactivate User"
                    [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon"
                    (click)="activateDeactivateUser(dataItem, false)">
                    <i class="material-icons">
                        visibility_off
                    </i>
                </button>
                <button mat-raised-button type="button"
                    matTooltip="Delete User from account"
                    [disabled]="!featurePermission.delete" [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon"
                    (click)="deleteUserLink(dataItem)">
                    <i class="material-icons">close</i>
                </button>

            </ng-template>
        </kendo-grid-column>


    </kendo-grid>

</div>