import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';

import { Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PageModel } from '../models/pageModel';
import { MarketingGroupBaseViewModel, SupplierGroupsViewModel, SupplierTrustedNetworks, SupplierTrustedNetworksViewModel } from '../models/promotions/marketing.model';
import { MarketUpdateViewModel } from '../models/promotions/marketupdate.model';
import { SellerService } from './Seller.Service';

import { UtilsService } from './utils.service';
import { CustomerModel } from '../models/customer/customer.model';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
    })
};

@Injectable({
    providedIn: 'root'
})
export class MarketingService {

    headers: Headers;
    apiURl: string;
    private sellerService: SellerService;
    private handleError: HandleError;



    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, sellerService: SellerService) {
        this.handleError = httpErrorHandler.createHandleError('MarketingService');
        this.apiURl = environment.apibaseUrl;
        this.sellerService = sellerService;
    }

    PostMarketingUpdate(marketUpdateViewModel: MarketUpdateViewModel): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/PostMarketingUpdate`;
                return this.http.post<any>(url, marketUpdateViewModel, httpOptions)}),
            catchError(this.handleError<any>('PostMarketingUpdate'))
        );
    }
    GetSupplierGroups(): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/GetSupplierGroups`;
                return this.http.get<SupplierGroupsViewModel>(url, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('GetSupplierGroups'))
        );
    }
    deleteMarketingGroup(marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/${marketingGroupViewModel.id}`;
                return this.http.delete<SupplierGroupsViewModel>(url, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('deleteMarketingGroup'))
        );
    }
    UpdateMarketingGroup(marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/UpdateMarketingGroup`;
                return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('UpdateMarketingGroup'))
        );
    }
    CreateMarketingGroup(marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/CreateMarketingGroup`;
                return this.http.post<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('CreateMarketingGroup'))
        );
    }

    ActivateOrDeactivateMarketingGroup(marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/ActivateOrDeactivateMarketingGroup`;
                return this.http.post<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('ActivateOrDeactivateMarketingGroup'))
        );
    }

    updateProductCatgegoryGroup(GroupId: number, ProductCategories: any[]): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/updateProductCatgegoryGroup?GroupId=${GroupId}`;
                return this.http.post<any>(url, ProductCategories, httpOptions)}),
            catchError(this.handleError<any>('updateProductCatgegoryGroup'))
        );
    }
    updateMarketingGroupMembers(GroupId: number, Members: any[]): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/updateMarketingGroupMembers?GroupId=${GroupId}`;
                return this.http.post<any>(url, Members, httpOptions)}),
            catchError(this.handleError<any>('updateMarketingGroupMembers'))
        );
    }

    AddProductCatgegoryToGroup(CategoryId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/AddProductCatgegoryToGroup?CategoryId=${CategoryId}`;
                return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('AddProductCatgegoryToGroup'))
        );
    }
    RemoveProductCatgegoryFromGroup(CategoryId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/RemoveProductCatgegoryFromGroup?CategoryId=${CategoryId}`;
                return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('RemoveProductCatgegoryFromGroup'))
        );
    }
    AddGroupMember(MemberId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/AddGroupMember?MemberId=${MemberId}`;
                return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('AddGroupMember'))
        );
    }
    RemoveGroupMember(MemberId: number, marketingGroupViewModel: MarketingGroupBaseViewModel): Observable<SupplierGroupsViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/RemoveGroupMember?MemberId=${MemberId}`;
                return this.http.put<SupplierGroupsViewModel>(url, marketingGroupViewModel, httpOptions)}),
            catchError(this.handleError<SupplierGroupsViewModel>('RemoveGroupMember'))
        );
    }

    GetBuyersInSupllierNetwork(): Observable<SupplierTrustedNetworksViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Seller/SellerCustomers`;
                return this.http.get<SupplierTrustedNetworksViewModel>(url, httpOptions)}),
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('GetSellerCustomers'))
        );
    }
    GetMarketingGroupMembers(groupId: number): Observable<SupplierTrustedNetworksViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Marketing/MembersInGroup?groupId=${groupId}`;
                return this.http.get<SupplierTrustedNetworksViewModel>(url, httpOptions)}),
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('GetSellerCustomers'))
        );
    }    

    GetBuyersInSupllierNetworkByStatus(status:number,pageNo:number,limit:number,isActive:boolean=true): Observable<PageModel<CustomerModel>> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Seller/SellerCustomersByStatus?status=${status}&pageNo=${pageNo}&limit=${limit}&isActive=${isActive}`;
                return this.http.get<PageModel<CustomerModel>>(url, httpOptions)}),
            catchError(this.handleError<PageModel<CustomerModel>>('GetSellerCustomersByStatus'))
        );
    }
    ActivateOrDeactivateCustomer(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<SupplierTrustedNetworksViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Seller/ActivateOrDeactivateCustomer`;
                return this.http.post<SupplierTrustedNetworksViewModel>(url, supplierTrustedNetworks, httpOptions)}),
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('ActivateOrDeactivateCustomer'))
        );
    }

    RejectCustomer(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<SupplierTrustedNetworksViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Seller/RejectCustomer`;
                return this.http.post<SupplierTrustedNetworksViewModel>(url, supplierTrustedNetworks, httpOptions)}),
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('RejectCustomer'))
        );
    }

    ApproveCustomer(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<SupplierTrustedNetworksViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Seller/ApproveCustomer`;
                return this.http.post<SupplierTrustedNetworksViewModel>(url, supplierTrustedNetworks, httpOptions)}),
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('ApproveCustomer'))
        );
    }

    UpdatePaymentTerms(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<SupplierTrustedNetworksViewModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Seller/PaymentTerms`;
                return this.http.post<SupplierTrustedNetworksViewModel>(url, supplierTrustedNetworks, httpOptions)}),
            catchError(this.handleError<SupplierTrustedNetworksViewModel>('UpdatePaymentTerms'))
        );
    }
    addCustomer(Customer: CustomerModel): Observable<CustomerModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Customer`;    
                return this.http.post<CustomerModel>(url, Customer, httpOptions)}),
          catchError(this.handleError<CustomerModel>('addCustomer'))
        );
      }
      updateCustomer(Customer: CustomerModel): Observable<CustomerModel> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Customer`;    
                return this.http.put<CustomerModel>(url, Customer, httpOptions)}),
          catchError(this.handleError<CustomerModel>('addCustomer'))
        );
      }
      IsCustomerValidABN(abn:string,source:number,customerId?:number): Observable<boolean> {

        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                let url="";
                if(customerId==null)
                {
                     url = `${this.apiURl}/api/v1/${seller.id}/Customer/IsValidCustomerABN?ABN=${abn}&source=${source}`;    
                 
                }
                else
                {
                     url = `${this.apiURl}/api/v1/${seller.id}/Customer/IsValidCustomerABN?ABN=${abn}&source=${source}&customerId=${customerId}`;    
               
                }
                 return this.http.get<boolean>(url, httpOptions)}),
          catchError(this.handleError<boolean>('IsValidABN'))
        );
      
    }
}
