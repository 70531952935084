
<div class="tab-pane active" id="profile">
    <table class="table">
        <thead>
            <th>
                <div class="container-fluid">
                    <div class="row">
                        <div class="form-check col-lg-4 col-md-4">
                            <a mat-button class="nav-link">{{data.customerName}}</a>
                        </div>
                        <div class="form-check col-lg-4 col-md-4">

                            <a mat-button class="nav-link">
                Order No:
                {{data.orderId}}
                <div class="ripple-container"></div>
              </a>
                        </div>
                        <div class="form-check col-lg-3 col-md-3">

                            <a mat-button class="nav-link">
                Total Price: ${{data.orderItemsTotalPrice}}
                <div class="ripple-container"></div>
              </a>
                        </div>
                    </div>
                </div>
            </th>

        </thead>
        <thead>
            <th>
                <div class="container-fluid">
                    <div class="row">
                        <div class="form-check col-lg-4 col-md-4">
                          <span> Order Notes:</span> 
                        </div>
                        <div class="form-check col-lg-7 col-md-7">
                            {{data.note}}
                        </div>
                    </div>
                </div>
            </th>
        </thead>
        <thead *ngIf="data.deliveryType == 'pickup'">
            <th>
                <div class="container-fluid">
                    <div class="row">
                        <div class="form-check col-lg-4 col-md-4">
                            Pickup at Bay:
                        </div>
                        <div class="form-check col-lg-7 col-md-7">
                            {{data.bay}}
                        </div>
                    </div>
                </div>
            </th>
        </thead>
        <thead *ngIf="data.deliveryType != 'pickup'">
            <th>
                <div class="container-fluid">
                    <div class="row">
                        <div class="form-check col-lg-4 col-md-4">
                            Delivery Address:
                        </div>
                        <div class="form-check col-lg-7 col-md-7">
                            {{data.address}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-check col-lg-4 col-md-4">                            
                        </div>
                        <div class="form-check col-lg-7 col-md-7">
                            {{data.city}}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-check col-lg-4 col-md-4">                            
                        </div>
                        <div class="form-check col-lg-2 col-md-2">
                            {{data.state}}
                        </div>
                        <div class="form-check col-lg-5 col-md-5">
                            {{data.postalCode}}
                        </div>
                    </div>
                </div>
            </th>
        </thead>
       
    </table>
</div>

<div class="col-md-12">
    <mat-card-title class="cardHeader"> Order Items</mat-card-title>   
    <form novalidate #myForm="ngForm">
      <kendo-grid 
      #grid
      [kendoGridBinding]="gridView"
              kendoGridSelectBy="id"
              [selectedKeys]="mySelection"
              [loading]="loading" 
              [pageSize]="10"
              [pageable]="true"
              [sortable]="true"
              [groupable]="false"
              [reorderable]="true"
              [resizable]="true"
              [height]="300"
              [columnMenu]="{ filter: true }"
              [selectable]="selectableSettings"
              kendoGridSelectBy="id"
              [kendoGridSelectBy]="mySelectionKey"
              (selectedKeysChange)="keyChange($event)"
              [selectable]="{enabled: true, checkboxOnly: true}"
         >             
               
         <kendo-grid-column field="lineItemInOrder.lineitemId" title="Id" [hidden]="true"  [editable]="false" [width]="220">
        </kendo-grid-column> 

         <!-- <kendo-grid-column field="lineItemInOrder.isDeleted" title="Delete" width="120">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

                <input [attr.disabled]="dataItem.editable && featurePermission.delete?null:true" type="checkbox"  (click)="toggle(rowIndex)" [checked]="dataItem.lineItemInOrder.isDeleted"  [name]="dataItem.lineItemInOrder.isDeleted"    />
             
            </ng-template>
        </kendo-grid-column>  -->
                      
       <kendo-grid-column field="productInOrder.category" title="Product" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{ dataItem.productInOrder.category}} - {{dataItem.productInOrder.varietal}}
         </ng-template>
       </kendo-grid-column>        
       <kendo-grid-column field="productInOrder.grade" title="Grade" [width]="220">
       </kendo-grid-column>   
       <kendo-grid-column field="productInOrder.format" title="Format" [width]="220">
    </kendo-grid-column>   
    <kendo-grid-column   field="lineItemInOrder.approvedQuantity" title="Quantity" [width]="220">
        <ng-template  kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"> 
            <div *ngIf="dataItem.editable">
                <kendo-numerictextbox [disabled]="!featurePermission.update"  #approvedQuantity="ngModel" appDigitOnly [name]="dataItem.lineItemInOrder.approvedQuantity"  [(ngModel)]="dataItem.lineItemInOrder.approvedQuantity" required [min]="0" [maxNumber]="dataItem.lineItemInOrder.maxQuantity"  class="form-control">
                </kendo-numerictextbox>
      <p *ngIf="approvedQuantity.errors?.required ">Quantity is required</p>
      <!-- <p *ngIf="approvedQuantity.errors?.customMax ">Maximum Quantity in stock {{dataItem.productInOrder.maxStock}} </p> -->
      <p *ngIf="approvedQuantity.errors?.maxNumber ">Maximum Quantity is {{dataItem.lineItemInOrder.maxQuantity}} </p>
           
            </div>
            <div *ngIf="!dataItem.editable">
                
                    {{dataItem.lineItemInOrder.approvedQuantity}}
               
      
            </div>
            </ng-template>

       </kendo-grid-column>
       <kendo-grid-column field="lineItemInOrder.soldPrice" title="Price"  [width]="220">
       
         <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex"> 
            <div *ngIf="dataItem.editable">
            <kendo-numerictextbox [disabled]="!featurePermission.update" [(ngModel)]="dataItem.lineItemInOrder.soldPrice" #soldPrice="ngModel" [name]="dataItem.lineItemInOrder.soldPrice"  [min]="0" [maxPrice]="dataItem.lineItemInOrder.maxPrice" required [price]="dataItem.lineItemInOrder.approvedQuantity" class="form-control">
            </kendo-numerictextbox>
            <p *ngIf="soldPrice.errors?.maxPrice">Maximum Price is {{dataItem.lineItemInOrder.maxPrice}} </p>
            <p *ngIf="soldPrice.errors?.required">Price is required</p>
            </div>
            <div *ngIf="!dataItem.editable">
                {{dataItem.lineItemInOrder.soldPrice}}
            </div>
        </ng-template>
       </kendo-grid-column> 
       <kendo-grid-column field="lineItemInOrder.totalPrice" title="Total" [width]="220">
        <ng-template kendoGridCellTemplate let-dataItem>
            {{dataItem.lineItemInOrder.soldPrice * dataItem.lineItemInOrder.approvedQuantity | currency}}
         </ng-template>
       </kendo-grid-column> 
       <kendo-grid-column field="lineItemInOrder.status" title="Status" [editable]="false" [width]="220">
    </kendo-grid-column>  


  
   </kendo-grid> 
   <mat-card-actions *ngIf="editableForm" align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="saveChanges()" [disabled]="!myForm.form.valid || !featurePermission.update"  cdkFocusInitial>Save</button>
  </mat-card-actions>   
</form>
        

 </div>     
