import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import {  DataBindingDirective } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.dialog.html',
  styleUrls: ['./subscription.dialog.css']
})
export class SubscriptionDialog implements OnInit {
  public opened = true;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(public dialogRef: MatDialogRef<SubscriptionDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public dialogdata: any,public dialog: MatDialog,
    private sellerService: SellerService
  ) {

  }

  ngOnInit(): void {
   
  }



  public renew(): void {
    this.sellerService.getBilling().subscribe(res => {
      window.open(res.data, '_blank');
    });
  }
  
}
