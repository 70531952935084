
<nav class="navbar navbar-expand-lg navbar-transparent  " *ngIf="loggedIn">
  <div class="container-fluid">
    <div class="navbar-wrapper">
      <h1><b>{{getTitle()}}</b></h1>
    </div>
    
    <button mat-raised-button class="navbar-toggler" type="button" (click)="sidebarToggle()">
      <span class="sr-only">Toggle navigation</span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
      <span class="navbar-toggler-icon icon-bar"></span>
    </button>
    <div class="collapse navbar-collapse justify-content-end" id="navigation">
      <ul class="navbar-nav">        
        <li class="nav-item dropdown" *ngIf="loggedIn">
          <a class="nav-link" href="javascript:void(0)" id="navbarDropdownMenuLink" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            <i class="material-icons">notifications</i>
            <span class="notification">{{notidicationsCount}}</span>
          </a>
          <div *ngIf="notidicationsCount>0" class="dropdown-menu dropdown-menu-right"
            aria-labelledby="navbarDropdownMenuLink">
            <a *ngFor="let notification of notidications" class="dropdown-item" href="javascript:void(0)">
              {{notification.notificationMessage}}
              <button mat-raised-button type="button" matTooltip="close" [matTooltipPosition]="'above'"
                class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="closenotification(notification)">
                <i class="material-icons">close</i>
              </button>
            </a>

          </div>
        </li>

        <li class="nav-item dropdown" style="z-index: 1030;" *ngIf="loggedIn">
          <!-- <a mat-raised-button matTooltip="click to logout or edit" [matTooltipPosition]="'above'"
            class="nav-link btn btn-success" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <i class="material-icons">person</i>
            <div class="ripple-container"></div>
            {{username}}
          </a> -->
          <!-- <a  matTooltip="click to logout or edit" [matTooltipPosition]="'above'"
            class="nav-link btn  profile-button" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">          
            <div class="ripple-container"></div>
            <img src="/assets/img/avatar.png" alt="Profile Picture" class="profile-picture">
            {{username}}
          </a> -->
          <a matTooltip="click to logout or edit" [matTooltipPosition]="'above'"
            class="nav-link btn profile-button" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
            <img src="/assets/img/avatar.png" alt="Profile Picture" class="profile-picture">
            {{username}}
          </a>


          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">

            <a class="dropdown-item nav-link" mat-button color="info" href="javascript:void(0)" (click)="editProfile()">

              <i class="material-icons" style="color:rgb(15, 78, 29)"> mode_edit</i>
              Edit Profile
            </a>
            <a class="dropdown-item nav-link" mat-button color="error" href="javascript:void(0)" (click)="logout()">

              <i class="material-icons green" style="color:rgb(255, 0, 0)"> logout</i>

              Logout

            </a>

          </div>
        </li>
        <li class="nav-item" *ngIf="!loggedIn">
          <button mat-raised-button matTooltip="click to login" [matTooltipPosition]="'above'" type="submit"
            (click)="login()" class="btn btn-primary">

            <i class="material-icons">login</i>
            <div class="ripple-container"></div>
            Login
          </button>
        </li>
      </ul>
    </div>
  </div>  
</nav>
<div>
  <!-- <mat-toolbar  style="min-height: unset;max-height: 32px;">    
    <mat-toolbar-row>
      <button mat-button>        
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-button>
        Button 1
      </button>
      <button mat-button>
        Button 2
      </button>
    </mat-toolbar-row>
  </mat-toolbar> -->
  <notification-bar (action)="onNotificationAction($event)"></notification-bar>
</div>
