import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { SiteModel, StateModel } from 'app/shared/models/sites/site.model';
import { ProductGrower } from 'app/shared/models/catalogue/lockups';
import { NewGrowerDialog } from '../dialogs/new-grower/new-grower.dialog';
import Swal from 'sweetalert2';


@Component({
  selector: '[app-growers-settings]',
  templateUrl: './growers-settings.component.html',
  styleUrls: ['./growers-settings.component.css']
})
export class GrowersSetttingsComponent implements OnInit {
  public gridView: GridDataResult;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  featurePermission: FeaturePermission = new FeaturePermission();
  show: boolean = true;

  constructor(
    public dialog: MatDialog,
    private userService: UserService, private sellerService: SellerService
  ) {
  
  }

  ngOnInit(): void {
    this.loading = true;
    this.init();
  }
  
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SETTINGS )[0];
  console.log(this.featurePermission);
  this.show = this.featurePermission.read;
  });
   
}
  async init() {
    this.loading = true;
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    
     this.sellerService.getGrowersSettings(this.currentPage,this.pageSize).subscribe((data) => {
       this.gridView = {
         data: data.items,
         total: data.totalItems,
       };
       this.loading = false;
     });
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getGrid();
  }
  
  getGrid()
  {
    this.loading = true;
    this.sellerService.getGrowersSettings(this.currentPage,this.pageSize).subscribe((data) => {
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;
    });
  }

  
   newGrower(editable:boolean=false,model:ProductGrower=null): void {
    var Data = { editable: editable, model: model }
     const dialogRef = this.dialog.open(NewGrowerDialog,{data: Data ,width:'1000px'});
     dialogRef.afterClosed().subscribe(result => {
       this.loading = true;
       this.init();
     });
   }
   deleteGrower(id:number)
   {
    
    this.loading = true;
    this.sellerService.checkGrowerIsExistInProducts(id).subscribe((isExist) => {
      if(!isExist)
      {
        this.sellerService.deleteGrower(id).subscribe((data) => {
          this.loading = true;
           this.init();
        });
      }
      else
      {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          html: "<strong> The selected Grower can not be deleted, as it is being used by other products or batches, please delete any reference to this Grower in order to be able to delete it  </strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        }).then((result) => {
          this.loading = true;
          this.init();
        })
      }
       
    });
   }
}

