import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderSummaryResponse } from 'app/shared/models/order/order.model';
import { LineItemInOrder, OrderRequest, OrderUpdateRequest, ProductLineItemDetails } from 'app/shared/models/order/PlainProductInOrders.Model';
import { OrderService } from 'app/shared/services/order.service';
import { IChatParticipant } from 'ng-chat';
import { SignalRPaginatedAdapter } from 'app/chat/adapter/signalr-paginated-adapter';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { CancelEvent, CellClickEvent, CellCloseEvent, CreateFormGroupArgs, EditEvent, GridComponent, GridDataResult, RemoveEvent, RowArgs, SaveEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { EditOrderService } from 'app/shared/services/edit-order.service';
import { State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { UserService } from 'app/shared/services/user.service';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { SellerService } from 'app/shared/services/Seller.Service';
@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.dialog.html',
  styleUrls: ['./order-item.dialog.css']
})
export class OrderItemDialog implements OnInit {
  public view: Observable<GridDataResult>;
  public gridView: any[];
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public loading: boolean = false;
  public isEdited = false;
  orderRequest: OrderRequest=new OrderRequest();
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };
  public selectableSettings: SelectableSettings;
  ProductLineItemsDetails: ProductLineItemDetails[];
  public formGroup: FormGroup;
  orderUpdateRequest: OrderUpdateRequest= new OrderUpdateRequest();
  snackbar: any;
  horizontalPosition: any;
  verticalPosition: any;
  editableForm: boolean;
  featurePermission: FeaturePermission;;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService,
  private eventEmitterService: EventEmitterService,private sellerService: SellerService,
  public dialog: MatDialog,private formBuilder: FormBuilder,public editService: EditOrderService ,private userService: UserService,
  private spinner: NgxSpinnerService ,public dialogRef: MatDialogRef<OrderItemDialog>) {
    // this.createFormGroup = this.createFormGroup.bind(this);
    
   }

  ngOnInit(): void {
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.load();
  }
  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.ORDERS )[0];
    });
     
 }
load(): void {
  
  this.orderService.GetOrderLineItemsDetails(this.data.orderId).
  subscribe((result) => {
    this.ProductLineItemsDetails = result;
    this.gridView=this.ProductLineItemsDetails;
    this.editableForm=this.ProductLineItemsDetails[0].editable;
  }

  );
}
  openchat(): void {
    var items = this.ProductLineItemsDetails;
    let User = SignalRPaginatedAdapter.Buyers.filter(function (element: IChatParticipant) {
      return element.id == items[0].buyerId;
    });
    this.eventEmitterService.OpenChatClick(User[0]);
  }

  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    //
    return context.dataItem.lineItemInOrder.lineitemId;
  }
  keyChange(e){    
    this.selectedUID = e;
  }

  isEditDisabled()
  {
    return (this.selectedUID == null || this.selectedUID.length == 0 );
  }

    saveChanges() {
      this.spinner.show();
      const obj = this.gridView.map(item => (<OrderUpdateRequest>{
        lineitemId: item.lineItemInOrder.lineitemId ,
        qunatity :item.lineItemInOrder.approvedQuantity,
        unitPrice:  item.lineItemInOrder.soldPrice,
        totalPrice:item.lineItemInOrder.soldPrice*item.lineItemInOrder.approvedQuantity,
        isDeleted:item.lineItemInOrder.isDeleted
        }));
      this.orderRequest.OrderUpdateRequest=obj;
      this.orderRequest.buyerId=this.ProductLineItemsDetails[0].buyerId;
      this.orderRequest.sellerId=this.ProductLineItemsDetails[0].productInOrder.supplierId;
      this.orderService.ChangeOrderItemQuantity(this.orderRequest).subscribe((result) => {
    
        if(result>0)
        {
          Swal.fire({
            icon: 'success',
            title: 'Refund Amount',
            html: "<strong> An amount of $"+result+" will be refunded to the customer’s credit card</strong>",
            showCloseButton: false,
            showCancelButton: false,
            confirmButtonColor: "#0c6acb"
          });
        }
        this.spinner.hide();      
        this.dialogRef.close(true);    
      });    
    }  
    public toggle(index: any): void {
      this.gridView[index].lineItemInOrder.isDeleted = !this.gridView[index].lineItemInOrder.isDeleted ;      
    }
}
