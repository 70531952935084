import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Product } from 'app/shared/models/product/product.model';
import { FormControl, Validators } from '@angular/forms';
import { data } from 'jquery';
import { dateInputsHaveChanged } from '@angular/material/datepicker/datepicker-input-base';
import { MatStartDate } from '@angular/material/datepicker';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MarketingGroupViewModel } from 'app/shared/models/promotions/marketing.model';
import { UploadService } from 'app/shared/services/upload.service';
import { MarketUpdateViewModel } from 'app/shared/models/promotions/marketupdate.model';
import { MarketingService } from 'app/shared/services/marketing.service';


@Component({
  selector: 'dialogs-MarketUpdate',
  templateUrl: './marketUpdate.dialog.html',
  styleUrls: ['./marketUpdate.dialog.css']
})
export class MarketUpdateDialog implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  marketUpdateViewModel: MarketUpdateViewModel;
  public EditorConfig = "{removePlugins: ['heading', 'image'],toolbar: ['bold', 'italic', 'link', '|','bulletedList', 'numberedList' ],  placeholder: 'Type the content here'}";
  static file: any;
  boxes: any;
  htmlVariable: any;
  public model = {
    editorData: '<p>Hello, world!</p>'
  };
  ngOnInit(): void {
    this.boxes = document.querySelectorAll('.box');
    for (let i = 0; i < this.boxes.length; i++) {
      let box = this.boxes[i];
      this.initDropEffect(box);
      this.initImageUpload(box);

    }

  }

  Title: string = "Public Market Update"
  constructor(@Inject(MAT_DIALOG_DATA) public data: { supplierId: number, marketingGroupId: number }, private snackbar: MatSnackBar, private uploadService: UploadService, private marketingService: MarketingService) {
    this.marketUpdateViewModel = {
      id: 0,
      supplierId: 0,
      marketingGroupId: 0,
      marketTopic: "",
      marketHeadding: "",
      imageUrl: "",
      createdDate: new Date()
    }
    //debugger;
    this.marketUpdateViewModel.supplierId = data.supplierId;
    this.marketUpdateViewModel.marketingGroupId = data.marketingGroupId == 0 ? null : data.marketingGroupId;
    if (data.marketingGroupId != 0) {
      this.Title = "Private Market Update";
    }
  }

  initImageUpload(box: any) {
    let uploadField = box.querySelector('.image-upload');

    uploadField.addEventListener('change', getFile);

    function getFile(e) {
      MarketUpdateDialog.file = e.currentTarget.files[0];
      checkType(MarketUpdateDialog.file);
    }

    function previewImage(file) {
      let thumb = box.querySelector('.js--image-preview'),
        reader = new FileReader();

      reader.onload = function () {
        thumb.style.backgroundImage = 'url(' + reader.result + ')';
      }
      reader.readAsDataURL(file);
      thumb.className += ' js--no-default';
    }

    function checkType(file) {
      let imageType = /image.*/;
      if (!file.type.match(imageType)) {
        throw 'please choose supported image type';
      } else if (!file) {
        throw 'please upload an image';
      } else {
        previewImage(file);
      }
    }

  }

  // initialize box-scope






  /// drop-effect
  initDropEffect(box: any): void {
    let area, drop, areaWidth, areaHeight, maxDistance, dropWidth, dropHeight, x, y;

    // get clickable area for drop effect
    area = box.querySelector('.js--image-preview');
    area.addEventListener('click', fireRipple);

    function fireRipple(e) {
      area = e.currentTarget
      // create drop
      if (!drop) {
        drop = document.createElement('span');
        drop.className = 'drop';
        this.appendChild(drop);
      }
      // reset animate class
      drop.className = 'drop';

      // calculate dimensions of area (longest side)
      areaWidth = getComputedStyle(this, null).getPropertyValue("width");
      areaHeight = getComputedStyle(this, null).getPropertyValue("height");
      maxDistance = Math.max(parseInt(areaWidth, 10), parseInt(areaHeight, 10));

      // set drop dimensions to fill area
      drop.style.width = maxDistance + 'px';
      drop.style.height = maxDistance + 'px';

      // calculate dimensions of drop
      dropWidth = getComputedStyle(this, null).getPropertyValue("width");
      dropHeight = getComputedStyle(this, null).getPropertyValue("height");

      // calculate relative coordinates of click
      // logic: click coordinates relative to page - parent's position relative to page - half of self height/width to make it controllable from the center
      x = e.pageX - this.offsetLeft - (parseInt(dropWidth, 10) / 2);
      y = e.pageY - this.offsetTop - (parseInt(dropHeight, 10) / 2) - 30;

      // position drop and animate
      drop.style.top = y + 'px';
      drop.style.left = x + 'px';
      drop.className += ' animate';
      e.stopPropagation();

    }
  }

  submit(): void {

    const formData = new FormData();
    // formData.set("json", JSON.stringify(this.Seller.supplier));
    if (MarketUpdateDialog.file) {
      formData.append(`${this.data.supplierId}/${MarketUpdateDialog.file.name}`, MarketUpdateDialog.file);
      // formData.append('image', files[0]);
    }

    this.uploadService
      .uploadImage(formData)
      .subscribe(
        (result) => {
          this.marketUpdateViewModel.imageUrl = result[0].absoluteUri;

          this.marketingService.PostMarketingUpdate(this.marketUpdateViewModel).subscribe((data) => {


          });
        }
      );
    this.snackbar.open('Update sent to customers', 'close', {
      duration: 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

}
