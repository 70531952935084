import { SupplierViewModel } from "./supplierViewModel";

export class SellerViewModel {
  constructor() {
    this.supplier = new SupplierViewModel();
  }
  id: number;
  identityGuid: string;
  name: string;
  email: string;
  supplierId: number;
  subscriptionDeleted: boolean;
  subscriptionCanceled: boolean;
  active: boolean;
  approved: boolean;
  subscriptionStatus:SubscriptionStatus;
  sellerSubscription:SellerSubscriptionModel;
  supplier: SupplierViewModel | undefined;
}

export class SellerSubscriptionModel {

  id: number;
  customerId?: string;
  subscriptionId?: string;
  quantity?: string;
  subscriptionStartDate?: Date;
  subscriptionEndDate?: Date;
  trialStart?: Date;
  trialEnd?: Date;
  cancelAt?: Date;
  amount?: number;
}
export enum SubscriptionStatus {
  Active = 1,
  PendingApproval=2,
  Cancelled = 3,
  Deleted = 4,
  PaymentMethodNotSet=5
}