import { Component, OnInit } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
// import { UserProfile } from 'app/shared/models/buyer/buyer.model';
import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller';
import { SellerService } from 'app/shared/services/Seller.Service';
import { UploadService } from 'app/shared/services//upload.service';
import { UploadResult } from 'app/shared/models/uploadresult';
import { JsonPipe } from '@angular/common';
import { AccountInfo } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';
import { AuthorizeService } from 'app/shared/services/authorize.service';
import { Account } from 'app/shared/models/account'
import { UtilsService } from 'app/shared/services/utils.service';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ABNValidator } from 'app/shared/validators/ABNValidator';
import { PostalCodeValidator } from 'app/shared/validators/PostalCodeValidator';
import { SellerAddressModel } from 'app/shared/models/address/seller_address.model';
import { StateModel } from 'app/shared/models/sites/site.model';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  Seller: SellerViewModel;
  sellerAddress: SellerAddressModel;
  sellerAddressObj:  SellerAddressModel=new SellerAddressModel();
  filename = '';
  imageSource = '';
  public isLoading : boolean=true;
  result: UploadResult[];
  showProfile: boolean = true;
  featurePermission: FeaturePermission = new FeaturePermission();
  abnControl: FormControl;
  postalCodeControl: FormControl;
  states: StateModel[];
  profileImageUrl='';
  public profileForm: FormGroup;
  constructor(
    private AuthService: MsalService,
    private utilities: UtilsService,
    private snackbar: MatSnackBar, private sellerservice: SellerService,
    private uploadService: UploadService,
    private userService: UserService, private sellerService: SellerService,
    private spinner: NgxSpinnerService,public fb: FormBuilder
  ) {
    this.Seller = new SellerViewModel();
  }

  ngOnInit() {
    this.spinner.show();
    this.sellerservice.getStates().subscribe((data) => {
      this.states=data;
    
    });
    this.profileForm=this.fb.group(
      {
        businessName:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        abn:["",[Validators.maxLength(250),Validators.minLength(11),Validators.maxLength(11),Validators.required,Validators.pattern('[a-zA-Z0-9]*')],[ABNValidator.createValidator(this.sellerservice)]],
        address:["",[Validators.minLength(3),Validators.maxLength(250),Validators.required]],
        city:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        stateId:[null,[Validators.required]],
        postalCode:["",[Validators.maxLength(4),Validators.minLength(4),Validators.required],[PostalCodeValidator.createValidator(this.sellerservice)]],
        marketLocation:[""],
        bay:["",[Validators.maxLength(250)]],
        businessEmail:["",[Validators.email,Validators.required]],
        mobile:["",[Validators.minLength(9),Validators.maxLength(10),Validators.required]],
        phone:[""],
        additionalNumber:[""],
        ownerName:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
        ownerEmail:["",[Validators.email,Validators.required]],
        addressId:[null]
      }
    
    )
    this.abnControl = new FormControl("",[Validators.maxLength(250),Validators.required],[ABNValidator.createValidator(this.sellerservice)]);
    this.postalCodeControl = new FormControl("",[Validators.maxLength(4),Validators.required],[PostalCodeValidator.createValidator(this.sellerservice)]);
    this.LoadSupplier();
  }

 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission=userPermissions.filter(x => x.feature == PermissionsConstants.PROFILE )[0];
  this.showProfile = this.featurePermission.read;
  });
}

  async LoadSupplier() {
    this.sellerservice.getSeller().subscribe(data => {
      this.Seller = data;
      this.imageSource = this.Seller.supplier.profileImageUrl_Small;      
      this.profileImageUrl = this.Seller.supplier.profileImageUrl_Small; 
        this.loadPermissions(this.Seller);
        this.sellerservice.getSupplier().subscribe((seller) => {
          console.log(seller );
          this.Seller = seller;
          this.sellerAddress=this.Seller.supplier.addresses.length>0?this.Seller.supplier.addresses.filter(s=>s.isDefault)[0]:{...this.sellerAddressObj};
      
           this.profileForm.patchValue({
             businessName: this.Seller.supplier.businessName,
             abn: this.Seller.supplier.abn,
             address: this.sellerAddress.addressLine1,
             city: this.sellerAddress.city,
             stateId: this.sellerAddress.stateId,
             postalCode: this.sellerAddress.postalCode,
             marketLocation: this.Seller.supplier.marketLocation,
             bay: this.Seller.supplier.bay,
             businessEmail: this.Seller.supplier.businessEmail,
             mobile: this.sellerAddress.mobile,
             phone: this.sellerAddress.phone,
             additionalNumber: this.sellerAddress.additionalNumber,
             ownerName:this.Seller.supplier.ownerName,
             ownerEmail:this.Seller.supplier.ownerEmail,
             addressId:this.sellerAddress.id
           });
          this.spinner.hide();
          this.isLoading=false;
        });
        
    }
    );
  }
  save(): void {
console.log(this.Seller );
if(this.Seller.supplier.addresses.length<=0)
{
  this.sellerAddress.isDefault=true;
  this.Seller.supplier.addresses.push(this.sellerAddress);
}

    this.sellerservice.updateSupplier(this.Seller.supplier).subscribe((data) => {
      this.LoadSupplier();
      this.snackbar.open('User Profile Saved', 'close', {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    },
      error => {

        this.snackbar.open(error, 'close', {
          duration: 1000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
      }
    );

  }

  setFilename(files) {
    if (files[0]) {
      this.filename = `${this.Seller.supplierId}/${files[0].name}`;
    }
  }

  upload(files) {
    const formData = new FormData();
    // formData.set("json", JSON.stringify(this.Seller.supplier));
    if (files[0]) {
      ;
      formData.append(`${this.Seller.supplierId}/${files[0].name}`, files[0]);
      // formData.append('image', files[0]);

    }

    this.uploadService
      .uploadImage(formData)
      .subscribe(
        (data) => {
          this.profileImageUrl = data[0].absoluteUri;
          this.imageSource = data[1].absoluteUri;


        }
      );
  }
  getErrorMessage() {
    return this.abnControl.hasError('required') ? 'ABN is required' :
        this.abnControl.hasError('isValid') ? 'ABN already exists' :
            '';
  }
  onSubmit() {
    this.spinner.show(); 
  let profile: SupplierViewModel= this.profileForm.value;
  profile.timeZone=Intl.DateTimeFormat().resolvedOptions().timeZone;
  profile.profileImageUrl_Normal=this.profileImageUrl;
  profile.profileImageUrl_Small=this.profileImageUrl;
  let address = new SellerAddressModel();
  address.id=this.profileForm.controls['addressId'].value;
         address.addressLine1= this.profileForm.controls['address'].value;
        address.city= this.profileForm.controls['city'].value;
        address.postalCode= this.profileForm.controls['postalCode'].value;
        address.mobile= this.profileForm.controls['mobile'].value;
        address.additionalNumber= this.profileForm.controls['additionalNumber'].value;
        address.phone= this.profileForm.controls['phone'].value;
        address.stateId= this.profileForm.controls['stateId'].value;
        address.isDefault=true;
        let addresses: SellerAddressModel[]=[];
        addresses.push(address);
        profile.addresses=addresses;
        this.sellerservice.updateSupplier(profile).subscribe((data) => {
          this.LoadSupplier();
          this.snackbar.open('User Profile Saved', 'close', {
            duration: 1000,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        },
          error => {
    
            this.snackbar.open(error, 'close', {
              duration: 1000,
              horizontalPosition: this.horizontalPosition,
              verticalPosition: this.verticalPosition
            });
          }
        );

 }

}
