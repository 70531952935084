import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyFileViewModel } from 'app/shared/models/integration/companyfile.model';
import { IntegrationService } from 'app/shared/services/integration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NetsuiteSettingsViewModel } from 'app/shared/models/integration/netsuitesettings.model';
import Swal from 'sweetalert2';

@Component({
  selector: 'netsuite-dialog',
  templateUrl: './netsuite.dialog.html',
  styleUrls: ['./netsuite.dialog.css']

})
export class NetsuiteDialog implements OnInit {
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  featurePermission: FeaturePermission;
  netsuiteSettingsForm: FormGroup;
  accountId: any;
  consumerKey: any;
  consumerSecret: any;
  tokenId: any;
  tokenSecret: any;
  apiUrl: any;
  public isLoading: boolean = false;

  constructor(public dialogRef: MatDialogRef<NetsuiteDialog>,
    private integrationService: IntegrationService,
    private snackbar: MatSnackBar,
    private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    private userService: UserService, private sellerService: SellerService,
    private formBuilder: FormBuilder
  ) {
    this.netsuiteSettingsForm = this.formBuilder.group({
      accountId: [''],
      consumerKey: [''],
      consumerSecret: [''],
      tokenId: [''],
      tokenSecret: [''],
      apiUrl: [''],
    });
  }
  ngOnInit(): void {
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
  }
  loadPermissions(seller: SellerViewModel) {
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {
      this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.INTEGRATION)[0];

    });
  }
  saveForm() {
    var netsuiteSettings: NetsuiteSettingsViewModel = {
      accountId: this.netsuiteSettingsForm.value['accountId'],
      consumerKey: this.netsuiteSettingsForm.value['consumerKey'],
      consumerSecret: this.netsuiteSettingsForm.value['consumerSecret'],
      tokenId: this.netsuiteSettingsForm.value['tokenId'],
      tokenSecret: this.netsuiteSettingsForm.value['tokenSecret'],
      apiURL: this.netsuiteSettingsForm.value['apiUrl']
    };
    this.isLoading = true; // Add this line to show the loading icon or message

    this.integrationService.UpdateNetsuiteAccount(netsuiteSettings).subscribe((response) => {
      this.isLoading = false; // Add this line to hide the loading icon or message
      if (response) {
        Swal.fire({
          icon: 'success',
          title: '',
          html: "<strong> Connection to NetSuite was successful</strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        });
        this.dialogRef.close({ data: response });
      } else {
        Swal.fire({
          icon: 'error',
          title: '',
          html: "<strong> Failed to connect to NetSuite, please review the details and try again</strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        });
      }
    }).add(() => {
      this.isLoading = false; // Add this line to hide the loading icon or message
    });
  }
}
