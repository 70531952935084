<div class="card scrollable-content"  >
  
<div class="card-body" *ngIf="showSites">
  <div class="tab-content">
      <div class="tab-pane active" id="active">
          <kendo-grid [data]="gridView" [loading]="loading" [pageSize]="pageSize" [skip]="skip"
              [pageable]="true" [resizable]="true" (pageChange)="pageChange($event)" [sortable]="true"
              [sort]="sort" (sortChange)="sortChange($event)">
  
              <kendo-grid-column field="name" title="Site Name"
                  class='k-text-center font-weight-bold'
                  headerClass="k-text-center font-weight-bold" [width]="150">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <a mat-button class="nav-link" matTooltip="Site Name" (click)="newSite(true,dataItem)" >
                          {{dataItem.name}}
                          <div class="ripple-container"></div>
                      </a>

                  </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Code" class='k-text-center font-weight-bold'
                  headerClass="k-text-center font-weight-bold" [width]="150">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <a mat-button class="nav-link" matTooltip="Code">
                          {{dataItem.code}}
                          <div class="ripple-container"></div>
                      </a>

                  </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="data" title="Address" class='k-text-center font-weight-bold'
                  headerClass="k-text-center font-weight-bold" [width]="150">
                  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                      <a mat-button class="nav-link" matTooltip="Code">
                          {{dataItem.addressLine1}} - {{dataItem.city}} - {{dataItem.postalCode}}
                          <div class="ripple-container"></div>
                      </a>

                  </ng-template>
              </kendo-grid-column>
              <kendo-grid-column field="lastStockTakeDate" title="Last Stock Take Date"  class='k-text-center font-weight-bold' 
              headerClass="k-text-center font-weight-bold" [width]="220">     
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span>
              {{dataItem.lastStockTakeDate | date: 'dd/MM/yyyy'}} 
                  </span>
                </ng-template>         
              </kendo-grid-column>
              <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold' [sticky]="true" [filterable]="false"
headerClass="k-text-center font-weight-bold" [width]="150">
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  <div *ngIf="dataItem.currntStockTake==null ||(dataItem.currntStockTake!=null && dataItem.currntStockTake.dateCompleted!=null) ">
    <button mat-raised-button type="button" matTooltip="Start Stock Take" [matTooltipPosition]="'above'"
    class="btn btn-primary " (click)="openStockTakeDialog(dataItem)"
   >
    Start Stock Take
 </button>
  </div>
  <div *ngIf="dataItem.currntStockTake!=null && dataItem.currntStockTake.dateCompleted==null ">
    <button mat-raised-button type="button" matTooltip="Start Stock Take" [matTooltipPosition]="'above'"
    class="btn btn-success " (click)="openStockTakeDialog(dataItem)"
   >
   Continue Stock Take
 </button>
  </div>
  
</ng-template>
</kendo-grid-column>
          </kendo-grid>
      </div>
     
      
  </div>

</div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading ... </p>
</ngx-spinner>
<div *ngIf="! showSites">
  <img src="/assets/img/403.png">
</div>