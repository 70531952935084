import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import {  DataBindingDirective } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import Swal from 'sweetalert2';


@Component({
  selector: 'under-approve',
  templateUrl: './under-approve.dialog.html',
  styleUrls: ['./under-approve.dialog.css']
})
export class UnderApproveDialog implements OnInit {
  public opened = true;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(public dialogRef: MatDialogRef<UnderApproveDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public dialogdata: any,public dialog: MatDialog,
    private sellerService: SellerService
  ) {

  }

  ngOnInit(): void {

  }

  
}
