<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-warning card-header-icon">
            <div class="card-icon">
              <i class="material-icons">push_pin</i>
            </div>
            <p class="card-category"><a routerLink="/orders/awaitingApproval/customer-orders">Pending Approvals</a></p>
            <h3 class="card-title">{{dashBoardStatisticsModel?.statistcs?.pendingOrders}}
              <small>Orders</small>
            </h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons text-danger">warning</i>
              <a [routerLink]="['/orders/tracking']" [queryParams]="{status: '4'}">{{dashBoardStatisticsModel?.statistcs?.awaitingFulfillmentOrders}} Orders Awaiting Fulfilment</a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-primary card-header-icon">
            <div class="card-icon">
              <i class="material-icons">attach_money</i>
            </div>
            <p class="card-category"><a routerLink="/tracking">Sales</a></p>
            <h3 class="card-title">${{dashBoardStatisticsModel?.statistcs?.last7dayRevenue}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">date_range</i> Last 7 days
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-danger card-header-icon">
            <div class="card-icon">
              <i class="material-icons">eco</i>
            </div>
            <p class="card-category"><a routerLink="/inventory">Available Products</a></p>
            <h3 class="card-title">{{dashBoardStatisticsModel?.statistcs?.availableProducts}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              {{dashBoardStatisticsModel?.statistcs?.productsOnPromotion}} &nbsp;<a routerLink="/inventory">On Promotion</a>
            </div>

          </div>
        </div>
      </div>
      <div class="col-lg-3 col-md-6 col-sm-6">
        <div class="card card-stats">
          <div class="card-header card-header-info card-header-icon">
            <div class="card-icon">
              <!-- <i class="fa fa-twitter"></i> -->
              <i class="material-icons">group</i>
            </div>
            <p class="card-category"> <a routerLink="/customers">Customers</a></p>
            <h3 class="card-title">{{dashBoardStatisticsModel?.statistcs?.numberOfCustomers}}</h3>
          </div>
          <div class="card-footer">
            <div class="stats">
              {{dashBoardStatisticsModel?.statistcs?.numberOfGroups}} &nbsp;<a routerLink="/market">Groups</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-chart">                 
          <div class="card-body">
            <span class="k-icon k-i-loading" style="color: #ff6358" *ngIf="loadingPanelVisible"></span>            
            <kendo-chart [transitions]="true" *ngIf="!loadingPanelVisible" [seriesColors]="colors">
              <kendo-chart-series>
                <kendo-chart-title
                  text="Orders by Status for the current month">
                </kendo-chart-title>
                <kendo-chart-tooltip format="{0:0}%"></kendo-chart-tooltip>
                <kendo-chart-series-item
                  [autoFit]="true"
                  type="donut"
                  [data]="ordersData"
                  categoryField="kind"
                  field="share"
                >
                  <kendo-chart-series-item-labels
                    position="outsideEnd"
                    color="#000"
                    [content]="labelContent"
                  >
                  </kendo-chart-series-item-labels>
                </kendo-chart-series-item>
              </kendo-chart-series>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
            </kendo-chart>     
            <h4 class="card-title">Total number of orders for the current month {{monthlyOrdersTotal}}</h4>       
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> Current month only
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-chart">                 
          <div class="card-body">
            <span class="k-icon k-i-loading" style="color: #ff6358" *ngIf="loadingPanelVisible"></span>            
            <kendo-chart [transitions]="true" *ngIf="!loadingPanelVisible" [categoryAxis]="{ categories: dailyOrderDays }">
              <kendo-chart-series>
                <kendo-chart-title
                  text="Daily number of orders for the current week">
                </kendo-chart-title>
                <kendo-chart-tooltip format="{0:0}"></kendo-chart-tooltip>
                  <kendo-chart-series-item type="column" [data]="noOfOrders">
                  </kendo-chart-series-item>                  
              </kendo-chart-series>
              <kendo-chart-legend [visible]="false"></kendo-chart-legend>
            </kendo-chart>     
            <h4 class="card-title">Total number of orders for the current week {{weeklyOrdersTotal}}</h4>       
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> Current week only
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card card-chart">     
            <!-- <div class="card-header card-header-success">
                <div class="ct-chart" id="dailySalesChart"></div>                
              </div>               -->
          <div class="card-body">
            <span class="k-icon k-i-loading" style="color: #ff6358" *ngIf="loadingPanelVisible"></span>
            <kendo-chart [transitions]="true" *ngIf="!loadingPanelVisible" [categoryAxis]="{ categories: dataDailySalesChart.labels }" >
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="{ format: '${0:0.00}' }">
                  </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-title
                  text="Daily Sales"
                ></kendo-chart-title>
                <kendo-chart-legend
                  position="bottom"
                  orientation="horizontal"
                ></kendo-chart-legend>
                <kendo-chart-tooltip format="${0:0.00}"></kendo-chart-tooltip>
                <kendo-chart-series >
                  <kendo-chart-series-item
                    *ngFor="let item of dataDailySalesChart.series"
                    type="line"
                    style="smooth"
                    [data]="item"
                    name="Daily Sales"
                  >
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            <h4 class="card-title">Daily Sales</h4>            
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> live data
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-chart">            
          <!-- <div class="card-header card-header-primary">
            <div class="ct-chart" id="completedTasksChart"></div>
          </div> -->
          <div class="card-body">
            <span class="k-icon k-i-loading" style="color: #ff6358" *ngIf="loadingPanelVisible"></span>
            <kendo-chart [transitions]="true" *ngIf="!loadingPanelVisible" [categoryAxis]="{ categories: dataCompletedTasksChart.labels}"  >
                <kendo-chart-title
                  text="Order Confirmation Rate"
                ></kendo-chart-title>
                <kendo-chart-value-axis>
                  <kendo-chart-value-axis-item [labels]="{ format: '{0:0}%' }">
                  </kendo-chart-value-axis-item>
              </kendo-chart-value-axis>
                <kendo-chart-legend
                  position="bottom"
                  orientation="horizontal">
                </kendo-chart-legend>
                <kendo-chart-tooltip format="{0:0}%"></kendo-chart-tooltip>
                <kendo-chart-series>
                  <kendo-chart-series-item
                    *ngFor="let item of dataCompletedTasksChart.series"
                    type="line"
                    style="smooth"
                    [data]="item"
                    name="Order Confirmation Rate"
                  >
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
            <h4 class="card-title">Confirmed Orders Rate</h4>            
          </div>
          <div class="card-footer">
            <div class="stats">
              <i class="material-icons">access_time</i> updated 2 days ago
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>
