<div class="tab-pane active" id="active">
  <kendo-grid [data]="gridView" [pageSize]="pageSize" [skip]="skip" [pageable]="true" [resizable]="true" [loading]="loading" [resizable]="true"
  (pageChange)="pageChange($event)">
    
    <kendo-grid-column field="category" title="Products" class='k-text-center font-weight-bold'
      headerClass="k-text-center font-weight-bold" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>

          <a mat-button class="nav-link" (click)="opendialog(true,dataItem)">
            {{dataItem.category.categoryName}} - {{dataItem.type?.typeName}} - {{dataItem.varietal?.varietalName}} -
            {{dataItem.productUoM?.uoM}}</a>
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="productCode" title="Code" class='k-text-center font-weight-bold'
      headerClass="k-text-center font-weight-bold" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <span>
            {{dataItem.productCode}}
        </span>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="unitPrice" title="Default Price" class='k-text-center font-weight-bold'
      headerClass="k-text-center font-weight-bold" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <kendo-numerictextbox [(ngModel)]="dataItem.unitPrice" format="c2"
          [disabled]="!featurePermission.update" [min]="0"   (valueChange)="onChange(dataItem)"
          class="form-control">
        </kendo-numerictextbox>

      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="stock" title="Quantity" class='k-text-center font-weight-bold'
      headerClass="k-text-center font-weight-bold" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">

        <a mat-button class="nav-link">                    
          {{dataItem.infinityStock ? '∞' : dataItem.stock}}
        </a>
      </ng-template>
    </kendo-grid-column>
    <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
      headerClass="k-text-center font-weight-bold" [width]="150">
      <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
        <button mat-raised-button type="button" matTooltip="Edit product settings"
          [disabled]="!featurePermission.update" [matTooltipPosition]="'above'"
          class="btn btn-primary btn-link btn-sm btn-just-icon" (click)="opendialog(true,dataItem)">
          <i class="material-icons">settings</i>
        </button>
        <button mat-raised-button type="button" matTooltip="Manage Promotions" [matTooltipPosition]="'above'"
          [disabled]="!featurePermission.update" class="btn btn-success btn-link btn-sm btn-just-icon"
          (click)="openPromotiondialog(true,dataItem)">
          <i *ngIf="hasActivePromotion(dataItem)" class="material-icons">monetization_on</i>
          <i *ngIf="! hasActivePromotion(dataItem)" class="material-icons"> highlight_off</i>
        </button>
        <button *ngIf="showError(dataItem)" mat-raised-button type="button"
          matTooltip="Product needs to be pushed or linked to external system" [matTooltipPosition]="'above'"
          class="btn btn-danger btn-link btn-sm btn-just-icon">
          <i class="material-icons">priority_high</i>
        </button>
        <button mat-raised-button type="button" matTooltip="Add batch" [matTooltipPosition]="'above'"
          [disabled]="!createInventory || dataItem.tradedBySingleBatch" class="btn btn-primary btn-link btn-sm btn-just-icon"
          (click)="addBatch(dataItem)">
          <i class="material-icons">add_box</i>
        </button>
        
        <button *ngIf="dataItem.active" mat-raised-button type="button" matTooltip="Shown on marketplace"
          [matTooltipPosition]="'above'" class="btn btn-success btn-link btn-sm btn-just-icon"
          (click)="activateDeactivateProduct(dataItem)">

          <i class="material-icons">done_all</i>

        </button>
        <button *ngIf="! dataItem.active" mat-raised-button type="button" matTooltip="Hidden from marketplace"
          [disabled]="!featurePermission.update" [matTooltipPosition]="'above'"
          class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="activateDeactivateProduct(dataItem)">
          <i class="material-icons">close</i>
        </button>
        <button *ngIf="isLinkedToExternalSystem" mat-raised-button type="button"
          [disabled]="hasExternalId(dataItem)" matTooltip="Push Product To External System"
          [matTooltipPosition]="'above'" class="btn btn-danger btn-link btn-sm btn-just-icon"
          (click)="push(dataItem)">
          <i class="material-icons">cloud_upload</i>
        </button>
        <button *ngIf="isLinkedToExternalSystem" mat-raised-button type="button"
          matTooltip="Link Product To External System" [matTooltipPosition]="'above'"
          class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="link(dataItem)">
          <i class="material-icons">link</i>
        </button>
      </ng-template>
    </kendo-grid-column>
  </kendo-grid>
<div *ngIf="Products?.length === 0">No records found</div>
<div *ngIf="! showInventory">
  <img src="/assets/img/403.png">
</div>
</div>