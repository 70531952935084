import { Component, OnInit } from '@angular/core';
import {  ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ProductDialog } from 'app/inventory/product/product.dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SellerViewModel } from 'app/shared/models/seller';
import { ConfirmationDialog } from 'app/dialogs/confirm/confirm.dialog';
import { SellerService } from 'app/shared/services/Seller.Service';
import { NgxSpinnerService } from "ngx-spinner";
import { IntegrationService } from 'app/shared/services/integration.service';
import { IntegrationItemsDialog } from 'app/inventory/dialogs/integration-items/integration-items.dialog';
import { BatchDialog } from 'app/inventory/dialogs/batch/batch.dialog';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-pending-products',
  templateUrl: './pending-products.component.html',
  styleUrls: ['./pending-products.component.css']
})
export class PendingProductsComponent implements OnInit {
  public gridView: GridDataResult;
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public pendingGridData: any[];
  public pendingGridView: any[];
  public pendingProductSelection: string[] = [];
  public pendingProductselectedUID:string  = '';
  decimalPattern = new RegExp('[0-9]+([.][0-9]+)?');
  Products: ProductViewMdel[];
  newstock: number = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  Product: ProductViewMdel;
  SupplierId: number;
  isLinkedToExternalSystem: boolean;
  ConfirmationdialogRef: MatDialogRef<ConfirmationDialog>;
  IntegrationDialogRef: MatDialogRef<IntegrationItemsDialog, any>;
  BatchDialogRef: MatDialogRef<BatchDialog, any>;
  showInventory: boolean = true;
  featurePermission: FeaturePermission  = new FeaturePermission();
  headerStyle: any;
  createInventory:boolean =false;


  constructor(
    private ProductService: ProductService, public dialog: MatDialog, private snackbar: MatSnackBar, private sellerService: SellerService,
    private spinner: NgxSpinnerService, private integrationService: IntegrationService,private userService: UserService
  ) {
    this.setSelectableSettings();
  }

  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.INVENTORY )[0];
    this.showInventory=this.featurePermission.read;   
    this.createInventory=this.featurePermission.create;  
    });
     
 }
  ngOnInit(): void {
    this.loading = true;
    this.initInventory();
    this.getLinkedApps();
  }
  async initInventory() {
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.getInventory();
  }

  getInventory()
  {
      this.loading = true;
      this.ProductService.getPendingProductImportsforSupplier().subscribe(
        (data) => {  
          this.pendingGridData = data;
          this.pendingGridView = this.pendingGridData;
          this.loading = false;
          this.mySelection[0] = data.externalId; 
        }
      );
    
  }

  
  
  push(product: ProductViewMdel): void {
    this.spinner.show("pushing");
    this.integrationService.PushProductToExternalSystem(product).subscribe((data) => {
      product.externalId = data[1];
      this.snackbar.open('Product Pushed Successfully', 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide("pushing");
    }, (error) =>{
      this.snackbar.open(`Failed to push product due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide("pushing");
    });    
  }

  hasExternalId(product: ProductViewMdel): boolean {
    return product.externalId != null;
  }

  link(product: ProductViewMdel): void {
    this.IntegrationDialogRef = this.dialog.open(IntegrationItemsDialog, { data: { productId: product.id, externalId: product.externalId }});
    this.IntegrationDialogRef.afterClosed().subscribe(result => {
      if(result.data)
      {
        product.externalId = result.data;
      }
    }); 
  }

  getLinkedApps(): any {    
    this.sellerService.getSeller().subscribe((seller) => {
      this.integrationService.GetLinkedApps(seller.id).subscribe((data) => {
        if(data.length > 0){
          this.isLinkedToExternalSystem = true;
        }
      });    
    });
  }
  showError(Product: ProductViewMdel): boolean {        
    return this.isLinkedToExternalSystem && (Product.externalId == null);
  }  
  hasActivePromotion(Product: ProductViewMdel): boolean {        
    return Product.batches.find(b => b.onPromotion) != null; 
  }
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }

  public pendingProductSelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  pendingProductKeyChange(e){    
    this.pendingProductselectedUID = e;
    console.log(this.pendingProductselectedUID);
  }

  public getHeaderStyle(): any {
  return {'text-align': 'center !important', 'font-weight':'bold'};
  }

  openImportDialog(): void {
    var pendingProduct = this.pendingGridData.filter(x => x.uid == this.pendingProductselectedUID)[0];
    var product = new ProductViewMdel();
    //todo get current supplier Id
    product.supplierId = 0;
    product.id = 0;    
    product.externalId = this.pendingProductselectedUID[0];
    product.discription = pendingProduct.description;
    product.productCode = pendingProduct.number;
    product.unitPrice = pendingProduct.unitPrice;
    var Data = { editmode: false, Product: product }
    const dialogRef = this.dialog.open(ProductDialog, { data: Data });    
    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      this.getInventory();
      this.spinner.hide();
    });

  }

}