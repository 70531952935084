<form #productForm="ngForm">
  <mat-card>
    <mat-card-header class="mb-3">

      <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
        style="background-image: url(./assets/img/oba-leaf.jpg)"
        *ngIf="dialogdata.Product.thumbnailUrl =='' || dialogdata.Product.thumbnailUrl == null">
        <span class="material-icons">camera_alt</span>
        <span>Edit</span>
      </div>
      <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
        [ngStyle]="{ 'background-image': 'url(' +dialogdata.Product.thumbnailUrl + ')'}"
        *ngIf="dialogdata.Product.thumbnailUrl !='' && dialogdata.Product.thumbnailUrl != null">
        <span class="material-icons">camera_alt</span>
        <span>Edit</span>
      </div>
      <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" />
      <mat-card-title class="cardHeader"> Product settings</mat-card-title>
    </mat-card-header>
    <mat-dialog-content>

      <section class="d-flex" *ngIf="dialogdata.editmode">
        <div class="col-md-12">
          <div class="card card-chart">
            <div class="card-body">
              <span class="k-icon k-i-loading" style="color: #ff6358" *ngIf="loadingPanelVisible"></span>
              <kendo-chart [transitions]="true" *ngIf="!loadingPanelVisible">
                <kendo-chart-title text="Predicted Stock Level for next 6 weeks"></kendo-chart-title>
                <kendo-chart-category-axis>
                  <kendo-chart-category-axis-item [categories]="['1', '2', '3', '4', '5', '6']"
                    [title]="{ text: 'Weeks' }">
                  </kendo-chart-category-axis-item>
                </kendo-chart-category-axis>
                <kendo-chart-series>
                  <kendo-chart-series-item type="line" [data]="stockLevels">
                  </kendo-chart-series-item>
                </kendo-chart-series>
              </kendo-chart>
              <h4 class="card-title">Stock Level Prediction according to current consumption </h4>
            </div>
            <div class="card-footer">
              <div class="stats">
                <i class="material-icons">access_time</i>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="d-flex">
        <mat-form-field class="col-sm-12">
          <mat-label>ProductCode</mat-label>
          <input matInput type="text" [(ngModel)]="dialogdata.Product.productCode" name="productCode" required
            #productCode="ngModel" />
          <mat-error *ngIf="productCode.errors?.required">Product Code is required</mat-error>
        </mat-form-field>
      </section>
      <section class="d-flex">
        <mat-form-field class="col-sm-12">
          <mat-label>Product description</mat-label>
          <textarea matInput type="text" [(ngModel)]="dialogdata.Product.discription" name="discription" required
            maxlength="100" #productDescription="ngModel"></textarea>
          <span><b> {{dialogdata.Product.discription?.length}}/100 </b></span>
          <mat-error *ngIf="productDescription.errors?.required">Product description is required</mat-error>
        </mat-form-field>
      </section>
      <section class="d-flex">
        <mat-form-field class="col-md-4">
          <mat-label>Category</mat-label>
          <mat-select matNativeControl (selectionChange)="loadvarital($event.value);loadTypes($event.value)"
            [(ngModel)]="dialogdata.Product.productCategoryId" name="Category" required #category="ngModel">
            <mat-option value="-1" selected>Please select category</mat-option>
            <mat-option *ngFor="let c of ProductCategories" [value]="c.id">
              {{c.categoryName}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="category.errors?.required">Category is required</mat-error>
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>Type</mat-label>
          <mat-select matNativeControl [(ngModel)]="dialogdata.Product.productTypeId" name="Type">
            <mat-option value="-1" selected>Please select Type</mat-option>
            <mat-option *ngFor="let v of ProductTypes" [value]="v.id">
              {{v.typeName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>UoM</mat-label>
          <mat-select [(ngModel)]="dialogdata.Product.productUoMId" required name="UoM">
            <mat-option value="-1" selected>Please select UoM</mat-option>
            <mat-option *ngFor="let u of ProductUoM" [value]="u.id">
              {{u.uoM}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>
      <section class="d-flex">
        <mat-form-field class="col-md-4">
          <mat-label>Varietal</mat-label>
          <mat-select matNativeControl [(ngModel)]="dialogdata.Product.productVarietalId" name="Varietal">
            <mat-option value="-1" selected>Please select varietal</mat-option>
            <mat-option *ngFor="let v of ProductVaritals" [value]="v.id">
              {{v.varietalName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>Grade</mat-label>
          <mat-select matNativeControl [(ngModel)]="dialogdata.Product.productGradeId" name="Grade">
            <mat-option value="-1" selected>Please select grade</mat-option>
            <mat-option *ngFor="let G of ProductGrades" [value]="G.id">
              {{G.grade}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>Grower</mat-label>
          <mat-select [(ngModel)]="dialogdata.Product.productGrowerId" name="Grower">
            <mat-option value="-1" selected>Please select grower</mat-option>
            <mat-option *ngFor="let Go of ProductGrowers" [value]="Go.id">
              {{Go.growerName}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </section>
      <section class="d-flex">
        <mat-form-field class="col-md-4">
          <mat-label>Ripeness</mat-label>
          <mat-select [(ngModel)]="dialogdata.Product.productRipenessId" name="ripeness">
            <mat-option value="-1" selected>Please select Ripeness</mat-option>
            <mat-option *ngFor="let r of ProductRipness" [value]="r.id">
              {{r.ripeness}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>Default Price:</mat-label>
          <input matInput placeholder="Default Price" [(ngModel)]="dialogdata.Product.unitPrice" name="unitPrice" />
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>Stock</mat-label>
          <input matInput #stock="ngModel" type="text" appDigitOnly [minNumber]="1"
            [required]="dialogdata.Product.tradedBySingleBatch" [disabled]="!dialogdata.Product.tradedBySingleBatch"
            class="" [(ngModel)]="dialogdata.Product.stock" name="stock" />
          <mat-error *ngIf="stock.errors?.minNumber">Stock must greater than 0</mat-error>
        </mat-form-field>

      </section>
      <section class="d-flex">
        <mat-form-field class="col-md-4">
          <mat-label>Size</mat-label>
          <mat-select [(ngModel)]="dialogdata.Product.productSizeId" name="size">
            <mat-option value="-1" selected>Please select Size</mat-option>
            <mat-option *ngFor="let s of ProductSizes" [value]="s.id">
              {{s.size}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>Region</mat-label>
          <mat-select [(ngModel)]="dialogdata.Product.region" name="Region">
            <mat-option value="NSW">NSW</mat-option>
            <mat-option value="QLD">QLD</mat-option>
            <mat-option value="SA">SA</mat-option>
            <mat-option value="VIC">VIC</mat-option>
            <mat-option value="WA">WA</mat-option>
            <mat-option value="TAS">TAS</mat-option>
            <mat-option value="NT">NT</mat-option>
            <mat-option value="ACT">ACT</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field class="col-md-4">
          <mat-label>Minimum Quantity</mat-label>
          <input matInput type="text" appDigitOnly [(ngModel)]="dialogdata.Product.minQuantity" #minQty="ngModel"
            name="minQuantity" [maxNumber]="dialogdata.Product.maxQuantity" />
          <span>
            <mat-error *ngIf="!quantityIsValid() ">Minimum Quantity is greater than Maximum Quantity</mat-error>
          </span>
        </mat-form-field>
      </section>
      <section class="d-flex">
        <mat-form-field class="col-md-4">
          <mat-label>Maximum Quantity</mat-label>
          <input matInput appDigitOnly type="text" [(ngModel)]="dialogdata.Product.maxQuantity" #maxQty="ngModel"
            name="maxQuantity" [minNumber]="dialogdata.Product.minQuantity" />
          <span>
            <mat-error *ngIf="!quantityIsValid() ">Maximum Quantity is less than Minimum Quantity</mat-error>
          </span>
        </mat-form-field>
      </section>


      <section class="d-flex mt-3">
        <div class="col-md-4">
          <span>Make this product always available</span>
        </div>
        <div class="col-md-2">
          <kendo-switch [(ngModel)]="dialogdata.Product.allowZeroStockOrders" name="allowZeroStockOrders" onLabel="Yes"
            offLabel="No">
          </kendo-switch>
        </div>
        <div class="col-md-4">
          <span>Requires Approval</span>
        </div>
        <div class="col-md-2">
          <kendo-switch [(ngModel)]="dialogdata.Product.requiresApproval" name="requiresApproval" onLabel="Yes"
            offLabel="No">
          </kendo-switch>
        </div>
      </section>
      <section class="d-flex mt-3">

        <div class="col-md-4">
          <span>Subject to GST</span>
        </div>
        <div class="col-md-2">
          <kendo-switch [(ngModel)]="dialogdata.Product.subjectToGST" name="subjectToGST" onLabel="Yes" offLabel="No">
          </kendo-switch>
        </div>
        <div class="col-md-4">
          <span>No Batch Tracking</span>
          <mat-error *ngIf="dialogdata.Product.tradedBySingleBatch && dialogdata.Product.stock == 0">Please make sure
            you enter value for stock</mat-error>
        </div>
        <div class="col-md-2">
          <kendo-switch [disabled]="isSingleTrackDisabled" [(ngModel)]="dialogdata.Product.tradedBySingleBatch"
            name="tradedBySingleBatch" onLabel="Yes" offLabel="No">
          </kendo-switch>
        </div>
      </section>
      <section class="d-flex" *ngIf="dialogdata.Product.id > 0">
        <div class="col-md-12">
          <mat-card-title class="cardHeader"> Batches</mat-card-title>
          <kendo-grid id="my-grid" [kendoGridBinding]="gridView" [rowClass]="rowCallback" kendoGridSelectBy="id"
            [selectedKeys]="mySelection" [loading]="loading" [pageSize]="10" [pageable]="true" [sortable]="true"
            [groupable]="false" [reorderable]="true" [resizable]="true" [height]="300" [columnMenu]="{ filter: true }"
            [selectable]="selectableSettings" kendoGridSelectBy="id" [kendoGridSelectBy]="mySelectionKey"
            (selectedKeysChange)="keyChange($event)">
            <ng-template kendoGridToolbarTemplate let-dataItem>
              <button kendoGridAddCommand (click)="addNewBatch()" [disabled]="isAddingNewDisabled()">Add new</button>
              <button kendoGridAddCommand (click)="editBatch()" [disabled]="isEditDisabled()">Edit</button>
              <button kendoGridAddCommand (click)="wasteBatch()" [disabled]="isEditDisabled()">Writeoff</button>
              <button kendoGridAddCommand (click)="packChildBatch()" [disabled]="isEditDisabled()">Pack Child
                Batch</button>

              <kendo-grid-spacer></kendo-grid-spacer>

              <kendo-grid-checkbox-column [width]="45" [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }" [resizable]="false" [columnMenu]="false"
                showSelectAll="false"></kendo-grid-checkbox-column>
            </ng-template>
            <kendo-grid-column field="id" title="ID" [width]="220" visibility="false">
            </kendo-grid-column>
            <kendo-grid-column field="number" title="Number" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="date" title="Date" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span>
                  {{dataItem.date | date: 'dd/MM/yyyy'}}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="expiryDate" title="Expiry Date" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span>
                  {{dataItem.expiryDate | date: 'dd/MM/yyyy'}}
                </span>
              </ng-template>
            </kendo-grid-column>

            <kendo-grid-column field="grade.grade" title="Grade" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="grower.growerName" title="Grower" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="region" title="Region" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="paddock" title="Paddock" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="siteName" title="Site" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="zoneName" title="Zone" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="productRipeness" title="Ripeness" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span>
                  {{dataItem.productRipeness?.ripeness}}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="productSize" title="Size" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span>
                  {{dataItem.productSize?.size}}
                </span>
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="unitPrice" title="Unit Price" format="{0:c2}" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="stockFormatted" title="Quantity" [width]="220">
            </kendo-grid-column>
            <kendo-grid-column field="number" title="Parent Batch Number" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span>
                  {{dataItem.parent?.number }}
                </span>
              </ng-template>
            </kendo-grid-column>
          </kendo-grid>
        </div>
      </section>

    </mat-dialog-content>
    <mat-card-actions align="end" class="mt-5">
      <mat-error *ngIf="showErrors">
        Please enter all mandatory fields
      </mat-error>
      <button mat-button mat-dialog-close class="btn btn-danger">Cancel</button>
      <button mat-button class="btn btn-success" (click)="saveOrUpdateProduct()" [disabled]="!formIsValid()"
        cdkFocusInitial>Save</button>
    </mat-card-actions>
  </mat-card>
</form>