import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { FormControl, Validators } from '@angular/forms';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from 'app/shared/services/upload.service';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import { Site, Zone } from 'app/shared/models/catalogue/lockups';
import { BatchTransferModel, SiteModel, ZoneModel } from 'app/shared/models/sites/site.model';
import { SellerService } from 'app/shared/services/Seller.Service';
import Swal from 'sweetalert2';


@Component({
  selector: 'dialogs-transfer-batch',
  templateUrl: './transfer-batch.dialog.html',
  styleUrls: ['./transfer-batch.dialog.css']
})
export class TransferBatchDialog implements OnInit {
  public batch = new BatchViewModel();
  Sites: SiteModel[];
  Zones: ZoneModel[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  showErrors :boolean=false;
  public batchTransferModel= new  BatchTransferModel();
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(
    public dialogRef: MatDialogRef<TransferBatchDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { batch: BatchViewModel },
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private sellerService: SellerService,
    private productService: ProductService
  ) { 
   
      this.batch=this.data.batch;
      this.batchTransferModel.batchId=this.batch.id;
      this.batchTransferModel.sourceZoneId=this.batch.zoneId;


  }


  ngOnInit(): void {
    this.spinner.show();
    this.sellerService.getSellerSites().subscribe((data) => {
      this.Sites = data;   
      this.loadZones(this.batch?.siteId, false);
      this.spinner.hide();
    });
  }  
  loadZones(siteId: any, resetCurrentZone: boolean = true) {
    this.batchTransferModel.destinationZoneId=null;
    this.Zones = this.Sites.filter(x => x.id == siteId)[0].zones.filter(s=>s.id!=this.batchTransferModel.sourceZoneId); 
    if(resetCurrentZone)
      this.batch.zoneId = -1;   
  }  
  saveOrUpdateBatch() {        
    if(this.batchTransferModel.destinationZoneId==null ||  this.batchTransferModel.destinationZoneId <= 0|| this.batchTransferModel.batchId <= 0 || this.batchTransferModel.sourceZoneId<= 0 ){
      this.showErrors = true;
      return;
    }  
    let zone=this.Zones.filter(s=>s.id==this.batchTransferModel.destinationZoneId)[0];
    let site=this.Sites.filter(x => x.id == zone.siteId)[0];
    Swal.fire({
      title: 'Transfer Batch Confirmation',
      text: 'Are you sure you want to transfer batch.no: '+this.batch.number+' from site:'+this.batch.siteName+' - zone:'+this.batch.zoneName+' to site:'+site.name+' - zone:'+zone.name+' ?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      cancelButtonText: 'Cancel',
    }).then((result) => {
      if (result.isConfirmed) {
        this.productService.transferBatches(this.batchTransferModel).subscribe((result) => {
           var message = "Batch successfully transfered";
           this.snackbar.open(message, 'close', {
             duration: 5000,
             horizontalPosition: this.horizontalPosition,
             verticalPosition: this.verticalPosition
           });
        });
     
         this.dialogRef.close(true);
      } else {
        // User clicked "Cancel" or closed the dialog
        // Put your logic for canceling the action here
      }
    });
    
  }
  formIsValid() {        
    if(this.batchTransferModel.destinationZoneId==null ||  this.batchTransferModel.destinationZoneId <= 0|| this.batchTransferModel.batchId <= 0 || this.batchTransferModel.sourceZoneId<= 0 ){

      return false;
    }  
    return true;
  }
}
