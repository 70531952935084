import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AdminLayoutRoutes } from './admin-layout.routing';
import { DashboardComponent } from '../../dashboard/dashboard.component';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { TableListComponent } from '../../table-list/table-list.component';
import { TypographyComponent } from '../../typography/typography.component';
import { IconsComponent } from '../../icons/icons.component';
import { MapsComponent } from '../../maps/maps.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { ApprovalComponent } from '../../orders/approvals/approval.component';
import { InventoryComponent } from 'app/inventory/management/inventory.component';
import { TrackingComponent } from 'app/orders/tracking/tracking.component';
import { MatInputModule } from '@angular/material/input';
import { MatNativeDateModule, MatRippleModule, MatCommonModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatSliderModule } from '@angular/material/slider';
import { MatTableModule } from '@angular/material/table';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { MatDividerModule } from '@angular/material/divider'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { ProductDialog } from 'app/inventory/product/product.dialog';
import { OrderDialog } from 'app/orders/dialogs/manual-order/manual.order.dialog';
import { BatchDialog } from 'app/inventory/dialogs/batch/batch.dialog';
import { MyobDialog } from 'app/integration/dialogs/myob/myob.dialog';
import { XeroDialog } from 'app/integration/dialogs/xero/xero.dialog';
import { NetsuiteDialog } from 'app/integration/dialogs/netsuite/netsuite.dialog';
import { IntegrationCustomersDialog } from 'app/customers/dialogs/integration-customers/integration-customers.dialog';
import { PermissionsDialog } from 'app/users/dialogs/permissions/permissions.dialog';
import { InvitationsDialog } from 'app/users/dialogs/invitations/invitations.dialog';
import { IntegrationItemsDialog } from 'app/inventory/dialogs/integration-items/integration-items.dialog';

import { OrderItemDialog } from 'app/orders/dialogs/order-item/order-item.dialog';
import { ManageCustomersComponent } from 'app/customers/manage-customers/manage-customers.component';
import { ManageUsersComponent } from 'app/users/manage-users/manage-users.component';
import { ManageIntegrationComponent } from 'app/integration/management/manage-integration.component';
import { SettingsComponent } from 'app/settings/settings.component';
import { PromotionDetialsDialog } from 'app/inventory/promotion-details/promotion-details.dialog';
import { MarketUpdateDialog } from 'app/dialogs/marketUpdate/marketUpdate.dialog';

// import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { ManageMarketComponent } from 'app/marketing/manage-market.component';
import { MarketingGroupDialog } from 'app/customers/dialogs/marketing-group/marketing-group.dialog';
import { MarketingCategoriesDialog } from 'app/customers/dialogs/marketing-categories/marketing-categories.dialog';
import { MarketingCustomersDialog } from 'app/customers/dialogs/marketing-customers/marketing-customers.dialog';
import { GridModule } from '@progress/kendo-angular-grid';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { IndicatorsModule } from "@progress/kendo-angular-indicators";
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { EditorModule } from '@progress/kendo-angular-editor';
import { IntlModule } from '@progress/kendo-angular-intl';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { PickSlipDialog } from 'app/orders/dialogs/pickslip/pickslip.dialog';
import { InvoiceDialog } from 'app/orders/dialogs/invoice/invoice.dialog';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SharedModule } from 'app/shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { WasteBatchDialog } from 'app/inventory/dialogs/waste-batch/waste-batch.dialog';
import { ChildBatchProductsDialog } from 'app/inventory/dialogs/child-batch-products/child-batch-products.dialog';
import { ChildBatchesDialog } from 'app/inventory/dialogs/child-batches/child-batches.dialog';
import { WorkspaceDialog } from 'app/errors/dialogs/workspace/workspace.dialog';
import { DetailsDialog } from 'app/errors/dialogs/workspace/details/details.dialog';
import { JoinDialog } from 'app/errors/dialogs/workspace/join/join.dialog';

import {MatStepperModule} from '@angular/material/stepper';
import { CustomMaxDirective } from 'app/shared/directives/custom-max-validator.directive';
import { DigitOnlyDirective } from 'app/shared/directives/digit-only.directive';
import { MaxNumberDirective } from 'app/shared/directives/max-number-validator.directive';
import { MaxPriceDirective } from 'app/shared/directives/max-price-validator.directive';
import { BillingComponent } from 'app/billing/billing.component';
import { SubscriptionDialog } from 'app/errors/dialogs/workspace/subscription/subscription.dialog';
import { NewSubscriptionDialog } from 'app/errors/dialogs/workspace/new-subscription/new-subscription.dialog';
import { UnderApproveDialog } from 'app/errors/dialogs/workspace/under-approve/under-approve.dialog';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { MinNumberDirective } from 'app/shared/directives/min-number-validator.directive';
import { StripePaymentComponent } from 'app/stripe-payment/stripe-payment.component';
import { CustomerDetailsDialog } from 'app/customers/dialogs/customer-details/customer-details.dialog';
import { CustomerDialog } from 'app/dialogs/customer/customer.dialog';
import { PaymentCartDialog } from 'app/errors/dialogs/workspace/paymentCart/paymentCart.dialog';
import { SitesComponent } from 'app/sites/sites.component';
import { GeneralSettingsComponent } from 'app/settings/generalSettings/generalSettings.component';
import { BankSettingsComponent } from 'app/settings/bankSettings/bankSettings.component';
import { NewSiteDialog } from 'app/sites/dialogs/newSite/new-site.dialog';
import { ZonesComponent } from 'app/sites/dialogs/zones/zones.component';
import { NewZoneDialog } from 'app/sites/dialogs/zones/dialogs/new-zone.dialog';
import { ActiveProductsComponent } from 'app/inventory/active-products/active-products.component';
import { PendingProductsComponent } from 'app/inventory/pending-products/pending-products.component';
import { SiteProductsComponent } from 'app/inventory/sites/site-products.component';
import { BatchInfoDialog } from 'app/inventory/dialogs/batch-info/batch-info.dialog';
import { TransferBatchDialog } from 'app/inventory/dialogs/transfer-batch/transfer-batch.dialog';
import { DateCompareDirective } from 'app/shared/directives/date-compare-validator.directive';
import { StockTakeSitesDialog } from 'app/inventory/dialogs/stock-take-sites/stock-take-sites.dialog';
import { StockTakeBatchesComponent } from 'app/inventory/dialogs/stock-take-batches/stock-take-batches.component';
import { ActiveUsersComponent } from 'app/users/active-users/active-users.component';
import { PendingUsersComponent } from 'app/users/pending-users/pending-users.component';
import { ActiveCustomersComponent } from 'app/customers/active-customers/active-customers.component';
import { DeactiveCustomersComponent } from 'app/customers/deactive-customers/deactive-customers.component';
import { NewCustomersComponent } from 'app/customers/new-customers/new-customers.component';
import { RejectedCustomersComponent } from 'app/customers/rejected-customers/rejected-customers.component';
import { PendingImportsCustomersComponent } from 'app/customers/pending-imports/pending-imports-customers.component';
import { AccountingIntegrationComponent } from 'app/integration/accounting/accounting-integration.component';
import { EmailIntegrationComponent } from 'app/integration/email/email-integration.component';
import { PaymentIntegrationComponent } from 'app/integration/payment/payment-integration.component';
import { CustomerOrdersComponent } from 'app/orders/approvals/customers/customer-orders.component';
import { ProductOrdersComponent } from 'app/orders/approvals/products/product-orders.component';
import { GrowersSetttingsComponent } from 'app/settings/growers-settings/growers-settings.component';
import { NewGrowerDialog } from 'app/settings/dialogs/new-grower/new-grower.dialog';
import { SizesSetttingsComponent } from 'app/settings/sizes-settings/sizes-settings.component';
import { UoMSetttingsComponent } from 'app/settings/uom-settings/uom-settings.component';
import { RipenessSetttingsComponent } from 'app/settings/ripeness-settings/ripeness-settings.component';
import { NewRipenessDialog } from 'app/settings/dialogs/new-ripeness/new-ripeness.dialog';
import { NewUoMDialog } from 'app/settings/dialogs/new-UoM/new-uom.dialog';
import { NewSizeDialog } from 'app/settings/dialogs/new-size/new-size.dialog';
import { VarietalsSetttingsComponent } from 'app/settings/varietal-settings/varietals-settings.component';
import { MasterDataDropDownComponent } from 'app/settings/master-data-dropdown/master-data-dropdown-settings.component';
import { NewVarietalDialog } from 'app/settings/dialogs/new-varietal/new-varietal.dialog';
import { OrdersComponent } from 'app/orders/orders.component';
import { CategorySetttingsComponent } from 'app/settings/category-settings/category-settings.component';
import { TypesSetttingsComponent } from 'app/settings/type-settings/types-settings';
import { NewCategoryDialog } from 'app/settings/dialogs/new-category/new-category.dialog';
import { NewTypeDialog } from 'app/settings/dialogs/new-type/new-type.dialog';
import { WebhookAPIComponent } from 'app/integration/webhook_API/webhook-api.component';
import { InvoiceSettingsComponent } from 'app/settings/invoice-settings/invoice-settings.component';
import { OrderItemsFulFillmentDialog } from 'app/orders/dialogs/fulfill-order-items/order-items-fulfillment.dialog';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AdminLayoutRoutes),
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    IntlModule,
    InputsModule,
    EditorModule,
    DialogsModule,
    PDFExportModule,
    GridModule,
    ChartsModule,
    IndicatorsModule,
    MatButtonModule,
    MatRippleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatRadioModule,
    MatTooltipModule,
    MatTableModule,
    MatToolbarModule,
    MatSliderModule,
    MatSnackBarModule,
    MatDialogModule,
    MatStepperModule,
    MatSlideToggleModule,
    MatDividerModule,
    MatCheckboxModule,
    MatCardModule,
    MatIconModule,
    MatExpansionModule,
    MatDatepickerModule,
    MatMenuModule,
    MatNativeDateModule,
    MatCommonModule,
    MatListModule,
    NgxSpinnerModule,
    PdfViewerModule,
    SharedModule.forRoot()
  ],
  declarations: [
    DashboardComponent,
    UserProfileComponent,
    TableListComponent,
    TypographyComponent,
    IconsComponent,
    MapsComponent,
    NotificationsComponent,
    ApprovalComponent,
    InventoryComponent,
    TrackingComponent,
    ManageCustomersComponent,
    ManageUsersComponent,
    ManageIntegrationComponent,
    SettingsComponent,
    ManageMarketComponent,
    PromotionDetialsDialog,
    PickSlipDialog,
    InvoiceDialog,
    ProductDialog,
    OrderDialog,
    BatchDialog,
    MyobDialog,
    XeroDialog,
    NetsuiteDialog,
    IntegrationCustomersDialog,
    PermissionsDialog,
    InvitationsDialog,
    IntegrationItemsDialog,
    OrderItemDialog,
    MarketUpdateDialog,
    MarketingGroupDialog,
    MarketingCategoriesDialog,
    MarketingCustomersDialog,
    WasteBatchDialog,
    ChildBatchProductsDialog,
    ChildBatchesDialog,
    WorkspaceDialog,
    DetailsDialog,
    CustomMaxDirective,
    DigitOnlyDirective,
    MaxNumberDirective,
    MaxPriceDirective,
    JoinDialog,
    BillingComponent,
    SubscriptionDialog,
    NewSubscriptionDialog,
    UnderApproveDialog,
    MinNumberDirective,
    StripePaymentComponent,
    CustomerDetailsDialog,
    CustomerDialog,
    PaymentCartDialog,
    SitesComponent,
    GeneralSettingsComponent,
    BankSettingsComponent,
    NewSiteDialog,
    ZonesComponent,
    NewZoneDialog,
    ActiveProductsComponent,
    PendingProductsComponent,
    SiteProductsComponent,
    BatchInfoDialog,
    TransferBatchDialog,
    DateCompareDirective,
    StockTakeSitesDialog,
    StockTakeBatchesComponent,
    ActiveUsersComponent,
    PendingUsersComponent,
    ActiveCustomersComponent,
    DeactiveCustomersComponent,
    NewCustomersComponent,
    RejectedCustomersComponent,
    PendingImportsCustomersComponent,
    AccountingIntegrationComponent,
    EmailIntegrationComponent,
    PaymentIntegrationComponent,
    CustomerOrdersComponent,
    ProductOrdersComponent,
    GrowersSetttingsComponent,
    NewGrowerDialog,
    SizesSetttingsComponent,
    UoMSetttingsComponent,
    RipenessSetttingsComponent,
    NewRipenessDialog,
    NewUoMDialog,
    NewSizeDialog,
    VarietalsSetttingsComponent,
    MasterDataDropDownComponent,
    NewVarietalDialog,
    OrdersComponent,
    CategorySetttingsComponent,
    TypesSetttingsComponent,
    NewCategoryDialog,
    NewTypeDialog,
    WebhookAPIComponent,
    InvoiceSettingsComponent,
    OrderItemsFulFillmentDialog
    
  ],
  exports: [CustomMaxDirective,DigitOnlyDirective,MaxNumberDirective,MaxPriceDirective,DateCompareDirective],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

})

export class AdminLayoutModule { }
