<div class="tab-pane" id="bank" [ngClass]="{active: bankDetailsIsActive}">
    <form [formGroup]="bankAccountDetailsForm" class="form-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                   Bank account details for system generated invoices
                </mat-card-title>
            </mat-card-header>
            <mat-card-content style="margin: top 50px;">                                                
                    <div class="col-md-8">                                                    
                        <div class="row">
                          <div class="col-md-6">
                            <mat-form-field class="example-full-width" >
                              <input matInput [readonly] ="!featurePermission.update"   placeholder="Bank Account Name" name="AccountID" [formControlName]="'accountName'">
                            </mat-form-field>
                          </div>
                          <div class="col-md-6">
                              &nbsp;
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                              <input matInput [readonly] ="!featurePermission.update"  placeholder="Bank Account Number" type="number" required name="Bank Account Number"[formControlName]="'accountNumber'">
                            </mat-form-field>
                          </div>
                          <div class="col-md-6">
                            &nbsp;
                          </div>                                                                        
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                              <mat-form-field class="example-full-width">
                                <input matInput [readonly] ="!featurePermission.update"  placeholder="BSB" type="number" maxlength="6" required name="SB" [formControlName]="'bsb'">
                              </mat-form-field>
                            </div>
                            <div class="col-md-6">
                                &nbsp;
                            </div>                                                                        
                          </div>
                    </div>
            </mat-card-content>
            <mat-card-actions align="end">
                <!-- <mat-error *ngIf="showErrors">
                  Please enter all mandatory fields
                </mat-error> -->                                            
                <button mat-button class="btn btn-success btn-round" [disabled]="!featurePermission.update" (click)="saveSettings()" cdkFocusInitial>Save</button>
            </mat-card-actions>
        </mat-card>
    </form>
</div>   