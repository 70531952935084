import { StateModel } from "../sites/site.model"

export class CustomerAddressModel {

  id: number
  customerId: number
  street:string
  addressLine1 :string
  addressLine2 :string
  addressLine3 :string
  city :string
  postalCode :string
  mobile :string
  phone :string
  isDefault :boolean
  stateId :number
  state:StateModel
  addressTypeId :number
  additionalNumber:string
  AddressType:AddressTypeModel

}
export enum AddressTypeModel {
  Billing=1,
  Shipping=2,
  Storage=3,
  Production=4,
  Packing=5
}
