import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BehaviorSubject, Observable, zip } from "rxjs";

import { map } from "rxjs/operators";
import { ProductLineItemDetails } from "../models/order/PlainProductInOrders.Model";
import { OrderService } from "./order.service";


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};


const CREATE_ACTION = "create";
const UPDATE_ACTION = "update";
const REMOVE_ACTION = "destroy";

const itemIndex = (item: ProductLineItemDetails, data: ProductLineItemDetails[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].lineItemInOrder.lineitemId === item.lineItemInOrder.lineitemId) {
      return idx;
    }
  }

  return -1;
};

const cloneData = (data: ProductLineItemDetails[]) =>
  data.map((item) => Object.assign({}, item));

  @Injectable({
    providedIn: 'root'
  })
export class EditOrderService extends BehaviorSubject<unknown[]> {
  private orderId: number;
  private data: ProductLineItemDetails[] = [];
  private originalData: ProductLineItemDetails[] = [];
  private createdItems: ProductLineItemDetails[] = [];
  private updatedItems: ProductLineItemDetails[] = [];
  private deletedItems: ProductLineItemDetails[] = [];
  private orderService: OrderService;

  constructor(private http: HttpClient, orderService: OrderService) {
    super([]);
    this.orderService=orderService;
  }

  public read(orderId: number): void {
    if (this.data.length) {
      return super.next(this.data);
    }

    this.fetch("",orderId).subscribe((data) => {
      this.orderId=orderId;
      this.data = data;
      this.originalData = cloneData(data);
      super.next(data);
    });
  }
  public orginalData(origData : any): void {
    this.data = origData;
      this.originalData = cloneData(origData);
      super.next(origData);
    
  }
  public create(item: ProductLineItemDetails): void {
    this.createdItems.push(item);
    this.data.unshift(item);

    super.next(this.data);
  }

  public update(item: ProductLineItemDetails): void {
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: ProductLineItemDetails): void {
    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    index = itemIndex(item, this.createdItems);
    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: ProductLineItemDetails): boolean {
    return !item.lineItemInOrder.lineitemId;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length ||
        this.updatedItems.length ||
        this.createdItems.length
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    const completed = [];
    if (this.deletedItems.length) {
      completed.push(this.fetch(REMOVE_ACTION, this.orderId,this.deletedItems));
    }

    if (this.updatedItems.length) {
      completed.push(this.fetch(UPDATE_ACTION,this.orderId, this.updatedItems));
    }

    if (this.createdItems.length) {
      completed.push(this.fetch(CREATE_ACTION,this.orderId, this.createdItems));
    }

    this.reset();

    zip(...completed).subscribe(() => this.read(this.orderId));
  }

  public cancelChanges(): void {
    this.reset();

    this.data = this.originalData;
    this.originalData = cloneData(this.originalData);
    super.next(this.data);
  }

  public assignValues(target: unknown, source: unknown): void {
    Object.assign(target, source);
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
  }

  private fetch(action = "",orderId : number, data?: ProductLineItemDetails[]): Observable<ProductLineItemDetails[]> {
    return this.orderService.GetOrderLineItemsDetails(orderId);
    // return this.http
    //   .jsonp(
    //     `https://demos.telerik.com/kendo-ui/service/Products/${action}?${this.serializeModels(
    //       data
    //     )}`,
    //     "callback"
    //   )
    //   .pipe(map((res) => <ProductLineItemDetails[]>res));
  }

  private serializeModels(data?: ProductLineItemDetails[]): string {
    return data ? `&models=${JSON.stringify(data)}` : "";
  }
}