<div class="tab-pane" id="accounting" [ngClass]="{active: accountingIsActive}">
    <a mat-button class="nav-link" href="{{myobLoginURL}}">
        <div class="mat-elevation-z4" matRipple routerLinkActive="active">
            <img src="/assets/img/integration/myob.png" class="integration-image">
            <img *ngIf="isLinkedToMYOB" src="/assets/img/green-check.png">
        </div>
    </a>
    <img *ngIf="isLinkedToMYOB" src="/assets/img/green-check.png" class="green-check-image">
    <a mat-button class="nav-link" href="{{xeroLoginURL}}">
        <div class="mat-elevation-z4" matRipple routerLinkActive="active">
            <img src="/assets/img/integration/xero.png" class="integration-image">
            <img *ngIf="isLinkedToXero" src="/assets/img/green-check.png">
        </div>
    </a>
    <a mat-button class="nav-link" (click)="openNetsuiteDialog()">
        <div class="mat-elevation-z4" matRipple routerLinkActive="active">
            <img src="/assets/img/integration/netsuite.jpg" class="integration-image">
            <img *ngIf="isLinkedToNetsuite" src="/assets/img/green-check.png">
        </div>
    </a>
</div>
