

export class SettingsViewModel {
    id: number;
    timezoneId: string;
    bankAccountName?: string;
    bankAccountNumber?: number;
    bankStateBranchCode?: number;
    enableMarketplace: boolean;
    disableMarketplaceSetting: boolean;
    enableInvoiceCreation?: boolean;
    invoiceGenerationPhaseId?:number;
    logo?:string;
}
export class InvoiceSettingsViewModel {
    id: number;
    enableInvoiceCreation: boolean;
    invoiceGenerationPhaseId:number;
    bankAccountName?: string;
    bankAccountNumber?: number;
    bankStateBranchCode?: number;
    logo?:string;
}
export enum OrderGeneratedPhaseModel {
    Confirmation=1,
    Fulfillment=2
}