export class ProductCategory {
    id: number = 0;
    categoryName: string;
    selected: boolean = false;
    sellerId: number = 0;
}

export class ProductGrower {
    id: number = 0;
    growerName: string;
    sellerId: number = 0;
}

export class Site {
    id: number = 0;
    name: string;
    sellerId: number = 0;
}

export class Zone {
    id: number = 0;
    name: string;
    sellerId: number = 0;
}

export class ProductGrade {
    id: number = 0;
    grade: string;
    sellerId: number = 0;
}
export class ProductVarital {
    id: number = 0;
    varietalName: string;
    category: ProductCategory;
    sellerId: number = 0;
}

export class ProductType {
    id: number = 0;
    typeName: string;
    category: ProductCategory;
    sellerId: number = 0;
}
export class ProductSize {
    id: number = 0;
    size: string;
    sellerId: number = 0;
}
export class ProductRipeness {
    id: number = 0;
    ripeness: string;
    sellerId: number = 0;
}
export class ProductUoM {
    id: number = 0;
    uoM: string;
    sellerId: number = 0;
}