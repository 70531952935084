import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { NewGrowerDialog } from '../dialogs/new-grower/new-grower.dialog';
import { ProductSize, ProductVarital } from 'app/shared/models/catalogue/lockups';
import { NewSizeDialog } from '../dialogs/new-size/new-size.dialog';
import { NewVarietalDialog } from '../dialogs/new-varietal/new-varietal.dialog';
import Swal from 'sweetalert2';


@Component({
  selector: '[app-varietals-settings]',
  templateUrl: './varietals-settings.component.html',
  styleUrls: ['./varietals-settings.component.css']
})
export class VarietalsSetttingsComponent implements OnInit {
  public gridView: GridDataResult;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  featurePermission: FeaturePermission = new FeaturePermission();
  show: boolean = true;

  constructor(
    public dialog: MatDialog,
    private userService: UserService, private sellerService: SellerService
  ) {
  
  }

  ngOnInit(): void {
    this.loading = true;
    this.init();
  }
  
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SETTINGS )[0];
  console.log(this.featurePermission);
  this.show = this.featurePermission.read;
  });
   
}
  async init() {
    this.loading = true;
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    
     this.sellerService.getVarietalsSettings(this.currentPage,this.pageSize).subscribe((data) => {
       this.gridView = {
         data: data.items,
         total: data.totalItems,
       };
       this.loading = false;
     });
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getGrid();
  }
  
  getGrid()
  {
    this.loading = true;
    this.sellerService.getVarietalsSettings(this.currentPage,this.pageSize).subscribe((data) => {
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;
    });
  }

  
   newVarietal(editable:boolean=false,model:ProductVarital=null): void {
    var Data = { editable: editable, model: model }
     const dialogRef = this.dialog.open(NewVarietalDialog,{data: Data ,width:'1000px'});
     dialogRef.afterClosed().subscribe(result => {
       this.loading = true;
       this.init();
     });
   }
   deleteVarietal(id:number)
   {
    this.loading = true;
    this.sellerService.checkVarietalIsExistInProducts(id).subscribe((isExist) => {
      if(!isExist)
      {
        this.sellerService.deleteVarietals(id).subscribe((data) => {
          this.loading = true;
           this.init();
        });
      }
      else
      {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          html: "<strong> The selected Varietal can not be deleted, as it is being used by other products or batches, please delete any reference to this Varietal in order to be able to delete it  </strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        }).then((result) => {
          this.loading = true;
          this.init();
        })
      }
       
    });
    
   }
}

