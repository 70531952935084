<div class="tab-pane" id="inactive">
    <kendo-grid [data]="gridView" [loading]="loading" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
        [resizable]="true" (pageChange)="pageChange($event)">
        <kendo-grid-column field="data" title="Customer Name" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a mat-button class="nav-link" matTooltip="Customer Name"
                    (click)="openCustomerDialog(dataItem.customer.id)">
                    {{dataItem.customer.businessName}}
                    <div class="ripple-container"></div>
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="Email" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a mat-button class="nav-link" matTooltip="Email">
                    {{dataItem.customer.businessEmail}}
                    <div class="ripple-container"></div>
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button [disabled]="!featurePermission.update" *ngIf="!dataItem.isActive && dataItem.status=='approved'"
                    mat-raised-button type="button" matTooltip="Activate" [matTooltipPosition]="'above'"
                    class="btn btn-success btn-link btn-sm btn-just-icon" (click)="activateDeactivateProduct(dataItem)">
                    <i class="material-icons">visibility</i>
                </button>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>