import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { MsalService } from '@azure/msal-angular';
import { IntegrationCustomersDialog } from 'app/customers/dialogs/integration-customers/integration-customers.dialog';
import { SupplierTrustedNetworks, SupplierTrustedNetworksViewModel } from 'app/shared/models/promotions/marketing.model';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { MarketingService } from 'app/shared/services/marketing.service';
import { UtilsService } from 'app/shared/services/utils.service';
import { IChatParticipant } from 'ng-chat';
import { NgxSpinnerService } from "ngx-spinner";
import { IntegrationService } from 'app/shared/services/integration.service';
import {UserService} from 'app/shared/services/user.service';
import { UserModel } from 'app/shared/models/user/user.model';
import { PermissionsDialog } from '../dialogs/permissions/permissions.dialog';
import { InvitationsDialog } from '../dialogs/invitations/invitations.dialog';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';

@Component({
  selector: 'app-manage-users',
  templateUrl: './manage-users.component.html',
  styleUrls: ['./manage-users.component.css']
})
export class ManageUsersComponent implements OnInit {
  public gridView: GridDataResult;
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public isActive: boolean=true;
  activeUsers: UserModel[];
  pendingUsers: UserModel[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  PermissionsDialogRef: MatDialogRef<PermissionsDialog, any>;
  InvitationDialogRef: MatDialogRef<InvitationsDialog, any>;
  showUsers: boolean = true;  
  featurePermission: FeaturePermission = new FeaturePermission();

  constructor(
    private spinner: NgxSpinnerService,
    public dialog: MatDialog,
    private eventEmitterService: EventEmitterService,
    private integrationService: IntegrationService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private sellerService: SellerService
  ) {}

  ngOnInit(): void {    
    this.loadUserPermissions();
    this.initUsers();
  }

  async loadUserPermissions(){
    this.sellerService.getSeller().subscribe(seller => {
      this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
      this.featurePermission=userPermissions.filter(x => x.feature == PermissionsConstants.USERS )[0];
      this.showUsers = this.featurePermission.read;
      });
    });
  }

  async initUsers(isActive:boolean=true) {    
    //this.spinner.show();
    this.loading = true;    
    this.userService.GetSellerUsers(this.currentPage,this.pageSize,isActive).subscribe((data) => {      
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };        
      //this.spinner.hide();
      this.loading = false;
    });
  }

  managePermissions(user: UserModel, ispending: boolean): void {   
    this.PermissionsDialogRef = this.dialog.open(PermissionsDialog, { data: { isPending: ispending,  userId: user.id, user: user} });
    this.PermissionsDialogRef.afterClosed().subscribe(result => {
      this.PermissionsDialogRef = null;
    });
  }

  activateDeactivateUser(user: UserModel, activate:boolean): void {
    this.spinner.show();
    this.userService.UpdateUserLinkStatus(user.id, activate).subscribe((data) => {      
      this.initUsers();        
      this.spinner.hide();
    });
  }

  deleteUserLink(user: UserModel): void {
    this.spinner.show();
    this.userService.DeleteUserSellerLink(user.id).subscribe((data) => {      
      this.initUsers();            
      this.spinner.hide();
    });
  }

 

  InviteUser(): void {
    this.InvitationDialogRef = this.dialog.open(InvitationsDialog);
    this.InvitationDialogRef.afterClosed().subscribe(result => {
      this.InvitationDialogRef = null;
      this.spinner.show();
      this.userService.GetSellerPendingUsers().subscribe((data) => {
        this.pendingUsers = data;
        this.spinner.hide();
      });
    });
  }

  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }

  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }

  keyChange(e){    
    this.selectedUID = e;
  }

  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.userService.GetSellerUsers(this.currentPage,this.pageSize).subscribe((data) => {      
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      }  
    });

  }

  getGrid(isActive:boolean=true)
  {
    this.isActive=isActive;
    this.loading = true;
    this.userService.GetSellerUsers(this.currentPage,this.pageSize,isActive).subscribe((data) => {      
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      }  
      this.loading = false;
    });    
  }
}

