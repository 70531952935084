import { Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';

import { MessageService } from 'app/shared/services/message.service';
import { NgxSpinnerService } from "ngx-spinner";
import { DialogService } from '@progress/kendo-angular-dialog';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { ButtonsModule } from "@progress/kendo-angular-buttons";

import Swal from 'sweetalert2'
import { MatDialog } from '@angular/material/dialog';
import { WorkspaceDialog } from 'app/errors/dialogs/workspace/workspace.dialog';



/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
   requestCount: number=0;
  _dialogService: DialogService;
  constructor(private _injector: Injector, private messageService: MessageService, private spinner: NgxSpinnerService, private dialogService: DialogService,public dialog: MatDialog
  ) {
    this.requestCount=0;
    setTimeout(() => {
      this._dialogService = <DialogService>this._injector.get(DialogService);
    });

  }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => {
    this.requestCount=0;
    return <T>(operation = 'operation', result = {} as T) =>
      this.handleError(serviceName, operation, result);
  }


  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {
    
    return (error: HttpErrorResponse): Observable<T> => {
      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      const message = (error.error instanceof ErrorEvent) ?
        error.error.message :
        `server returned code ${error.status} with body "${error.error}"`;

      // TODO: better job of transforming error for user consumption
      //this.messageService.add(`${serviceName}: ${operation} failed: ${message}`);

      this.spinner.hide();

      // if (error.status == 403) {
      //   // this.messageService.error("Please contact OmniOrder for Onboarding", true);
      //   Swal.fire({
      //     icon: 'error',
      //     title: 'Oops...',
      //     html: "<strong> You don't have permission to access "  + operation + " this feature, please contact your admin to grant you access</strong>",
      //     footer: '',
      //     showCloseButton: false,
      //     showCancelButton: false,
      //     confirmButtonColor: "#0c6acb"
      //   })
      //   // Let the app keep running by returning a safe result.
      //   return of(result);
      // };

      if (operation == 'getSellerInfo') {
        
        // this.messageService.error("Please contact OmniOrder for Onboarding", true);
        if(error.status==404)
        {
        if(this.requestCount<1)
        {
          this.dialog.open(WorkspaceDialog,{data:{hasProfile:false},width:'500px', disableClose: true });
          this.requestCount=this.requestCount+1;
        }
         
        }
        // if(this.requestCount<1)
        // {
        //   Swal.fire({
        //     icon: 'error',
        //     title: '"Ooops you are not linked to a workspace',
        //     html: '<a  [routerLink]="/inventory" ></a>',
        //     footer: '<a href="https://omniorder.com.au/contact/">onboarding Request..</a>',
        //     showCloseButton: false,
        //     showCancelButton: false,
        //     confirmButtonColor: "#0c6acb"
        //   })
        // }
        
        // Let the app keep running by returning a safe result.
        return of(result);
      };

    }

  }
}
