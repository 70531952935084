import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyobDialog } from 'app/integration/dialogs/myob/myob.dialog';
import { MarketingService } from 'app/shared/services/marketing.service';
import { IntegrationService } from 'app/shared/services/integration.service';
import { CompanyFileViewModel } from 'app/shared/models/integration/companyfile.model';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'environments/environment';
import {ActivatedRoute}  from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { XeroTenantViewModel } from 'app/shared/models/integration/xerotenant.model';
import { XeroDialog } from 'app/integration/dialogs/xero/xero.dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SellerService } from 'app/shared/services/Seller.Service';
import { TimezoneViewModel } from 'app/shared/models/common/TimezoneViewModel';
import { SupplierViewModel } from 'app/shared/models/seller/supplierViewModel';
import { InvoiceSettingsViewModel, OrderGeneratedPhaseModel, SettingsViewModel } from 'app/shared/models/seller/settingsViewModel';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { UploadService } from 'app/shared/services/upload.service';


@Component({
  selector: 'invoice-settings',
  templateUrl: './invoice-settings.component.html',
  styleUrls: ['./invoice-settings.component.scss']
})
export class InvoiceSettingsComponent implements OnInit {  
  showSettings: boolean = true;
  enableInvoiceCreation: boolean = true;
  public invoiceGenerationPhaseId: number=-1;
  public confirmation=OrderGeneratedPhaseModel.Confirmation;
  public fulfillment=OrderGeneratedPhaseModel.Fulfillment;
  public phaseVal: number=-1;
  public confirmationClass :string ="";
  public fulfillmentClass :string ="";
  public logo :string ="";
  featurePermission: FeaturePermission = new FeaturePermission();
  public form: FormGroup;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  @ViewChild('fileInput') fileInput: ElementRef;
  //bankAccountDetailsForm: FormGroup;
  constructor(

    private fb: FormBuilder,
    private marketingService: MarketingService,
    private integrationService: IntegrationService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    private sellerservice: SellerService,
    private userService: UserService,
    private snackbar: MatSnackBar,
    private uploadService: UploadService
  ) {}

  generalIsActive : boolean = true;
  bankDetailsIsActive : boolean = false;
  timezones: TimezoneViewModel[];
  settings: SettingsViewModel;

 
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission=userPermissions.filter(x => x.feature == PermissionsConstants.SETTINGS )[0];
  this.showSettings = this.featurePermission.read;
  });
}
 
 
  ngOnInit(): void {   
    this.spinner.show('loading'); 
    
    this.form=this.fb.group(
      {
        enableInvoiceCreation:[null,[Validators.required,]],
        invoiceGenerationPhaseId:[1,[Validators.required,]],
        accountName: [''],
    accountNumber: [''],
    bsb:['']
      }      
    );
    
    this.sellerservice.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
 
    this.loadSettings();
  }
loadSettings()
{
  this.spinner.show('loading'); 
  this.sellerservice.getSellerSettings().subscribe(data => {
    this.settings = data;
    console.log( this.settings);
    this.enableInvoiceCreation=data.enableInvoiceCreation;
    this.logo=data.logo;
    this.form.patchValue({
      enableInvoiceCreation: data.enableInvoiceCreation,
      invoiceGenerationPhaseId:data.invoiceGenerationPhaseId==null?OrderGeneratedPhaseModel.Confirmation:data.invoiceGenerationPhaseId,
      accountName: data.bankAccountName,
      accountNumber: data.bankAccountNumber,
      bsb: data.bankStateBranchCode
    });
    this.spinner.hide('loading'); 
  }
);
}
  saveSettings(){
    var invoiceSellerSettings: InvoiceSettingsViewModel =  {
        id: this.settings?.id,
        enableInvoiceCreation: this.form.value['enableInvoiceCreation'],
        invoiceGenerationPhaseId:this.form.value['invoiceGenerationPhaseId'],
        bankAccountName: this.form.value['accountName'],
        bankAccountNumber: this.form.value['accountNumber'],
        bankStateBranchCode: this.form.value['bsb']
    };   
    invoiceSellerSettings.logo=this.logo; 
    this.spinner.show('loading'); 
    this.sellerservice.updateInvoiceSellerSettings(invoiceSellerSettings).subscribe( data => {
      if(data)
      {
        this.snackbar.open('Invoice Settings updated successfully', 'close', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.loadSettings();
      }
      else
      {
        this.snackbar.open(`Failed to update Invoice Settings`, 'close', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.spinner.hide('loading');   
      }
    
    },(error)=>{

      this.snackbar.open(`Failed to update Invoice Settings due to error ${error.error}`, 'close', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide('loading');   
    });
  }
  updateClasses( phaseVal: number) {

    switch (phaseVal) {
      
      case OrderGeneratedPhaseModel.Confirmation:
        this.invoiceGenerationPhaseId=OrderGeneratedPhaseModel.Confirmation;
         this.confirmationClass = 'border border-primary';
         this.fulfillmentClass = '';
        break;
      case OrderGeneratedPhaseModel.Fulfillment:
        this.invoiceGenerationPhaseId=OrderGeneratedPhaseModel.Fulfillment;
         this.fulfillmentClass = 'border border-primary';
         this.confirmationClass = '';
        break;

      default:
        this.invoiceGenerationPhaseId=-1;
         this.confirmationClass = '';
         this.fulfillmentClass = '';
    }
  }
  upload(files) {
    const formData = new FormData();
    this.spinner.show();
    if (files[0]) {
      // Todod Append Product Id
      formData.append('550c0516-3dac-40e7-95e6-ba2e9d3a40a7/' + files[0].name, files[0]);
    }

    this.uploadService
      .uploadImage(formData)
      .subscribe(
        (data) => {
          this.logo = data[0].absoluteUri;
          this.spinner.hide();
          //this.imageSource = data[1].absoluteUri;
        }
      );
  }
  showFull = false;

  isFullImage = false;

  toggleFullImage() {
    this.isFullImage = !this.isFullImage;
  }

  showFullImage() {
    this.isFullImage = true;
  }

  hideFullImage() {
    this.isFullImage = false;
  }
 openFileDialog(event: Event) {
  event.preventDefault();
  this.fileInput.nativeElement.click();
  }
  toggleValidation() {
    let enableInvoice = this.form.get('enableInvoiceCreation').value;
   const accountNameControl=this.form.get('accountName');
   const accountNumberControl=this.form.get('accountNumber');
   const bsbControl=this.form.get('bsb');
    if (enableInvoice) {
      accountNameControl.setValidators(Validators.required);
      accountNumberControl.setValidators(Validators.required);
      bsbControl.setValidators(Validators.required);
    } else {
      accountNameControl.clearValidators();
      accountNumberControl.clearValidators();
      bsbControl.clearValidators();
    }
  
    accountNameControl.updateValueAndValidity();
    accountNumberControl.updateValueAndValidity();
    bsbControl.updateValueAndValidity();
  }
}
