import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SellerViewModel } from 'app/shared/models/seller/sellerViewModel';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { UserService } from 'app/shared/services/user.service';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {  
  featurePermission: FeaturePermission = new FeaturePermission();
  showSettings: boolean = true;
  
  constructor(
    public dialog: MatDialog,
    private userService: UserService
  ) {}

  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission=userPermissions.filter(x => x.feature == PermissionsConstants.ORDERS )[0];
    this.showSettings = this.featurePermission.read;
    });
  }

  ngOnInit(): void {   
  
  }
}
