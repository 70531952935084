import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { CustomerDetailsDialog } from '../dialogs/customer-details/customer-details.dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';

@Component({
  selector: 'app-manage-customers',
  templateUrl: './manage-customers.component.html',
  styleUrls: ['./manage-customers.component.css']
})
export class ManageCustomersComponent implements OnInit {
  public loading: boolean = false;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  
  constructor(
    public dialog: MatDialog, private route: ActivatedRoute, private router: Router,
    private snackbar: MatSnackBar
  ) {

  }

  ngOnInit(): void {
  }

  opendialog(): void {
    const dialogRef = this.dialog.open(CustomerDetailsDialog);
    dialogRef.afterClosed().subscribe(result => {
      if(result!=null && result!="")
      {
        this.snackbar.open('Customer added successfully', 'close', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.reloadCurrentRoute();
      }
      


    }, (error) => {
      this.snackbar.open(`Failed to add customer due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
  }
  reloadCurrentRoute() {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate(['customers']);
    });

  }
}

