import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

import { FooterComponent } from './footer/footer.component';
import { NavbarComponent } from './navbar/navbar.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AlertComponent } from './alert/alert.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NotificationBarModule } from '../../notification-bar/notification-bar.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatTooltipModule,
    MatToolbarModule,
    MatButtonModule,
    NotificationBarModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AlertComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    AlertComponent
  ]
})
export class ComponentsModule { }
