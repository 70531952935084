import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { SiteModel, StateModel } from 'app/shared/models/sites/site.model';
import { NewSiteDialog } from './dialogs/newSite/new-site.dialog';

@Component({
  selector: '[app-sites-customers]',
  templateUrl: './sites.component.html',
  styleUrls: ['./sites.component.css']
})
export class SitesComponent implements OnInit {
  public gridView: GridDataResult;
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public isActive: boolean=true;
  sitesViewModel: SiteModel[];
  states: StateModel[];
  featurePermission: FeaturePermission = new FeaturePermission();
  showSites: boolean = true;

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
    private snackbar: MatSnackBar,
    private userService: UserService, private sellerService: SellerService
  ) {
    this.setSelectableSettings();
  }

  ngOnInit(): void {
    this.loading = true;
    this.initSites();
  }
  
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SITE_MANAGEMENT )[0];
  console.log(this.featurePermission);
  this.showSites = this.featurePermission.read;
  });
   
}
  async initSites() {
    this.loading = true;
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    
     this.sellerService.getSites(this.currentPage,this.pageSize).subscribe((data) => {
       this.gridView = {
         data: data.items,
         total: data.totalItems,
       };
       this.loading = false;
     });
  }
  
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }

  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getGrid();
  }
  
  getGrid()
  {
    this.loading = true;
    this.sellerService.getSites(this.currentPage,this.pageSize).subscribe((data) => {
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;
    });
  }

  
   newSite(editable:boolean=false,siteModel:SiteModel=null): void {
    var Data = { editable: editable, siteModel: siteModel }
     const dialogRef = this.dialog.open(NewSiteDialog,{data: Data ,width:'1000px'});
     dialogRef.afterClosed().subscribe(result => {
       this.loading = true;
       this.initSites();
       this.spinner.hide();
     });
   }
}

