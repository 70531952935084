<div mat-dialog-content>
  <div *ngIf="!isShow" class="loading-container">
    <mat-spinner></mat-spinner>
  </div>
  <div>
    <div *ngIf="isShow">
      <a [href]="pdfSrc" class="btn btn-primary btn-lg active pdf-btn" role="button" aria-pressed="true">
        <i class="fa fa-download"></i>
      </a>
      <pdf-viewer [src]="pdfSrc" [render-text]="false" [original-size]="true" [fit-to-page]="true"
        (after-load-complete)="onLoadComplete()"></pdf-viewer>
    </div>
  </div>
</div>