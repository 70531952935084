<div class="tab-pane" id="general" [ngClass]="{active: generalIsActive}">
    <form [formGroup]="form" class="form-container">
        <mat-card>
          
            <mat-card-header>
             
                <mat-card-title>
                    Invoice Settings
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>    
              <div class="row mt-5">
                <div class="col-md-3">                                                    
                  <maat-label class="span-black-color">Logo</maat-label>
              </div>
                <div class="col-md-9 mb-5 " >
                  <div *ngIf="logo!='' && logo != null" class="image-container col-md-4 " (click)="toggleFullImage()" (mouseover)="showFullImage()" (mouseout)="hideFullImage()">
   
                  <img  [src]="logo" alt="Image Description" class=" rounded  "[ngClass]="{'full-image': isFullImage}" >
                  <button type="button" [disabled]="this.isMarketPlaceSource" class=" span-button" (click)="openFileDialog($event)" ><span class="camera material-icons">camera_alt</span></button>
                  <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" accept="image/*" />
                </div>
                <div *ngIf="logo =='' || logo == null" class="image-container col-md-4 d-flex align-items-center justify-content-center" (click)="toggleFullImage()" (mouseover)="showFullImage()" (mouseout)="hideFullImage()"style="background-image: url(./assets/img/oba-leaf.jpg)">
   
                  <button id="camera" [disabled]="this.isMarketPlaceSource" class="btn span-button" (click)="openFileDialog($event)"><span  class="camera material-icons">camera_alt</span></button>
                  <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" accept="image/*" />
                </div>
                </div>
              </div>                                        
                                       
                <div class="row mt-5">   
                    <div class="col-md-3">                                                    
                        <maat-label class="span-black-color">Enable Invoice Creation</maat-label>
                    </div>
                    <div class="col-md-1" style="height: 5em;">                                                                                                                
                            <kendo-switch style="padding-left: 0!important;" [disabled]="!featurePermission.update " formControlName="enableInvoiceCreation" name="EnableInvoiceCreation" (ngModelChange)="toggleValidation()" class="pl-5" onLabel="Yes" offLabel="No">
                            </kendo-switch>          
                    </div>

                </div>                                            
                <div class="row mt-2">   
                    <div class="col-md-3">                                                    
                        <maat-label class="span-black-color">Invoices are generated at</maat-label>
                    </div>
                    <mat-radio-group  [disabled]="!form.get('enableInvoiceCreation').value"   formControlName="invoiceGenerationPhaseId" class="col-md-9">

                        <div class="row">
                         <article class="card mb-4 col-md-4 " [ngClass]="{'disable-radio-group': !form.get('enableInvoiceCreation').value,'border border-primary':invoiceGenerationPhaseId==confirmation}" >
                           <div class="card-body "  >
                             <label class="js-check box active" >
                               <mat-radio-button   [value]="confirmation" (change)="updateClasses(confirmation)"> <span class="span-black-color">Confirmation</span> </mat-radio-button >
                     
                             </label>
                             
                           </div>
                         </article>
                         <article class="card mb-4 col-md-4 offset-md-1"  [ngClass]="{'disable-radio-group': !form.get('enableInvoiceCreation').value,fulfillmentClass:form.get('enableInvoiceCreation').value,'border border-primary':invoiceGenerationPhaseId==fulfillment}">
                           <div class=" card-body ">
                             <label class="js-check box active">
                               <mat-radio-button   [value]="fulfillment" (change)="updateClasses(fulfillment)"> <span class="span-black-color">Fulfillment</span> </mat-radio-button >
                       
                             </label>
                             
                           </div>
                         </article>
                        </div>
                           
                           </mat-radio-group>

                </div>  
                <mat-card>
          
                  <mat-card-header>
                   
                      <mat-card-title>
                          Payment Settings
                      </mat-card-title>
                  </mat-card-header>
                  <mat-card-content>
                    <div class="row mt-2">
                      <div class="col-md-8">                                                    
                        <div class="row">
                          <div class="col-md-3">                                                    
                            <maat-label class="span-black-color">Bank Account Name</maat-label>
                        </div>
                          <div class="col-md-6">
                            <mat-form-field class="example-full-width" >
                              <input matInput [ngClass]="{'disabled-btn': !featurePermission.update || !form.get('enableInvoiceCreation').value}" [disabled]="!featurePermission.update || !form.get('enableInvoiceCreation').value"    placeholder="Bank Account Name" name="AccountID" [formControlName]="'accountName'">
                            </mat-form-field>
                          </div>
                       
                        </div>
                        <div class="row">
                          <div class="col-md-3">                                                    
                            <maat-label class="span-black-color">Bank Account Number</maat-label>
                        </div>
                          <div class="col-md-6">
                            <mat-form-field class="example-full-width">
                              <input matInput [ngClass]="{'disabled-btn': !featurePermission.update || !form.get('enableInvoiceCreation').value}" [disabled]="!featurePermission.update || !form.get('enableInvoiceCreation').value"   appDigitOnly placeholder="Bank Account Number" type="number"  name="Bank Account Number"[formControlName]="'accountNumber'">
                            </mat-form-field>
                          </div>
                                                                                            
                        </div>
                        <div class="row">
                          <div class="col-md-3">                                                    
                            <maat-label class="span-black-color">Bank Account Number</maat-label>
                        </div>
                            <div class="col-md-6">
                              <mat-form-field class="example-full-width">
                                <input matInput [ngClass]="{'disabled-btn': !featurePermission.update || !form.get('enableInvoiceCreation').value}" [disabled]="!featurePermission.update || !form.get('enableInvoiceCreation').value"   appDigitOnly  placeholder="BSB" type="number" maxlength="6"  name="SB" [formControlName]="'bsb'">
                              </mat-form-field>
                            </div>
                                                                                                   
                          </div>
                    </div>
                    </div>   
                  </mat-card-content> 
                   </mat-card>  
                                                    
            </mat-card-content>
            <mat-card-actions align="end">
                <!-- <mat-error *ngIf="showErrors">
                  Please enter all mandatory fields
                </mat-error> -->                                            
                <button mat-button class="btn btn-success btn-round" [disabled]=" !form.valid"  (click)="saveSettings()" cdkFocusInitial>Save</button>                                            
            </mat-card-actions>
        </mat-card>
    </form>
</div>