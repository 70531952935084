<div class="tab-pane" id="email" [ngClass]="{active: emailIsActive}">
    <a mat-button class="nav-link" href="{{googleOauthURL}}">
        <div class="mat-elevation-z4" matRipple routerLinkActive="active">
            <img src="/assets/img/integration/gmail.png" height="136" width="136">
            <img *ngIf="isLinkedToGoogle" src="/assets/img/green-check.png">
        </div>
    </a>

    <a mat-button class="nav-link" href="{{exchangeOauthURL}}">
        <div class="mat-elevation-z4" matRipple routerLinkActive="active">
            <img src="/assets/img/integration/outlook.png" height="136" width="136">
            <img *ngIf="isLinkedToMicrosoft" src="/assets/img/green-check.png">
        </div>
    </a>
</div>