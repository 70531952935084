import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerABNValidator } from 'app/shared/validators/CustomerABNValidator';
import { ExternalCustomerModel } from 'app/shared/models/integration/external.customer.model';
import { SiteModel, StateModel, ZoneModel } from 'app/shared/models/sites/site.model';
import { SellerViewModel } from 'app/shared/models/seller/sellerViewModel';
import { UserService } from 'app/shared/services/user.service';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { PermissionsConstants } from 'app/shared/permissions.constants';


@Component({
  selector: 'app-new-site',
  templateUrl: './new-site.dialog.html',
  styleUrls: ['./new-site.dialog.css']
})
export class NewSiteDialog implements OnInit {
  public opened = true;
  public siteForm: FormGroup;
  states: StateModel[];
  zones: ZoneModel[];
  public isLoading:boolean=true;
  public isEditableForm:boolean=true;
  featurePermission: FeaturePermission = new FeaturePermission();
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(public dialogRef: MatDialogRef<NewSiteDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: { editable: boolean, siteModel: SiteModel}
    ,public dialog: MatDialog
    ,private sellerservice: SellerService,
    public fb: FormBuilder,private snackbar: MatSnackBar,
    private userService: UserService,

  ) {
  }


   async ngOnInit() {
    this.spinner.show();  
    this.isEditableForm=this.isEditable();
    await this.sellerservice.getSeller().subscribe(seller => {
       this.loadPermissions(seller);
      
    });
    
    this.sellerservice.getStates().subscribe((data) => {
      this.states=data;
      this.spinner.hide();
    });

   
  }
  async loadPermissions(seller: SellerViewModel) {   
    await this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SITE_MANAGEMENT )[0];

    if(this.data.editable && this.data.siteModel==null)
    {
      this.isEditableForm=this.isEditable();
      this.siteForm=this.fb.group(
        {
          name:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required,]],
          code:["",[Validators.maxLength(250),Validators.minLength(1),Validators.required]],
          addressLine1:["",[Validators.minLength(3),Validators.maxLength(250),Validators.required]],
          city:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
          stateId:[null,[Validators.required]],
          postalCode:["",[Validators.maxLength(4),Validators.minLength(4),Validators.required]]
        }      
      );
      this.isLoading=false;
    }
    if(this.data.editable && this.data.siteModel!=null)
    {
       this.isEditableForm=this.isEditable();
      this.siteForm=this.fb.group(
        {
          name:[{value:"",disabled: !this.isEditableForm},[Validators.maxLength(250),Validators.minLength(3),Validators.required,]],
          code:[{value:"",disabled: !this.isEditableForm},[Validators.maxLength(250),Validators.minLength(1),Validators.required]],
          addressLine1:[{value:"",disabled: !this.isEditableForm},[Validators.minLength(3),Validators.maxLength(250),Validators.required]],
          city:[{value:"",disabled:!this.isEditableForm},[Validators.maxLength(250),Validators.minLength(3),Validators.required]],
          stateId:[{value:null,disabled: !this.isEditableForm},[Validators.required]],
          postalCode:[{value:"",disabled: !this.isEditableForm },[Validators.maxLength(4),Validators.minLength(4),Validators.required]]
        }      
      );
      this.siteForm.patchValue({
        name: this.data.siteModel.name,
        code:this.data.siteModel.name,
        addressLine1:this.data.siteModel.addressLine1,
        city:this.data.siteModel.city,
        stateId:this.data.siteModel.stateId,
        postalCode:this.data.siteModel.postalCode,
      });
      this.zones=this.data.siteModel.zones;
     this.isLoading=false;

    }
    });
     
  }
  get f() { return this.siteForm.controls; }

  public close(status: string): void {
   
    this.opened = false;
  }
  openSellerDetails()
  {   
    
  }

  onSubmit() {
    this.spinner.show(); 
    let siteModel: SiteModel= this.siteForm.value;
    siteModel.zones=this.zones;
    if(this.data?.siteModel?.id){
      siteModel.id = this.data.siteModel.id;
      this.sellerservice.updateSite(siteModel).subscribe((data) => {
        this.spinner.hide(); 
        this.dialogRef.close();
     });
    } else {
      this.sellerservice.createNewSite(siteModel).subscribe((data) => {
        this.spinner.hide(); 
        this.dialogRef.close();
     });
    }    
 }

 receiveDataFromChild(data: ZoneModel[]) {
  this.zones = data;
  }
  isEditable()
  {
    if(this.data.editable && this.data.siteModel==null && this.featurePermission.create)
    {
      return true;
    }
    else if(this.data.editable && this.data.siteModel!=null && this.featurePermission.update)
    {
   
      return true;

    }
    else
    {
      return false;
    }
  }
}
