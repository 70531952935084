<!-- <div class="container-fluid">
<div class="row mb-5">
    <div class="col-12 text-center">
        <span class="h3 font-weight-bold">Manage group members for: {{MarketingGroup.groupName}}</span>
    </div>
</div>

<div class="row">
    
    <div class="col-3"  *ngFor="let member of customers ; let memberIndex=index">
       
        <label class="" for="member">{{member.name}}</label>
      <input type="checkbox" [(ngModel)]="member.selected" #member kendoCheckBox />
    </div>
    
  </div>
</div> -->
  

<mat-card>
    <mat-card-header class="mb-3">

      <mat-card-title class="cardHeader h3">Manage group members for: {{MarketingGroup.groupName}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <section class="d-flex" >
        <div class="col-md-12">         
              <kendo-grid
                [kendoGridBinding]="gridView"
                kendoGridSelectBy="id"
                [pageSize]="10"
                [pageable]="true"
                [sortable]="true"
                [groupable]="false"
                [reorderable]="true"
                [resizable]="true"
                [height]="300"
                [columnMenu]="{ filter: true }"
                kendoGridSelectBy="id"
                >                          
                <kendo-grid-column field="selected" title="" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <input type="checkbox" [(ngModel)]="dataItem.selected" />
                    </ng-template>
                </kendo-grid-column>          
              <kendo-grid-column field="customer.businessName" title="Name" [width]="150">
              </kendo-grid-column>                    
              <kendo-grid-column field="customer.phone" title="Phone" [width]="150">
              </kendo-grid-column>   
              <kendo-grid-column field="customer.businessEmail" title="Email" [width]="150">
              </kendo-grid-column>    
                                             
          </kendo-grid>         
        </div>      
      </section>
    </mat-card-content>
    <mat-card-actions align="end">
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="save()" [mat-dialog-close]="data" cdkFocusInitial>Save</button>
    </mat-card-actions>
  </mat-card>
  