import { Component, Inject, OnInit } from '@angular/core';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { from } from 'rxjs';
import { ProductDialog } from 'app/inventory/product/product.dialog';
import { PromotionDetialsDialog } from 'app/inventory/promotion-details/promotion-details.dialog';

import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { PromotionViewMdel } from 'app/shared/models/catalogue/promotionViewModel';
import { SellerViewModel } from 'app/shared/models/seller';
import { Router } from '@angular/router';
import { ConfirmationDialog } from 'app/dialogs/confirm/confirm.dialog';
import { MsalService } from '@azure/msal-angular';
import { SellerService } from 'app/shared/services/Seller.Service';
import { NgxSpinnerService } from "ngx-spinner";
import { IntegrationService } from 'app/shared/services/integration.service';
import { IntegrationItemsDialog } from 'app/inventory/dialogs/integration-items/integration-items.dialog';
import { BatchDialog } from 'app/inventory/dialogs/batch/batch.dialog';
import Swal from 'sweetalert2';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { StockTakeSitesDialog } from '../dialogs/stock-take-sites/stock-take-sites.dialog';
import { DataService } from 'app/shared/services/data.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.css']
})
export class InventoryComponent implements OnInit {
  public gridView: GridDataResult;
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public pendingGridData: any[];
  public pendingGridView: any[];
  public pendingProductSelection: string[] = [];
  public pendingProductselectedUID:string  = '';
  decimalPattern = new RegExp('[0-9]+([.][0-9]+)?');
  Products: ProductViewMdel[];
  newstock: number = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  Product: ProductViewMdel;
  SupplierId: number;
  isLinkedToExternalSystem: boolean;
  ConfirmationdialogRef: MatDialogRef<ConfirmationDialog>;
  IntegrationDialogRef: MatDialogRef<IntegrationItemsDialog, any>;
  BatchDialogRef: MatDialogRef<BatchDialog, any>;
  showInventory: boolean = true;
  featurePermission: FeaturePermission  = new FeaturePermission();
  headerStyle: any;
  createInventory:boolean =false;


  constructor(
    private ProductService: ProductService, public dialog: MatDialog, private snackbar: MatSnackBar, private sellerService: SellerService,
    private spinner: NgxSpinnerService, private integrationService: IntegrationService,private userService: UserService
,private dataService: DataService
  ) {
    this.setSelectableSettings();
  }

  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.INVENTORY )[0];
    this.showInventory=this.featurePermission.read;   
    this.createInventory=this.featurePermission.create;  
    });
     
 }
  ngOnInit(): void {
    
    this.loading = true;
    this.initInventory();
    this.getLinkedApps();
  }
  async initInventory() {
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.getProducts(1,this.pageSize)
  }

  getInventory(isActive:boolean=true)
  {
    if(isActive)
      this.getProducts(1, this.pageSize);
    else{
      this.loading = true;
      this.ProductService.getPendingProductImportsforSupplier().subscribe(
        (data) => {  
          this.pendingGridData = data;
          this.pendingGridView = this.pendingGridData;
          this.loading = false;
          this.mySelection[0] = data.externalId; 
        }
      );
    }
  }

  getProducts(pageNo:number,limit:number): void {
    this.loading=true;
  
    this.ProductService.getProductsforSupplier(pageNo,limit).subscribe(
      (data) => {  
        this.loading = false;
        this.Products = data.items;
        this.gridView = {
          data: data.items,
          total: data.totalItems,
        };
      }
    );
  }
  
  push(product: ProductViewMdel): void {
    this.spinner.show("pushing");
    this.integrationService.PushProductToExternalSystem(product).subscribe((data) => {
      product.externalId = data[1];
      this.snackbar.open('Product Pushed Successfully', 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide("pushing");
    }, (error) =>{
      this.snackbar.open(`Failed to push product due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide("pushing");
    });    
  }

  hasExternalId(product: ProductViewMdel): boolean {
    return product.externalId != null;
  }

  link(product: ProductViewMdel): void {
    this.IntegrationDialogRef = this.dialog.open(IntegrationItemsDialog, { data: { productId: product.id, externalId: product.externalId }});
    this.IntegrationDialogRef.afterClosed().subscribe(result => {
      if(result.data)
      {
        product.externalId = result.data;
      }
    }); 
  }

  addBatch(product: ProductViewMdel): void {
    var Data = { editmode: false, Product: product }
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
      setTimeout(() => this.getProducts(this.currentPage,this.pageSize), 1000);     
    });
    
  }

  activateDeactivateProduct(product: ProductViewMdel): void {
    this.ProductService.activateDeactivateProduct(product.id).subscribe((data) => {
      this.getProducts(this.currentPage,this.pageSize);
    });

  }
  opendialog(editmode: boolean, product: ProductViewMdel = null): void {
    if (!editmode) {
      product = new ProductViewMdel();
      //todo get current supplier Id
      product.supplierId = 0;
      product.id = 0;
    }
    var Data = { editmode: editmode, Product: product }
    const dialogRef2 = this.dialog.open(ProductDialog, { data: Data });
    dialogRef2.afterClosed().subscribe(result => {
      this.getProducts(this.currentPage,this.pageSize);
      this.spinner.hide();
      this.dataService.sendData({ data: this.gridView });
  
    });

  }

  openPromotiondialog(isEditmode: boolean, Product: ProductViewMdel): void {
    var Data = { Product: Product}
    const dialogRef = this.dialog.open(PromotionDetialsDialog, { data: Data });
    dialogRef.afterClosed().subscribe(result => {  
      if(result){
        this.getProducts(this.currentPage,this.pageSize);
        Swal.fire({
          icon: 'success',
          title: '',
          html: "<strong> Your promotion has been set up successfully</strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        });
      }
    });
  }

  public opened = false;

  public close(status) {
    this.opened = false;
  }

  public open() {
    this.opened = true;
  }

  public value = 5;
  public events: string[] = [];
  public onEnter(Product: ProductViewMdel) {
    this.openConfirmationDialog(Product)
    //this.opened = true;
    // alert(object.id + '' + object.unitPrice);
  }
  public onFocus(): void {
    this.log("NumericTextBox is focused");
  }

  public onBlur(): void {
    this.log("NumericTextBox is blurred");
  }

  public onChange(value: string): void {
    this.log(`valueChange ${value}`);
  }

  private log(event: string): void {
    this.events.unshift(`${event}`);
  }
  openConfirmationDialog(Product: ProductViewMdel) {
    this.ConfirmationdialogRef = this.dialog.open(ConfirmationDialog, {
      disableClose: false
    });
    this.ConfirmationdialogRef.componentInstance.confirmMessage = `Are you sure you want to update product Price to \$${Product.unitPrice} ?`

    this.ConfirmationdialogRef.afterClosed().subscribe(result => {
      if (result) {
        // do confirmation actions
        this.ProductService.UpdateProductUnitPrice(Product).subscribe((data) => {
          this.getProducts(this.currentPage,this.pageSize);
          this.snackbar.open('Product price Updated ..', 'close', {
            duration: 500,
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition
          });
        }
        )
      }
      this.ConfirmationdialogRef = null;
    });
  }

  getLinkedApps(): any {    
    this.sellerService.getSeller().subscribe((seller) => {
      this.integrationService.GetLinkedApps(seller.id).subscribe((data) => {
        if(data.length > 0){
          this.isLinkedToExternalSystem = true;
        }
      });    
    });
  }
  showError(Product: ProductViewMdel): boolean {        
    return this.isLinkedToExternalSystem && (Product.externalId == null);
  }  
  hasActivePromotion(Product: ProductViewMdel): boolean {        
    return Product.batches.find(b => b.onPromotion) != null; 
  }
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }

  public pendingProductSelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  pendingProductKeyChange(e){    
    this.pendingProductselectedUID = e;
    console.log(this.pendingProductselectedUID);
  }

  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getProducts(this.currentPage,this.pageSize);
  }
  public getHeaderStyle(): any {
  return {'text-align': 'center !important', 'font-weight':'bold'};
  }

  openImportDialog(): void {
    var pendingProduct = this.pendingGridData.filter(x => x.uid == this.pendingProductselectedUID)[0];
    var product = new ProductViewMdel();
    //todo get current supplier Id
    product.supplierId = 0;
    product.id = 0;    
    product.externalId = this.pendingProductselectedUID[0];
    product.discription = pendingProduct.description;
    product.productCode = pendingProduct.number;
    product.unitPrice = pendingProduct.unitPrice;
    var Data = { editmode: false, Product: product }
    const dialogRef = this.dialog.open(ProductDialog, { data: Data });    
    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      this.getInventory(false);
      this.spinner.hide();
    });

  }
  openStockTakeDialog(): void {
   
    const dialogRef = this.dialog.open(StockTakeSitesDialog);    
    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      this.getInventory(false);
      this.spinner.hide();
    });

  }
  
}