import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataBindingDirective, RowArgs, RowClassArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from 'app/shared/services/upload.service';
import { BatchViewModel, ParentChildBatchModel } from 'app/shared/models/catalogue/batchViewModel';
import { WasteBatchViewModel } from 'app/shared/models/catalogue/WasteBatchViewModel';
import { Subscription } from 'rxjs';
import { ChildBatchProductsDialog } from '../child-batch-products/child-batch-products.dialog';
import { BatchDialog } from '../batch/batch.dialog';
import Swal from 'sweetalert2';


@Component({
  selector: 'dialogs-child-batches',
  templateUrl: './child-batches.dialog.html',
  styleUrls: ['./child-batches.dialog.css']
})
export class ChildBatchesDialog implements OnInit {  
  public form: FormGroup;
  childProductId: number;
  deductQty: string='';
  batch: BatchViewModel;  
  parentChildBatchModel= new ParentChildBatchModel();
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  BatchDialogRef: MatDialogRef<BatchDialog, any>;
  public gridView: BatchViewModel[];
  public gridData: BatchViewModel[];
  public mySelection: string[] = [];
  public selectedID:number  ;
  public loading: boolean = false;
  public showErrors: boolean = false;
  public NewVarietalName = "";
  public selectableSettings: SelectableSettings;
  public quantityToDeductFromParentBatch: number;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(
    public dialogRef: MatDialogRef<ChildBatchesDialog>,    
    @Inject(MAT_DIALOG_DATA) public dialogdata: {  Batch: BatchViewModel, Product: ProductViewMdel },
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,  public dialog: MatDialog,
    private ProductService: ProductService, private uploadService: UploadService
  ) {     
    console.log(this.dialogdata.Batch);
      this.batch = this.dialogdata.Batch;  
  }

  ngOnInit(): void {
    this.setSelectableSettings();
    this.form = new FormGroup({
      product: new FormControl('', [Validators.required, Validators.min(1)]),
    });
    this.gridData =[] ;
    this.gridView = this.gridData;
  
  } 

  quantityIsValid():boolean {
    
     if(parseInt( this.deductQty)>this.dialogdata.Batch.stock)
     {
       return false
     }
    return true;
  }

  ngOnDestroy() {
  }
  
  public hasError = (controlName: string, errorName: string) =>{
    return  this.form.controls[controlName].hasError(errorName);
  }

  save() {
    this.spinner.show();
    this.gridView.forEach(s=>{
      s.parentId=this.dialogdata.Batch.id; s.id=0;
      s.productRipeness=null;
      s.productUoM=null;
      s.productSize=null;
      s.Parent=null;
    } );
    this.parentChildBatchModel.batches=this.gridView;
    this.parentChildBatchModel.deductQuantity=parseInt( this.deductQty);
    this.parentChildBatchModel.parentBatch=this.batch;
    this.ProductService.AddBatchesToProduct(this.parentChildBatchModel).subscribe((result) => {
      console.log(result);
      var message = "Batches successfully added";

      this.snackbar.open(message, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide();
    this.dialogRef.close();
    });
  }

  onSelectValueChange() {

  }
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): number {    
    return context.index;
  }
  keyChange(e){   
    
    this.selectedID = e;
  }
  public onFilter(inputValue: string): void {
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "uid",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "name",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }
 
  addNewBatch(): any {   

    const newObject = { ...this.dialogdata.Batch };   
    const newProduct = { ...this.dialogdata.Batch.product};  
    newObject.stock = 0;  
    var Data = { editmode: false, Product: newProduct, Batch:newObject ,childmode:true }
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
   
      if(result!='' && result!==undefined)
      {   
        this.gridData.push(result);
        this.gridView=[...this.gridData]; 
        console.log( this.gridView)
      } 
       
    });
  }

  editBatch(): any {          
    
    var selectedBatch = this.gridView[this.selectedID];
    const newObject = { ...selectedBatch };  
    var Data = { editmode: true, Product: this.dialogdata.Batch.product, Batch: newObject,childmode:true }
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
      if(result!='' && result!==undefined)
      {   
        this.gridData[this.selectedID]=result;
        this.gridView=[...this.gridData]; 
      }       
    });
  }
  deleteBatch(): any {   
    let index=parseInt(this.selectedID[0]);         
    let deletedBatch=this.gridData[index];
    this.gridData.splice(index, 1);
    this.gridView=[...this.gridData]  ;
  }
  formIsValid()
  {
    if( parseInt( this.deductQty)<this.dialogdata.Batch.stock && this.gridView.length>0 ){
      return true;
    }
    return false;
  }
  isEditDisabled()
  {
   
    return (this.selectedID == null || this.selectedID<0 ||  this.selectedID.toString() == "");
  }

}
