<form [formGroup]="siteForm" (ngSubmit)="onSubmit()" *ngIf="!isLoading" novalidate>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">New Site</h4>
            </div>
            <div class="card-body" >
              <div class="row">
              
                <div class="col-md-12">
                  <div class="row">
            
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Site Name*"  formControlName="name">
                        <mat-error >Invalid Site Name</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Code*" formControlName="code">
                        <mat-error >Invalid Code</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
           
                 
                  <div class="row"> 
                                     
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <textarea matInput placeholder="Address Line 1*" formControlName="addressLine1"> </textarea>
                        <mat-error >Invalid Address Line 1</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="City*" type="text" formControlName="city">
                        <mat-error >Invalid City</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                 
                  <div class="row">
                    
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
  
                        <mat-label>ٍState*</mat-label>
                        <mat-select  formControlName="stateId">
                          <mat-option *ngFor="let stateObj of states" [value]="stateObj.id">{{stateObj.name}}</mat-option>
                        </mat-select>
                        <mat-error >Invalid State</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <mat-label>Postal Code*</mat-label>
                        <input matInput #postalCode maxlength="4" appDigitOnly placeholder="Ex. 9410" formControlName="postalCode">
                        <mat-hint align="end">{{postalCode.value.length}} / 4</mat-hint>
                        <mat-error >Invalid Postal Code</mat-error>
                      </mat-form-field>
                    </div>                                    
                  </div>                 
                </div>
              </div>
              <div class="row"></div>
              <div class="row">
                <app-zones (zonesDataEvent)="receiveDataFromChild($event)" [zonesList]="this.zones" [featurePermission]="this.featurePermission" [editable]="isEditable()"></app-zones>
              </div>
              <div class="row" *ngIf="isEditableForm">
                
                <div class="col-md-3 mr-3 text-center">
                  <button mat-button  type="submit" [disabled]="!siteForm.valid" class="btn btn-primary"  >
                    Save
                </button>
                </div>
                <div class="col-md-2 text-center">
                  <button mat-button class="btn btn-danger" [mat-dialog-close]="true">
                    Cancel
                </button>
                </div>    
            </div>

            </div>
            
          </div>
        </div>
  
      </div>

      
    </div>
  </div>
</form>