import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductLineItemDetails } from 'app/shared/models/order/PlainProductInOrders.Model';
import { OrderService } from 'app/shared/services/order.service';
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { aggregateBy } from "@progress/kendo-data-query";
import { SellerService } from 'app/shared/services/Seller.Service';
import { MsalService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller';
import { BuyerViewModel } from 'app/shared/models/promotions/marketing.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerModel } from 'app/shared/models/customer/customer.model';


interface Account extends AccountInfo {
  idTokenClaims?: {
    roles?: string[];
    sub?: string;
    aud?: string;
    nonce?: string;
    iat?: number;
    auth_time?: number;
    given_name?: string;
    family_name?: string;
    idp?: string;
    oid?: string;
    city?: string;
    country?: string;

  }

}

@Component({
  selector: 'pickslip-dialog',
  templateUrl: './pickslip.dialog.html',
  styleUrls: ['./pickslip.dialog.css']

})
export class PickSlipDialog implements OnInit {
  ProductLineItemsDetails: ProductLineItemDetails[] = [];
  Seller: SellerViewModel = null;
  Customer: CustomerModel = null;
  Supplier: SupplierViewModel = null;
  currentDate: Date = new Date();
  pdfSrc: string ;
  isShow:boolean=false;

  constructor(public dialogRef: MatDialogRef<PickSlipDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService,
    private sellerservice: SellerService,private spinner: NgxSpinnerService
  ) { }
  ngOnInit(): void {
    this.spinner.show();
    this.orderService.GetOrderLineItemsDetails(this.data.orderId).
      subscribe((result) => {
        this.pdfSrc=result[0].lineItemInOrder.pickSlipUrl;
        this.isShow=true;
        this.spinner.hide();
      }

      );
  }
  LoadSupplier() {

    this.sellerservice.getSeller().subscribe(data => {
      this.Seller = data;

    });
    this.sellerservice.getSupplier().subscribe(data => {
      this.Supplier = data.supplier;
    });
    this.sellerservice.getCustomer(this.data.buyerId).subscribe(data => {
      this.Customer = data;
    });

  }
  private aggregates: any[] = [
    {
      field: "lineItemInOrder.approvedQuantity",
      aggregate: "sum",
    },
    {
      field: "total",
      aggregate: "sum",
    },
  ];

  public get totals(): any {
    return aggregateBy(this.ProductLineItemsDetails, this.aggregates) || {};
  }
}
