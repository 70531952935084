<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 ml-auto mr-auto">
                <div class="card">
                    <td><div [id] = "rootId"></div></td>                                        
                    <div class="card-body">
                        <div class="table-responsive ">
                            <table class="table">                                
                                <tbody>
                                    <tr>
                                        
                                    </tr>                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
