<div class="tab-pane" id="pendingImports">
  <kendo-grid [kendoGridBinding]="pendingGridView" kendoGridSelectBy="uid" [selectedKeys]="pendingCustomerSelection"
    [loading]="loading" [pageSize]="20" [pageable]="true" [sortable]="true" [groupable]="false" [reorderable]="true"
    [resizable]="true" [height]="500" [columnMenu]="{ filter: true }" [selectable]="selectableSettings"
    (selectedKeysChange)="pendingCustomerKeyChange($event)">
    <ng-template kendoGridToolbarTemplate>
      <input placeholder="Search in all columns..." kendoTextBox (input)="onFilter($event.target.value)" />
      <kendo-grid-spacer></kendo-grid-spacer>
    </ng-template>
    <kendo-grid-checkbox-column [width]="45" [headerClass]="{ 'text-center': true }" [class]="{ 'text-center': true }"
      [resizable]="false" [columnMenu]="false" showSelectAll="false"></kendo-grid-checkbox-column>
    <kendo-grid-column field="uid" title="UID" [width]="220" visibility="false">
    </kendo-grid-column>
    <kendo-grid-column field="displayID" title="DisplayId" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="name" title="Company Name" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="abn" title="ABN" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="firstName" title="First Name" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="lastName" title="Last Name" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="email" title="Email" [width]="220">
    </kendo-grid-column>
    <kendo-grid-column field="currentBalance" title="Current Balance" [width]="220">
    </kendo-grid-column>
  </kendo-grid>
  <button mat-raised-button matTooltip="Import Customer" [matTooltipPosition]="'above'" class="btn btn-info btn-sm"
    (click)="openImportDialog()" [disabled]="! pendingCustomerselectedUID || pendingCustomerselectedUID.length == 0">
    <i class="material-icons">note_add</i> Import Customer
  </button>
</div>