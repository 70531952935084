<app-master-data-dropdown-settings></app-master-data-dropdown-settings>
<div class="card">
    <div class="card-header card-header-tabs card-header-primary">

        <span class="nav-tabs-title">Ripeness</span>
    </div>
    <div class="card-body" *ngIf="show">
        <div class="row">
            <div class="card-body">
                <button mat-raised-button matTooltip="Add New Ripeness" [disabled]="!this.featurePermission.create"
                    (click)="newRipeness(true,null)" [matTooltipPosition]="'above'" class="btn btn-primary btn-round">
                    <i class="material-icons">note_add</i> Add New Ripeness

                </button>
            </div>
        </div>
        <div class="tab-content">
            <div class="tab-pane active" id="active">
                <kendo-grid [data]="gridView" [loading]="loading" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
                    [resizable]="true" (pageChange)="pageChange($event)">

                    <kendo-grid-column field="data" title="Ripeness" class='k-text-center font-weight-bold'
                        headerClass="k-text-center font-weight-bold" [width]="150">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <a mat-button class="nav-link" matTooltip="Ripeness" (click)="newRipeness(true,dataItem)">
                                {{dataItem.ripeness}}
                                <div class="ripple-container"></div>
                            </a>

                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
                        headerClass="k-text-center font-weight-bold" [width]="150">
                        <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                            <button [disabled]="!featurePermission.delete" *ngIf="dataItem.sellerId" mat-raised-button
                                type="button" matTooltip="Delete" [matTooltipPosition]="'above'"
                                class="btn btn-danger btn-link btn-sm btn-just-icon"
                                (click)="deleteRipeness(dataItem.id)">
                                <i class="material-icons">
                                    delete
                                </i>
                            </button>

                        </ng-template>
                    </kendo-grid-column>
                </kendo-grid>
            </div>


        </div>

    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading ... </p>
</ngx-spinner>
<div *ngIf="! show">
    <img src="/assets/img/403.png">
</div>