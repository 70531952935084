<form  #batchForm="ngForm">
<mat-card>
  <mat-card-header class="mb-3">

    <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
      style="background-image: url(./assets/img/oba-leaf.jpg)"
      *ngIf="batch.thumbnailUrl =='' || batch.thumbnailUrl == null">
      <span class="material-icons">camera_alt</span>
      <span>Edit</span>
    </div>
    <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
      [ngStyle]="{ 'background-image': 'url(' +batch.thumbnailUrl + ')'}"
      *ngIf="batch.thumbnailUrl !='' && batch.thumbnailUrl != null">
      <span class="material-icons">camera_alt</span>
      <span>Edit</span>
    </div>
    <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" />
    <mat-card-title class="cardHeader"> Batch Settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex">
      <mat-form-field *ngIf="dialogdata.childmode" class="col-md-4">
        <mat-label>Product</mat-label>
        <mat-select  matNativeControl (selectionChange)="loadData($event.value)"
           name="Product" [(ngModel)]="selectedValue" required>
          <mat-option value="-1" selected>Please select product</mat-option>
          <mat-option *ngFor="let c of Products"  [value]="c.id">
            {{c.productCode}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field  *ngIf="!dialogdata.childmode" class="col-md-8">
        <mat-label>ProductCode</mat-label>
        <input matInput type="text" disabled [(ngModel)]="dialogdata.Product.productCode" name="productCode" required />
      </mat-form-field> 
    </section>    
    <section class="d-flex">
      <mat-form-field class="col-md-4">
        <mat-label>Category</mat-label>
        <mat-select disabled matNativeControl (selectionChange)="loadvarital($event.value)"
          [(ngModel)]="dialogdata.Product.productCategoryId" name="Category" required>
          <mat-option value="-1" selected>Please select category</mat-option>
          <mat-option *ngFor="let c of ProductCategories" [value]="c.id">
            {{c.categoryName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Type</mat-label>
        <mat-select disabled matNativeControl [(ngModel)]="dialogdata.Product.productTypeId" name="Type">
          <mat-option value="-1" selected>Please select Type</mat-option>
          <mat-option *ngFor="let v of ProductTypes" [value]="v.id">
            {{v.typeName}}
          </mat-option>
        </mat-select>
      </mat-form-field>     
      <mat-form-field class="col-md-4">
        <mat-label>UoM</mat-label>
        <input matInput type="text" disabled [(ngModel)]="uom" name="format" required #format="ngModel" />
        <mat-error *ngIf="format.errors?.required">Unit of Measure is required</mat-error>
     </mat-form-field>     
    </section>

    <section class="d-flex">
     <mat-form-field class="col-md-4">
        <mat-label>Batch No.</mat-label>
        <input matInput type="text"  [(ngModel)]="batch.number" name="number" required #batchNo="ngModel"/>
        <mat-error *ngIf="batchNo.errors?.required">Batch Number is required</mat-error>
     </mat-form-field>
     <mat-form-field class="col-md-4">
        <mat-label>Batch Date</mat-label>
        <input matInput [matDatepicker]="batchDate" [(ngModel)]="batch.date" name="batchDate" required #batchDateModel="ngModel">
        <mat-datepicker-toggle matSuffix [for]="batchDate"></mat-datepicker-toggle>
        <mat-datepicker #batchDate ></mat-datepicker>
        <mat-error *ngIf="batchDateModel.errors?.required">Batch Date is required</mat-error>
     </mat-form-field>
     <mat-form-field class="col-md-4">
      <mat-label>Varietal</mat-label>
      <mat-select  matNativeControl [(ngModel)]="batch.productVarietalId" name="Varietal">
        <mat-option value="-1" selected>Please select varietal</mat-option>
        <mat-option *ngFor="let v of ProductVaritals" [value]="v.id">
          {{v.varietalName}}
        </mat-option>
      </mat-select>
    </mat-form-field>                
    </section>

    <section class="d-flex">
     <mat-form-field class="col-md-4">
        <mat-label>Grade</mat-label>
        <mat-select matNativeControl [(ngModel)]="batch.productGradeId" name="Grade">
          <mat-option value="-1" selected>Please select grade</mat-option>
          <mat-option *ngFor="let G of ProductGrades" [value]="G.id">
            {{G.grade}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Grower</mat-label>
        <mat-select [(ngModel)]="batch.productGrowerId" name="Grower">
          <mat-option value="-1" selected>Please select grower</mat-option>
          <mat-option *ngFor="let Go of ProductGrowers" [value]="Go.id">
            {{Go.growerName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Paddock</mat-label>
        <input matInput type="text"  [(ngModel)]="batch.paddock" name="paddock"  #format="ngModel" />
        <!-- <mat-error *ngIf="format.errors?.required">Paddock is required</mat-error> -->
     </mat-form-field>           
    </section>

    <section class="d-flex">
      <mat-form-field class="col-md-4">
        <mat-label>Region</mat-label>
        <mat-select [(ngModel)]="batch.region" name="Region">
          <mat-option value="NSW">NSW</mat-option>
          <mat-option value="QLD">QLD</mat-option>
          <mat-option value="SA">SA</mat-option>
          <mat-option value="VIC">VIC</mat-option>
          <mat-option value="WA">WA</mat-option>
          <mat-option value="TAS">TAS</mat-option>
          <mat-option value="SA">NT</mat-option>
          <mat-option value="SA">ACT</mat-option>
        </mat-select>
      </mat-form-field>    
      <mat-form-field class="col-md-4">
        <mat-label>Site</mat-label>
        <mat-select [(ngModel)]="batch.siteId" name="Site" required (selectionChange)="loadZones($event.value)">
          <mat-option value="-1" selected>Please select Site</mat-option>
          <mat-option *ngFor="let s of Sites" [value]="s.id">
            {{s.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="batchNo.errors?.required">Site is required</mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Zone</mat-label>
        <mat-select [(ngModel)]="batch.zoneId" required name="Zone">
          <mat-option value="-1" selected>Please select Zone</mat-option>
          <mat-option *ngFor="let z of Zones" [value]="z.id">
            {{z.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="batchNo.errors?.required">Zone is required</mat-error>
      </mat-form-field>             
    </section>    

    <section class="d-flex">
    
      <mat-form-field class="col-md-4">
        <mat-label>Ripeness</mat-label>
        <mat-select [(ngModel)]="batch.productRipenessId" name="ripeness">
          <mat-option value="-1" selected>Please select Ripeness</mat-option>
          <mat-option *ngFor="let r of ProductRipness" [value]="r.id">
            {{r.ripeness}}
          </mat-option>
        </mat-select>
      </mat-form-field>  
      <mat-form-field class="col-md-4">
        <mat-label>Unit Price:</mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput placeholder="Unit Price" [(ngModel)]="batch.unitPrice" name="unitPrice"    />
      </mat-form-field>     
      <mat-form-field class="col-md-4">
        <mat-label>Quantity</mat-label>
        <input matInput [disabled]="dialogdata.editmode && !dialogdata.childmode " type="text" class="" [(ngModel)]="batch.stock" required #quantity="ngModel" name="stock" />
        <mat-error *ngIf="quantity.errors?.required">Quantity is required</mat-error>
      </mat-form-field>        
    </section>    

    <section class="d-flex">      
   
      <mat-form-field class="col-md-4">
        <mat-label>Size</mat-label>
        <mat-select [(ngModel)]="batch.productSizeId" name="size">
          <mat-option value="-1" selected>Please select Size</mat-option>
          <mat-option *ngFor="let s of ProductSizes" [value]="s.id">
            {{s.size}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Expiry Date</mat-label>
        <input matInput [matDatepicker]="expiryDate" [dateCompare]="batch.date" [(ngModel)]="batch.expiryDate" name="expiryDate" #expiryDateModel="ngModel">
        <mat-datepicker-toggle matSuffix [for]="expiryDate"></mat-datepicker-toggle>
        <mat-datepicker #expiryDate ></mat-datepicker>
        <mat-error *ngIf="!dateIsValid() ">Expiry date less than batch date</mat-error>
     </mat-form-field>   
    </section>    

  </mat-card-content>
    <mat-card-actions align="end">
      <mat-error *ngIf="showErrors">
        Please enter all mandatory fields
      </mat-error>
      <button mat-button mat-dialog-close>Cancel</button>
      <button mat-button (click)="saveOrUpdateBatch()" [disabled]="!formIsValid()"  cdkFocusInitial>Save</button>
    </mat-card-actions>
</mat-card>
</form>