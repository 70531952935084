import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { FormControl, Validators } from '@angular/forms';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from 'app/shared/services/upload.service';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import { Site, Zone } from 'app/shared/models/catalogue/lockups';
import { SiteModel, ZoneModel } from 'app/shared/models/sites/site.model';
import { SellerService } from 'app/shared/services/Seller.Service';
import { BatchDialog } from '../batch/batch.dialog';


@Component({
  selector: 'dialogs-batch-info',
  templateUrl: './batch-info.dialog.html',
  styleUrls: ['./batch-info.dialog.css']
})
export class BatchInfoDialog implements OnInit {
  public batch = new BatchViewModel();
  public gridView: BatchViewModel[]=[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public loading: boolean = false;
  public showErrors: boolean = false;
  public batchList: BatchViewModel[]=[];
  public NewVarietalName = "";
  public selectableSettings: SelectableSettings;

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(
    public dialogRef: MatDialogRef<BatchInfoDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { batch: BatchViewModel },
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private sellerService: SellerService,
    private ProductService: ProductService,  public dialog: MatDialog
  ) { 
   
      this.batch=this.data.batch;
      // this.gridView.push(this.batch);
    
  }


  ngOnInit(): void {
    this.setSelectableSettings();
    this.getBatch(this.batch.id);
      
  }  
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }
  isEditDisabled()
  {
    return (this.selectedUID == null || this.selectedUID.length == 0 );
  }
  editBatch(): any {            
    var selectedBatch = this.gridView.filter(x => x.id == parseInt(this.selectedUID[0]))[0];
    var Data = { editmode: true, Product: selectedBatch.product, Batch: selectedBatch}
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
    
      this.getBatch(result.id);

    });
  }
  getBatch(batchId: number)
  {
    this.ProductService.getBatchForSeller(batchId).subscribe(result => {
      
      this.loading = true;  
      this.spinner.show();
      this.batchList=[];    
       this.batchList.push(result);
       this.gridView=[...this.batchList]; 
       this.loading = false;
       this.spinner.hide();
    });
  }
}
