import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';
import { catchError, switchMap } from 'rxjs/operators';
import { UploadResult } from 'app/shared/models/uploadresult';
import { Observable, of } from 'rxjs';
import { SupplierViewModel } from '../models/seller/supplierViewModel';
import { SellerService } from './Seller.Service';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        Authorization: 'my-auth-token'
    })
};
@Injectable({ providedIn: 'root' })
export class UploadService {
    headers: Headers;
    apiURl: string;

    private handleError: HandleError;
    private sellerService: SellerService;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, sellerService: SellerService) {

        this.handleError = httpErrorHandler.createHandleError('UploadService');
        this.apiURl = environment.apibaseUrl;
        this.sellerService = sellerService;
    }

    uploadFile(formData: FormData, Supplier: SupplierViewModel): Observable<UploadResult> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Upload/UploadFile`;
                return this.http.post<UploadResult>(
                    url, formData, httpOptions).pipe(
                        catchError(this.handleError<UploadResult>('uploadFile')));
                    }));
    }
    uploadImage(formData: FormData): Observable<UploadResult[]> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Upload/UploadImage`;
                return this.http.post<UploadResult[]>(url,formData)}),
            catchError(this.handleError<UploadResult[]>('UploadImage')));
    }
    uploadSellerImage(formData: FormData): Observable<UploadResult[]> {
        const url = `${this.apiURl}/api/v1/Upload/UploadSellerImage`;
                return this.http.post<UploadResult[]>(url,formData);
    }
}