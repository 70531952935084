import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';

import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { DailyOrders, DashBoardViewModel, MonthlyOrdersByStatusModel, MonthlyOrdersModel, StockLevelModel } from '../models/dashboard/dashboardViewModel';
import { SellerService } from './Seller.Service';
import { UtilsService } from './utils.service';
// import { PromotionGroupCollection } from '../mocks/promotionGroups-mock';
// import {  } from '../models/promotions/marketing.model';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DashBoardService {

  headers: Headers;
  apiURl: string;
  private sellerService: SellerService;
  private handleError: HandleError;



  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, sellerService: SellerService) {
    this.handleError = httpErrorHandler.createHandleError('MarketingService');
    this.apiURl = environment.apibaseUrl;
    this.sellerService = sellerService;
  }


  GetSupplierDashBoard(sellerId: number): Observable<DashBoardViewModel> {
    const url = `${this.apiURl}/api/v1/${sellerId}/DashBoard/GetSupplierDashBoard`;

    return this.http.get<DashBoardViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<DashBoardViewModel>('GetSupplierDashBoard'))
    );
  }

  GetDashBoardStatistics(sellerId: number): Observable<DashBoardViewModel> {
    const url = `${this.apiURl}/api/v1/${sellerId}/DashBoard/DashboardStatistics`;

    return this.http.get<DashBoardViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<DashBoardViewModel>('DashboardStatistics'))
    );
  }

  GetNumberOfDailyOrdersForTheCurrentWeek(sellerId: number): Observable<DailyOrders[]> {
    const url = `${this.apiURl}/api/v1/${sellerId}/DashBoard/DailyOrdersPerWeek`;

    return this.http.get<DailyOrders[]>(url, httpOptions).pipe(
      catchError(this.handleError<DailyOrders[]>('GetDailyOrders'))
    );
  }

  GetMonthlyOrdersByStatus(sellerId: number): Observable<MonthlyOrdersModel> {
    const url = `${this.apiURl}/api/v1/${sellerId}/DashBoard/MonthlyOrdersByStatus`;

    return this.http.get<MonthlyOrdersModel>(url, httpOptions).pipe(
      catchError(this.handleError<MonthlyOrdersModel>('GetDailyOrders'))
    );
  }

  GetFutureProjectedStockLevelValues(sellerId: number, productId: number): Observable<StockLevelModel[]> {
    const url = `${this.apiURl}/api/v1/${sellerId}/DashBoard/ProjectStockLevel/${productId}`;

    return this.http.get<StockLevelModel[]>(url, httpOptions).pipe(
      catchError(this.handleError<StockLevelModel[]>('GetDailyOrders'))
    );
  }
}


