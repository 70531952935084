import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class StorageService {

  saveData(key:string,value:any){
    localStorage.setItem(key,JSON.stringify(value));
  }
  getData(key:string):any{
   return JSON.parse(localStorage.getItem(key));
  }

  /**
   * Stores the data in local storage.
   * @param key - The key under which the data is stored.
   * @param data - The data to store. This can be an object, an array, a number, or a string.
   */
  set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
    } catch (error) {
      console.error('Error saving to localStorage', error);
    }
  }

  /**
   * Retrieves the data from local storage.
   * @param key - The key under which the data is stored.
   * @returns The data, or null if not found.
   */
  get<T>(key: string): T | null {
    try {
      const data = localStorage.getItem(key);
      if (data) {
        return JSON.parse(data);
      }
    } catch (error) {
      console.error('Error getting data from localStorage', error);
    }
    return null;
  }

  /**
   * Removes the data from local storage.
   * @param key - The key under which the data is stored.
   */
  remove(key: string): void {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error('Error removing data from localStorage', error);
    }
  }

  /**
   * Clears all data from local storage.
   */
  clearAll(): void {
    try {
      localStorage.clear();
    } catch (error) {
      console.error('Error clearing localStorage', error);
    }
  }

}