import { ProductCategory, ProductGrade, ProductGrower, ProductVarital } from '../catalogue';
import { BatchViewModel } from '../catalogue/batchViewModel';

export class OrderSummaryResponse {
    orderId: number;

    orderItemsTotalPrice?: number;
    orderItemsCount: number;
    orderStatus: string;
    customerName: string;
    customerId: number;
    email: string;
    orderDate: string;
    payment: string;
    deliveryType: string;
    bay: string;
    city: string;
    address: string;
    state: string;
    postalCode: string;
    note: string;
    source:string;
    invoiceURL:string;
    pickupSlipURL:string;
}

export class ManualOrderItemModel {
    constructor() {
    }
    id: number;
    item: string;
    categoryId: number;
    varietalId: number;
    typeId: number;
    productGradeId: number;
    productGrowerId: number;
    region: string;
    buyerId: number;    
    customerId?: number;
    unitPrice: number;
    quantity: number;
    batch: BatchViewModel; 
    batchId: number; 
    total: number;
        
}

export class ManualOrderDeliveryModel {
    constructor() {
    }
    public deliveryTypePickup: boolean = false;
    public deliveryTypeAddress: boolean = false;
    public deliveryBay: string;
    public deliveryAddress: string;
    public deliveryCity: string;
    public deliveryState: string;
    public deliveryPostalCode: number;
}


export class ManualOrderModel {
    constructor() {
    }    
    buyerId?: number;    
    customerId: number;  
    orderItems: ManualOrderItemModel[];
    delivery: ManualOrderDeliveryModel;
}