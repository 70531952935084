<div class="card" >
  <div class="card-header card-header-tabs card-header-primary">
    <span class="nav-tabs-title">Order Items</span>
    
  </div> 
  <div class="card-body">
    <div class="col-md-12">

      <form novalidate #myForm="ngForm">
        <kendo-grid 
        #grid
        [kendoGridBinding]="gridView"
             
                [loading]="loading" 
                [pageSize]="10"
                [pageable]="true"
                [sortable]="true"
                [groupable]="false"
                [reorderable]="true"
                [resizable]="true"
                [height]="300"
                [columnMenu]="{ filter: true }"

           >             
                 
           <kendo-grid-column field="lineItemInOrder.lineitemId" title="Id" [hidden]="true"  [editable]="false" [width]="220">
          </kendo-grid-column> 
          <kendo-grid-spacer></kendo-grid-spacer>        
          <kendo-grid-column field="selected" title="" [width]="50">
            <ng-template kendoGridCellTemplate let-dataItem>
             
                <input class="custom-checkbox" type="checkbox" [(ngModel)]="dataItem.selected" [name]="dataItem.lineItemInOrder.lineitemId" [disabled]="isCheckboxDisabled(dataItem)" />
           
              
            </ng-template>
        </kendo-grid-column>   
           <!-- <kendo-grid-column field="lineItemInOrder.isDeleted" title="Delete" width="120">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  
                  <input [attr.disabled]="dataItem.editable && featurePermission.delete?null:true" type="checkbox"  (click)="toggle(rowIndex)" [checked]="dataItem.lineItemInOrder.isDeleted"  [name]="dataItem.lineItemInOrder.isDeleted"    />
               
              </ng-template>
          </kendo-grid-column>  -->
             
         <kendo-grid-column field="productInOrder.category" title="Product" [width]="220">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{ dataItem.productInOrder.category}} - {{dataItem.productInOrder.varietal}}
           </ng-template>
         </kendo-grid-column>        
         <kendo-grid-column field="productInOrder.grade" title="Grade" [width]="220">
         </kendo-grid-column>   
         <kendo-grid-column field="productInOrder.format" title="Format" [width]="220">
      </kendo-grid-column>   
      <kendo-grid-column   field="lineItemInOrder.approvedQuantity" title="Quantity" [width]="220">
         </kendo-grid-column>
         <kendo-grid-column field="lineItemInOrder.soldPrice" title="Price"  [width]="220">
         </kendo-grid-column> 
         <kendo-grid-column field="lineItemInOrder.totalPrice" title="Total" [width]="220">
          <ng-template kendoGridCellTemplate let-dataItem>
              {{dataItem.lineItemInOrder.soldPrice * dataItem.lineItemInOrder.approvedQuantity | currency}}
           </ng-template>
         </kendo-grid-column> 
         <kendo-grid-column field="lineItemInOrder.status" title="Status" [editable]="false" [width]="220">
      </kendo-grid-column>  
  
     </kendo-grid> 
  
      <button  type="button" matTooltip="Fulfill" [matTooltipPosition]="'above'"
      class="btn btn-success " (click)="fulfillOrderItems()" [disabled]="!isFulfillEnabled()"
     >
     Fulfill
   </button>
  
  </form>
          
  
   </div>  

  </div>
</div>

   
