import * as React from 'react';
import {Elements, PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js';
import {Stripe, loadStripe} from '@stripe/stripe-js';
import Swal from 'sweetalert2';
import { environment } from 'environments/environment';
var stripe: Stripe;
const stripeKey=environment.stripePublishableKey;
const stripePromise = loadStripe(stripeKey).then(s=>stripe=s);
 // how to unwrap the value inside this  promise
 function CheckoutForm(props) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleError = (error) => {
    setLoading(false);
    setErrorMessage(error.message);
  }

  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError);
      return;
    }
    // Create the PaymentMethod using the details collected by the Payment Element
    const {error:methodError, paymentMethod} = await stripe.createPaymentMethod({
      elements,
      params: {
        billing_details: {
          name: props.name,
          phone:props.phone,
          email:props.email,
        }
      }
    });
    if (methodError) {
      // This point is only reached if there's an immediate error when
      // creating the PaymentMethod. Show the error to your customer (for example, payment details incomplete)
      handleError(methodError);
      return;
    }
    // Create the SetupIntent and obtain clientSecret
    const res = await fetch(`${environment.apibaseUrl}/api/v1/payment/create-intent`, {
      method: "POST",
      headers: new Headers({'content-type': 'application/json'}),
      body: JSON.stringify({
          paymentMethodId:paymentMethod.id,
          customerId:props.customerId,
      
        })
    });
    Swal.fire({
      icon: 'success',
      title: 'Success',
      html: "Your account should get approved within 24 hours",
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonColor: "#0c6acb",
      timer:3000
    }).then(()=>{
      window.location.reload();
    
    });
    const {client_secret: clientSecret} = await res.json();

  };
  
    return (
      <form onSubmit={handleSubmit}>
        {<div className="row">
        <div className="col-md-12">
          <div>
          <label className="font-weight-bold text-info">“Your trial period is over, in order to continue using OmniOrder you need to enter your payment details, you will be paying AUD $200 per user per month”</label>
          </div>
        </div>
      </div>}
        <PaymentElement />
        <button type="submit" className="btn btn-success" disabled={!stripe || loading}>
          Submit
        </button>
        {errorMessage && <div className='font-weight-bold text-danger'>{errorMessage}</div>}
        
      </form>
    );
  }
 
const MyReactPaymentComponent = (props ) => {

      return (
       <div>
       
        <Elements stripe={stripePromise} options={{
         mode: 'setup',
         currency: 'aud',
         paymentMethodCreation: 'manual',
         appearance: {},}} >
        <CheckoutForm customerId={props.customerId}  />
         </Elements>
       </div>
         
      );
}
export default MyReactPaymentComponent;