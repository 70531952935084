import { Routes } from '@angular/router';

import { DashboardComponent } from '../../dashboard/dashboard.component';
import { UserProfileComponent } from '../../user-profile/user-profile.component';
import { TableListComponent } from '../../table-list/table-list.component';
import { TypographyComponent } from '../../typography/typography.component';
import { IconsComponent } from '../../icons/icons.component';
import { ApprovalComponent } from '../../orders/approvals/approval.component';
import { MapsComponent } from '../../maps/maps.component';
import { NotificationsComponent } from '../../notifications/notifications.component';
import { InventoryComponent } from 'app/inventory/management/inventory.component';
import { TrackingComponent } from 'app/orders/tracking/tracking.component';
import { ManageCustomersComponent } from 'app/customers/manage-customers/manage-customers.component';
import { ManageIntegrationComponent } from 'app/integration/management/manage-integration.component';
import { ManageMarketComponent } from 'app/marketing/manage-market.component';
import { SettingsComponent } from 'app/settings/settings.component';
import { ManageUsersComponent } from 'app/users/manage-users/manage-users.component';
import { BillingComponent } from 'app/billing/billing.component';
import { SitesComponent } from 'app/sites/sites.component';
import { GeneralSettingsComponent } from 'app/settings/generalSettings/generalSettings.component';
import { BankSettingsComponent } from 'app/settings/bankSettings/bankSettings.component';
import { ActiveProductsComponent } from 'app/inventory/active-products/active-products.component';
import { PendingProductsComponent } from 'app/inventory/pending-products/pending-products.component';
import { SiteProductsComponent } from 'app/inventory/sites/site-products.component';
import { ActiveUsersComponent } from 'app/users/active-users/active-users.component';
import { PendingUsersComponent } from 'app/users/pending-users/pending-users.component';
import { ActiveCustomersComponent } from 'app/customers/active-customers/active-customers.component';
import { DeactiveCustomersComponent } from 'app/customers/deactive-customers/deactive-customers.component';
import { NewCustomersComponent } from 'app/customers/new-customers/new-customers.component';
import { RejectedCustomersComponent } from 'app/customers/rejected-customers/rejected-customers.component';
import { PendingImportsCustomersComponent } from 'app/customers/pending-imports/pending-imports-customers.component';
import { AccountingIntegrationComponent } from 'app/integration/accounting/accounting-integration.component';
import { EmailIntegrationComponent } from 'app/integration/email/email-integration.component';
import { PaymentIntegrationComponent } from 'app/integration/payment/payment-integration.component';
import { CustomerOrdersComponent } from 'app/orders/approvals/customers/customer-orders.component';
import { ProductOrdersComponent } from 'app/orders/approvals/products/product-orders.component';
import { GrowersSetttingsComponent } from 'app/settings/growers-settings/growers-settings.component';
import { SizesSetttingsComponent } from 'app/settings/sizes-settings/sizes-settings.component';
import { UoMSetttingsComponent } from 'app/settings/uom-settings/uom-settings.component';
import { RipenessSetttingsComponent } from 'app/settings/ripeness-settings/ripeness-settings.component';
import { VarietalsSetttingsComponent } from 'app/settings/varietal-settings/varietals-settings.component';
import { OrdersComponent } from 'app/orders/orders.component';
import { CategorySetttingsComponent } from 'app/settings/category-settings/category-settings.component';
import { TypesSetttingsComponent } from 'app/settings/type-settings/types-settings';
import { WebhookAPIComponent } from 'app/integration/webhook_API/webhook-api.component';
import { InvoiceSettingsComponent } from 'app/settings/invoice-settings/invoice-settings.component';
export const AdminLayoutRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent
  },

  {
    path: 'inventory', component: InventoryComponent,
    children: [
      { path: '', redirectTo: 'activeProducts', pathMatch: 'full' },
      { path: 'activeProducts', component: ActiveProductsComponent },
      { path: 'pendingProducts', component: PendingProductsComponent },
      { path: 'siteProducts', component: SiteProductsComponent }
    ]
  },
 
  { path: 'market', component: ManageMarketComponent },
  {
    path: 'customers', component: ManageCustomersComponent,
    children: [
      { path: '', redirectTo: 'active-customers', pathMatch: 'full' },
      { path: 'active-customers', component: ActiveCustomersComponent },
      { path: 'deactive-customers', component: DeactiveCustomersComponent },
      { path: 'new-customers', component: NewCustomersComponent },
      { path: 'rejected-customers', component: RejectedCustomersComponent },
      { path: 'pending-import-customers', component: PendingImportsCustomersComponent }
    ]
  },
  {
    path: 'integration', component: ManageIntegrationComponent,
    children: [
      { path: '', redirectTo: 'accounting-integration', pathMatch: 'full' },
      { path: 'accounting-integration', component: AccountingIntegrationComponent },
      { path: 'email-integration', component: EmailIntegrationComponent },
      { path: 'payment-integration', component: PaymentIntegrationComponent },
      { path: 'webhook-api-integration', component: WebhookAPIComponent }
    ]
  },
  { path: 'integration/:code', component: ManageIntegrationComponent },
  { path: 'integration?code', component: ManageIntegrationComponent },
  { path: 'user-profile', component: UserProfileComponent },
  { path: 'table-list', component: TableListComponent },
  { path: 'typography', component: TypographyComponent },
  { path: 'icons', component: IconsComponent },
  { path: 'maps', component: MapsComponent },
  { path: 'notifications', component: NotificationsComponent },
  {
    path: 'settings', component: SettingsComponent,
    children: [
      { path: '', redirectTo: 'generalSettings', pathMatch: 'full' },
      { path: 'sites', component: SitesComponent },
      { path: 'generalSettings', component: GeneralSettingsComponent },
      { path: 'bankSettings', component: BankSettingsComponent },
      {
        path: 'growers-settings', component: GrowersSetttingsComponent
      },
      {
        path: 'sizes-settings', component: SizesSetttingsComponent
      }
      ,
      {
        path: 'uom-settings', component: UoMSetttingsComponent
      }
      ,
      {
        path: 'ripeness-settings', component: RipenessSetttingsComponent
      }
      ,
      {
        path: 'varietals-settings', component: VarietalsSetttingsComponent
      }
      ,
      {
        path: 'category-settings', component: CategorySetttingsComponent
      },
      {
        path: 'types-settings', component: TypesSetttingsComponent
      },
      {
        path: 'invoice-settings', component: InvoiceSettingsComponent
      }
    ]

  },
  {
    path: 'users', component: ManageUsersComponent,
    children: [
      { path: '', redirectTo: 'active-users', pathMatch: 'full' },
      { path: 'active-users', component: ActiveUsersComponent },
      { path: 'pending-users', component: PendingUsersComponent }
    ]
  },
  { path: 'billing', component: BillingComponent },
  {
    path: 'orders', component: OrdersComponent,
    children: [
      { path: '', redirectTo: 'tracking', pathMatch: 'full' },
      { path: 'tracking', component: TrackingComponent },
      { path: 'awaitingApproval', component: ApprovalComponent,
      children: [
        { path: '', redirectTo: 'customer-orders', pathMatch: 'full' },
        { path: 'customer-orders', component: CustomerOrdersComponent },
        { path: 'product-orders', component: ProductOrdersComponent }
      ]
    }
      
    ]
  }
];
