import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { CustomerDialog } from 'app/dialogs/customer/customer.dialog';
import { IntegrationService } from 'app/shared/services/integration.service';
import { ExternalCustomerModel } from 'app/shared/models/integration/external.customer.model';
import { CustomerDetailsDialog } from '../dialogs/customer-details/customer-details.dialog';

@Component({
  selector: 'app-pending-imports-customers',
  templateUrl: './pending-imports-customers.component.html',
  styleUrls: ['./pending-imports-customers.component.css']
})
export class PendingImportsCustomersComponent implements OnInit {

  public pendingGridData: any[];
  public pendingGridView: any[];
  public pendingCustomerSelection: string[] = [];
  public pendingCustomerselectedUID: string = '';
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage: number = 1;
  public selectableSettings: SelectableSettings;
  showCustomers: boolean = true;
  CustomerDialogRef: MatDialogRef<CustomerDialog, any>;
  externalCustomers: ExternalCustomerModel[];

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private integrationService: IntegrationService
  ) {
    this.setSelectableSettings();
  }

  ngOnInit(): void {
    this.loading = true;
    this.getPendingCustomers();
  }
  getPendingCustomers() {
    this.loading = true;
    this.integrationService.GetPendingExternalCustomers().subscribe((data) => {
      this.externalCustomers = data;
      this.pendingGridData = data;
      this.pendingGridView = this.pendingGridData;
      this.loading = false;
    });
  }
  openImportDialog(): void {
    var customer = this.externalCustomers.filter(x => x.uid == this.pendingCustomerselectedUID)[0];
    const dialogRef = this.dialog.open(CustomerDetailsDialog, { data: { hasProfile: false, externalCustomer: customer } });
    dialogRef.afterClosed().subscribe(result => {
      this.loading = true;
      this.getPendingCustomers();
      this.spinner.hide();
    });
  }
  public pendingCustomerSelectionKey(context: RowArgs): string {
    return context.dataItem.id;
  }
  pendingCustomerKeyChange(e) {
    this.pendingCustomerselectedUID = e;
    console.log(this.pendingCustomerselectedUID);
  }
  public setSelectableSettings(): void {
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
}

