import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCategory } from 'app/shared/models/catalogue';

import { MarketingGroupViewModel } from 'app/shared/models/promotions/marketing.model';
import { MarketingService } from 'app/shared/services/marketing.service';
import { ProductService } from 'app/shared/services/product.service';


@Component({
  selector: 'app-marketing-categories',
  templateUrl: './marketing-categories.dialog.html',
  styleUrls: ['./marketing-categories.dialog.css']
})
export class MarketingCategoriesDialog {
  MarketingGroup: MarketingGroupViewModel
  categories: any[];


  constructor(
    public dialogRef: MatDialogRef<MarketingCategoriesDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MarketingGroupViewModel, private marketingService: MarketingService, private productService: ProductService) {
    this.MarketingGroup = data;
    this.categories = [];
    this.productService.getSellerProductCategoriesInGroup(this.MarketingGroup.id).subscribe((result) => {
      result.forEach(element => {
        this.categories.push({ id: element.id, categoryName: element.categoryName, selected: element.selected })
      });
      if(this.categories.length > 2)
        this.dialogRef.updateSize('80%', '80%');
      else 
        this.dialogRef.updateSize('80%');
    });

  }

  ngOnInit() {    
}

  activateDeactivate(): void {
    this.marketingService.ActivateOrDeactivateMarketingGroup(this.MarketingGroup);
  }
  save(): void {

    
    this.marketingService.updateProductCatgegoryGroup(this.MarketingGroup.id, this.categories.filter(x => x.selected)).subscribe((data) => {
      
    },
      error => {
      


      }
    );

  }
}
