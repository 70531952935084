<mat-dialog-content>
  <mat-card  class="card">
  
    <mat-card-header class="mb-3 card-header card-header-tabs card-header-primary rounded ">    
      <span class ="header-font-size">Create New Order</span>
    </mat-card-header>
<mat-horizontal-stepper completed="false" [linear]="isLinear" #stepper>
  <mat-step class="mb-5" label="Order Details">

  
    <section class="d-flex">
      <mat-form-field class="col-sm-4">
        <mat-label>Customer</mat-label>
        <mat-select matNativeControl  
          [(ngModel)]="dialogdata.Order.customerId" [name]="dialogdata.Order.customerId" #buyer="ngModel" (selectionChange)="loadBatchesForCustomer($event.value)" required 
          [disabled]="isCustomerSelectionDisabled">
          <mat-option value="-1" selected>Please select Customer</mat-option>
          <mat-option *ngFor="let c of Customers" [value]="c.customerId">
            {{c.customer.businessName}}
          </mat-option>
        
        </mat-select>
        <mat-error *ngIf="buyer.errors?.required">Customer is required</mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Category</mat-label>
        <mat-select matNativeControl (selectionChange)="loadVarietlasAndFilterBatches($event.value)"
          [(ngModel)]="dialogdata.Order.categoryId" name="Category" >
          <mat-option value="-1" selected>Please select category</mat-option>
          <mat-option *ngFor="let c of ProductCategories" [value]="c.id">
            {{c.categoryName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Varietal</mat-label>
        <mat-select matNativeControl (selectionChange)="filterBatches()"
          [(ngModel)]="dialogdata.Order.varietalId" name="Category" >
          <mat-option value="-1" selected>Please select varietal</mat-option>
          <mat-option *ngFor="let v of ProductVaritals" [value]="v.id">
            {{v.varietalName}}
          </mat-option>
        </mat-select>
      </mat-form-field>      
    </section>        
    <section class="d-flex">
      <mat-form-field class="col-sm-4">
        <mat-label>Grade</mat-label>
        <mat-select matNativeControl (selectionChange)="filterBatches()"
          [(ngModel)]="dialogdata.Order.productGradeId" name="Grade" >
          <mat-option value="-1" selected>Please select grade</mat-option>
          <mat-option *ngFor="let G of ProductGrades" [value]="G.id">
            {{G.grade}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Grower</mat-label>
        <mat-select (selectionChange)="filterBatches()"
          [(ngModel)]="dialogdata.Order.productGrowerId" name="Grower">
          <mat-option value="-1" selected>Please select grower</mat-option>
          <mat-option *ngFor="let Go of ProductGrowers" [value]="Go.id">
            {{Go.growerName}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field class="col-md-4">
        <mat-label>Region</mat-label>
        <mat-select (selectionChange)="filterBatches()"
          [(ngModel)]="dialogdata.Order.region" name="Region">
          <mat-option value="-1" selected>Please select region</mat-option>
          <mat-option value="NSW">NSW</mat-option>
          <mat-option value="QLD">QLD</mat-option>
          <mat-option value="SA">SA</mat-option>
          <mat-option value="VIC">VIC</mat-option>
          <mat-option value="WA">WA</mat-option>
          <mat-option value="TAS">TAS</mat-option>
          <mat-option value="NT">NT</mat-option>
          <mat-option value="ACT">ACT</mat-option>
        </mat-select>
      </mat-form-field>      
    </section>        
    <section class="d-flex" >
      <div class="col-md-12">
         <mat-card-title class="cardHeader" required> Batches in Stock</mat-card-title>       
            <kendo-grid
              [kendoGridBinding]="batchesGridView"
              kendoGridSelectBy="id"
              [selectedKeys]="batchSelection"
              [loading]="loading" 
              [pageSize]="10"
              [pageable]="true"
              [sortable]="true"
              [groupable]="false"
              [reorderable]="true"
              [resizable]="true"
              [height]="300"
              [columnMenu]="{ filter: true }"
              [selectable]="selectableSettings"
              kendoGridSelectBy="id"
              [kendoGridSelectBy]="batchSelectionKey"
              (selectedKeysChange)="batchKeyChange($event)"
              >                          
           <ng-template kendoGridToolbarTemplate>
              <!-- <button kendoGridAddCommand (click)="addNewBatch()" >Add new</button>
              <button kendoGridAddCommand (click)="editBatch()" [disabled]="selectedUID == null || selectedUID.length == 0">Edit</button> -->
              <!-- <input
                placeholder="Search in all columns..."
                kendoTextBox
                (input)="onBatchFilter($event.target.value)"
              /> -->
              <kendo-grid-spacer></kendo-grid-spacer>        
            
              <kendo-grid-checkbox-column
                [width]="45"
                [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }"
                [resizable]="false"
                [columnMenu]="false"
                showSelectAll="false"          
                ></kendo-grid-checkbox-column>
            </ng-template>        
            <kendo-grid-column field="id" title="ID" [width]="220" visibility="false">
            </kendo-grid-column>     
            <kendo-grid-column field="number" title="Batch Number" [width]="220"  editable="false">
            </kendo-grid-column>                             
            <kendo-grid-column field="productName" title="Product" [width]="220" visibility="false">
            </kendo-grid-column>                                
            <kendo-grid-column field="format" title="Unit of Measure" [width]="220" >          
            </kendo-grid-column>     
            <kendo-grid-column field="grade.grade" title="Grade" [width]="220">          
            </kendo-grid-column>                         
            <kendo-grid-column field="grower.growerName" title="Grower" [width]="220">          
            </kendo-grid-column>                                             
            <kendo-grid-column field="region" title="Region" [width]="220">          
            </kendo-grid-column>                                             
            <kendo-grid-column field="ripness" title="Ripeness" [width]="220">          
            </kendo-grid-column>
            <kendo-grid-column field="unitPrice" title="Unit Price" format="{0:c2}" [width]="220">          
            </kendo-grid-column>
            <kendo-grid-column field="bestPrice" title="Best Buyer Price" format="{0:c2}" [width]="220">          
            </kendo-grid-column>
            <kendo-grid-column field="bestPriceSource" title="Best Price Source" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span 
                  style="{{ dataItem.bestPriceSource == 'Promotion' ? 'color:#5FA136;' : dataItem.bestPriceSource == 'Private' ? 'color:#2D4D19;' : 'color:#0C6ACB;' }}">{{ dataItem.bestPriceSource }}
                </span>                
              </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="stockFormatted" title="Quantity" [width]="220">          
            </kendo-grid-column>            
        </kendo-grid>         
      </div>      
    </section>
    <section class="d-flex">      
      <div class="col-md-4">
        <mat-label>&nbsp;</mat-label>        
      </div>
      <div class="col-md-4">
        <mat-label>&nbsp;</mat-label>        
      </div>
      <div class="col-md-4">
        <mat-label>&nbsp;</mat-label>        
      </div>
    </section>
    <section class="d-flex">            
      <mat-form-field class="col-md-6">
        <mat-label>Price <mat-icon>edit</mat-icon></mat-label>
        <span matPrefix>$ &nbsp;</span>
        <input matInput type="number" placeholder="Price" min="1" [(ngModel)]="dialogdata.Order.unitPrice" #unitPrice="ngModel" required />
        <mat-error *ngIf="unitPrice.errors?.required">Price is required</mat-error>
      </mat-form-field>
      <mat-form-field class="col-md-6">
        <mat-label>Quantity <mat-icon>edit</mat-icon></mat-label>
        <input matInput type="number" appDigitOnly  class="" [(ngModel)]="dialogdata.Order.quantity" min="1" #quantity="ngModel"  required />
        <mat-error *ngIf="quantity.errors?.required">Quantity is required</mat-error>
      </mat-form-field>
    </section>
    <section class="d-flex">      
      <div class="col-md-4">
        <mat-label>&nbsp;</mat-label>        
      </div>
      <div class="col-md-4">
        <mat-label>&nbsp;</mat-label>        
      </div>
      <div class="col-md-4">
        <mat-label>&nbsp;</mat-label>        
      </div>
    </section>
    <section class="d-flex" align="end">            
      <div class="col-md-4 offset-md-4" align="end">
        <mat-error *ngIf="showBatchErrors">
          {{errorMessage}}
        </mat-error>        
        <mat-error *ngIf="showItemAlreadyExists">
          Item already exists
        </mat-error>        
      </div>
      <div class="col-md-4" align="end">        
        <button mat-button [disabled]="!AddItemIsValid()" (click)="AddOrderItem()" >Add Item</button>
      </div>
    </section>

    <section class="d-flex" >
      <div class="col-md-12">
         <mat-card-title class="cardHeader"> Order Items</mat-card-title>       
            <kendo-grid
              #grid              
              [data]="view | async"
              kendoGridSelectBy="id"
              [selectedKeys]="orderItemSelection"
              [loading]="loading" 
              [pageSize]="10"
              [pageable]="true"
              [sortable]="true"
              [groupable]="false"
              [reorderable]="true"
              [resizable]="true"
              [height]="300"
              [columnMenu]="{ filter: true }"
              [selectable]="selectableSettings"
              kendoGridSelectBy="id"
              [kendoGridSelectBy]="orderItemSelectionKey"
              (selectedKeysChange)="orderItemKeyChange($event)"
              
              (cellClick)="cellClickHandler($event)"
              (cellClose)="cellCloseHandler($event)"
              (cancel)="cancelHandler($event)"
              (save)="saveHandler($event)"
              (remove)="removeHandler($event)"
              (add)="addHandler($event)"
              [navigable]="true"
              >                          
           <ng-template kendoGridToolbarTemplate>              
              <kendo-grid-spacer></kendo-grid-spacer>                    
              <kendo-grid-checkbox-column
                [width]="45"
                [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }"
                [resizable]="false"
                [columnMenu]="false"
                showSelectAll="false"          
                ></kendo-grid-checkbox-column>
            </ng-template>        
            <kendo-grid-column field="id" title="Batch Id" [width]="220" visibility="false" editable="false">
            </kendo-grid-column>                                 
            <kendo-grid-column field="item" title="Item" [width]="220" visibility="false" editable="false">
            </kendo-grid-column>                                       
            <kendo-grid-column field="quantity" title="Qty" [width]="220" editor="numeric"  >          
            </kendo-grid-column>     
            <kendo-grid-column field="maxqty" title="maxQty" [width]="220" editor="numeric" hidden="true"  >          
            </kendo-grid-column>     
            <kendo-grid-column field="unitPrice" title="Price" [width]="220" format="{0:c2}" editor="numeric" >          
            </kendo-grid-column>     
            <kendo-grid-column field="total" title="Total" format="{0:c2}" [width]="220" editable="false">          
            </kendo-grid-column>
            <kendo-grid-command-column title="Action" [width]="220">
              <ng-template kendoGridCellTemplate let-isNew="isNew">
                <button kendoGridRemoveCommand>Remove</button>
                <button kendoGridSaveCommand>Add</button>
                <button kendoGridCancelCommand>Cancel</button>
              </ng-template>
            </kendo-grid-command-column>
        </kendo-grid>         
      </div>      
    </section>
    <section class="d-flex">      
      <div class="col-md-4   offset-md-8">
        <mat-label>&nbsp;</mat-label>        
      </div>
    </section>
  
    
    <!-- <section class="d-flex">                  
        <div class="col-md-12">
          <mat-card-title class="cardHeader"> Delivery Type</mat-card-title> 
        </div>            
      </section>
      
      <section class="d-flex">      
        <div class="col-md-4   offset-md-8">
          <mat-label>&nbsp;</mat-label>        
        </div>
      </section> -->

      <!-- <section class="d-flex">
        <div class="col-md-2">        
          <mat-checkbox [(ngModel)]="deliveryTypePickup"  (change)="handleDeliveryTypePickup()"> <div style="display:inline; line-height: 15px;"> Pickup (edit)</div> </mat-checkbox>
        </div>        
        <div class="col-md-1" align="end">          
          Market Bay 
        </div>      
        <div class="col-md-9">          
          <input matInput type="text" placeholder="Bay" [(ngModel)]="deliveryBay" required />
        </div>      
    </section>   
      <section class="d-flex">
        <div class="col-md-2">        
          <mat-checkbox [(ngModel)]="deliveryTypeAddress"  (change)="handleDeliveryTypeAddress()"><div style="display:inline; line-height: 15px;"> Address (edit)</div></mat-checkbox>
        </div>        
        <div class="col-md-10" align="end">          
          &nbsp;          
        </div>      
    </section>
    <section class="d-flex">
      <div class="col-md-2">        
        &nbsp;
      </div>        
      <div class="col-md-1" align="end">          
        Street
      </div>      
      <div class="col-md-9">
        <input matInput type="text" placeholder="Street" [(ngModel)]="deliveryAddress"  />          
      </div>      
  </section>
    <section class="d-flex">      
      <div class="col-md-1 offset-md-2" align="end">          
        City
      </div>      
      <div class="col-md-9">
        <input matInput type="text" placeholder="City" [(ngModel)]="deliveryCity"/>
      </div>      
    </section>
    <section class="d-flex">      
      <div class="col-md-1 offset-md-2" align="end">          
        State
      </div>      
      <div class="col-md-1">
        <input matInput type="text" placeholder="State" [(ngModel)]="deliveryState"  />
      </div> 
      <div class="col-md-1" align="end">          
        Postal Code
      </div>      
      <div class="col-md-2">
        <input matInput type="number" placeholder="Postal Code" [(ngModel)]="deliveryPostalCode"  />
      </div>
      <div class="col-md-5">        
          &nbsp;
      </div>
    </section>   
    
    <section class="d-flex paymentHeader">                  
      <div class="col-md-12">
        <mat-card-title class="cardHeader"> Payment Type</mat-card-title> 
      </div>            
    </section>
    <section class="d-flex">
      <div class="col-md-1">        
        <mat-checkbox [(ngModel)]="isBusinessTradeCredit"  (change)="onCheckboxChange(1)">  </mat-checkbox>
      </div>        
      <div class="col-md-2" >          
        Business Trade Credit
      </div>    
      <div class="col-md-1">        
        <mat-checkbox [(ngModel)]="isMarketTradeCredit"  (change)="onCheckboxChange(2)"> </mat-checkbox>
      </div>        
      <div class="col-md-2" >          
        Market Trade Credit
      </div>    
  </section>    -->
    <section class="d-flex">      
      <div class="col-md-4   offset-md-8">
        <mat-label>&nbsp;</mat-label>        
      </div>
    </section>
    <section class="d-flex" >           
        <div class="col-md-4  ">
         
          <h2 class="text text-info">Order Total: ${{Total}}</h2>              
        </div>
    </section>             
    <section class="d-flex" >           
      <div class="col-md-2 offset-md-10 ">
        <button mat-button class="btn btn-primary" matStepperNext>Next</button>           
      </div>
      
      
  </section>  
  <!-- <mat-card-actions >
    <mat-error *ngIf="showErrors">
      Please enter all mandatory fields
    </mat-error>
    <button mat-button class="btn btn-primary" matStepperNext>Next</button>
     <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="createNewOrder()" [disabled]="! isOrderReadyForSubmit()">Create</button> 
  </mat-card-actions> -->

</mat-step>
<mat-step label="Address & Payment">
  <section class="row">
    <div class="col-md-12 ">
      <h2 >Payment Type</h2>
    </div>  
    <mat-radio-group [(ngModel)]="paymentTypeId" class="col-md-12">

   <div class="row">
    <article class="card mb-4 col-md-4 offset-md-1" [ngClass]="tradeClass">
      <div class="card-body "  >
        <label class="js-check box active">
          <mat-radio-button   [value]="businessTradeCredit" (change)="updateClasses(businessTradeCredit)"> <span class="span-black-color">Business Trade Credit</span> </mat-radio-button >

        </label>
        
      </div>
    </article>
    <article class="card mb-4 col-md-4 offset-md-1" [ngClass]="marketClass">
      <div class=" card-body ">
        <label class="js-check box active">
          <mat-radio-button    [value]="marketTradeCredit" (change)="updateClasses(marketTradeCredit)"> <span class="span-black-color">Market Trade Credit</span> </mat-radio-button >
  
        </label>
        
      </div>
    </article>
   </div>
      
      </mat-radio-group>
  </section>
  
  <section class="row">
    <div class="col-md-12 ">
      <h2 >Delivery Type</h2>
    </div>  


    <article class="card mb-4 col-md-4 offset-md-1" [ngClass]="pickClass">
      <div class="card-body "  >
        <div class="row">
          
          <mat-checkbox class="col-md-12 mb-3" [(ngModel)]="deliveryTypePickup"  (change)="handleDeliveryTypePickup()"> <span class="span-black-color">Pickup (edit)</span> </mat-checkbox>
          <div class="col-md-4">
            <span>Market Pay </span> 
          </div>
          <div class="col-md-4">
            <input matInput type="text" placeholder="Bay" [(ngModel)]="deliveryBay" required />
          </div>
        </div>
        
            
       
        
      </div>
    </article>
    <article class="card mb-4 col-md-5 offset-md-1" [ngClass]="addressClass">
      <div class=" card-body ">
        
        <div class="row">
          
          <mat-checkbox class="col-md-12 mb-3" [(ngModel)]="deliveryTypeAddress"  (change)="handleDeliveryTypeAddress()"><span class="span-black-color"> Address (edit)</span> </mat-checkbox>
          <div class="col-md-3 mb-3">
            <span>Street </span> 
          </div>
          <div class="col-md-9 mb-3 ">
            <input class="form-control" matInput type="text" placeholder="Street" [(ngModel)]="deliveryAddress"  />    
          </div>
          <div class="col-md-3 mb-3">
            <span>Postal Code </span> 
          </div>
          <div class="col-md-3 mb-2">
            <input matInput class="form-control" type="number" placeholder="Postal Code" [(ngModel)]="deliveryPostalCode"  />
          </div>
          <div class="col-md-3">
            <span>City </span> 
          </div>
          <div class="col-md-3">
            <input matInput class="form-control" type="text" placeholder="City" [(ngModel)]="deliveryCity"/>
          </div>
          <div class="col-md-3">
            <span>State </span> 
          </div>
          <div class="col-md-3">
            <input matInput class="form-control" type="text" placeholder="State" [(ngModel)]="deliveryState"  />
          </div>
        </div>
        
      </div>
      
    </article>
   
     
  </section>
  <section class="row">
    <div class="col-md-4">
      <button class="btn btn-primary" mat-button matStepperPrevious>Prev</button>
    </div>
    <div class="col-md-1 offset-md-6 ">
      <button mat-button class="btn btn-success" (click)="createNewOrder()" [disabled]="! isOrderReadyForSubmit()">Create</button>           
    </div>
   
  </section>
</mat-step>
</mat-horizontal-stepper>
</mat-card>
</mat-dialog-content>