import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';

@Component({
  selector: 'app-manage-integration',
  templateUrl: './manage-integration.component.html',
  styleUrls: ['./manage-integration.component.css']
})
export class ManageIntegrationComponent implements OnInit {
  showIntegrations: boolean = true;
  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private userService: UserService, private sellerService: SellerService
  ) {

  }

  ngOnInit(): void {
    this.spinner.show('loading');
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
  }
  loadPermissions(seller: SellerViewModel) {
    this.userService.hasPermissionFor(PermissionsConstants.INTEGRATION, PermissionsConstants.READ, seller.id).subscribe(x => { this.showIntegrations = x; this.spinner.hide('loading'); });
  }
}
