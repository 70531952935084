<div class="row">
  <div align="center" class=" col-md-2">
    <h2>OmniOrder API Key</h2>
  </div>
  <mat-form-field appearance="fill" class="col-md-6">
    <input readonly class="star-password" matInput [type]="hidePassword ? 'password' : 'text'" [(ngModel)]="password">
    <button mat-icon-button matSuffix (click)="togglePasswordVisibility()">
      <mat-icon class="star-password">{{ hidePassword ? 'visibility' : 'visibility_off' }}</mat-icon>
    </button>
  </mat-form-field>

</div>