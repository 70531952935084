<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isLoading" novalidate>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">New Varietal</h4>
            </div>
            <div class="card-body" >
              <div class="row">
              
                <div class="col-md-12">
                  <div class="row">
            
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Varietal Name*"  formControlName="varietalName">
                        <mat-error >Invalid Varietal Name</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-md-6">
                      <mat-form-field >
                        <mat-label>Category</mat-label>
                        <mat-select  matNativeControl  formControlName="productCategoryId"
                          [(ngModel)]="productCategoryId" name="Category" required>
                          <mat-option value="-1" selected>Please select category</mat-option>
                          <mat-option *ngFor="let c of ProductCategories" [value]="c.id">
                            {{c.categoryName}}
                          </mat-option>
                        </mat-select>
                        <mat-error >Please select category</mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                        
                </div>
              </div>
  

              <div class="row" *ngIf="isEditableForm">
                
                <div class="col-md-3 mr-3 text-center">
                  <button mat-button  type="submit" [disabled]="!form.valid" class="btn btn-primary"  >
                    Save
                </button>
                </div>
                <div class="col-md-2 text-center">
                  <button mat-button class="btn btn-danger" [mat-dialog-close]="true">
                    Cancel
                </button>
                </div>    
            </div>

            </div>
            
          </div>
        </div>
  
      </div>

      
    </div>
  </div>
</form>