import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { NgxSpinnerService } from "ngx-spinner";
import { UploadService } from 'app/shared/services/upload.service';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import { WasteBatchViewModel } from 'app/shared/models/catalogue/WasteBatchViewModel';
import { Subscription } from 'rxjs';


@Component({
  selector: 'dialogs-waste-batch',
  templateUrl: './waste-batch.dialog.html',
  styleUrls: ['./waste-batch.dialog.css']
})
export class WasteBatchDialog implements OnInit {
  private _subscriptions: Subscription[] = [];
  public form: FormGroup;
  public wasteBatch = new WasteBatchViewModel();
  batch: BatchViewModel;
  stockMax : number;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(
    public dialogRef: MatDialogRef<WasteBatchDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: {  Batch: BatchViewModel },
    private snackbar: MatSnackBar,
    private spinner: NgxSpinnerService,
    private ProductService: ProductService, private uploadService: UploadService
  ) { 
    
      this.batch = this.dialogdata.Batch;
      this.wasteBatch.batchId=this.batch.id;
      
  }


  ngOnInit(): void {
    this.stockMax=this.batch.stock;
    this.form = new FormGroup({
      quantity: new FormControl('', [Validators.required, Validators.min(1), Validators.max(this.stockMax)]),
      reason: new FormControl('', [Validators.required, Validators.maxLength(250)])
    });
     
  } 

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
  }
  
  public hasError = (controlName: string, errorName: string) =>{
    return  this.form.controls[controlName].hasError(errorName);
  }

  addWasteBatch() {        
    const s=this.ProductService.AddWasteBatch(this.wasteBatch).subscribe((result) => {
      const message = "Waste Batch successfully added";
      this.snackbar.open(message, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });
    this._subscriptions.push(s);
    this.dialogRef.close(true);
  }
  
}
