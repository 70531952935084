import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar} from '@angular/material/snack-bar';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { NgxSpinnerService } from "ngx-spinner";
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { SiteModel, StateModel } from 'app/shared/models/sites/site.model';
import { StockTakeBatchesComponent } from '../stock-take-batches/stock-take-batches.component';
import { ProductService } from 'app/shared/services/product.service';
import Swal from 'sweetalert2';
import { SortDescriptor, orderBy } from '@progress/kendo-data-query';

@Component({
  selector: 'app-stock-take-sites-dialog',
  templateUrl: './stock-take-sites.dialog.html',
  styleUrls: ['./stock-take-sites.dialog.css']
})
export class StockTakeSitesDialog implements OnInit {
  public gridView: GridDataResult;
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public isActive: boolean=true;
  sitesViewModel: SiteModel[];
  states: StateModel[];
  featurePermission: FeaturePermission = new FeaturePermission();
  showSites: boolean = true;
  public sort: SortDescriptor[] = [
    {
      field: "lastStockTakeDate",
      dir: "desc",
    },
  ]; 

  constructor(
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
    private snackbar: MatSnackBar,
    private userService: UserService, private productService: ProductService, private sellerService: SellerService
  ) {
    this.setSelectableSettings();
  }

  ngOnInit(): void {
    this.loading = true;
    this.initSites();
  }
  
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SITE_MANAGEMENT )[0];
  console.log(this.featurePermission);
  this.showSites = this.featurePermission.read;
  });
   
}
  async initSites() {
    this.loading = true;
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    
     this.sellerService.getSites(this.currentPage,this.pageSize).subscribe((data) => {
       this.gridView = {
         data:orderBy(data.items, this.sort) ,
         total: data.totalItems,
       };
       console.log(data.items);
       this.loading = false;
     });
  }
  
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }

  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getGrid();
  }
  
  getGrid()
  {
    this.loading = true;
    this.sellerService.getSites(this.currentPage,this.pageSize).subscribe((data) => {
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;
    });
  }
  openStockTakeDialog( site: SiteModel): void {
    if(site.currntStockTake==null)
    {
      Swal.fire({
        title: 'Stock Take Confirmation',
        text: 'Starting a stock take in this site will result in all orders against batches in this site will require manual approval, are you sure you want to proceed?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.spinner.show();
          this.productService.startStockTake(site.id).subscribe((data) => {
            site.currntStockTake=data;
            var Data = { site: site }
            this.spinner.hide();
          const dialogRef2 = this.dialog.open(StockTakeBatchesComponent, { data: Data });
          dialogRef2.afterClosed().subscribe(result => {
            this.spinner.show();
            this.initSites();
            this.spinner.hide();
          });
          });
        }});
    } else {
      var Data = { site: site }
       const dialogRef2 = this.dialog.open(StockTakeBatchesComponent, { data: Data });
       dialogRef2.afterClosed().subscribe(result => {
        this.spinner.show();
         this.initSites();
         this.spinner.hide();
       });
    }
  }
  public sortChange(sort: SortDescriptor[]): void {
    this.sort = sort;
    this.initSites();
  }
} 
 
