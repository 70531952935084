import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { OrderSummaryResponse } from 'app/shared/models/order/order.model';
import { LineItemInOrder, OrderRequest, OrderUpdateRequest, ProductLineItemDetails } from 'app/shared/models/order/PlainProductInOrders.Model';
import { OrderService } from 'app/shared/services/order.service';
import { IChatParticipant } from 'ng-chat';
import { SignalRPaginatedAdapter } from 'app/chat/adapter/signalr-paginated-adapter';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { CancelEvent, CellClickEvent, CellCloseEvent, CreateFormGroupArgs, EditEvent, GridComponent, GridDataResult, RemoveEvent, RowArgs, SaveEvent, SelectableSettings } from '@progress/kendo-angular-grid';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { EditOrderService } from 'app/shared/services/edit-order.service';
import { State } from '@progress/kendo-data-query';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { UserService } from 'app/shared/services/user.service';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { SellerService } from 'app/shared/services/Seller.Service';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-order-items-fulfillment',
  templateUrl: './order-items-fulfillment.dialog.html',
  styleUrls: ['./order-items-fulfillment.dialog.css']
})
export class OrderItemsFulFillmentDialog implements OnInit {
  public view: Observable<GridDataResult>;
  public gridView: any[];
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:number[]  = [];
  public loading: boolean = false;
  public isEdited = false;
  orderRequest: OrderRequest=new OrderRequest();
  public gridState: State = {
    sort: [],
    skip: 0,
    take: 5,
  };
  public selectableSettings: SelectableSettings;
  ProductLineItemsDetails: ProductLineItemDetails[];
  public formGroup: FormGroup;
  orderUpdateRequest: OrderUpdateRequest= new OrderUpdateRequest();
  horizontalPosition: any;
  verticalPosition: any;
  editableForm: boolean;
  disableCheckbox: boolean;
  featurePermission: FeaturePermission;;
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private orderService: OrderService,
  private eventEmitterService: EventEmitterService,private sellerService: SellerService,
  private snackbar: MatSnackBar,
  public dialog: MatDialog,private formBuilder: FormBuilder,public editService: EditOrderService ,private userService: UserService,
  private spinner: NgxSpinnerService ,public dialogRef: MatDialogRef<OrderItemsFulFillmentDialog>) {
    // this.createFormGroup = this.createFormGroup.bind(this);
    
   }

  ngOnInit(): void {
    this.setSelectableSettings();
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.load();
  }
  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.ORDERS )[0];
    });
     
 }
load(): void {
  
  this.orderService.GetOrderLineItemsDetails(this.data.orderId).
  subscribe((result) => {
    this.ProductLineItemsDetails = result;
    this.gridView=this.ProductLineItemsDetails;
    this.editableForm=this.ProductLineItemsDetails[0].editable;
  }

  );
}
  openchat(): void {
    var items = this.ProductLineItemsDetails;
    let User = SignalRPaginatedAdapter.Buyers.filter(function (element: IChatParticipant) {
      return element.id == items[0].buyerId;
    });
    this.eventEmitterService.OpenChatClick(User[0]);
  }

  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: true,
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    //
   
    return context.dataItem.lineItemInOrder.lineitemId;
  }
  keyChange(e){    

    this.selectedUID = e;
    console.log( this.selectedUID)
  }

  isEditDisabled()
  {
    return (this.selectedUID == null || this.selectedUID.length == 0 );
  }

    
    public toggle(index: any): void {
      this.gridView[index].lineItemInOrder.isDeleted = !this.gridView[index].lineItemInOrder.isDeleted ;      
    }
    onCheckboxChange(event) {
      alert(event)
      // `event` contains information about the row and whether it was selected or deselected
      const selectedDataItem = event.dataItem;
      const isSelected = event.selected;
    
      // You can now work with the selected data item and its selection state
      if (isSelected) {
        // Handle selection
      } else {
        // Handle deselection
      }
    }

    fulfillOrderItems() {
      this.spinner.show();
      let list=this.gridView.filter(s=>s.selected).map(s=>s.lineItemInOrder.lineitemId);
      console.log(list)
      this.orderService.FulfillOrderItems(list).subscribe((data) => 
    {
      console.log(data);
      if(data==true)
      {
        this.spinner.hide();
        this.snackbar.open('Order Item Fulfilled Successfully', 'close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.dialogRef.close();
      }
      
      else
      {
        this.spinner.hide();
        this.snackbar.open(`Failed to fulfill order item `, 'close', {
          duration: 5000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        
        
      }
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.snackbar.open(`Failed to fulfill order item due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      
    });
    }
    isCheckboxDisabled(dataItem: any): boolean {
      // Determine whether the checkbox should be disabled based on the condition
      
      return dataItem.lineItemInOrder?.status!="Confirmed"?true:false;
    }
    isFulfillEnabled(): boolean {
      // Determine whether the checkbox should be disabled based on the condition
      let count=this.gridView.filter(s=>s.selected).map(s=>s.lineItemInOrder.lineitemId).length;
      return count>0;
    }
    
}
