<div class="tab-pane active" id="active">
    <kendo-grid [data]="gridView" [loading]="loading" [pageSize]="pageSize" [skip]="skip" [pageable]="true"
        [resizable]="true" (pageChange)="pageChange($event)">
        <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button [disabled]="dataItem.customer.customerSourceId!=1" mat-raised-button type="button"
                    matTooltip="start chat with customer" [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="openchat(dataItem.buyer.id)">
                    <i class="material-icons">chat</i>
                </button>
                <button *ngIf="showError(dataItem)" mat-raised-button type="button"
                    matTooltip="Customer needs to be pushed or linked to external system" [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon">
                    <i class="material-icons">priority_high</i>
                </button>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="Customer Name" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a mat-button class="nav-link" matTooltip="Customer Name"
                    (click)="openCustomerDialog(dataItem.customer.id)">
                    {{dataItem.customer.businessName}}
                    <div class="ripple-container"></div>
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="Email" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <a mat-button class="nav-link" matTooltip="Email">
                    {{dataItem.customer.businessEmail}}
                    <div class="ripple-container"></div>
                </a>
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="Marketplace" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                {{dataItem.customer.customerSourceValue == 'Marketplace' ? 'Yes' : 'No'}}
            </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="data" title="" class='k-text-center font-weight-bold'
            headerClass="k-text-center font-weight-bold" [width]="150">
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <button [disabled]="!featurePermission.update" *ngIf="!dataItem.isActive && dataItem.status=='approved'"
                    mat-raised-button type="button" matTooltip="Activate" [matTooltipPosition]="'above'"
                    class="btn btn-success btn-link btn-sm btn-just-icon" (click)="activateDeactivateProduct(dataItem)">
                    <i class="material-icons">visibility</i>
                </button>
                <button [disabled]="!featurePermission.update" *ngIf="dataItem.isActive && dataItem.status=='approved'"
                    mat-raised-button type="button" matTooltip="Deactivate Customer" [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="activateDeactivateProduct(dataItem)">
                    <i class="material-icons">
                        visibility_off
                    </i>
                </button>
                <button [disabled]="!integrationPermission.create || hasExternalId(dataItem)"
                    *ngIf="isLinkedToExternalSystem" mat-raised-button type="button"
                    matTooltip="Push Customer To External System" [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="push(dataItem)">
                    <i class="material-icons">cloud_upload</i>
                </button>
                <button [disabled]="!integrationPermission.read" *ngIf="isLinkedToExternalSystem" mat-raised-button
                    type="button" matTooltip="Link Customer To External System" [matTooltipPosition]="'above'"
                    class="btn btn-danger btn-link btn-sm btn-just-icon" (click)="link(dataItem)">
                    <i class="material-icons">link</i>
                </button>
            </ng-template>
        </kendo-grid-column>
    </kendo-grid>
</div>