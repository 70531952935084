import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import {  StateModel, ZoneModel } from 'app/shared/models/sites/site.model';
import { SellerViewModel } from 'app/shared/models/seller/sellerViewModel';
import { UserService } from 'app/shared/services/user.service';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import {  ProductSize } from 'app/shared/models/catalogue/lockups';


@Component({
  selector: 'app-new-size',
  templateUrl: './new-size.dialog.html',
  styleUrls: ['./new-size.dialog.css']
})
export class NewSizeDialog implements OnInit {
  public opened = true;
  public form: FormGroup;
  states: StateModel[];
  zones: ZoneModel[];
  public isLoading:boolean=false;
  public isEditableForm:boolean=true;
  featurePermission: FeaturePermission = new FeaturePermission();
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(public dialogRef: MatDialogRef<NewSizeDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: { editable: boolean, model: ProductSize}
    ,public dialog: MatDialog
    ,private sellerservice: SellerService,
    public fb: FormBuilder,private snackbar: MatSnackBar,
    private userService: UserService,

  ) {
  }


   async ngOnInit() {
    this.spinner.show();  
    this.isEditableForm=this.isEditable();
    await this.sellerservice.getSeller().subscribe(seller => {
       this.loadPermissions(seller);
       this.spinner.hide();  
    });
    
  }
  async loadPermissions(seller: SellerViewModel) {   
    await this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SETTINGS )[0];

    if(this.data.editable && this.data.model==null)
    {
      this.isEditableForm=this.isEditable();
      this.form=this.fb.group(
        {
          size:["",[Validators.maxLength(250),Validators.minLength(3),Validators.required,]]
        }      
      );
      
    }
    if(this.data.editable && this.data.model!=null)
    {
       this.isEditableForm=this.isEditable();
      this.form=this.fb.group(
        {
          size:[{value:"",disabled: !this.isEditableForm},[Validators.maxLength(250),Validators.minLength(3),Validators.required,]],
           }      
      );
      this.form.patchValue({
        growerName: this.data.model.size
      });
     

    }
    });
     
  }
  get f() { return this.form.controls; }

  public close(status: string): void {
   
    this.opened = false;
  }
  openSellerDetails()
  {   
    
  }

  onSubmit() {
    this.spinner.show(); 
    let model: ProductSize= this.form.value;
    if(this.data?.model?.id){
      model.id = this.data.model.id;
      this.sellerservice.updateSize(model).subscribe((data) => {
        this.spinner.hide(); 
        this.dialogRef.close();
     });
    } else {
      this.sellerservice.createSize(model).subscribe((data) => {
        this.spinner.hide(); 
        this.dialogRef.close();
     });
    }    
 }

  isEditable()
  {
    if(this.data.editable && this.data.model==null && this.featurePermission.create)
    {
      return true;
    }
    else if(this.data.editable && this.data.model!=null && this.featurePermission.update)
    {
   
      return true;

    }
    else
    {
      return false;
    }
  }
}
