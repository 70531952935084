export class OrderUpdateRequest {
    supplierId?: number;
    customerId?: number;
    orderId?: number;
    productId?: number;
    lineItemId?: number;
    qunatity?: number;
    unitPrice?: number;
    totalPrice?: number;
    isDeleted?: boolean;
}
export class OrderRequest {
    OrderUpdateRequest: OrderUpdateRequest[];
    buyerId?: number;
    sellerId?: number;
    paymentType?: number;

}
export class OrdersLineItemsDetailsRequest {

    supplierId: number;
    buyerId: number;
}

export class PlainProductInOrders {
    id: number;
    customerInOrder: CustomerInOrder;
    productInOrder: ProductInOrder;
    lineItemInOrder: LineItemInOrder;
    orderItemTotalPrice:number;
    approvedQuantity:number;
    stock:number;
    
}


export class PendingOrdersbyBuyer {
    customerInOrder: CustomerInOrder;
    totalRequested: number;
    totalSellingPrice: number;
    productsLineItemDetails: ProductLineItemDetails[];
    constructor() {

        this.customerInOrder = new CustomerInOrder();
    }

}

export class PendingOrdersbyProduct {
    productInOrder: ProductInOrder;
    totalRequested: number;
    totalSellingPrice: number;
    buyersLineItemDetails: CustomerLineItemDetails[];
    constructor() {

        this.productInOrder = new ProductInOrder();
    }

}


export class CustomerLineItemDetails {

    constructor() {

        this.customerInOrder = new CustomerInOrder();
        this.lineItemInOrder = new LineItemInOrder();


    }

    customerInOrder: CustomerInOrder;
    lineItemInOrder: LineItemInOrder;

}
export class ProductLineItemDetails {

    constructor() {

        this.productInOrder = new ProductInOrder();
        this.lineItemInOrder = new LineItemInOrder();
      
    }
    buyerId: number;
    PaymentType: string;
    productInOrder: ProductInOrder;
    lineItemInOrder: LineItemInOrder;
    stockMax: number;
    editable: boolean;
    selected:boolean;
    disabled:boolean;

}

export class ProductInOrder {
    productId: number;
    supplierId: number;
    productCode: string;
    format: string;
    region: string;
    discription: string;
    stock: number;
    isInfinityStock: boolean;
    grower: string;
    grade: string;
    varietal: string;
    ripness: string;
    category: string;
}

export class LineItemInOrder {

    constructor() {
        this.updatedQuantity = 1;
        this.isDeleted = false;
    }
    lineitemId: number;
    orderId: number;
    orderDate: Date;
    requestedQuantiy: number;
    approvedQuantity: number;
    updatedQuantity: number;
    maxQuantity: number ;
    maxPrice: number;
    isDeleted: boolean;
    soldPrice?: number;
    updatedSoldPrice?: number;
    orderItemTotalPrice?: number;
    status: string;
    soldPriceUpdated: boolean;
    approvedQuantityUpdated: boolean;
    totalPrice?: number;
    invoiceUrl: string;
    pickSlipUrl: string;
}
export class CustomerInOrder {
    customerId: number;
    customerName: string;
    email: string
}

export enum UpdateType {
    Approve = 1,
    Reject,
    UpdateQuantity,
    SetUnitPrice,
    ConfirmAllProductsforCustomer,
    ConfirmCertainProductInAllOrders
  }


