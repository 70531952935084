import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from "ngx-spinner";
import { DataBindingDirective } from "@progress/kendo-angular-grid";
import { SellerService } from 'app/shared/services/Seller.Service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CustomerABNValidator } from 'app/shared/validators/CustomerABNValidator';
import { ExternalCustomerModel } from 'app/shared/models/integration/external.customer.model';
import { SiteModel, ZoneModel } from 'app/shared/models/sites/site.model';


@Component({
  selector: 'app-new-zone',
  templateUrl: './new-zone.dialog.html',
  styleUrls: ['./new-zone.dialog.css']
})
export class NewZoneDialog implements OnInit {
  public opened = true;
  public zoneForm: FormGroup;
  

  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  constructor(public dialogRef: MatDialogRef<NewZoneDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public data: { zone : ZoneModel,editable:boolean}
    ,public dialog: MatDialog
    ,private sellerservice: SellerService,
    public fb: FormBuilder,private snackbar: MatSnackBar,

  ) {
  }


   ngOnInit(): void {
    this.zoneForm=this.fb.group(
      {
        name:["",[Validators.maxLength(250),Validators.minLength(1),Validators.required]]
      }      
    );
    if(this.data.zone){
      this.zoneForm.patchValue({
        name: this.data.zone.name,        
      });
    }
  }

  get f() { return this.zoneForm.controls; }

  public close(): void {   
    console.log('close is called');
    this.dialogRef.close(null);
    this.opened = false;
  }

  save() {
      console.log('onSubmit ', this.zoneForm.value);
      this.spinner.show(); 
      let zoneModel=new  ZoneModel();
      if(this.data.editable)
      {
        zoneModel=this.data.zone;
        zoneModel.name=this.zoneForm.value.name;
      }
      else
      {
        zoneModel= this.zoneForm.value;
      }
      this.spinner.hide(); 
      this.dialogRef.close(zoneModel);
 }
}
