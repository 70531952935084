import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductViewMdel } from 'app/shared/models/catalogue';
import { PromotionViewMdel } from 'app/shared/models/catalogue/promotionViewModel';
import { ProductService } from 'app/shared/services/product.service';
import { NgxSpinnerService } from "ngx-spinner";
import { CellClickEvent, CellCloseEvent, DataBindingDirective, GridDataResult, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { Keys } from '@progress/kendo-angular-common';
import { PromotionsEditService } from './promotions.edit.service';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import { FormBuilder, FormGroup, NgForm, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-promotion-details',
  templateUrl: './promotion-details.dialog.html',
  styleUrls: ['./promotion-details.dialog.css']
})
export class PromotionDetialsDialog implements OnInit {
  calbackcompleted: boolean = false;
  public gridView: GridDataResult;
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public loading: boolean = false;
  public showErrors: boolean = false;
  public selectableSettings: SelectableSettings;
  private formBuilder: FormBuilder;
  // @ViewChild('pormotionForm', {static: true}) pormotionForm: NgForm;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(public dialogRef: MatDialogRef<PromotionDetialsDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public dialogdata: { Product: ProductViewMdel },
    private ProductService: ProductService,
    public editService: PromotionsEditService 
  ) {
    this.gridData = this.dialogdata.Product.batches;
    this.gridView = {
      data: this.gridData,
      total:this.gridData.length,
    };
  }

  ngOnInit(): void {
    // this.loadBatches();
    
  }

  loadBatches() {    
    // this.gridData = this.dialogdata.Product.batches;
    // this.gridView = this.gridData;
  }
  onSelectValueChange() {
  }
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }



  savePromotions() {
    this.calbackcompleted = false;
    this.spinner.show();
    this.ProductService.UpdateBatchPromotions(this.gridView.data).subscribe((result) => {
      this.spinner.hide();      
      this.dialogRef.close(true);      
    });    
  }  

  public cellClickHandler({
    sender,
    column,
    rowIndex,
    columnIndex,
    dataItem,
    isEdited,
  }: CellClickEvent): void {
    if (!isEdited && (column.field != 'id' && column.field != 'total')) {
      sender.editCell(rowIndex, columnIndex, this.createFormGroup(dataItem));
    }
  }
  public createFormGroup(dataItem: BatchViewModel): FormGroup {
    return this.formBuilder.group({
      id: dataItem.id,
      quantity: [dataItem.promotionalPrice, Validators.required],     
    });
  }
  public cellCloseHandler(args: CellCloseEvent): void {
    const { formGroup, dataItem } = args;
    if (!formGroup.valid) {
      // prevent closing the edited cell if there are invalid values.
      args.preventDefault();
    } else if (formGroup.dirty) {
      if (args.originalEvent && args.originalEvent.keyCode === Keys.Escape) {
        return;
      }
      this.editService.assignValues(dataItem, formGroup.value);
      this.editService.update(dataItem);
    }
  }
 checkIsActive(batch:BatchViewModel) {
  if(batch.onPromotion)
  {
   if(batch.promotionStartDate!=null && batch.promotionEndDate!=null)
   {
     return false;
   }
   return true;
  }
  else
  { 
   return false;
  }
 }  
 checkBatches() {
let isValid=false;
   this.gridView.data.forEach((batch)=>{
    if(batch.onPromotion)
    {
     if(batch.promotionStartDate==null || batch.promotionEndDate==null)
     {
      isValid= true;
     }
     
    }
  })
  return isValid;
 
 }
}
