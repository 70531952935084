import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MyobDialog } from 'app/integration/dialogs/myob/myob.dialog';
import { MarketingService } from 'app/shared/services/marketing.service';
import { IntegrationService } from 'app/shared/services/integration.service';
import { CompanyFileViewModel } from 'app/shared/models/integration/companyfile.model';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'environments/environment';
import {ActivatedRoute}  from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { catchError } from 'rxjs/operators';
import { XeroTenantViewModel } from 'app/shared/models/integration/xerotenant.model';
import { XeroDialog } from 'app/integration/dialogs/xero/xero.dialog';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SellerService } from 'app/shared/services/Seller.Service';
import { TimezoneViewModel } from 'app/shared/models/common/TimezoneViewModel';
import { SupplierViewModel } from 'app/shared/models/seller/supplierViewModel';
import { SettingsViewModel } from 'app/shared/models/seller/settingsViewModel';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';


@Component({
  selector: 'general-settings',
  templateUrl: './generalSettings.component.html',
  styleUrls: ['./generalSettings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {  
  showSettings: boolean = true;
  disableMarketplaceSetting: boolean = false;
  featurePermission: FeaturePermission = new FeaturePermission();
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  //bankAccountDetailsForm: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private marketingService: MarketingService,
    private integrationService: IntegrationService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    private sellerservice: SellerService,
    private userService: UserService,
    private snackbar: MatSnackBar,
  ) {}

  generalIsActive : boolean = true;
  bankDetailsIsActive : boolean = false;
  timezones: TimezoneViewModel[];
  settings: SettingsViewModel;

 
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission=userPermissions.filter(x => x.feature == PermissionsConstants.SETTINGS )[0];
  this.showSettings = this.featurePermission.read;
  });
}
  generalForm = this.formBuilder.group({
    timezone:new FormControl(),
    enableMarketplace: new FormControl(null, Validators.required),
  });

  

  

  ngOnInit(): void {   
    this.loadSettings();
  }
  loadSettings()
  {
    this.spinner.show('loading'); 
    this.sellerservice.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.sellerservice.getTimezones().subscribe(data => {
        this.timezones = data;  
           
      }
    );
    this.sellerservice.getSellerSettings().subscribe(data => {
      this.settings = data;
      //this.disableMarketplaceSetting = data.disableMarketplaceSetting;
      var selectedTimezone = data.timezoneId != null ? data.timezoneId : "-1";
      this.generalForm.controls['timezone'].setValue(selectedTimezone) ;  
      this.generalForm.controls['enableMarketplace'].setValue(data.enableMarketplace) ;  
      this.spinner.hide('loading');  
    }
  );
  }
  saveSettings(){

    var sellerSettings: SettingsViewModel =  {
        id: this.settings?.id,
        disableMarketplaceSetting: this.disableMarketplaceSetting,
        timezoneId: this.generalForm.value['timezone'],
        enableMarketplace: this.generalForm.value['enableMarketplace']
    };    
    this.spinner.show('loading'); 
    this.sellerservice.updateSellerSettings(sellerSettings).subscribe( data => {
      this.spinner.hide('loading');     
      if(data)
      {
        this.snackbar.open(' Settings updated successfully', 'close', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.loadSettings();
      }
      else
      {
        this.snackbar.open(`Failed to update  Settings`, 'close', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.spinner.hide('loading');   
      }
    
    },(error)=>{

      this.snackbar.open(`Failed to update Settings due to error ${error.error}`, 'close', {
        duration: 2000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide('loading');   
    }
    );
  }
  
}
