import { Component, OnInit } from '@angular/core';
import { MatDialog,  } from '@angular/material/dialog';
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import { ProductUoM } from 'app/shared/models/catalogue/lockups';
import { NewUoMDialog } from '../dialogs/new-UoM/new-uom.dialog';
import Swal from 'sweetalert2';


@Component({
  selector: '[app-uom-settings]',
  templateUrl: './uom-settings.component.html',
  styleUrls: ['./uom-settings.component.css']
})
export class UoMSetttingsComponent implements OnInit {
  public gridView: GridDataResult;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  featurePermission: FeaturePermission = new FeaturePermission();
  show: boolean = true;

  constructor(
    public dialog: MatDialog,
    private userService: UserService, private sellerService: SellerService
  ) {
  
  }

  ngOnInit(): void {
    this.loading = true;
    this.init();
  }
  
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.SETTINGS )[0];
  console.log(this.featurePermission);
  this.show = this.featurePermission.read;
  });
   
}
  async init() {
    this.loading = true;
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    
     this.sellerService.getUoMSettings(this.currentPage,this.pageSize).subscribe((data) => {
      console.log(data.items)
       this.gridView = {
         data: data.items,
         total: data.totalItems,
       };
       this.loading = false;
     });
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getGrid();
  }
  
  getGrid()
  {
    this.loading = true;
    this.sellerService.getUoMSettings(this.currentPage,this.pageSize).subscribe((data) => {
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;
    });
  }

  
   newUoM(editable:boolean=false,model:ProductUoM=null): void {
    var Data = { editable: editable, model: model }
     const dialogRef = this.dialog.open(NewUoMDialog,{data: Data ,width:'1000px'});
     dialogRef.afterClosed().subscribe(result => {
       this.loading = true;
       this.init();
     });
   }
   deleteUoM(id:number)
   {
    
    this.loading = true;
    this.sellerService.checkUoMIsExistInProducts(id).subscribe((isExist) => {
      if(!isExist)
      {
        this.sellerService.deleteUoM(id).subscribe((data) => {
          this.loading = true;
           this.init();
        });
      }
      else
      {
        Swal.fire({
          icon: 'error',
          title: 'Failed',
          html: "<strong> The selected Unit of Measure can not be deleted, as it is being used by other products or batches, please delete any reference to this Unit of Measure in order to be able to delete it  </strong>",
          showCloseButton: false,
          showCancelButton: false,
          confirmButtonColor: "#0c6acb"
        }).then((result) => {
          this.loading = true;
          this.init();
        })
      }
       
    });
   }
}

