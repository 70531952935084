import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CompanyFileViewModel } from 'app/shared/models/integration/companyfile.model';
import { IntegrationService } from 'app/shared/services/integration.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { IntegrationItemViewModel } from 'app/shared/models/integration/integration.item.model';
import { SellerService } from 'app/shared/services/Seller.Service';

@Component({
  selector: 'integration-items-dialog',
  templateUrl: './integration-items.dialog.html',
  styleUrls: ['./integration-items.dialog.css']

})
export class IntegrationItemsDialog implements OnInit {
  companyFiles: CompanyFileViewModel[];
  selectedCompanyFileId: string;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  externalItems: IntegrationItemViewModel[];
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  public gridData: any[];
  public gridView: any[];
  public mySelection: string[] = [];
  public selectedUID:string[];
  public loading: boolean = false;
  public selectableSettings: SelectableSettings;
  public System: string;
  constructor(public dialogRef: MatDialogRef<IntegrationItemsDialog>,
    @Inject(MAT_DIALOG_DATA) public data: { productId: number , externalId: string},
    private integrationService: IntegrationService,    
    private snackbar: MatSnackBar,
    private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    private sellerService: SellerService
  ) { 
    this.setSelectableSettings();
    
  }
  ngOnInit(): void {
    this.loadItems();
    this.sellerService.getSeller().subscribe((seller) => {
    this.integrationService.GetLinkedApps(seller.id).subscribe((data) => {
      if(data.indexOf("MYOB") >= 0){
        this.System =  "MYOB";
      }
      if(data.indexOf("Xero") >= 0){
        this.System = "Xero";
      }
    });    
  });
  }

  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
      enabled: true,
    };
  }

  keyChange(e){
    this.selectedUID = e;
  }

  public isRowSelected = (e: RowArgs) => e.dataItem.uid == this.data.externalId;

  loadItems() {
    this.loading = true;
    this.integrationService.GetExternalItems(this.data.externalId).subscribe((data) => {       
      this.externalItems = data;
      this.gridData = data;
      this.gridView = this.gridData;
      this.loading = false;
      this.mySelection[0] = this.data.externalId; 
    });
  }
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.uid;
  }
  public onFilter(inputValue: string): void {
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "uID",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "name",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }
  saveSettings() {
    if(this.selectedUID.length <= 0)
    {
      this.snackbar.open('Please select an item in MYOB to link product to', 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    }
    let selected = this.selectedUID.find(x => x != null && x.length > 0);
    this.integrationService.LinkExternalItemToProduct(this.data.productId, selected).subscribe((data) => {             
      this.snackbar.open('Product is linked to MYOB Item successfully', 'close', {
        duration: 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.dialogRef.close({ data: selected});
    }, (error) =>{
      this.snackbar.open(`Failed to link to MYOB Item due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    });    
  }
}
