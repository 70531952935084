import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { MarketingCategoriesDialog } from 'app/customers/dialogs/marketing-categories/marketing-categories.dialog';
import { MarketingCustomersDialog } from 'app/customers/dialogs/marketing-customers/marketing-customers.dialog';
import { MarketingGroupDialog } from 'app/customers/dialogs/marketing-group/marketing-group.dialog';
import { MarketUpdateDialog } from 'app/dialogs/marketUpdate/marketUpdate.dialog';
import { MarketingGroupViewModel, SupplierGroupsViewModel } from 'app/shared/models/promotions/marketing.model';
import { SellerViewModel } from 'app/shared/models/seller';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { MarketingService } from 'app/shared/services/marketing.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { UtilsService } from 'app/shared/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, of, pipe } from "rxjs";
import { map } from "rxjs/operators";

@Component({
  selector: 'app-manage-market',
  templateUrl: './manage-market.component.html',
  styleUrls: ['./manage-market.component.css']
})
export class ManageMarketComponent implements OnInit {
  supplierGroupsViewModel: SupplierGroupsViewModel;
  SupplierId: number;
  showGroups: boolean = true;
  featurePermission: FeaturePermission = new FeaturePermission();
  customerPermission: FeaturePermission = new FeaturePermission();
  inventoryPermission: FeaturePermission = new FeaturePermission();

  public model = {
    editorData: '<p>Enter Content Here!</p>'
  };

  constructor(private AuthService: MsalService, private utilities: UtilsService, private marketingService: MarketingService, 
    public dialog: MatDialog, private snackbar: MatSnackBar, private router: Router,
    private userService: UserService, private sellerService: SellerService, 
    private spinner: NgxSpinnerService) {
  }
  ngOnInit(): void {
    this.initMarketingGroups();
  }


 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.MARKETING )[0];
  this.customerPermission=userPermissions.filter(x => x.feature == PermissionsConstants.CUSTOMERS )[0];
  this.inventoryPermission=userPermissions.filter(x => x.feature == PermissionsConstants.INVENTORY )[0];
  this.showGroups =this.featurePermission.read;   
  });
   
}
  async initMarketingGroups() {
    this.supplierGroupsViewModel = new SupplierGroupsViewModel();
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.marketingService.GetSupplierGroups().subscribe((data) => {
      this.supplierGroupsViewModel = data
      this.SupplierId = data.id;
    });
  }


  getclass(isactive: boolean) {
    if (isactive) {
      return '';
    }

    else { return 'addOverlay'; }
  }
  @HostListener("window:keydown", ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.code == "ArrowRight") {
      this.step++;

    }
    else if (e.code == "ArrowLeft") {
      this.step--;
    }
    
  }
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }


  openmarketupdatesdialog(Id: number): void {

    //debugger;
    const dialogRef = this.dialog.open(MarketUpdateDialog, {
      data: { supplierId: this.SupplierId, marketingGroupId: Id },
      height: '800px',
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {

      this.initMarketingGroups();
    });

  }
  opendialog(editmode: boolean = false, marketingGroup: MarketingGroupViewModel = null): void {
   
    if (!editmode) {
      marketingGroup = new MarketingGroupViewModel();
      marketingGroup.supplierId = this.SupplierId;
    }
    var Data = { editmode: editmode, marketingGroup: marketingGroup }

    const dialogRef = this.dialog.open(MarketingGroupDialog, { data: Data });
    dialogRef.afterClosed().subscribe(result => {
      if(result){
      this.initMarketingGroups();
      }
    });

  }

  deleteGroup(marketingGroup: MarketingGroupViewModel): void {     
    this.spinner.show();
    this.marketingService.deleteMarketingGroup(marketingGroup).subscribe((data) => {
      this.marketingService.GetSupplierGroups().subscribe((data) => {
        this.supplierGroupsViewModel = data
      });
      this.spinner.hide();
    });
  }

  openCategoriesdialog(MarketingGroup: MarketingGroupViewModel = null): void {
    const dialogRef2 = this.dialog.open(MarketingCategoriesDialog, { data: MarketingGroup });
    dialogRef2.afterClosed().subscribe(result => {
      this.initMarketingGroups();
    });
  }

  openMembersdialog(MarketingGroup: MarketingGroupViewModel = null): void {  
    const dialogRef2 = this.dialog.open(MarketingCustomersDialog, { data: MarketingGroup,height: '500px',
    width: '900px', });
    dialogRef2.afterClosed().subscribe(result => {
      this.initMarketingGroups();
    });

  }
}
