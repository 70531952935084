import { NgModule, ModuleWithProviders } from '@angular/core';
import { FormsModule, ReactiveFormsModule, FormBuilder } from '@angular/forms';
import { RouterModule } from '@angular/router';

// Services
import { OrderService } from './services/order.service';
import { UserService } from './services/user.service';
import { ProductService } from './services/product.service';
import { EventEmitterService } from './services/event-emitter.service';
import { SellerService } from './services/Seller.Service';
import { MarketingService } from './services/marketing.service';
import { IntegrationService } from './services/integration.service';
import { DashBoardService } from './services/dashboard.service';
import { OrderItemsEditService } from '../orders/dialogs/manual-order/manual.order.items.edit.service';
import { PromotionsEditService } from '../inventory/promotion-details/promotions.edit.service';
import { UtilsService } from './services/utils.service';
// Pipes:
import { UppercasePipe } from './pipes/uppercase.pipe';
import { AuthorizeService } from './services/authorize.service';
import { DialogService } from '@progress/kendo-angular-dialog';
import { ChartsModule } from "@progress/kendo-angular-charts";
import { ButtonsModule } from "@progress/kendo-angular-buttons";
import { InfinityPipe } from './pipes/infinity.pipe';




@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    // No need to export as these modules don't expose any components/directive etc'
  ],
  declarations: [
    UppercasePipe,
    InfinityPipe
  ],
  exports: [
    UppercasePipe,
    InfinityPipe
  ]
})

export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        { provide: UtilsService },
        { provide: AuthorizeService },
        { provide: OrderService },
        { provide: UserService },
        { provide: MarketingService },
        { provide: IntegrationService },
        { provide: ProductService },
        { provide: SellerService },
        { provide: DashBoardService },
        { provide: OrderItemsEditService },
        { provide: PromotionsEditService },
      ]
    };
  }
}