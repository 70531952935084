import { AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as ReactDOM from 'react-dom';
import * as React from 'react';
import MyReactPaymentComponent from './StripePaymentComponent';
import { SellerCustomerModel } from 'app/shared/models/seller/sellerCustomerModel';
import { environment } from 'environments/environment';

@Component({
  selector: 'stripe-payment',
  templateUrl: './stripe-payment.component.html',
  styleUrls: ['./stripe-payment.component.css']
})
export class StripePaymentComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() customer : SellerCustomerModel;
  public rootId = "rootId";
  
  constructor() { }
  ngAfterViewInit(): void {
    var props = { customerId: this.customer.customerId,phone:this.customer.phone
      ,email:this.customer.email,name:this.customer.name
    };
    this.render(props);
  }
  ngOnChanges(changes: SimpleChanges): void {
    var props = { customerId: this.customer.customerId,phone:this.customer.phone
      ,email:this.customer.email,name:this.customer.name
    };
    this.render(props);
  }
  ngOnDestroy(): void {
      
  }
  ngOnInit() {
  }

  private render(props: any){
    ReactDOM.render(React.createElement(MyReactPaymentComponent,props), document.getElementById(this.rootId));
  }

}
