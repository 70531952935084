import {
    AbstractControl,
    AsyncValidatorFn,
    ValidationErrors,
  } from '@angular/forms';
  import { Observable } from 'rxjs';
  import { map } from 'rxjs/operators';
import { MarketingService } from '../services/marketing.service';
import { CustomerSource } from '../models/customer/customer.model';

  
  export class CustomerABNValidator {
    static createValidator(marketingService: MarketingService,customerId?:number): AsyncValidatorFn {
      return (control: AbstractControl): Observable<ValidationErrors> => {
        return marketingService
          .IsCustomerValidABN(control.value,CustomerSource.Manual,customerId)
          .pipe(
            map((result: boolean) =>
              !result ? { isValid: true } : null
            )
          );
      };
    }
  }