<mat-card>
  <mat-card-header class="mb-3">        
    <mat-card-title class="cardHeader"> Xero settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>    
    <section class="d-flex">      
      <mat-form-field  appearance="fill">
        <mat-label>Tenants</mat-label>
        <mat-select matNativeControl 
          [(ngModel)]="selectedTenantId" [(value)]="selectedTenantId" name="Tenant" required>          
          <mat-option *ngFor="let c of tenants" [value]="c.tenantId">
            {{c.tenantName}} - {{c.tenantType}}
          </mat-option>
        </mat-select>
      </mat-form-field> 
    </section>

  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="saveSettings()" [disabled]="!featurePermission.update" cdkFocusInitial>Save</button>
  </mat-card-actions>
</mat-card>