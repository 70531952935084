<section class="d-flex" >
  <div class="col-md-12">
     <mat-card-title class="cardHeader"> Batches</mat-card-title>       
        <kendo-grid
        #grid
          [kendoGridBinding]="gridView"
          kendoGridSelectBy="id"
          [selectedKeys]="mySelection"
          [loading]="loading" 
          [pageSize]="10"
          [pageable]="true"
          [sortable]="true"
          [groupable]="false"
          [reorderable]="true"
          [resizable]="true"
          [height]="300"
          [columnMenu]="{ filter: true }"
          [selectable]="selectableSettings"
          kendoGridSelectBy="id"
          [kendoGridSelectBy]="mySelectionKey"
          (selectedKeysChange)="keyChange($event)"
          >                          
       <ng-template kendoGridToolbarTemplate>
          <button kendoGridAddCommand (click)="editBatch()" [disabled]="isEditDisabled()">Edit</button>
          <input
            placeholder="Search in all columns..."
            kendoTextBox
            (input)="onFilter($event.target.value)"
          />
          <kendo-grid-spacer></kendo-grid-spacer>        
        
          <kendo-grid-checkbox-column
            [width]="45"
            [headerClass]="{ 'text-center': true }"
            [class]="{ 'text-center': true }"
            [resizable]="false"
            [columnMenu]="false"
            showSelectAll="false"          
            ></kendo-grid-checkbox-column>
        </ng-template>        
        <kendo-grid-column field="id" title="ID" [width]="220" visibility="false">
        </kendo-grid-column>                    
        <kendo-grid-column field="number" title="Number" [width]="220">
        </kendo-grid-column>        
        <kendo-grid-column field="date" title="Date" [width]="220" >          
        </kendo-grid-column>     
        <kendo-grid-column field="format" title="Unit of Measure" [width]="220" >          
        </kendo-grid-column>     
        <kendo-grid-column field="grade.grade" title="Grade" [width]="220">          
        </kendo-grid-column>                         
        <kendo-grid-column field="grower.growerName" title="Grower" [width]="220">          
        </kendo-grid-column>                                             
        <kendo-grid-column field="region" title="Region" [width]="220">          
        </kendo-grid-column>
        <kendo-grid-column field="siteName" title="Site" [width]="220">          
        </kendo-grid-column>
        <kendo-grid-column field="zoneName" title="Zone" [width]="220">          
        </kendo-grid-column>
        <kendo-grid-column field="ripness" title="Ripeness" [width]="220">          
        </kendo-grid-column>
        <kendo-grid-column field="unitPrice" title="Unit Price" format="{0:c2}" [width]="220">          
        </kendo-grid-column>
        <kendo-grid-column field="stockFormatted" title="Quantity" [width]="220">          
        </kendo-grid-column>            
    </kendo-grid>         
  </div>      
</section>