
import { BatchViewModel } from './batchViewModel';
import { ProductCategory, ProductGrade, ProductGrower, ProductRipeness, ProductSize, ProductUoM, ProductVarital } from './lockups'
export class ProductViewMdel {
    constructor() {
        this.grade = new ProductGrade();
        this.category = new ProductCategory();
        this.varietal = new ProductVarital();
        this.grower = new ProductGrower();
        this.productSize = new ProductSize();
        this.productUoM = new ProductUoM();
        this.productRipeness = new ProductRipeness();
    }
    id: number;
    externalId: string;
    productCode: string;
    format: string;
    region: string;
    discription: string;
    ripness: string;
    xeroGUID: string;
    mYOBGUID: string;
    threshold: number;
    unitPrice?: number;
    infinityStock: boolean = false;
    subjectToGST: boolean = false;
    requiresApproval: boolean = false;
    allowZeroStockOrders: boolean = false;
    active: boolean = true;    
    stock: number = 0;
    stockFormatted: string;
    stockThreshold: number;
    // alertStock: number;
    thumbnailUrl: string;
    primaryImageUrl: string;
    productGrowerId: number;
    productSizeId: number;
    productRipenessId: number;
    productUoMId: number;
    productGradeId: number;
    productVarietalId: number;
    productTypeId: number;
    supplierId: number;
    productCategoryId: number
    grower: ProductGrower;
    productSize: ProductSize;
    productUoM?: ProductUoM;
    productRipeness: ProductRipeness;
    grade: ProductGrade;
    varietal: ProductVarital;
    category: ProductCategory;
    edited: boolean = false;
    promotionIsActive: boolean;
    batches: BatchViewModel[];
    activePromotion: boolean;
    minQuantity:number;
    maxQuantity:number;
    tradedBySingleBatch: boolean ;
    // front end only 

}