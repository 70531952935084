import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, Observable, zip } from "rxjs";

import { map } from "rxjs/operators";
import { ManualOrderItemModel } from 'app/shared/models/order/order.model';

const CREATE_ACTION = "create";
const UPDATE_ACTION = "update";
const REMOVE_ACTION = "destroy";

const itemIndex = (item: ManualOrderItemModel, data: ManualOrderItemModel[]): number => {
  for (let idx = 0; idx < data.length; idx++) {
    if (data[idx].id === item.id) {
      return idx;
    }
  }

  return -1;
};

const cloneData = (data: ManualOrderItemModel[]) =>
  data.map((item) => Object.assign({}, item));

@Injectable()
export class OrderItemsEditService extends BehaviorSubject<unknown[]> {
  private data: ManualOrderItemModel[] = [];
  private originalData: ManualOrderItemModel[] = [];
  private createdItems: ManualOrderItemModel[] = [];
  private updatedItems: ManualOrderItemModel[] = [];
  private deletedItems: ManualOrderItemModel[] = [];

  constructor(private http: HttpClient) {
    super([]);
  }

  public read(): void {
    if (this.data.length) {
      return super.next(this.data);
    }

    // this.fetch().subscribe((data) => {
    //   this.data = data;
    //   this.originalData = cloneData(data);
    //   super.next(data);
    // });
  }

  public create(item: ManualOrderItemModel): void {
    this.createdItems.push(item);
    this.data.unshift(item);
    super.next(this.data);
  }

  public clear(): void {
    while(this.data.length > 0){
      this.data.pop();
    }
    this.originalData = [];
    this.createdItems = [];
    this.updatedItems = [];
    this.deletedItems = [];
  }

  public update(item: ManualOrderItemModel): void {
    item.total = item.quantity * item.unitPrice;
    if (!this.isNew(item)) {
      const index = itemIndex(item, this.updatedItems);
      if (index !== -1) {        
        this.updatedItems.splice(index, 1, item);
      } else {
        this.updatedItems.push(item);
      }
    } else {
      const index = this.createdItems.indexOf(item);
      this.createdItems.splice(index, 1, item);
    }
  }

  public remove(item: ManualOrderItemModel): void {
    let index = itemIndex(item, this.data);
    this.data.splice(index, 1);

    if (index >= 0) {
      this.createdItems.splice(index, 1);
    } else {
      this.deletedItems.push(item);
    }

    index = itemIndex(item, this.updatedItems);
    if (index >= 0) {
      this.updatedItems.splice(index, 1);
    }

    super.next(this.data);
  }

  public isNew(item: ManualOrderItemModel): boolean {
    return !item.id;
  }

  public hasChanges(): boolean {
    return Boolean(
      this.deletedItems.length ||
        this.updatedItems.length ||
        this.createdItems.length
    );
  }

  public saveChanges(): void {
    if (!this.hasChanges()) {
      return;
    }

    const completed = [];
    if (this.deletedItems.length) {
      //completed.push(this.fetch(REMOVE_ACTION, this.deletedItems));
    }

    if (this.updatedItems.length) {
      //completed.push(this.fetch(UPDATE_ACTION, this.updatedItems));
    }

    if (this.createdItems.length) {
      //completed.push(this.fetch(CREATE_ACTION, this.createdItems));
    }

    this.reset();

    zip(...completed).subscribe(() => this.read());
  }
  public getData() {
    return this.data;
  }
  public cancelChanges(): void {
    this.reset();

    this.data = this.originalData;
    this.originalData = cloneData(this.originalData);
    super.next(this.data);
  }

  public assignValues(target: unknown, source: unknown): void {
    Object.assign(target, source);
  }

  private reset() {
    this.data = [];
    this.deletedItems = [];
    this.updatedItems = [];
    this.createdItems = [];
  }

  // private fetch(action = "", data?: OrderModel[]): Observable<OrderModel[]> {
  //   return this.http
  //     .jsonp(
  //       `https://demos.telerik.com/kendo-ui/service/Products/${action}?${this.serializeModels(
  //         data
  //       )}`,
  //       "callback"
  //     )
  //     .pipe(map((res) => <OrderModel[]>res));
  // }

  private serializeModels(data?: ManualOrderItemModel[]): string {
    return data ? `&models=${JSON.stringify(data)}` : "";
  }

}