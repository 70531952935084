import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { DataBindingDirective, RowArgs, SelectableSettings } from "@progress/kendo-angular-grid";
import { process } from "@progress/kendo-data-query";
import { UserService } from 'app/shared/services/user.service';
import { UserModel } from 'app/shared/models/user/user.model';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';

@Component({
  selector: 'permissions-dialog',
  templateUrl: './permissions.dialog.html',
  styleUrls: ['./permissions.dialog.css']

})
export class PermissionsDialog implements OnInit {  
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';  
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;
  public tickAll: boolean = true;
  public gridData: any[];
  public gridView: any[];
  public user: UserModel;
  public loading: boolean = false;
  public selectableSettings: SelectableSettings;
  public permissions : FeaturePermission[] = [];
  constructor(public dialogRef: MatDialogRef<PermissionsDialog>,    
    @Inject(MAT_DIALOG_DATA) public data: {isPending: boolean, userId: number, user: UserModel },
    private userService: UserService
  ) { 
    //this.setSelectableSettings();
  }
  ngOnInit(): void {    
    this.user = this.data.user;
    this.loadPermissions();    
  }

  public handleTickAllSwitch() {  
    this.permissions.forEach((data) => {data.create = this.tickAll; data.update = this.tickAll; data.delete = this.tickAll; data.read = this.tickAll});
  }

  public handlePermissionChange() {  
    console.log("handlePermissionChange is called");
    this.permissions.forEach((data) => {console.log(data)});
    this.tickAll = true;
    this.permissions.forEach((data) => {if(! data.create || ! data.update || ! data.delete || ! data.read ) this.tickAll = false; });
  }

  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }

  loadPermissions() {
    this.loading = true;
    this.userService.GetUserPermissions(this.data.user, this.data.isPending,).subscribe((response) => {       
      this.permissions = response;
      this.gridData = response;
      this.gridView = this.gridData;      
      this.permissions.forEach((data) => {if(! data.create || ! data.update || ! data.delete || ! data.read ) this.tickAll = false; });
      this.loading = false;    
    });
  }
  public mySelectionKey(context: RowArgs): string {
    return context.dataItem.uid;
  }  

  public onFilter(inputValue: string): void {
    this.gridView = process(this.gridData, {
      filter: {
        logic: "or",
        filters: [
          {
            field: "uid",
            operator: "contains",
            value: inputValue,
          },
          {
            field: "name",
            operator: "contains",
            value: inputValue,
          },
        ],
      },
    }).data;

    this.dataBinding.skip = 0;
  }

  saveSettings() {    
    this.userService.UpdateUserPermissions(this.data.user, this.data.isPending, this.permissions).subscribe((response) => {       
        this.dialogRef.close();
    });
  }
}
