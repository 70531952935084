<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-header card-header-tabs card-header-success" *ngIf="showIntegrations">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <ul class="nav nav-tabs" data-tabs="tabs">

                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="accounting-integration"
                                            routerLinkActive="active">
                                            Accounting
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="email-integration" routerLinkActive="active">
                                            Email
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="payment-integration" routerLinkActive="active">
                                            Payment
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="webhook-api-integration" routerLinkActive="active">
                                            API
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="showIntegrations">
                        <div class="tab-content">
                            <router-outlet></router-outlet>
                        </div>
                    </div>
                    <div *ngIf="! showIntegrations">
                        <img src="/assets/img/403.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true"
    name="loading">
    <p style="color: white"> Loading ... </p>
</ngx-spinner>