<div class="main-content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-tabs card-header-success">            
            <div class="nav-tabs-navigation">
              <div class="nav-tabs-wrapper">
                  <ul class="nav nav-tabs" data-tabs="tabs">
                      <li class="nav-item">
                          
                          <a class="nav-link"  (click)="getInventory(true)" routerLink="activeProducts" routerLinkActive="active">
                            
                            <i class="material-icons">done_all</i> Active Products
                            <div class="ripple-container"></div>
                          </a>
                                  
                      </li>
                      <li class="nav-item">
                      
                          <a class="nav-link"  (click)="getInventory(false)" routerLink="pendingProducts" routerLinkActive="active">
                            
                              <i class="material-icons"> hourglass_empty</i> Pending Imports
                              <div class="ripple-container"></div>
                          </a>
                      </li>    
                      <li class="nav-item">
                          
                        <a class="nav-link"  routerLink="siteProducts" routerLinkActive="active">
                          
                          <i class="material-icons">location_on</i> Sites
                          <div class="ripple-container"></div>
                        </a>
                                
                    </li>  
                                      
                  </ul>
              </div>
          </div>
          </div>
          <div class="card-body" >
            <div class="row">
              <div class="card-body">          
                  <div class="clearfix"></div>
                  <a href="javascript:void(0)" matTooltip="Add New Product" [matTooltipPosition]="'above'"
                      class="btn btn-primary btn-round mr-10"  (click)="opendialog(false,Product)"
                      [class.disabled]="!createInventory"><i class="material-icons">note_add</i> Add New Product</a>
                  <a href="javascript:void(0)" matTooltip="Stock Take" [matTooltipPosition]="'above'"
                      class="btn btn-primary btn-round"  (click)="openStockTakeDialog()"
                      [class.disabled]="!createInventory"> Stock Take </a>
              </div>
            </div>
            <div class="tab-content">
                <router-outlet></router-outlet>
                
            </div>
          </div>                                  
          
        </div>
      </div>
    </div>
    <kendo-dialog title="Please confirm" *ngIf="opened" (close)="close('cancel')" [minWidth]="250" [width]="200">
      <p style="margin: 30px; text-align: center;">
        Are you sure you want to continue?
      </p>
      <kendo-dialog-actions>
        <button kendoButton (click)="close('no')">No</button>
        <button kendoButton (click)="close('yes')" primary="true">Yes</button>
      </kendo-dialog-actions>
    </kendo-dialog>
  </div>
</div>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading ... </p>
</ngx-spinner>
<ngx-spinner name="pushing" bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box"
  [fullScreen]="true">
  <p style="color: white"> Pushing item ... </p>
</ngx-spinner>