import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';
import { environment } from '../../../environments/environment';
import { UtilsService } from 'app/shared/services/utils.service';
import { SellerViewModel, SupplierViewModel } from 'app/shared/models/seller/index';
import { EMPTY, Observable, ReplaySubject, of } from 'rxjs';
import { catchError, delay, filter, map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { BuyerViewModel } from '../models/promotions/marketing.model';
import { NotificationViewModel } from '../models/seller/notificationViewmodel';
import { LookupViewModel } from '../models/LookupViewModel';
import { TimezoneViewModel } from '../models/common/TimezoneViewModel';
import { InvoiceSettingsViewModel, SettingsViewModel } from '../models/seller/settingsViewModel';
import { StorageService } from './storage.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { UserService } from './user.service';
import { AuthorizeService } from './authorize.service';
import { InteractionStatus } from '@azure/msal-browser';
import { SellerCustomerModel } from '../models/seller/sellerCustomerModel';
import { CustomerModel } from '../models/customer/customer.model';
import { BatchTransferModel, SiteModel, StateModel, ZoneModel } from '../models/sites/site.model';
import { PageModel } from '../models/pageModel';
import { ProductCategory, ProductGrower, ProductRipeness, ProductSize, ProductType, ProductUoM, ProductVarital } from '../models/catalogue/lockups';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class SellerService {
  //  options: RequestOptions;
  headers: Headers;
  apiURl: string;
  private handleError: HandleError;

  public sellerSubject: ReplaySubject<SellerViewModel> = new ReplaySubject(1);
  public sellerFetched = false;
  private seller$: Observable<SellerViewModel> | null = null;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, private storageService: StorageService,
     private authService: MsalService, private msalBroadcastService: MsalBroadcastService) {
    this.handleError = httpErrorHandler.createHandleError('SellerService');
    this.apiURl = environment.apibaseUrl;
  }  

getSeller(): Observable<SellerViewModel> {
  // If seller data is not yet fetched, attempt to fetch it
  if (!this.sellerFetched) {
    this.sellerFetched = true; // Ensure we don't start multiple requests

    const url = `${this.apiURl}/api/v1/sellerInfo`;

    // If user is authenticated, immediately fetch the seller
    if (this.authService.instance?.getActiveAccount() != null || 
        this.authService.instance?.getAllAccounts().length > 0) {

      this.fetchSeller(url);

    } else {
      // If user is not yet authenticated, wait for authentication to complete then fetch the seller
      this.msalBroadcastService?.inProgress$
        .pipe(
          filter((status: InteractionStatus) => status === InteractionStatus.None),
          take(1) // Complete the observable after getting the desired value once
        )
        .subscribe(() => {
          this.fetchSeller(url);
        });
    }
  }
  return !this.seller$ ? this.sellerSubject.asObservable() : this.seller$;
}

private fetchSeller(url: string): void {
  if (!this.seller$) {
    this.http.get<SellerViewModel>(url, httpOptions).pipe(
      catchError(this.handleError<SellerViewModel>('getSellerInfo'))
    )
    .subscribe(seller => {
      this.sellerSubject.next(seller);
      this.seller$ = of(seller);
    });
  }
}

  getAllPaymentTerms(): Observable<LookupViewModel[]> {
    return this.getSeller().pipe(
      switchMap(seller => {
      const url = `${this.apiURl}/api/v1/${seller.id}/Seller/PaymentTerms`;
      return this.http.get<LookupViewModel[]>(url, httpOptions)}),
        catchError(this.handleError<LookupViewModel[]>('getAllPaymentTerms'))
    );
  }

  GetNotifications(): Observable<NotificationViewModel[]> {
    return this.getSeller().pipe(
      switchMap(seller => {
      const url = `${this.apiURl}/api/v1/${seller.id}/Notifications`;    
      return this.http.get<NotificationViewModel[]>(url, httpOptions)}),
      catchError(this.handleError<NotificationViewModel[]>('GetBuyersNotifications'))
    );
  }

  CloseNotification(NotificationViewModel: NotificationViewModel): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Notifications/Read`;
        return this.http.put<boolean>(url, NotificationViewModel, httpOptions)}),
      catchError(this.handleError<boolean>('CloseNotification'))
    );
  }
  getCustomer(CustomerId: number): Observable<CustomerModel> {
    return this.getSeller().pipe(
      switchMap(seller => {
      const url = `${this.apiURl}/api/v1/${seller.id}/Customer?Id=${CustomerId}`;
      return this.http.get<CustomerModel>(url, httpOptions)}),
      catchError(this.handleError<CustomerModel>('getCustomer'))
    );
  }
  getSupplier(): Observable<SellerViewModel> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller`;    
        return this.http.get<SellerViewModel>(url, httpOptions)}),
      catchError(this.handleError<SellerViewModel>('getSupplier'))
    );
  }

  getTimezones(): Observable<TimezoneViewModel[]> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Timezones`;
        return this.http.get<TimezoneViewModel[]>(url, httpOptions)}),
      catchError(this.handleError<TimezoneViewModel[]>('getTimezones'))
    );
  }

  getSellerSettings(): Observable<SettingsViewModel> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Settings`;
        return this.http.get<SettingsViewModel>(url, httpOptions)}),
      catchError(this.handleError<SettingsViewModel>('getSellerSettings'))
    );
  }

  updateSupplier(Supplier: SupplierViewModel): Observable<SupplierViewModel> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller`;    
        return this.http.put<SupplierViewModel>(url, Supplier, httpOptions)}),
      catchError(this.handleError<SupplierViewModel>('updateSupplier'))
    );
  }
  updateSellerSettings(SupplierSettings: SettingsViewModel): Observable<SettingsViewModel> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Settings`;    
        return this.http.put<SettingsViewModel>(url, SupplierSettings, httpOptions)}),
      catchError(this.handleError<SettingsViewModel>('updateSupplier'))
    );
  }
  updateInvoiceSellerSettings(invoiceSettingsViewModel: InvoiceSettingsViewModel): Observable<InvoiceSettingsViewModel> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/InvoiceSettings`;    
        return this.http.put<InvoiceSettingsViewModel>(url, invoiceSettingsViewModel, httpOptions)}),
      catchError(this.handleError<InvoiceSettingsViewModel>('updateSupplier'))
    );
  }

  addSupplier(Supplier: SupplierViewModel): Observable<SupplierViewModel> {
    const url = `${this.apiURl}/api/Seller`;    
    return this.http.post<SupplierViewModel>(url, Supplier, httpOptions).pipe(
      catchError(this.handleError<SupplierViewModel>('addSupplier'))
    );
  }
  getBilling(): Observable<any> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Billing`;
        return this.http.get<string>(url, httpOptions)}),
      catchError(this.handleError<string>('getBilling'))
    );
  }
  newSubscribe(): Observable<any> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Subscribe`;
        return this.http.post<string>(url,null, httpOptions)}),
      catchError(this.handleError<string>('newSubscribe'))
    );
  }
  verifyInvitaion(code: string,email:string): Observable<boolean> {
    const url = `${this.apiURl}/api/v1/Registration/verify?code=${code}&email=${email}`;
    return this.http.get<boolean>(url, httpOptions).pipe(
      catchError(this.handleError<boolean>('verifyInvitaion'))
    );
  }
  IsValidABN(abn:string): Observable<boolean> {
      const url = `${this.apiURl}/api/v1/sellerInfo/IsValidABN?abn=${abn}`;    
      return this.http.get<boolean>(url, httpOptions).pipe(
        catchError(this.handleError<boolean>('IsValidABN'))
      );
    
  }
  stripeIntegration(): Observable<any> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/StripeIntegration`;
        return this.http.get<string>(url, httpOptions)}),
      catchError(this.handleError<string>('stripeIntegration'))
    );
  }
  getSellerCustomer(sellerId?:number): Observable<SellerCustomerModel> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/GetSellerCustomer`;
        return this.http.get<SellerCustomerModel>(url, httpOptions)}),
      catchError(this.handleError<SellerCustomerModel>('getSellerCustomer'))
    );
  }

  getSites(pageNo:number,limit:number): Observable<PageModel<SiteModel>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Sites?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<SiteModel>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<SiteModel>>('getSites'))
    );
  }
  getAllSites(): Observable<SiteModel[]> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Sites/GetAllSites`;
        return this.http.get<SiteModel[]>(url, httpOptions)}),
      catchError(this.handleError<SiteModel[]>('getAllSites'))
    );
  }
  createNewSite(siteModel: SiteModel): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Sites`;
        return this.http.post(url, siteModel, httpOptions)}),
      catchError(this.handleError<any>('createNewSite'))
    );
  }
  updateSite(siteModel: SiteModel): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Sites/${siteModel.id}`;
        return this.http.put(url, siteModel, httpOptions)}),
      catchError(this.handleError<any>('updateSite'))
    );
  }  
  getZonesBySiteId(siteId:number,pageNo:number,limit:number): Observable<PageModel<ZoneModel>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Sites/zonesBySiteId/?siteId=${siteId}`;
        return this.http.get<PageModel<ZoneModel>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ZoneModel>>('getZonesBySiteId'))
    );
    
  }
  getStates(countryCode:string="AU"): Observable<StateModel[]> {
    const url = `${this.apiURl}/api/v1/Lookup/${countryCode}/states`;
    return this.http.get<StateModel[]>(url, httpOptions).pipe(
      catchError(this.handleError<StateModel[]>('getStates'))
    );
    
  }

  getSellerSites(): Observable<SiteModel[]> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Inventory/Sites`;
        return this.http.get<SiteModel[]>(url, httpOptions)}),
      catchError(this.handleError<SiteModel[]>('SiteModel[]'))
    );
  }

  getGrowersSettings(pageNo:number,limit:number): Observable<PageModel<ProductGrower>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Growers?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductGrower>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductGrower>>('getGrowersSettings'))
    );
  }
  getSizesSettings(pageNo:number,limit:number): Observable<PageModel<ProductSize>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Sizes?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductSize>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductSize>>('getSizesSettings'))
    );
  }
  getRipenessSettings(pageNo:number,limit:number): Observable<PageModel<ProductRipeness>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Ripeness?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductRipeness>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductRipeness>>('getRipenessSettings'))
    );
  }
  getUoMSettings(pageNo:number,limit:number): Observable<PageModel<ProductUoM>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/UoM?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductUoM>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductUoM>>('getUoMSettings'))
    );
  }
  createGrower(model: ProductGrower): Observable<ProductGrower> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Growers`;
        return this.http.post(url, model, httpOptions)}),
      catchError(this.handleError<any>('createNewGrower'))
    );
  }
  updateGrower(model: ProductGrower): Observable<ProductGrower> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Growers`;
        return this.http.put(url, model, httpOptions)}),
      catchError(this.handleError<any>('updateGrower'))
    );
  }
  createSize(model: ProductSize): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Sizes`;
        return this.http.post(url, model, httpOptions)}),
      catchError(this.handleError<any>('createSize'))
    );
  }
  updateSize(model: ProductSize): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Sizes`;
        return this.http.put(url, model, httpOptions)}),
      catchError(this.handleError<any>('updateSize'))
    );
  }
  createUoM(model: ProductUoM): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/UoM`;
        return this.http.post(url, model, httpOptions)}),
      catchError(this.handleError<any>('createUoM'))
    );
  }
  updateUoM(model: ProductUoM): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/UoM`;
        return this.http.put(url, model, httpOptions)}),
      catchError(this.handleError<any>('updateUoM'))
    );
  }
  createRipeness(model: ProductRipeness): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Ripeness`;
        return this.http.post(url, model, httpOptions)}),
      catchError(this.handleError<any>('createRipeness'))
    );
  }
  updateRipeness(model: ProductRipeness): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Ripeness`;
        return this.http.put(url, model, httpOptions)}),
      catchError(this.handleError<any>('updateRipeness'))
    );
  }
  deleteUoM(id: number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/UoM?id=${id}`;
        return this.http.delete(url,  httpOptions)}),
      catchError(this.handleError<any>('deleteUoM'))
    );
  }
  deleteGrower(id: number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Growers?id=${id}`;
        return this.http.delete(url,  httpOptions)}),
      catchError(this.handleError<any>('deleteGrower'))
    );
  }
  deleteSize(id: number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Sizes?id=${id}`;
        return this.http.delete(url,  httpOptions)}),
      catchError(this.handleError<any>('deleteSize'))
    );
  }
  deleteRipeness(id: number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Ripeness?id=${id}`;
        return this.http.delete(url,  httpOptions)}),
      catchError(this.handleError<any>('deleteRipeness'))
    );
  }
  getVarietalsSettings(pageNo:number,limit:number): Observable<PageModel<ProductVarital>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Varietals?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductVarital>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductVarital>>('getVarietalsSettings'))
    );
  }
  updateVarietals(model: ProductVarital): Observable<ProductVarital> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Varietals`;
        return this.http.put(url, model, httpOptions)}),
      catchError(this.handleError<any>('updateVarietals'))
    );
  }
  createVarietals(model: ProductVarital): Observable<ProductVarital> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Varietals`;
        return this.http.post(url, model, httpOptions)}),
      catchError(this.handleError<any>('createVarietals'))
    );
  }
  deleteVarietals(id: number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Varietals?id=${id}`;
        return this.http.delete(url,  httpOptions)}),
      catchError(this.handleError<any>('deleteVarietals'))
    );
  }
  checkVarietalIsExistInProducts(id:number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/CheckVarietalIsExistInProducts?id=${id}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('checkVarietalIsExistInProducts'))
    );
  }
  checkRipenessIsExistInProducts(id:number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/CheckRipenessIsExistInProducts?id=${id}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('checkRipenessIsExistInProducts'))
    );
  }
  checkUoMIsExistInProducts(id:number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/CheckUoMIsExistInProducts?id=${id}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('checkUoMIsExistInProducts'))
    );
  }
  checkSizeIsExistInProducts(id:number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/CheckSizeIsExistInProducts?id=${id}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('checkSizeIsExistInProducts'))
    );
  }
  checkGrowerIsExistInProducts(id:number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/CheckGrowerIsExistInProducts?id=${id}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('checkGrowerIsExistInProducts'))
    );
  }
  getCategorySettings(pageNo:number,limit:number): Observable<PageModel<ProductCategory>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Categories?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductCategory>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductCategory>>('getCategorySettings'))
    );
  }
  updateCategory(model: ProductCategory): Observable<ProductCategory> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Categories`;
        return this.http.put(url, model, httpOptions)}),
      catchError(this.handleError<any>('updateCategory'))
    );
  }
  createCategory(model: ProductCategory): Observable<ProductCategory> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Categories`;
        return this.http.post(url, model, httpOptions)}),
      catchError(this.handleError<any>('createCategory'))
    );
  }
  deleteCategory(id: number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Categories?id=${id}`;
        return this.http.delete(url,  httpOptions)}),
      catchError(this.handleError<any>('deleteCategory'))
    );
  }
  checkCategoryIsExistInProducts(id:number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/CheckCategoryIsExistInProducts?id=${id}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('checkCategoryIsExistInProducts'))
    );
  }
  getTypesSettings(pageNo:number,limit:number): Observable<PageModel<ProductType>> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Types?pageNo=${pageNo}&limit=${limit}`;
        return this.http.get<PageModel<ProductType>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<ProductType>>('getTypesSettings'))
    );
  }
  updateType(model: ProductType): Observable<ProductType> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Types`;
        return this.http.put(url, model, httpOptions)}),
      catchError(this.handleError<any>('updateTypes'))
    );
  }
  createType(model: ProductType): Observable<ProductType> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Types`;
        return this.http.post(url, model, httpOptions)}),
      catchError(this.handleError<any>('createTypes'))
    );
  }
  deleteType(id: number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/Types?id=${id}`;
        return this.http.delete(url,  httpOptions)}),
      catchError(this.handleError<any>('deleteTypes'))
    );
  }
  checkTypeIsExistInProducts(id:number): Observable<boolean> {
    return this.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Seller/CheckTypeIsExistInProducts?id=${id}`;
        return this.http.get<boolean>(url, httpOptions)}),
      catchError(this.handleError<boolean>('checkTypeIsExistInProducts'))
    );
  }
}