
import { ProductCategory, ProductGrade, ProductGrower, ProductRipeness, ProductSize, ProductUoM, ProductVarital } from './lockups'
import { ProductViewMdel } from './productViewModel';
export class BatchViewModel {
    constructor() {
        this.grade = new ProductGrade();        
        this.grower = new ProductGrower();
    }
    productId: number;
    product:ProductViewMdel;
    id: number;
    number: string;
    date: Date = null;
    expiryDate: Date = null;
    format: string;
    region: string;    
    productRipeness?: ProductRipeness;
    productRipenessId?:number;
    productUoM?:ProductUoM;
    productUoMId?:number;
    productSize?: ProductSize;
    productSizeId?:number;
    siteId: number;
    siteName: string;
    zoneId: number;
    zoneName: string;
    unitPrice?: number;
    bestPrice?: number;
    bestPriceSource: number;
    unitOfMeasure: string;
    stock: number;    
    stockFormatted: string;    
    ProductCategoryName: string;    
    ProductVarietalName: string;
    ProductSellerName: string;
    productName: string;
    paddock: string;
    // alertStock: number;
    productGrowerId?: number;
    productGradeId?: number;
    productCategoryId: number;
    productVarietalId?: number;    
    productTypeId: number;    
    grower: ProductGrower;
    grade: ProductGrade;
    edited: boolean = false;
    thumbnailUrl: string;
    primaryImageUrl: string;
    onPromotion: boolean = false;
    infinityStock: boolean = false;
    promotionName: string;
    promotionalPrice?: number;
    promotionStartDate?: Date = null;
    promotionEndDate?: Date = null;
    stockTakeCompletionDate?: Date = null;
    parentId: number;
    Parent: BatchViewModel;
    isStockTake:boolean
    // front end only 
}
export class ParentChildBatchModel {
    batches:BatchViewModel[];
    parentBatch:BatchViewModel;
    deductQuantity:number;
}