<div class="col-md-12">
  <mat-card-title class="cardHeader"> Zones</mat-card-title>       
     <kendo-grid
     #grid
       [kendoGridBinding]="gridView"

       [selectedKeys]="mySelection"
       [loading]="loading" 
       [pageSize]="10"
       [pageable]="true"
       [sortable]="true"
       [groupable]="false"
       [reorderable]="true"
       [resizable]="true"
       [height]="300"
       [columnMenu]="{ filter: true }"
       [selectable]="selectableSettings"

       [kendoGridSelectBy]="mySelectionKey"
       (selectedKeysChange)="keyChange($event)"
       >                          
    <ng-template kendoGridToolbarTemplate>
       <button kendoGridAddCommand [disabled]="!this.editable  " (click)="add()" >Add new</button>
       <button kendoGridAddCommand [disabled]="!this.editable || this.selectedUID.length <= 0 " (click)="delete()" >Delete</button>
       <button kendoGridAddCommand [disabled]="!this.editable || this.selectedUID.length <= 0  " (click)="edit()" >Edit</button>
       <input
         placeholder="Search in all columns..."
         kendoTextBox
         (input)="onFilter($event.target.value)"
       />
       <kendo-grid-spacer></kendo-grid-spacer>        
     
       <kendo-grid-checkbox-column
       field="id"
         [width]="45"
         [headerClass]="{ 'text-center': true }"
         [class]="{ 'text-center': true }"
         [resizable]="false"
         [columnMenu]="false"
         showSelectAll="true"          
         ></kendo-grid-checkbox-column>
     </ng-template>           
     
     <kendo-grid-column field="name" title="Name" [width]="220">
     </kendo-grid-column>        
           
 </kendo-grid>         
</div>    