<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-header card-header-tabs card-header-success">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="generalSettings"
                                            routerLinkActive="active">General</a>
                                    </li>
                                    <li class="nav-item" [class.disabled]="!readSitePermssion">
                                        <a class="nav-link" routerLink="sites" routerLinkActive="active">Sites</a>
                                    </li>
                                    <li class="nav-item" [class.disabled]="!readSitePermssion">
                                        <a class="nav-link" routerLink="growers-settings" routerLinkActive="active">Master Data</a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="invoice-settings" routerLinkActive="active">Invoice Settings</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body z-index" *ngIf="showSettings">
                        <div class="tab-content">
                            <div><router-outlet></router-outlet></div>
                        </div>
                    </div>
                    <div *ngIf="! showSettings">
                        <img src="/assets/img/403.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true"
    name="loading">
    <p style="color: white"> Loading ... </p>
</ngx-spinner>