import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-join',
  templateUrl: './join.dialog.html',
  styleUrls: ['./join.dialog.css']
})
export class JoinDialog implements OnInit {
  


  constructor(public dialogRef: MatDialogRef<JoinDialog>,
    @Inject(MAT_DIALOG_DATA) public dialogdata: any,public dialog: MatDialog

  ) {

  }

  ngOnInit(): void {
    Swal.fire({
      icon: 'success',
      title: 'Join Now !',
      html: "<strong> Please contact your organization's admin to get a registration link</strong>",
      showCloseButton: false,
      showCancelButton: false,
      confirmButtonColor: "#0c6acb"
    
    }).then((result) => { this.dialogRef.close(); });

  }

}
