<form [formGroup]="form" (ngSubmit)="onSubmit()" *ngIf="!isLoading" novalidate>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header card-header-success">
              <h4 class="card-title">New Size</h4>
            </div>
            <div class="card-body" >
              <div class="row">
              
                <div class="col-md-12">
                  <div class="row">
            
                    <div class="col-md-6">
                      <mat-form-field class="example-full-width">
                        <input matInput placeholder="Size Name*"  formControlName="size">
                        <mat-error >Invalid Size Name</mat-error>
                      </mat-form-field>
                    </div>

                  </div>
                        
                </div>
              </div>
  

              <div class="row" *ngIf="isEditableForm">
                
                <div class="col-md-3 mr-3 text-center">
                  <button mat-button  type="submit" [disabled]="!form.valid" class="btn btn-primary"  >
                    Save
                </button>
                </div>
                <div class="col-md-2 text-center">
                  <button mat-button class="btn btn-danger" [mat-dialog-close]="true">
                    Cancel
                </button>
                </div>    
            </div>

            </div>
            
          </div>
        </div>
  
      </div>

      
    </div>
  </div>
</form>