<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-header card-header-tabs card-header-success">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">

                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    
                                    <li class="nav-item"  >
                                        <a class="nav-link"   routerLink="active-users" routerLinkActive="active"> <i class="material-icons">done_all</i> Active Users</a>
                                    </li>    
                                    <li class="nav-item"  >
                                        <a class="nav-link"   routerLink="pending-users" routerLinkActive="active"> <i class="material-icons">eco</i> Pending Users</a>
                                    </li> 
                                   
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body" *ngIf="showUsers">
                        <div class="tab-content">
                            <div  ><router-outlet></router-outlet></div>                             
                        </div>
                        <div class="row">
                            <div class="card-body">

                                <div class="clearfix"></div>
                                <a href="javascript:void(0)" (click)="InviteUser()"
                                    [class.disabled]="!featurePermission.create"
                                    class="btn btn-primary btn-round">Invite New User</a>
                            </div>
                        </div>

                    </div>
                    <div *ngIf="! showUsers">
                        <img src="/assets/img/403.png">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading ... </p>
</ngx-spinner>