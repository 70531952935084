import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/throw';

import { environment } from '../../../environments/environment';

import { UtilsService } from 'app/shared/services/utils.service';

import { Observable, ReplaySubject, of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';


import { HttpErrorHandler, HandleError } from 'app/base/http-error-handler.service';
import { HttpParams } from '@angular/common/http';
import { GroupedOrdersSummaryResponse } from '../models/buyer/buyer-orders.model';
import { OrderUpdateRequest, PendingOrdersbyBuyer, PendingOrdersbyProduct, ProductLineItemDetails } from '../models/order/PlainProductInOrders.Model';
import { ManualOrderModel } from '../models/order/order.model';
import { SellerService } from './Seller.Service';
import { InviteUserModel, UserModel } from '../models/user/user.model';
import { FeaturePermission } from '../models/user/featurePermission.model';
import { SellerUsers } from '../models/user/sellerUsers.model';
import { PendingUserPermissions } from '../models/user/pending-user-permissions.model';
import { StorageService } from './storage.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { Constants } from 'msal';
import { PermissionsConstants } from '../permissions.constants';
import { SellerViewModel } from '../models/seller';
import { PageModel } from '../models/pageModel';
import { InteractionStatus } from '@azure/msal-browser';


const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'my-auth-token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UserService {

  headers: Headers;
  apiURl: string;
  private sellerService: SellerService;
  private handleError: HandleError;
  private userPermissions: FeaturePermission[];
  public permissionsSubject: ReplaySubject<FeaturePermission[]> = new ReplaySubject(1);
  public permissionsFetched = false;
  private permissions$: Observable<FeaturePermission[]> | null = null;

  constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler,
    private authService: MsalService, sellerService: SellerService,
    private msalBroadcastService: MsalBroadcastService) {
    this.handleError = httpErrorHandler.createHandleError('SellerService');
    this.apiURl = environment.apibaseUrl;
    this.sellerService = sellerService;
    //this.sellerId = this.sellerService.sellerViewModel.id;
  }

  GetSellerUsers(pageNo:number,limit:number,isActive:boolean=true): Observable<PageModel<UserModel>> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Users?pageNo=${pageNo}&limit=${limit}&isActive=${isActive}`;
        return this.http.get<PageModel<UserModel>>(url, httpOptions)}),
      catchError(this.handleError<PageModel<UserModel>>('GetSellerUsers'))
    );
  }

  GetSellerPendingUsers(): Observable<UserModel[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Users/pending`;
        return this.http.get<UserModel[]>(url, httpOptions)}),
      catchError(this.handleError<UserModel[]>('GetSellerUsers'))
    );
  }

  GetUserPermissions(user : UserModel, ispending: boolean): Observable<FeaturePermission[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        if(ispending){
          const url = `${this.apiURl}/api/v1/${seller.id}/Permissions/PermissionsMapByEmail?email=${user.email}`;
          return this.http.get<FeaturePermission[]>(url, httpOptions).pipe(
            catchError(this.handleError<FeaturePermission[]>('GetUserPermissions'))
          );
        } else {
          const url = `${this.apiURl}/api/v1/${seller.id}/Permissions/PermissionsMap?userId=${user.id}`;
          return this.http.get<FeaturePermission[]>(url, httpOptions).pipe(
            catchError(this.handleError<FeaturePermission[]>('GetUserPermissions'))
          );
        }}));
  }

  UpdateUserPermissions(user : UserModel, ispending: boolean, permissions: FeaturePermission[] ): Observable<FeaturePermission[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        if(ispending)
        {
            var pendingUserPermissions = {
                email: user.email,
                FeaturePermissions : permissions
            };
            const url = `${this.apiURl}/api/v1/${seller.id}/Permissions/PermissionsMap`;
            return this.http.put<FeaturePermission[]>(url, pendingUserPermissions, httpOptions).pipe(
            catchError(this.handleError<FeaturePermission[]>('UpdateUserPermissions'))
          );
        } else {
          const url = `${this.apiURl}/api/v1/${seller.id}/Permissions/${user.id}/PermissionsMap`;
          return this.http.put<FeaturePermission[]>(url, permissions, httpOptions).pipe(
            catchError(this.handleError<FeaturePermission[]>('UpdateUserPermissions'))
          );
        }}));
  }

  InviteUser(users : any[] ): Observable<boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Users/Invite`;
        return this.http.post<boolean>(url, users, httpOptions)}),
      catchError(this.handleError<boolean>('UpdateUserPermissions'))
    );
  }

  UpdateUserLinkStatus(UserId: number, activate: boolean): Observable<UserModel[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Users/${UserId}?activate=${activate}`;
        return this.http.put<UserModel[]>(url, httpOptions)}),
      catchError(this.handleError<UserModel[]>('GetSellerUsers'))
    );
  }

  DeleteUserSellerLink(UserId: number): Observable<UserModel[]> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Users/${UserId}`;
        return this.http.delete<UserModel[]>(url, httpOptions)}),
      catchError(this.handleError<UserModel[]>('GetSellerUsers'))
    );
  }

  DeleteUserIntivation(user: UserModel): Observable<Boolean> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Users/DeleteUserIntivation`;
        return this.http.post<Boolean>(url,user, httpOptions)}),
      catchError(this.handleError<Boolean>('DeleteUserIntivation'))
    );
  }
  
  getUserPermissionsForSeller(sellerId: number): Observable<FeaturePermission[]> {
    // If permission data is not yet fetched, attempt to fetch it
    if (!this.permissionsFetched) {
      this.permissionsFetched = true; // Ensure we don't start multiple requests

      const url = `${this.apiURl}/api/v1/${sellerId}/Permissions/MyFeaturePermissions`;

      // If user is authenticated, immediately fetch the seller
      if (this.authService.instance.getActiveAccount() != null || 
          this.authService.instance.getAllAccounts().length > 0) {
        this.fetchPermissions(url);
      } else {
        // If user is not yet authenticated, wait for authentication to complete then fetch the permissions
        this.msalBroadcastService.inProgress$
          .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            take(1) // Complete the observable after getting the desired value once
          )
          .subscribe(() => {
            this.fetchPermissions(url);
          });
      }
    }
    return !this.permissions$ ? this.permissionsSubject.asObservable() : this.permissions$;
  }

  private fetchPermissions(url: string): void {
    if (!this.permissions$) {
      this.http.get<FeaturePermission[]>(url, httpOptions).pipe(
        catchError(this.handleError<FeaturePermission[]>('GetUserPermissions'))
      )
      .subscribe(permissions => {
        this.permissionsSubject.next(permissions);
        this.permissions$ = of(permissions);
      });
    }
  }
  
  hasPermissionFor(object: string, action:string, sellerId: number): Observable<boolean> {
    return this.getUserPermissionsForSeller(sellerId).pipe(map(userPermissions => {
      //var permissions = userPermissions.map(x => x.map(y => y));
      var objectPermissions = userPermissions.filter(x => x.feature == object )[0];
      switch (action) {
        case PermissionsConstants.CREATE:
          return objectPermissions.create;
          break;
        case PermissionsConstants.UPDATE:
          return objectPermissions.update;
          break;
        case PermissionsConstants.DELETE:
          return objectPermissions.delete;
          break;
        case PermissionsConstants.READ:
          return objectPermissions.read;
          break;      
        default:
          return false;
          break;
      } 
    }));    
  }

  getInvitationLink(): Observable<any> {
    return this.sellerService.getSeller().pipe(
      switchMap(seller => {
        const url = `${this.apiURl}/api/v1/${seller.id}/Users/GetInvitationLink`;
        return  this.http.get<any>(url, httpOptions)}),
      catchError(this.handleError<any>('GetInvitationLink'))
    );      
  }
}
