import { StateModel } from "../sites/site.model"
import { AddressTypeModel } from "./customer_address.model"

export class SellerAddressModel {

  id: number
  customerId: number
  street:string
  addressLine1 :string
  addressLine2 :string
  addressLine3 :string
  city :string
  postalCode :string
  mobile :string
  phone :string
  isDefault :boolean
  stateId :number
  countryId :number=169
  state:StateModel
  addressTypeId :number
  additionalNumber:string
  AddressType:AddressTypeModel

}

