<mat-card>

  <mat-card-content>     
    <section class="d-flex" >
      <div class="col-md-12">
         <mat-card-title class="cardHeader">Child Batches</mat-card-title>       
            <kendo-grid id="my-grid"
              [kendoGridBinding]="gridView"
         
              kendoGridSelectBy="id"
              [selectedKeys]="mySelection"
              [loading]="loading" 
              [pageSize]="10"
              [pageable]="true"
              [sortable]="true"
              [groupable]="false"
              [reorderable]="true"
              [resizable]="true"
              [height]="300"
              [columnMenu]="{ filter: true }"
              [selectable]="selectableSettings"
              kendoGridSelectBy="id"
              [kendoGridSelectBy]="mySelectionKey"
              (selectedKeysChange)="keyChange($event)"
              >                          
           <ng-template kendoGridToolbarTemplate>
            <button kendoGridAddCommand (click)="addNewBatch()" >Add new child batch</button>
              <button kendoGridAddCommand (click)="editBatch()" [disabled]="isEditDisabled()">Edit child batch</button>            
              <button kendoGridAddCommand (click)="deleteBatch()"  [disabled]="isEditDisabled()">Delete child batch</button>              
              <kendo-grid-spacer></kendo-grid-spacer>        
            
              <kendo-grid-checkbox-column
                [width]="45"
                [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }"
                [resizable]="false"
                [columnMenu]="false"
                showSelectAll="false"          
                ></kendo-grid-checkbox-column>
            </ng-template>        
                           
            <kendo-grid-column field="number" title="Number" [width]="220">
            </kendo-grid-column>    
            <kendo-grid-column field="product.productCode"title="Product Code" [width]="220">
            </kendo-grid-column>      
            <kendo-grid-column field="date" title="Date" [width]="220" > 
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span>
            {{dataItem.date | date: 'dd/MM/yyyy'}} 
                </span>
              </ng-template>         
            </kendo-grid-column>     
            <kendo-grid-column field="expiryDate" title="Expiry Date" [width]="220">     
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <span>
            {{dataItem.expiryDate | date: 'dd/MM/yyyy'}} 
                </span>
              </ng-template>         
            </kendo-grid-column>     
            <kendo-grid-column field="data" title="Unit of Measure" class='k-text-center font-weight-bold' [width]="220"
            headerClass="k-text-center font-weight-bold"> 
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span>
                {{dataItem.productUoM?.uoM}}
              </span>
            </ng-template>  
          </kendo-grid-column>  
            <kendo-grid-column field="grade.grade" title="Grade" [width]="220">          
            </kendo-grid-column>                         
            <kendo-grid-column field="grower.growerName" title="Grower" [width]="220">          
            </kendo-grid-column>                                             
            <kendo-grid-column field="region" title="Region" [width]="220">          
            </kendo-grid-column>
            <kendo-grid-column field="paddock" title="Paddock" [width]="220">          
            </kendo-grid-column>
            <kendo-grid-column field="siteName" title="Site" [width]="220">          
            </kendo-grid-column>
            <kendo-grid-column field="zoneName" title="Zone" [width]="220">          
            </kendo-grid-column>
           
            <kendo-grid-column field="data" title="Ripeness" class='k-text-center font-weight-bold' [width]="220"
            headerClass="k-text-center font-weight-bold"> 
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span>
          {{dataItem.productRipeness?.ripeness}}
              </span>
            </ng-template>  
            </kendo-grid-column>
            <kendo-grid-column field="data" title="Size" class='k-text-center font-weight-bold' [width]="220"
            headerClass="k-text-center font-weight-bold"> 
            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
              <span>
                {{dataItem.productSize?.size}}
              </span>
            </ng-template>  
            </kendo-grid-column>
            <kendo-grid-column field="unitPrice" title="Unit Price" format="{0:c2}" [width]="220">          
            </kendo-grid-column>
            <kendo-grid-column field="stock" title="Quantity" [width]="220">          
            </kendo-grid-column>      
                  
        </kendo-grid>         
      </div>      
    </section>
    <section class="d-flex" style="margin-top: 10mm;">      
      <mat-form-field class="col-md-4">
        <mat-label>Quantity to deduct from parent batch <span class="text text-primary font-weight-bold">(UoM: {{dialogdata.Batch.productUoM.uoM}})</span> </mat-label> 
        <input required matInput  [(ngModel)]="deductQty" name="deductQty"  appDigitOnly type="text"    />
        <span>
         
        <mat-error *ngIf="!quantityIsValid() "> Quantity is more than batch stock</mat-error>
      </span>
      </mat-form-field>      
    </section>    
  </mat-card-content>
  <mat-card-actions align="end">
    <!-- <mat-error *ngIf="showErrors">
      Please enter all mandatory fields
    </mat-error> -->
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="save()" [disabled]="!formIsValid()">Save</button>
  </mat-card-actions> 

</mat-card>
