import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CustomerModel } from 'app/shared/models/buyer/customer.model';
import { ProductCategory } from 'app/shared/models/catalogue';

import { MarketingGroupViewModel, SupplierGroupsViewModel, SupplierTrustedNetworks, SupplierTrustedNetworksViewModel } from 'app/shared/models/promotions/marketing.model';
import { MarketingService } from 'app/shared/services/marketing.service';
import { ProductService } from 'app/shared/services/product.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-marketing-customers',
  templateUrl: './marketing-customers.dialog.html',
  styleUrls: ['./marketing-customers.dialog.css']
})
export class MarketingCustomersDialog implements OnInit  {
  MarketingGroup: MarketingGroupViewModel
  customers: any= [];
   customersModel: CustomerModel[]=[];
  supplierGroupsViewModel: any;
  newproduct: any;
  calbackcompleted: boolean;
   supplierTrustedNetworks: SupplierTrustedNetworks[];
  public gridView: SupplierTrustedNetworks[];
  
  constructor(
    public dialogRef: MatDialogRef<MarketingCustomersDialog>,
    @Inject(MAT_DIALOG_DATA) public data: MarketingGroupViewModel, private marketingService: MarketingService, private productService: ProductService,private spinner: NgxSpinnerService) {
    
    this.MarketingGroup = data;

  }
  ngOnInit(): void {
    this.loadUsers();
  }

  loadUsers() {    
    this.marketingService.GetMarketingGroupMembers(this.MarketingGroup.id).subscribe((result) => {
      this.supplierTrustedNetworks = result.supplierTrustedNetworks.filter(x => x.status == 'approved');      
       this.gridView = this.supplierTrustedNetworks;
    });
  }
  getSupplierNetworkforSupplier(): void {

  }

  // activateDeactivate(): void {
  //   this.marketingService.ActivateOrDeactivateMarketingGroup(this.MarketingGroup);
  // }
  save(): void {
    this.spinner.show();
     this.supplierGroupsViewModel = new SupplierGroupsViewModel();
    let customer= this.gridView.filter(x => x.selected).map(s=>s.customer);
     this.calbackcompleted = false;

     this.marketingService.updateMarketingGroupMembers(this.MarketingGroup.id, customer).subscribe((data) => {
       this.marketingService.GetSupplierGroups().subscribe((data) => {
         this.supplierGroupsViewModel = data
         this.spinner.hide();      
      this.dialogRef.close(true);    

       });

     },
       error => {
         


       }
     );

     this.waites();

     this.dialogRef.close(true);

  }
  waites() {
    setTimeout(() => {
      if (!this.calbackcompleted) {
        this.waites();
      }
      //<<<---using ()=> syntax
    }, 3000);
  }


  
}
