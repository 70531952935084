<mat-card>
  <mat-card-header class="mb-3">        
    <mat-card-title class="cardHeader"> {{System}} Items</mat-card-title>
  </mat-card-header>
   
       
        <kendo-grid
          [data]="gridView"
          kendoGridSelectBy="uid"
          [selectedKeys]="mySelection"
          [loading]="loading" 
          [pageSize]="20"
          [pageable]="true"
          [sortable]="true"
          [groupable]="false"
          [reorderable]="true"
          [resizable]="true"
          [height]="500"
          [columnMenu]="{ filter: true }"
          [selectable]="selectableSettings"
          kendoGridSelectBy="uid"
          [kendoGridSelectBy]="mySelectionKey"
          (selectedKeysChange)="keyChange($event)"
          
          >            
        <ng-template kendoGridToolbarTemplate>
          <input
            placeholder="Search in all columns..."
            kendoTextBox
            (input)="onFilter($event.target.value)"
          />
          <kendo-grid-spacer></kendo-grid-spacer>        
        </ng-template>
        <kendo-grid-checkbox-column
          [width]="45"
          [headerClass]="{ 'text-center': true }"
          [class]="{ 'text-center': true }"
          [resizable]="false"
          [columnMenu]="false"
          showSelectAll="false"          
          ></kendo-grid-checkbox-column>
        <kendo-grid-column field="uid" title="UID" [width]="220" visibility="false">
        </kendo-grid-column>        
        <kendo-grid-column field="number" title="Number" [width]="220">          
        </kendo-grid-column>  
        <kendo-grid-column field="name" title="Name" [width]="220">          
        </kendo-grid-column>                      
        <kendo-grid-column field="description" title="Description" [width]="220">          
        </kendo-grid-column>        
        <kendo-grid-column field="unitPrice" title="Unit Price" [width]="220">          
        </kendo-grid-column>                     
        <kendo-grid-column field="quantityAvailable" title="Quantity Available" [width]="220">          
        </kendo-grid-column>                     
            
    </kendo-grid> 


  
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button (click)="saveSettings()" cdkFocusInitial>Save</button>
</mat-card>