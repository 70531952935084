<!-- 
<router-outlet *ngIf="!isIframe"></router-outlet> -->


<div class="wrapper">
  <div class="sidebar" data-color="blue" data-background-color="white" data-image="./assets/img/sidebar-1.jpg">
    <app-sidebar *ngIf="Auth.loggedIn" [loggedIn]="Auth.loggedIn" [username]="Auth.UserName" (editProfileClicked)="editProfile()"
      (logoutClicked)="logout()"></app-sidebar>
    <div class="sidebar-background" style="background-image: url(./assets/img/sidebar-4.jpg)"></div>
  </div>
  <div class="main-panel">
    <alert></alert>
    <app-navbar [loggedIn]="Auth.loggedIn" [username]="Auth.UserName" (editProfileClicked)="editProfile()"
      (logoutClicked)="logout()"></app-navbar>.

    <router-outlet></router-outlet>
    <app-footer></app-footer>
  </div>
</div>
