import { Component, OnInit } from '@angular/core';
import { LinksModel } from 'app/shared/models/common/LinksModel';
import { Router } from '@angular/router';


@Component({
  selector: 'app-master-data-dropdown-settings',
  templateUrl: './master-data-dropdown-settings.component.html',
  styleUrls: ['./master-data-dropdown-settings.component.css']
})
export class MasterDataDropDownComponent implements OnInit {
  selectedLink : string;
  links : LinksModel[] = [
    { pathLink : 'settings/sizes-settings', label : 'Sizes', id: 1 },
    { pathLink : 'settings/growers-settings', label : 'Growers', id: 2},
    { pathLink : 'settings/uom-settings', label : 'UoM', id: 3},
    { pathLink : 'settings/ripeness-settings', label : 'Ripeness', id: 4},
    { pathLink : 'settings/varietals-settings', label : 'Varietals', id: 5},
    { pathLink : 'settings/category-settings', label : 'Category', id: 6},
    { pathLink : 'settings/types-settings', label : 'Types', id: 7}
  ];

  constructor(
    private router : Router
  ) {
  
  }

  ngOnInit(): void {
  
  }
  routeToLink (link: any) {
    this.router.navigate([link]);

 }
}

