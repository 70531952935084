import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { IntegrationService } from 'app/shared/services/integration.service';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from 'environments/environment';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';

@Component({
  selector: 'app-email-integration',
  templateUrl: './email-integration.component.html',
  styleUrls: ['./email-integration.component.css']
})
export class EmailIntegrationComponent implements OnInit {
  googleOauthURL: string;
  outlookOauthURL: string;
  exchangeOauthURL: string;
  myobCode: string;
  apiURL: string;
  isLinkedToGoogle: boolean;
  isLinkedToOutlook: boolean;
  private handleError: HandleError;
  accountingIsActive: boolean = true;
  emailIsActive: boolean;
  isLinkedToMicrosoft: boolean;
  showIntegrations: boolean = true;
  constructor(

    private integrationService: IntegrationService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    httpErrorHandler: HttpErrorHandler,
    private userService: UserService, private sellerService: SellerService
  ) {
    this.googleOauthURL = environment.googleOauthUrl;
    this.googleOauthURL = environment.googleOauthUrl;
    this.outlookOauthURL = environment.outlookOauthUrl;
    this.exchangeOauthURL = environment.exchangeOauthUrl;
    this.apiURL = environment.apibaseUrl;
    this.handleError = httpErrorHandler.createHandleError('ChatService');
  }

  loadPermissions(seller: SellerViewModel) {
    this.userService.hasPermissionFor(PermissionsConstants.INTEGRATION, PermissionsConstants.READ, seller.id).subscribe(x => { this.showIntegrations = x });
  }

  ngOnInit(): void {
    this.spinner.show('loading');
    this.route.queryParams
      .subscribe(params => {
        this.myobCode = params.code;
      }
      );
    var googleCode = localStorage.getItem("googleCode");
    var microsoftCode = localStorage.getItem("microsoftCode");
    if (microsoftCode) {
      this.emailIsActive = true;
      this.accountingIsActive = false;
      this.authenticateToMicrosoft(microsoftCode);
    } else if (googleCode) {
      this.emailIsActive = true;
      this.accountingIsActive = false;
      this.authenticateToGoogle(googleCode);
    } else {
      this.getLinkedApps();
    }
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
  }

  authenticateToMicrosoft(code: string): any {
    this.integrationService.LinkMicrosoftAccount(code).subscribe((data) => {
      localStorage.removeItem("microsoftCode");
      this.spinner.hide('loading');
      this.isLinkedToMicrosoft = true;
    }, (err: HttpErrorResponse) => {
      console.log(err);
      this.spinner.hide('loading');
      localStorage.removeItem("microsoftCode");
    });
  }

  authenticateToGoogle(code: string): any {
    this.integrationService.LinkGoogleAccount(code).subscribe((data) => {
      localStorage.removeItem("googleCode");
      this.spinner.hide('loading');
      this.isLinkedToGoogle = true;
    }, (err: HttpErrorResponse) => {
      console.log(err);
      this.spinner.hide('loading');
      localStorage.removeItem("googleCode");
    });
  }

  getLinkedApps(): any {
    this.sellerService.getSeller().subscribe((seller) => {
      this.integrationService.GetLinkedApps(seller.id).subscribe((data) => {
        if (data.indexOf("Gmail") >= 0) {
          this.isLinkedToGoogle = true;
        }
        if (data.indexOf("Outlook") >= 0) {
          this.isLinkedToOutlook = true;
        }
        if (data.indexOf("Microsoft") >= 0) {
          this.isLinkedToMicrosoft = true;
        }
      });
      this.spinner.hide('loading');
    });
  }



}
