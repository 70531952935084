import { Component, OnInit, HostListener } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { InvoiceDialog } from 'app/orders/dialogs/invoice/invoice.dialog';
import { OrderItemDialog } from 'app/orders/dialogs/order-item/order-item.dialog';
import { PickSlipDialog } from 'app/orders/dialogs/pickslip/pickslip.dialog';
import { GroupedOrdersSummaryResponse } from 'app/shared/models/buyer/buyer-orders.model';
import { OrderSummaryResponse } from 'app/shared/models/order/order.model';
import { NgxSpinnerService } from "ngx-spinner";
import {ActivatedRoute}  from '@angular/router';
import { OrderService } from 'app/shared/services/order.service';
import { OrderUpdateRequest } from 'app/shared/models/order/PlainProductInOrders.Model';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { GridDataResult, PageChangeEvent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { groupBy, GroupDescriptor } from '@progress/kendo-data-query';
import { OrderItemsFulFillmentDialog } from '../dialogs/fulfill-order-items/order-items-fulfillment.dialog';

@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.css']
})
export class TrackingComponent implements OnInit {
  public gridView: GridDataResult;
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public groups: GroupDescriptor[] = [{ field: "orderDate" }];
  SupplierId: number;
  TrackingOrders: GroupedOrdersSummaryResponse[];
  newstock: number = 1;
  startDate: Date = new Date();
  endDate: Date = new Date();
  statusFilter: string = "-1";
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  InvoiceDialogRef: MatDialogRef<InvoiceDialog>;
  PickSlipdialogRef: MatDialogRef<PickSlipDialog, any>;
  showOrders: boolean = true;
  constructor(
    private OrderService: OrderService, public dialog: MatDialog, private spinner: NgxSpinnerService, private route: ActivatedRoute,
    private userService: UserService, private sellerService: SellerService
  ) { }
  loadPermissions(seller: SellerViewModel) {   
    this.userService.hasPermissionFor(PermissionsConstants.ORDERS, PermissionsConstants.READ, seller.id).subscribe(x => {this.showOrders = x});    
 }
  ngOnInit(): void {    
    this.statusFilter = "-1";
    this.startDate.setDate(this.startDate.getDate()-5);    
    this.route.queryParams.subscribe(
      params => {
        this.statusFilter =  params['status']? params['status'] : "-1";        
      }
    )
    if( this.statusFilter != "-1" ) {
        this.startDate = null;
        this.endDate = null;
    }
    this.initOrdersTracking();
  }
  async initOrdersTracking() {
    //this.spinner.show();
    this.loading = true;
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
    this.getOrders(1,this.pageSize);
  }
  @HostListener("window:keydown", ['$event'])
  onKeyDown(e: KeyboardEvent) {
    if (e.code == "ArrowRight") {
      this.step++;

    }
    else if (e.code == "ArrowLeft") {
      this.step--;
    }
  }
  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep(): void {
    this.step++;
  }

  prevStep() {
    this.step--;
  }

  getOrders(pageNo:number,limit:number): void {
    this.OrderService.FilterOrdersforSupplier(this.startDate, this.endDate, parseInt(this.statusFilter),pageNo,limit).
      subscribe((data) => {
        let groupData=groupBy(data.items, this.groups);
        // this.TrackingOrders = data.items;
        this.gridView = {
          data: groupData,
          total: data.totalItems,
        };
        
        //this.spinner.hide();
        this.loading = false;
      }
      );

  }
  opendialog(OrderdeItem: OrderSummaryResponse): void {
    var obj = {
      supplierId: this.SupplierId,
      orderId: OrderdeItem.orderId,
      customerId: OrderdeItem.customerId,
      note: OrderdeItem.note,
      deliveryType: OrderdeItem.deliveryType,
      bay: OrderdeItem.bay,
      address: OrderdeItem.address,
      state: OrderdeItem.state,
      city: OrderdeItem.city,
      postalCode: OrderdeItem.postalCode,
      customerName: OrderdeItem.customerName,
      orderItemsTotalPrice: OrderdeItem.orderItemsTotalPrice
    }


    const dialogRef=this.dialog.open(OrderItemDialog, { data: obj,height: '80%',
    width: '80%' });
    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(this.currentPage,this.pageSize);
    });

  }
  openInvoiceDialog(OrderdeItem: OrderSummaryResponse) {
    var obj = {
      supplierId: this.SupplierId,
      orderId: OrderdeItem.orderId,
      customerId: OrderdeItem.customerId,
      customerName: OrderdeItem.customerName,
      orderItemsTotalPrice: OrderdeItem.orderItemsTotalPrice
    }
    this.InvoiceDialogRef = this.dialog.open(InvoiceDialog, { data: obj });
    this.InvoiceDialogRef.afterClosed().subscribe(result => {

      this.InvoiceDialogRef = null;
    });
  }

  openPickSlipdialog(OrderdeItem: OrderSummaryResponse) {
    var obj = {
      supplierId: this.SupplierId,
      orderId: OrderdeItem.orderId,
      customerId: OrderdeItem.customerId,
      customerName: OrderdeItem.customerName,
      orderItemsTotalPrice: OrderdeItem.orderItemsTotalPrice
    }
    this.PickSlipdialogRef = this.dialog.open(PickSlipDialog, { data: obj });
    this.PickSlipdialogRef.afterClosed().subscribe(result => {

      this.PickSlipdialogRef = null;
    });
  }
  fulfillOrder(orderItem: OrderSummaryResponse, index:number, orderIndex:number) {

    var orderUpdateRequest = new OrderUpdateRequest();
    orderUpdateRequest.orderId = orderItem.orderId;
    orderUpdateRequest.supplierId = this.SupplierId;
    this.spinner.show();
    this.OrderService.MarkOrderAsFulfilled(orderUpdateRequest).
      subscribe((data) => {
        orderItem.orderStatus = 'Fulfilled';
        //this.TrackingOrders[orderIndex].orders[index] = orderItem;
        this.spinner.hide();
      }
      );
  }

  getclass(status: string): string {
    switch (status) {
      case "Awaiting Confirmation":
        return 'btn btn-warning btn-sm'
        break;
      case "Confirmed":
        return 'btn btn-primary btn-sm'
        break;
      case "Rejected":
        return 'btn btn-danger btn-sm'
        break;
        case "Partially Fulfilled":
        return 'btn btn-primary btn-sm'
        break;
      default:
        return 'btn btn-success btn-sm'
        break;
    }
    
  }
  hasInvoice(invoiceUrl: string): string {
    if(invoiceUrl==='' && invoiceUrl==null)
    {
      return 'btn disabled';
    }
    
  }

  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }

  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getOrders(this.currentPage,this.pageSize);
  }
  public groupChange(groups: GroupDescriptor[]): void {
    this.groups = groups;
    this.getOrders(this.currentPage,this.pageSize);
  }
  openFulfillDialog(OrderdeItem: OrderSummaryResponse): void {
    var obj = {
      supplierId: this.SupplierId,
      orderId: OrderdeItem.orderId,
      customerId: OrderdeItem.customerId,
      note: OrderdeItem.note,
      deliveryType: OrderdeItem.deliveryType,
      bay: OrderdeItem.bay,
      address: OrderdeItem.address,
      state: OrderdeItem.state,
      city: OrderdeItem.city,
      postalCode: OrderdeItem.postalCode,
      customerName: OrderdeItem.customerName,
      orderItemsTotalPrice: OrderdeItem.orderItemsTotalPrice
    }


    const dialogRef=this.dialog.open(OrderItemsFulFillmentDialog, { data: obj,height: '80%',
    width: '80%' });
    dialogRef.afterClosed().subscribe(result => {
      this.getOrders(this.currentPage,this.pageSize);
    });

  }
}
