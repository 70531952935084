import { Inject, Injectable, EventEmitter, Output, Type } from '@angular/core';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AccountInfo, AuthenticationResult, EventMessage, EventMessageUtils, EventType, InteractionStatus, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { b2cPolicies } from 'app/config/app-config';
import { ReplaySubject, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { SellerService } from './Seller.Service';
import { Account } from "app/shared/models/account"
import { UtilsService } from './utils.service';
import { promise } from 'protractor';
import { environment } from 'environments/environment';
import { productFruits } from 'product-fruits';
import { UserService } from './user.service';
@Injectable({
    providedIn: 'root'
})
export class AuthorizeService {

    calbackcompleted = false;
    loggedIn = false;
    UserName = '';

    private readonly _destroying$ = new Subject<void>();
    constructor(
        @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
        private authService: MsalService,
        private utilities: UtilsService,
        private sellerService: SellerService,
        private userService: UserService) { }

    updateLoggedInStatus() {      
        this.authService.instance.addEventCallback((event) => {                 
            switch (event.eventType) {
                case EventType.LOGIN_SUCCESS:
                    const account = event.payload.account;          
                    if(event.payload.state!=null && event.payload.state !== '')
                    {
                        let accounts = this.authService.instance.getAllAccounts();
                        let currentActiveAccount: Account = accounts[0];
                        this.authService.instance.setActiveAccount(currentActiveAccount);
                        let email= this.authService.instance.getActiveAccount().idTokenClaims['emails'];
                        let inviationCode=event.payload.state;
                        this.sellerService.verifyInvitaion(inviationCode,email).subscribe((res)=>{window.open(environment.sellerWebUrl,"_self");})
                    }  

                case EventType.ACQUIRE_TOKEN_SUCCESS:   
                    if (event.payload.account) {
                        this.setLoggedIn();
                        this.checkAndSetActiveAccount();
                        this.utilities.AddObjectToSession("AccessToken", event.payload['accessToken']);                        
                    }
                    break;
                case EventType.LOGOUT_END:
                    this.utilities.RemoveObjectfromSession("AccessToken");
                    break;
                case EventType.LOGIN_FAILURE:
                    this.loginWithRedirect();
                    break;
                case EventType.HANDLE_REDIRECT_END:
                    break;                
                default:
                    break;
            }
        });


    }

    login() {
        if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
            this.loginWithPopup();
        } else {
            this.loginWithRedirect();
        }
    }
    editProfile() {
        let editProfileFlowRequest = {
            scopes: ["openid"],
            authority: environment.editProfileAuthority,//b2cPolicies.authorities.editProfile.authority,
        };
        this.authService.loginRedirect(editProfileFlowRequest);

    }

    public getToken() {

        return this.utilities.getObjectFromSession('AccessToken');
    }

    getActiveAccount(): AccountInfo | null {
        return this.authService.instance.getActiveAccount();
    }

    private checkAndSetActiveAccount() {
        /**
        * If no active account set but there are accounts signed in, sets first account to active account
        * To use active account set here, subscribe to inProgress$ first in your component
        * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
        */
        let activeAccount = this.authService.instance.getActiveAccount();

        if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
            let accounts = this.authService.instance.getAllAccounts();
            let currentActiveAccount: Account = accounts[0];
            this.authService.instance.setActiveAccount(currentActiveAccount);
            //this.sellerService.getSeller();
            //this.userService.setUserPermissions();                        
            productFruits.init('4aH1BICwaD2Z2A5q', 'en', { username: currentActiveAccount.idTokenClaims['emails']}, { disableLocationChangeDetection: true });
        }


    }

    public isloggedIn(): boolean {
        return this.authService.instance.getAllAccounts().length > 0
    }

    private setLoggedIn() {
        this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
        let account = this.authService.instance.getAllAccounts()[0];
        this.UserName = account.idTokenClaims["given_name"];
    }

    private loginWithPopup() {
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);

                });
        } else {
            this.authService.loginPopup()
                .subscribe((response: AuthenticationResult) => {
                    this.authService.instance.setActiveAccount(response.account);
                });
        }
    }

    private loginWithRedirect() {
        this.clearCache();
        if (this.msalGuardConfig.authRequest) {
            this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
        } else {
            this.authService.loginRedirect();
        }
    }

    logout() {        
        this.clearCache();
        this.authService.logout({
            postLogoutRedirectUri: environment.postLogoutRedirectUri
          });
    }

    destroy() {
        this._destroying$.next(undefined);
        this._destroying$.complete();
    }

    clearCache(): void {
        // Clear your cached data
        this.sellerService.sellerFetched = false;
        this.sellerService.sellerSubject = new ReplaySubject(1); // reset the ReplaySubject
        this.userService.permissionsFetched = false;
        this.userService.permissionsSubject = new ReplaySubject(1); // reset the ReplaySubject
        localStorage.clear();
        sessionStorage.clear();
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/;domain=.omniorder.com.au");
        });
    }
}