<mat-card>
  <mat-card-header class="mb-3 ">
    
    <mat-card-title class=" text-danger  text-center">You can renew the subscription by clicking the below button</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex" >
      <button  class="text-center col-6 btn btn-primary" (click)="renew()">Renew</button>
    </section>
  </mat-card-content>
  </mat-card>