<div class="tab-pane" id="general" [ngClass]="{active: generalIsActive}">
    <form [formGroup]="generalForm" class="form-container">
        <mat-card>
            <mat-card-header>
                <mat-card-title>
                    General Settings
                </mat-card-title>
            </mat-card-header>
            <mat-card-content>                                            
                <div class="row">   
                    <div class="col-md-3">                                                    
                        <maat-label></maat-label>
                    </div>
                    <div class="col-md-3" style="height: 5em;">                                                        
                    </div>
                </div>                                            
                <div class="row">   
                    <div class="col-md-3">                                                    
                        <maat-label>Enable Marketplace</maat-label>
                    </div>
                    <div class="col-md-1" style="height: 5em;">                                                                                                                
                            <kendo-switch style="padding-left: 0!important;" [disabled]="!featurePermission.update || disableMarketplaceSetting" formControlName="enableMarketplace" name="EnableMarketplace" class="pl-5" onLabel="Yes" offLabel="No">
                            </kendo-switch>          
                    </div>
                    <div class="col-md-6" style="height: 5em;" *ngIf="disableMarketplaceSetting">                                                                                                                
                        <maat-label>You need to setup your Stripe payment account in order to enable this option</maat-label>
                    </div>
                </div>                                            
                    <div class="row">   
                        <div class="col-md-3">                                                    
                            <maat-label>Time Zone</maat-label>
                        </div>
                        <div class="col-md-3" style="height: 5em;">                                                                                                                
                            <mat-form-field >
                                <mat-label>Timezone</mat-label>
                                <mat-select matNativeControl [disabled]="!featurePermission.update" formControlName="timezone" name="Timezone" >
                                    <mat-option value="-1" selected>Please select Timezone</mat-option>
                                    <mat-option *ngFor="let v of timezones" [value]="v.timezoneId">
                                      {{v.timezoneId}} ({{v.offset}})
                                    </mat-option>          
                                  </mat-select>
                            </mat-form-field>
                        </div>
                    </div>                                            
            </mat-card-content>
            <mat-card-actions align="end">
                <!-- <mat-error *ngIf="showErrors">
                  Please enter all mandatory fields
                </mat-error> -->                                            
                <button mat-button class="btn btn-success btn-round" [disabled]="!featurePermission.update" (click)="saveSettings()" cdkFocusInitial>Save</button>                                            
            </mat-card-actions>
        </mat-card>
    </form>
</div>