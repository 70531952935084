import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { OrderDialog } from 'app/orders/dialogs/manual-order/manual.order.dialog';
import { ProductViewMdel } from 'app/shared/models/catalogue';
import { ManualOrderItemModel } from 'app/shared/models/order/order.model';
import { SellerViewModel } from 'app/shared/models/seller';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { AuthorizeService } from 'app/shared/services/authorize.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { UserService } from 'app/shared/services/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, takeUntil } from 'rxjs/operators';

declare const $: any;
export class RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
  permission: string;
  disabled: boolean;
}
export const ROUTES: RouteInfo[] = [
  { path: '/dashboard', title: 'Dashboard', icon: 'dashboard', class: 'text-primary',permission: PermissionsConstants.DASHBOARD , disabled: false },
  { path: '/inventory', title: 'Inventory', icon: 'eco', class: 'text-primary',permission: PermissionsConstants.INVENTORY , disabled: false},
  { path: '/customers', title: 'Customers', icon: 'groups', class: 'text-primary',permission: PermissionsConstants.CUSTOMERS , disabled: false},
  { path: '/orders', title: 'Orders', icon: 'shopping_cart', class: 'text-primary',permission: PermissionsConstants.ORDERS , disabled: false},  
  { path: '/market', title: 'Pricing Groups', icon: 'people_outline', class: 'text-primary',permission: PermissionsConstants.MARKETING , disabled: false},
  { path: '/integration', title: 'Integrations', icon: 'loop', class: 'text-primary',permission: PermissionsConstants.INTEGRATION , disabled: false},
  { path: '/user-profile', title: 'Profile', icon: 'person', class: 'text-primary',permission: PermissionsConstants.PROFILE , disabled: false},
  { path: '/users', title: 'Users', icon: 'supervised_user_circle', class: 'text-primary' ,permission: PermissionsConstants.USERS, disabled: false},
  { path: '/settings', title: 'Settings', icon: 'settings', class: 'text-primary' ,permission: PermissionsConstants.SETTINGS, disabled: false},
  { path: '/billing', title: 'Billing', icon: 'attach_money', class: 'text-primary' ,permission: PermissionsConstants.BILLING, disabled: false}
];

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  menuItems: any[];
  featurePermissions: FeaturePermission[];
  
  @Input() loggedIn = false;
  @Input() username: string = '';
  @Output() editProfileClicked = new EventEmitter<void>();
  @Output() logoutClicked = new EventEmitter<void>();
  constructor(
    public dialog: MatDialog, private snackbar: MatSnackBar, private spinner: NgxSpinnerService,private userService: UserService,
     private sellerservice: SellerService, private AuthService: AuthorizeService,private msalBroadcastService: MsalBroadcastService,private router: Router
  ) {

  }

  ngOnInit() {
    //debugger;
    this.spinner.show()
    this.msalBroadcastService.inProgress$        
        .subscribe((result) => {
          this.sellerservice.getSeller().subscribe(seller => {
            this.loadSellerPermissions(seller);
          });
    });    
    this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.spinner.hide();
    // this.loadPermissions();
  }
  loadSellerPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermissions = userPermissions; 
    ROUTES.forEach(s=>s.disabled= !this.checkPermission(s.permission));
    });
     
 }
 checkPermission(permission: string) {   
  return  this.featurePermissions.filter(x => x.feature == permission )[0].read;
   
}
  updateSettings(result: SellerViewModel) {
    this.userService.hasPermissionFor(PermissionsConstants.DASHBOARD, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/dashboard')[0].disabled = !x;          
    });
    this.userService.hasPermissionFor(PermissionsConstants.INVENTORY, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/inventory')[0].disabled = !x;          
    });
    this.userService.hasPermissionFor(PermissionsConstants.ORDERS, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/tracking')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.APPROVALS, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/ordersApproval')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.CUSTOMERS, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/customers')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.MARKETING, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/market')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.INTEGRATION, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/integration')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.PROFILE, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/user-profile')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.USERS, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/users')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.SETTINGS, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/settings')[0].disabled = !x;
    });
    this.userService.hasPermissionFor(PermissionsConstants.BILLING, PermissionsConstants.READ, result.id).subscribe(x => {
      this.menuItems.filter(x => x.path === '/settings')[0].disabled = !x;
    });
  }
  loadPermissions() {
    // if (this.AuthService.isloggedIn()) {
    //   this.sellerservice.getSeller().subscribe((result) => {        
    //     this.updateSettings(result);
    //   });
    // }
    // else {
      this.msalBroadcastService.inProgress$        
        .subscribe((result) => {
          this.sellerservice.getSeller().subscribe((result) => {            
            this.updateSettings(result);
          })
        });
    //}
  }
  isMobileMenu() {
    if ($(window).width() > 991) {
      return false;
    }
    return true;
  };
  EditProfile() {
    this.editProfileClicked.emit();
  }

  logout() {
    this.loggedIn = false;
    this.username = '';
    this.logoutClicked.emit();

  }

  createNewOrder(): void {
      var order = new ManualOrderItemModel();
      //todo get current supplier Id
      order.id = 0;    
    var Data = { editmode: false, Order: order }
    const dialogRef = this.dialog.open(OrderDialog, { data: Data });
    dialogRef.afterClosed().subscribe(result => {
      //this.getProducts();
    });
  }
  isActive(route: string): boolean {
    return this.router.isActive(route,true);
  }

}
