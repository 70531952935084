<form  (ngSubmit)="save()" #myForm="ngForm" >
<mat-card>
  <mat-card-header class="mb-3">
    <mat-card-title class="cardHeader"> Group settings</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex">
      <mat-form-field class="col-sm-12">
        <mat-label>Group Name</mat-label>
        <input matInput type="text" name="groupName" [(ngModel)]="MarketingGroup.groupName" required />
      </mat-form-field>
    </section>
    <section class="d-flex">
      <mat-form-field class="col-sm-12">
        <mat-label>Group Description</mat-label>
        <input matInput type="text" name="groupDiscription" [(ngModel)]="MarketingGroup.groupDiscription" required />
      </mat-form-field>
    </section>
    <section class="d-flex">
      <mat-form-field class="col-sm-12">
        <mat-label>Discount<span matPrefix>%</span>
        </mat-label>
        <input matInput type="number" name="flatDiscount" #flatDiscount   placeholder="flatDiscount" [(ngModel)]="MarketingGroup.flatDiscount" [formControl]="rateControl" required />
        <mat-error>Please enter a value between 0 and 100</mat-error>
      </mat-form-field>
    </section>
    <section class="d-flex">
      <mat-checkbox class="pt-3" labelPosition="before" name="isActive"  [(ngModel)]="MarketingGroup.isActive" color="primary">Active
      </mat-checkbox>
    </section>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button type="submit" [disabled]="!myForm.form.valid || !(MarketingGroup.flatDiscount <=100 && MarketingGroup.flatDiscount>=0)" cdkFocusInitial>Save</button>
  </mat-card-actions>
</mat-card>
</form>