import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import {  ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SellerViewModel } from 'app/shared/models/seller';
import { ConfirmationDialog } from 'app/dialogs/confirm/confirm.dialog';
import { SellerService } from 'app/shared/services/Seller.Service';
import { NgxSpinnerService } from "ngx-spinner";
import { IntegrationService } from 'app/shared/services/integration.service';
import { IntegrationItemsDialog } from 'app/inventory/dialogs/integration-items/integration-items.dialog';
import { BatchDialog } from 'app/inventory/dialogs/batch/batch.dialog';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import {  PageChangeEvent, RowArgs, RowClassArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import {process, AggregateDescriptor, CompositeFilterDescriptor, GroupDescriptor, GroupResult, filterBy, groupBy } from '@progress/kendo-data-query';
import { BatchViewModel } from 'app/shared/models/catalogue/batchViewModel';
import { SiteModel } from 'app/shared/models/sites/site.model';
import { BatchInfoDialog } from '../dialogs/batch-info/batch-info.dialog';
import { TransferBatchDialog } from '../dialogs/transfer-batch/transfer-batch.dialog';
import { Observable } from 'rxjs';
import { PageModel } from 'app/shared/models/pageModel';

interface ProductMap {
  [key: number]: ProductViewMdel[];
}
@Component({
  selector: 'app-site-products',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './site-products.component.html',
  styleUrls: ['./site-products.component.css']
})
export class SiteProductsComponent implements OnInit {
  public gridView: any;
  public gridData: any[];
  public mySelection: string[] = [];
  public selectedUID:string  = '';
  public selectableSettings: SelectableSettings;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  dropdownItems: SiteModel[] ;
  selectedOption: number;
  public pendingGridData: any[];
  public pendingGridView: any[];
  public pendingProductSelection: string[] = [];
  public pendingProductselectedUID:string  = '';
  decimalPattern = new RegExp('[0-9]+([.][0-9]+)?');
  Products: ProductViewMdel[];
  ProductsMap: ProductMap;
  newstock: number = 1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  Product: ProductViewMdel;
  SupplierId: number;
  isLinkedToExternalSystem: boolean;
  ConfirmationdialogRef: MatDialogRef<ConfirmationDialog>;
  IntegrationDialogRef: MatDialogRef<IntegrationItemsDialog, any>;
  BatchDialogRef: MatDialogRef<BatchDialog, any>;
  showInventory: boolean = true;
  featurePermission: FeaturePermission  = new FeaturePermission();
  headerStyle: any;
  createInventory:boolean =false;
  public batchList: BatchViewModel[]=[];
  public selectedBatch:BatchViewModel;
  public groupRes:BatchViewModel[] | GroupResult[];
  public aggregates: AggregateDescriptor[] = [
    { field: "stock", aggregate: "sum" },
  ];
  public group: GroupDescriptor[] = [{ field: "product.id", aggregates: this.aggregates }];
  public filter: CompositeFilterDescriptor = {
    logic: "and",
    filters: [],
  };
  
  constructor(
    private ProductService: ProductService, public dialog: MatDialog, private snackbar: MatSnackBar, private sellerService: SellerService,
    private spinner: NgxSpinnerService, private integrationService: IntegrationService,private userService: UserService
  ) {

  }

  stringify(obj: any): string {
    return JSON.stringify(obj);
  }

  loadPermissions(seller: SellerViewModel) {   
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
    this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.INVENTORY )[0];
    this.showInventory=this.featurePermission.read;   
    this.createInventory=this.featurePermission.create;  
    });
     
 }

  ngOnInit(): void {
    //this.spinner.show();
    this.loading = true;
    this.setSelectableSettings();
    this.getSites();
    this.initBtaches();

  }

  async initBtaches() {    
    this.sellerService.getSeller().subscribe(data => {
      this.loadPermissions(data);
      this.SupplierId = data.supplierId;
      this.getBatches(this.currentPage,this.pageSize);
      // this.imageSource = this.Seller.supplier.profileImageUrl_Small;
    });


  }
  public filterChange(filter: CompositeFilterDescriptor): void {
    this.filter = filter;
    let filteredData= filterBy(this.gridData , this.filter);
    this.groupRes=groupBy(filteredData , this.group);
        this.gridView=[...this.groupRes];

  }
  getBatches(currentPage:number,pageSize:number,siteId:number=null): void {
    this.loading = true;
    if(siteId==0)
    {
      siteId=null;
    }
    let x=this.ProductService.getBatchesforSupplier(currentPage,pageSize,siteId).subscribe(
      (data) => {  
        console.log(data)
        this.groupRes=groupBy(data.items , this.group);
        this.gridView = {
          data: this.groupRes,
          total: data.totalItems,
        };
        this.ProductsMap = data.items.reduce((acc, obj) => {
          if (!acc[obj.productId]) {
            acc[obj.productId] = [];
          }        
          acc[obj.productId].push(obj);
          return acc;
        }, {});

        this.loading = false;
      }
    );
  }
  getSites(): void {
    this.sellerService.getAllSites().subscribe(
      (data) => {  
        this.dropdownItems=data;
       
      }
    );
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getBatches(this.currentPage,this.pageSize,null);
  }

  opendialog( batch: BatchViewModel): void {
    
    var Data = { batch: batch }
    const dialogRef2 = this.dialog.open(BatchInfoDialog, { data: Data });
    dialogRef2.afterClosed().subscribe(result => {
      this.spinner.show();
      this.getBatches(this.currentPage,this.pageSize);
      this.spinner.hide();
    });

  }
  openTransferDialog( batch: BatchViewModel): void {
    
    var Data = { batch: batch }
    const dialogRef2 = this.dialog.open(TransferBatchDialog, { data: Data });
    dialogRef2.afterClosed().subscribe(result => {
      this.spinner.show();
      this.getBatches(this.currentPage,this.pageSize);
      this.spinner.hide();
    });

  }
  public setSelectableSettings(): void {  
    this.selectableSettings = {
      checkboxOnly: false,
      mode: "single",
      drag: false,
    };
  }
  public mySelectionKey(context: RowArgs): string {    
    return context.dataItem.id;
  }
  keyChange(e){    
    this.selectedUID = e;
  }
  editBatch(): any {   
   console.log( this.gridView)
    this.gridView.data.forEach(x => x.items.forEach(i=>{
      if(i.id== parseInt(this.selectedUID[0]))
      {
        this.selectedBatch=i;
      }
    }
      
      )
      
      );

    var Data = { editmode: true, Product: this.selectedBatch.product, Batch: this.selectedBatch}
    const batchDialog = this.dialog.open(BatchDialog, { data: Data });
    batchDialog.afterClosed().subscribe(result => {
  
      this.initBtaches();

    });
  }
  getBatch(batchId: number)
  {
    this.ProductService.getBatchForSeller(batchId).subscribe(result => {
      
      this.loading = true;  
      this.spinner.show();
      this.batchList=[];    
       this.batchList.push(result);
       this.gridView=[...this.batchList]; 
       this.loading = false;
       this.spinner.hide();
    });
  }
  isEditDisabled()
  {
    return (this.selectedUID == null || this.selectedUID.length == 0 || !this.featurePermission.update);
  }
  public rowCallback = (context: RowClassArgs) => {
    const now = new Date().toISOString();
    if (context.dataItem.expiryDate < now) {
      return { gold: true };
    } else {
      return '';
    }
  };
}