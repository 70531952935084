<div *ngIf="!isLoading"  >
  <div label="Profile" >
    <form [formGroup]="profileForm" (ngSubmit)="onSubmit()" novalidate>
      <div class="main-content">
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="card">
                <div class="card-header card-header-success">
                  <h4 class="card-title">Profile</h4>
                  <!-- <p class="card-category">Complete your profile</p> -->
                </div>
                <div class="card-body" *ngIf="showProfile">
                  <div class="row">
                    <!-- <div class="col-md-3 pt-3 ml-5  d-flex">
                      
                        <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
                          style="background-image: url(./assets/img/oba-leaf.jpg)" *ngIf="profileImageUrl =='' || profileImageUrl == null"
                          class="bg-style"
                          >
                          <span class="material-icons">camera_alt</span>
                          <span>Edit</span>
                        </div>
                        <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()" class="bg-style"
                          [ngStyle]="{ 'background-image': 'url(' +profileImageUrl + ')'}"
                          *ngIf="profileImageUrl!='' && profileImageUrl != null">
                          <span class="material-icons">camera_alt</span>
                          <span>Edit</span>
                        </div>
                        <input hidden #fileInput type="file" id="file" (change)="upload(fileInput.files)" accept="image/*" />
                    
                        
                    </div>  -->
                    <div class="col-md-3 pt-3 ml-5  d-flex">
                      <div class="card-title">
                        <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
                          style="background-image: url(./assets/img/oba-leaf.jpg)"
                          *ngIf="profileImageUrl =='' || profileImageUrl == null">
                          <span class="material-icons">camera_alt</span>
                          <span>Edit</span>
                        </div>
                        <div mat-card-avatar class="product-header-image profile-pic" (click)="fileInput.click()"
                          [ngStyle]="{ 'background-image': 'url(' +profileImageUrl + ')'}"
                          *ngIf="profileImageUrl !='' && profileImageUrl != null">
                          <span class="material-icons">camera_alt</span>
                          <span>Edit</span>
                        </div>
                        <input hidden  #fileInput type="file" id="file" (change)="upload(fileInput.files)" accept="image/*" />
                        
                        
                      </div>
                    </div>
                    <div class="col-md-8">
                      <div class="row">
                        <!-- <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Account ID" name="AccountID" [(ngModel)]="Seller.supplier.accountId">
                          </mat-form-field>
                        </div> -->
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="ABN*"  formControlName="abn">
                            <mat-error
                            *ngIf="profileForm.get('abn').hasError('isValid')"
                            > abn already <strong>exists</strong></mat-error>
                            <mat-error >Invalid ABN</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Business Name*" formControlName="businessName">
                            <mat-error >Invalid Business Name</mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Account Owner*"  formControlName="ownerName">
                            <mat-error >Invalid Account Owner</mat-error>
                          </mat-form-field>
                        </div>
      
      
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Email address*"  type="email" formControlName="ownerEmail">
                            <mat-error >Invalid Email address</mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Business Email address*"  type="email" formControlName="businessEmail">
                            <mat-error >Invalid Email address</mat-error>
                          </mat-form-field>
                        </div>
                        
      
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
      
                            <mat-label>Mobile*</mat-label>
                            <span matPrefix>+61 &nbsp;</span>
                            <input type="tel" matInput placeholder="000-000-000" appDigitOnly formControlName="mobile">
                            <mat-icon matSuffix>mode_edit</mat-icon>
                            <mat-error >Invalid Mobile Number</mat-error>
                          </mat-form-field>
                          
      
                        </div>
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <mat-label>Additional Number</mat-label>
                            <span matPrefix>+61 &nbsp;</span>
                            <input type="tel" matInput placeholder="000-000-000" appDigitOnly formControlName="additionalNumber">
                            <mat-icon matSuffix>mode_edit</mat-icon>
                            <mat-error >Invalid Additional Number</mat-error>
      
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <mat-label>Phone</mat-label>
                            <span matPrefix>+61 &nbsp;</span>
                            <input type="tel" matInput placeholder="000-000-000" appDigitOnly formControlName="phone">
                            <mat-icon matSuffix>mode_edit</mat-icon>
                            <mat-error >Invalid Phone Number</mat-error>
      
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <textarea matInput placeholder="Address*" formControlName="address"> </textarea>
                            <mat-error >Invalid Street Address</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-2">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="City*" type="text" formControlName="city">
                            <mat-error >Invalid City</mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-2">
                         
                          <mat-form-field class="example-full-width">
  
                            <mat-label>ٍState*</mat-label>
                            <mat-select  formControlName="stateId">
                              <mat-option *ngFor="let stateObj of states" [value]="stateObj.id">{{stateObj.name}}</mat-option>
                            </mat-select>
                            <mat-error >Invalid State</mat-error>
                          </mat-form-field>
                        </div>
                        <div class="col-md-2">
                          <mat-form-field class="example-full-width">
                            <mat-label>Postal Code*</mat-label>
                            <input matInput #postalCode maxlength="4" appDigitOnly placeholder="Ex. 9410" formControlName="postalCode">
                            <mat-hint align="end">{{postalCode.value.length}} / 4</mat-hint>
                            <mat-error >Invalid Postal Code</mat-error>
                          </mat-form-field>
                        </div>
      
                        <div class="col-md-3">
                          <mat-form-field class="example-full-width">
                            <mat-label>Market location</mat-label>
                            <mat-select name="Marketlocation" formControlName="marketLocation" >
                              <mat-option value="" selected>Choose here</mat-option>
                              <mat-option value="Adelaide (Pooraka)">Adelaide (Pooraka)</mat-option>
                              <mat-option value="Epping (Melbourne)">Epping (Melbourne)</mat-option>
                              <mat-option value="Sydney (Homebush)">Sydney (Homebush)</mat-option>
                              <mat-option value="Brisbane (Rocklea)">Brisbane (Rocklea)</mat-option>
                              <mat-option value="Perth (Market City)">Perth (Market City)</mat-option>
                              <mat-option value="Newcastle (Sandgate)">Newcastle (Sandgate)</mat-option>
                            </mat-select>
                            <mat-error >Invalid Market location</mat-error>
      
                          </mat-form-field>
                        </div>
                        <div class="col-md-3">
                          <mat-form-field class="example-full-width">
                            <input matInput placeholder="Bay" type="text" formControlName="bay" >
                            <mat-error >Invalid Bay</mat-error>
                          </mat-form-field>
                        </div>
                      </div>
                     
                    </div>
                  </div>

                  
                  <div class="row">
                    
                    <div class="col-md-3 mr-3 text-center">
                      <button mat-button  type="submit" [disabled]="!profileForm.valid" class="btn btn-primary"  >
                        Submit for Approval
                    </button>
                    </div>
                    <div class="col-md-2 text-center">
                      <button mat-button class="btn btn-danger" [mat-dialog-close]="true">
                        Cancel
                    </button>
                    </div>    
                </div>

                </div>
                <div *ngIf="! showProfile">
                  <img src="/assets/img/403.png">
                </div>
              </div>
            </div>
      
          </div>
    
          
        </div>
      </div>
    </form>
  </div>


</div>