import { Component, Inject, OnInit } from '@angular/core';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { ProductService } from 'app/shared/services/product.service';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { SellerViewModel } from 'app/shared/models/seller';
import { ConfirmationDialog } from 'app/dialogs/confirm/confirm.dialog';
import { SellerService } from 'app/shared/services/Seller.Service';
import { IntegrationItemsDialog } from 'app/inventory/dialogs/integration-items/integration-items.dialog';
import { BatchDialog } from 'app/inventory/dialogs/batch/batch.dialog';
import Swal from 'sweetalert2';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';

@Component({
  selector: 'app-billing',
  templateUrl:'./billing.component.html'
})
export class BillingComponent implements OnInit {
  decimalPattern = new RegExp('[0-9]+([.][0-9]+)?');
  Products: ProductViewMdel[];
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  Product: ProductViewMdel;
  SupplierId: number;
  isLinkedToExternalSystem: boolean;
  ConfirmationdialogRef: MatDialogRef<ConfirmationDialog>;
  IntegrationDialogRef: MatDialogRef<IntegrationItemsDialog, any>;
  BatchDialogRef: MatDialogRef<BatchDialog, any>;
  showInventory: boolean = true;

  constructor(
    public dialog: MatDialog, private sellerService: SellerService, private userService: UserService
  ) {

  }
  loadPermissions(seller: SellerViewModel) {   
    this.userService.hasPermissionFor(PermissionsConstants.INVENTORY, PermissionsConstants.READ, seller.id).subscribe(x => {this.showInventory = x});    
 }

  ngOnInit(): void {
    
  }
  billingPageRedirect(): void {
    this.sellerService.getBilling().subscribe(res => {
      window.open(res.data, '_blank');
    });
  }

}