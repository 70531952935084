import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HandleError, HttpErrorHandler } from 'app/base/http-error-handler.service';
import { environment } from 'environments/environment';
import { ProductCategory, ProductGrade, ProductGrower, ProductVarital, ProductViewMdel } from 'app/shared/models/catalogue/index';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { MarketingGroupBaseViewModel, SupplierGroupsViewModel, SupplierTrustedNetworks, SupplierTrustedNetworksViewModel } from '../models/promotions/marketing.model';
import { MarketUpdateViewModel } from '../models/promotions/marketupdate.model';

import { UtilsService } from './utils.service';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { SellerService } from './Seller.Service';
import { ExternalCustomerModel } from '../models/integration/external.customer.model';
import { NetsuiteSettingsViewModel } from '../models/integration/netsuitesettings.model';



const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'my-auth-token'
    })
};

@Injectable({
    providedIn: 'root'
})
export class IntegrationService  extends BehaviorSubject<GridDataResult> {    
    headers: Headers;
    apiURl: string;
    private sellerService: SellerService;
    private handleError: HandleError;

    constructor(private http: HttpClient, httpErrorHandler: HttpErrorHandler, sellerService: SellerService) {
        super(null);
        this.handleError = httpErrorHandler.createHandleError('IntegrationService');
        this.apiURl = environment.apibaseUrl;
        this.sellerService = sellerService;
        //this.sellerId = this.sellerService.sellerViewModel.id;
    }

    LinkXeroAccount(code: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/LinkXeroAccount?code=${code}`;        
                return this.http.post<any>(url, code, httpOptions)})
        );
    }  

    LinkMYOBAccount(code: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/LinkMYOBAccount?code=${code}`;        
                return this.http.post<any>(url, code, httpOptions);
            }));
    }    
    LinkExternalCustomerToBuyer(buyerId: number, myobUID: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/LinkExternalCustomerToBuyer?buyerId=${buyerId}&uid=${myobUID}`;
                return this.http.post<any>(url, null, httpOptions);
            }));
    }
    LinkExternalItemToProduct(itemId: number, myobUID: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/LinkExternalItemToProduct?itemId=${itemId}&uid=${myobUID}`;
                return this.http.post<any>(url, null, httpOptions);
            }));
    }
    LinkGoogleAccount(code: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/LinkGoogleAccount?code=${code}`;        
                return this.http.post<any>(url, code, httpOptions);
            }));
    }    
    LinkMicrosoftAccount(code: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/LinkMicrosoftAccount?code=${code}`;        
                return this.http.post<any>(url, code, httpOptions);
            }));
    }  

    UpdateNetsuiteAccount(netsuiteSettings: NetsuiteSettingsViewModel): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/UpdateNetsuiteSettings`;        
                return this.http.put<any>(url, netsuiteSettings, httpOptions);
            }));        
    }

    GetLinkedApps(sellerId: number): Observable<any> {
        const url = `${this.apiURl}/api/v1/${sellerId}/Integration/ActiveConnections`;        
        return this.http.get<any>(url);
    }

    GetExternalSystemName(): any {    
        this.sellerService.getSeller().subscribe((d) => {
            this.GetLinkedApps(d.id).subscribe((data) => {
                if(data.indexOf("MYOB") >= 0){
                  return "MYOB";
                }
                if(data.indexOf("Xero") >= 0){
                  return "Xero";
                }
              });    
        });        
      }

    GetExternalCustomers(externalId: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/ExternalSystemCustomers?externalId=${externalId}`;        
                return this.http.get<any>(url);
            }));
    }

    GetPendingExternalCustomers(): Observable<ExternalCustomerModel[]> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Customer/PendingImports`;        
                return this.http.get<any>(url);
            }));
    }

    GetExternalItems(externalId: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/ExternalSystemProducts?externalId=${externalId}`;        
                return this.http.get<any>(url);
            }));
    }
    
    SyncMYOBAccount(): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/SyncMYOBAccount`;        
                return this.http.post<any>(url, null, httpOptions);
            }));
    }

    SyncXeroAccount(): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/SyncXeroAccount`;        
                return this.http.post<any>(url, null, httpOptions);
            }));
    }

    PushCustomerToExternalSystem(supplierTrustedNetworks: SupplierTrustedNetworks): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/PushBuyerToExternalSystem`;        
                return this.http.post<any>(url, supplierTrustedNetworks, httpOptions);
            }));
    }

    PushProductToExternalSystem(product: ProductViewMdel): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/PushProductToExternalSystem`;        
                return this.http.post<any>(url, product, httpOptions);
            }));
    }

    UpdateMYOBAccount(selectedCompanyFileId: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/UpdateMYOBAccount?companyFileId=${selectedCompanyFileId}`;
                return this.http.put<any>(url, selectedCompanyFileId, httpOptions);
            }));
    }

    UpdateXeroAccount(selectedTenantId: string): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/UpdateXeroAccount?tenantId=${selectedTenantId}`;
                return this.http.put<any>(url, selectedTenantId, httpOptions);
            }));
    }
    getApiKey(): Observable<any> {
        return this.sellerService.getSeller().pipe(
            switchMap(seller => {
                const url = `${this.apiURl}/api/v1/${seller.id}/Integration/ApiKey`;
                return this.http.get<any>(url, httpOptions)}),
            catchError(this.handleError<any>('getApiKey'))
     
        );
      }
      
}
