import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { IntegrationCustomersDialog } from 'app/customers/dialogs/integration-customers/integration-customers.dialog';
import { SignalRPaginatedAdapter } from 'app/chat/adapter/signalr-paginated-adapter';
import { SupplierTrustedNetworks, SupplierTrustedNetworksViewModel } from 'app/shared/models/promotions/marketing.model';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { MarketingService } from 'app/shared/services/marketing.service';
import { IChatParticipant } from 'ng-chat';
import { NgxSpinnerService } from "ngx-spinner";
import { IntegrationService } from 'app/shared/services/integration.service';
import { UserService } from 'app/shared/services/user.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { SellerViewModel } from 'app/shared/models/seller';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent } from '@progress/kendo-angular-grid';
import { ConnectionStatus } from 'app/shared/models/seller/connectionStatusEnum';
import { CustomerDialog } from 'app/dialogs/customer/customer.dialog';
import { ExternalCustomerModel } from 'app/shared/models/integration/external.customer.model';
import { CustomerDetailsDialog } from '../dialogs/customer-details/customer-details.dialog';


@Component({
  selector: 'app-active-customers',
  templateUrl: './active-customers.component.html',
  styleUrls: ['./active-customers.component.css']
})
export class ActiveCustomersComponent implements OnInit {
  public gridView: GridDataResult;
  public inActiveGridView: GridDataResult;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage: number = 1;
  public approveStatus: number = ConnectionStatus.Approved;
  public currentStatus: number;
  public isActive: boolean = true;
  supplierTrustedNetworksViewModel: SupplierTrustedNetworksViewModel;
  activetrustedsupplierNetworks: SupplierTrustedNetworks[];
  isLinkedToExternalSystem: boolean;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  IntegrationDialogRef: MatDialogRef<IntegrationCustomersDialog, any>;
  featurePermission: FeaturePermission = new FeaturePermission();
  integrationPermission: FeaturePermission = new FeaturePermission();
  showCustomers: boolean = true;
  CustomerDialogRef: MatDialogRef<CustomerDetailsDialog, any>;
  externalCustomers: ExternalCustomerModel[];
  data: any;

  constructor(
    private marketingService: MarketingService, public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private eventEmitterService: EventEmitterService,
    private integrationService: IntegrationService,
    private snackbar: MatSnackBar,
    private userService: UserService,
    private sellerService: SellerService
  ) {

  }

  ngOnInit(): void {
    this.loading = true;
    this.initCutomers();
    this.getLinkedApps();
  }

  loadPermissions(seller: SellerViewModel) {
    this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {
      this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.CUSTOMERS)[0];
      this.integrationPermission = userPermissions.filter(x => x.feature == PermissionsConstants.INTEGRATION)[0];
      this.showCustomers = this.featurePermission.read;
    });

  }
  async initCutomers() {
    this.supplierTrustedNetworksViewModel = new SupplierTrustedNetworksViewModel();
    this.sellerService.getSeller().subscribe(seller => {
      this.loadPermissions(seller);
    });
      this.reloadSuppliersNetwork(this.supplierTrustedNetworksViewModel);

  
  }
  activateDeactivateProduct(supplierTrustedNetworks: SupplierTrustedNetworks): void {
    this.loading=true;
    this.marketingService.ActivateOrDeactivateCustomer(supplierTrustedNetworks).subscribe((data) => {
      this.supplierTrustedNetworksViewModel = data
      this.reloadSuppliersNetwork(this.supplierTrustedNetworksViewModel);
    });
  }
  push(supplierTrustedNetworks: SupplierTrustedNetworks): void {
    this.spinner.show("pushing");
    this.integrationService.PushCustomerToExternalSystem(supplierTrustedNetworks).subscribe((data) => 
    {
      supplierTrustedNetworks.customer.externalId = data[1];
      this.snackbar.open('Customer Pushed Successfully', 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide("pushing");
    }, (error) => {
      this.snackbar.open(`Failed to push customer due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide("pushing");
    });
  }
  hasExternalId(supplierTrustedNetworks: SupplierTrustedNetworks): boolean {
    return supplierTrustedNetworks.customer.externalId != null;
  }
  link(supplierTrustedNetworks: SupplierTrustedNetworks): void {
    this.IntegrationDialogRef = this.dialog.open(IntegrationCustomersDialog, { data: { buyerId: supplierTrustedNetworks.customerId, externalId: supplierTrustedNetworks.customer.externalId } });
    this.IntegrationDialogRef.afterClosed().subscribe(result => {
      if (result.data) {
        supplierTrustedNetworks.customer.externalId = result.data;
      }
    });
  }
  reloadSuppliersNetwork(Collection: SupplierTrustedNetworksViewModel) {
    this.getGrid(this.currentStatus, this.isActive);
  }
  openCustomerDialog(CustomerId: number) {
    var model = this.gridView.data.filter(x => x.customerId == CustomerId);
    console.log(model)
    this.CustomerDialogRef = this.dialog.open(CustomerDetailsDialog, { data: { Customer:model[0].customer, editmode:true }, width: '1200px', });
    this.CustomerDialogRef.afterClosed().subscribe(result => {
      
      if(result!=null && result!="")
      {
        this.snackbar.open('Customer updated successfully', 'close', {
          duration: 2000,
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition
        });
        this.CustomerDialogRef = null;
        this.getGrid(this.currentStatus, this.isActive);
      }
      
    }, (error) => {
      this.snackbar.open(`Failed to update customer due to error ${error.error}`, 'close', {
        duration: 5000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
      this.spinner.hide();
    });
  }
  openchat(Id: number): void {
    let User = SignalRPaginatedAdapter.Buyers.filter(function (element: IChatParticipant) {
      return element.id == Id
    });
    this.eventEmitterService.OpenChatClick(User[0]);
  }
  getLinkedApps(): any {
    this.sellerService.getSeller().subscribe((seller) => {
      this.integrationService.GetLinkedApps(seller.id).subscribe((data) => {
        if (data.length > 0) {
          this.isLinkedToExternalSystem = true;
        }
      });
    });
  }
  showError(supplierTrustedNetworks: SupplierTrustedNetworks): boolean {
    return this.isLinkedToExternalSystem && (supplierTrustedNetworks.customer.externalId == null);
  }
  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number(event.skip) + this.pageSize) / this.pageSize;
    this.skip = event.skip;
    this.getGrid(this.currentStatus, this.isActive);
  }
  getGrid(status: number, isActive: boolean = true) {
    this.currentStatus = status ?? this.approveStatus;
    this.isActive = isActive;
    this.loading = true;
    this.marketingService.GetBuyersInSupllierNetworkByStatus(this.currentStatus, this.currentPage, this.pageSize, this.isActive).subscribe((data) => {
      console.log(data.items)
      this.gridView = {
        data: data.items,
        total: data.totalItems,
      };
      this.loading = false;

    });
  }

}

