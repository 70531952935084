<section class="d-flex">
    <mat-form-field class="col-md-4">
        <mat-label>Master Data</mat-label>
        <mat-select  matNativeControl  (selectionChange)="routeToLink($event.value)"
        [(ngModel)]="selectedLink" id="MasterData" name="MasterData" required>
          <mat-option value="" selected>Select Master Data</mat-option>
          <mat-option *ngFor="let link of links" [value]="link.pathLink"> 
            {{link.label}}
          </mat-option>
        </mat-select>
      </mat-form-field> 
</section>