
<mat-card>
  <mat-card-header class="mb-3">
    <div mat-card-avatar class="product-header-image"
      [ngStyle]="{ 'background-image': 'url(' +dialogdata.Product.thumbnailUrl + ')'}"></div>
    <mat-card-title class="cardHeader">Manage Promotions for {{dialogdata.Product.category?.categoryName}} {{dialogdata.Product.varietal!=null?'-'+dialogdata.Product.varietal.varietalName:''}}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <section class="d-flex" *ngIf="dialogdata.Product.id > 0">
      <div class="col-md-12">         
            <kendo-grid
            [data]="gridView"
              kendoGridSelectBy="id"
              [selectedKeys]="mySelection"
              [loading]="loading" 
              [pageSize]="10"
              [pageable]="true"
              [sortable]="true"
              [groupable]="false"
              [reorderable]="true"
              [resizable]="true"
              [height]="300"
              [columnMenu]="{ filter: true }"
              [selectable]="selectableSettings"
              kendoGridSelectBy="id"
              [kendoGridSelectBy]="mySelectionKey"
              (selectedKeysChange)="keyChange($event)"
              >                          
              <!-- (cellClick)="cellClickHandler($event)"
              (cellClose)="cellCloseHandler($event)" -->

           <!-- <ng-template kendoGridToolbarTemplate>
              <button kendoGridAddCommand (click)="addNewBatch()" [disabled]="isAddingNewDisabled()">Add new</button>
              <button kendoGridAddCommand (click)="editBatch()" [disabled]="isEditDisabled()">Edit</button>
              <input
                placeholder="Search in all columns..."
                kendoTextBox
                (input)="onFilter($event.target.value)"
              />
              <kendo-grid-spacer></kendo-grid-spacer>        
            
              <kendo-grid-checkbox-column
                [width]="45"
                [headerClass]="{ 'text-center': true }"
                [class]="{ 'text-center': true }"
                [resizable]="false"
                [columnMenu]="false"
                showSelectAll="false"          
                ></kendo-grid-checkbox-column>
            </ng-template>         -->
            <!-- <kendo-grid-column field="id" title="ID" visibility="false">
            </kendo-grid-column>                     -->
            <kendo-grid-column field="number" title="Number" [width]="150">
            </kendo-grid-column>                    
            <kendo-grid-column field="format" title="Unit of Measure" [width]="150" >          
            </kendo-grid-column>     
            <kendo-grid-column field="grade.grade" title="Grade" [width]="150">          
            </kendo-grid-column>                         
            <!-- <kendo-grid-column field="grower.growerName" title="Grower">          
            </kendo-grid-column>                                                          -->
            <kendo-grid-column field="ripness" title="Ripeness" [width]="150">          
            </kendo-grid-column>
            <kendo-grid-column field="unitPrice" title="Unit Price" format="{0:c2}" [width]="150">          
            </kendo-grid-column>
            <!-- <kendo-grid-column field="promotionalPrice" title="Promotional Price" format="{0:c2}" [width]="220" editor="numeric">          
            </kendo-grid-column>       -->
            <kendo-grid-column field="promotionalPrice" title="Promotional Price" [width]="200">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                <kendo-numerictextbox [(ngModel)]="dataItem.promotionalPrice" format="c2"
                 [min]="0" 
                  class="form-control">
                </kendo-numerictextbox>

              </ng-template>
            </kendo-grid-column>                          
            <kendo-grid-column field="promotionEndDate" title="Promotion Period" [width]="220">
              <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex" >                              
                <span style="display: flex;">
                  <mat-date-range-input [rangePicker]="rangePicker">
                    <input matStartDate placeholder="Start date" [(ngModel)]="dataItem.promotionStartDate" name="promotionStartDate" #promotionStartDate="ngModel">
                    <input matEndDate placeholder="End date" [(ngModel)]="dataItem.promotionEndDate" name="promotionEndDate" #promotionEndDate="ngModel">
                  </mat-date-range-input>
                  <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
                  <mat-date-range-picker #rangePicker color="primary">
                    <mat-date-range-picker-actions>
                      <button mat-button matDateRangePickerCancel>Cancel</button>
                      <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                    </mat-date-range-picker-actions>
                  </mat-date-range-picker>
                  
              </span>
               <span>
                <mat-error *ngIf="checkIsActive(dataItem)">Pormotion Date is required</mat-error>
              </span> 
              </ng-template>
            </kendo-grid-column>            
            <kendo-grid-column field="onPromotion" title="Enabled" [width]="150">              
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                  <span class="left-align">
                    <kendo-switch [(ngModel)]="dataItem.onPromotion" name="onPromotion" #onPromotion="ngModel" class="pl-5" onLabel="Yes" offLabel="No">
                    </kendo-switch>          
                  </span>       
                </ng-template>
            </kendo-grid-column>                                
        </kendo-grid>         
      </div>      
    </section>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
    <button mat-button  (click)="savePromotions()" [disabled]="checkBatches()"  [mat-dialog-close]="dialogdata" cdkFocusInitial>Save</button>
  </mat-card-actions>
</mat-card>
