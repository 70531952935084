
export class SiteModel {
        id:number
        code:string
        name:string
        addressLine1 :string
        addressLine2? :string
        addressLine3? :string
        postalCode :string
        stateId? :number
        countryId? :number
        sellerId? :number
        active? :boolean
        state? :StateModel
        country? :CountryModel
        lastStockTakeDate? :Date
        city :string
        currntStockTake:StockTakeModel
        zones:ZoneModel[]
        stockTakes:StockTakeModel[]
}
export class StateModel {
        id?:number
        code?:number
        name?:string
        countryId? :string

}
export class CountryModel {
        id?:number
        countryCode?:string
        name?:string
        threeLettersCode? :string
        numericCode? :string
}
export class ZoneModel {
        id?:number
        siteId?:number
        name?:string
        site? :SiteModel
}

export class BatchTransferModel {
        batchId?:number
        sourceZoneId?:number
        destinationZoneId?:number
}
export class StockTakeModel {
        id?:number
        dateStarted?:Date
        dateCompleted?:Date
        siteId?:number
        site? :SiteModel
}

export class StockTakeRecordModel {
        id?:number
        inventoryStockTakeId?:number
        batchId?:number
        actualStock?:number
        stockBeforeStockTake? :SiteModel
}