import { ChatParticipantStatus, ChatParticipantType, IChatParticipant } from "ng-chat";
import * as internal from "stream";
import { ProductCategory } from "../catalogue";
import { SupplierViewModel } from "../seller";
import { CustomerModel } from "../customer/customer.model";
import { BuyerAddressModel } from "../address/buyer_address.model";
// import { Buyer } from "../buyer/buyer.model";


export class SupplierTrustedNetworksViewModel extends SupplierViewModel {
    constructor() {
        super();

        this.supplierTrustedNetworks = new Array;

    }
    supplierTrustedNetworks: SupplierTrustedNetworks[];
}

export class SupplierGroupsViewModel extends SupplierViewModel {
    constructor() {
        super();

        this.marketingGroups = new Array;

    }
    marketingGroups: MarketingGroupViewModel[];
}

export class MarketingGroupBaseViewModel {
    constructor() {

    }
    id: number;
    groupName: string;
    groupDiscription: string;
    flatDiscount: number;

    isActive: boolean = true;
    supplierId: number;
}
export class MarketingGroupViewModel extends MarketingGroupBaseViewModel {
    constructor() {
        super();
    }
    public id: number;
    groupName: string;
    groupDiscription: string;
    flatDiscount: number;
    marketingGroupMembers: MarketingGroupMemberViewModel[];
    productcategoriesInGroup: ProductcategoriesInGroupViewModel[];
    isActive: boolean = true;
    supplierId: number;
}
export class MarketingGroupMemberViewModel implements IChatParticipant {
    participantType: ChatParticipantType;
    status: ChatParticipantStatus;
    avatar: string;
    displayName: string;
    id: number;
    buyerId: number;
    groupId: number;
    buyer: BuyerViewModel;
}
export class ProductcategoriesInGroupViewModel {
    id: number;
    productCategoryId: number;
    productCategory: ProductCategory;
    groupId: number;


}
export class SupplierTrustedNetworks {
    constructor() {
    }
    id: number;
    status: string;
    buyerId: number;
    customerId: number;
    selected: boolean;
    isActive: boolean = true;
    supplierId: number;
    buyer: BuyerViewModel;
    customer: CustomerModel;
    paymentTermsId: number = 3;
    unreadMessagesCount: number;
}
export class BuyerViewModel {
    id: number;
    externalId: string;
    identityGuid: string;
    name: string;
    email: string;
    businessName: string;
    abn: string;
    address: string;
    city: string;
    state: string;
    postalCode: string;
    marketLocation: string;
    bay: string;
    firstName: string;
    lastName: string;
    mobile: string;
    phone: string;
    thumbnailUrl: string;
    profileImage: string;
    profileThubmnail: string;  
    addresses:BuyerAddressModel[]  
}
