<section class="d-flex">
  <mat-label class="mat-body-strong col-md-6">Filter by Site:</mat-label>
  <mat-form-field appearance="fill" class="col-md-6">
    <mat-select [(ngModel)]="selectedOption" placeholder="Choose site ..." (selectionChange)="getBatches(1,pageSize,selectedOption)">
      <mat-option  [value]="0">All</mat-option>
      <mat-option *ngFor="let item of dropdownItems" [value]="item.id">{{ item.name }}</mat-option>
    </mat-select>
  </mat-form-field>
</section>

<kendo-grid id="my-grid" [data]="gridView" [rowClass]="rowCallback" [loading]="loading"  [pageSize]="pageSize" [skip]="skip" [pageable]="true" [resizable]="true"
[groupable]="{ showFooter: true }"
[selectable]="selectableSettings"
kendoGridSelectBy="id"
[kendoGridSelectBy]="mySelectionKey"
(selectedKeysChange)="keyChange($event)"
[group]="group"

 
(pageChange)="pageChange($event)">
<ng-template kendoGridToolbarTemplate>
  <button kendoGridAddCommand (click)="editBatch()" [disabled]="isEditDisabled()">Edit</button>
  
  <kendo-grid-spacer></kendo-grid-spacer>        

  <kendo-grid-checkbox-column [sticky]="true"
    [width]="45"
    [headerClass]="{ 'text-center': true }"
    [class]="{ 'text-center': true }"
    [resizable]="false"
    [columnMenu]="false"
    showSelectAll="false"          
    ></kendo-grid-checkbox-column>
</ng-template>   

<kendo-grid-column [hidden]="true" field="product.category.categoryName" title="Product" class='k-text-center font-weight-bold'
    headerClass="k-text-center font-weight-bold" [width]="50">
    
  </kendo-grid-column>
 
  <kendo-grid-column [hidden]="true"  field="id" title="ID" [width]="220" visibility="false">
  </kendo-grid-column>       
  <kendo-grid-column title="#" [width]="100">
    <ng-template kendoGridCellTemplate let-rowIndex="rowIndex">
      {{ rowIndex+1 }}
    </ng-template>    
  </kendo-grid-column>         
  <kendo-grid-column field="number" title="Number" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">
  <ng-template kendoGridGroupFooterTemplate let-aggregates="aggregates" >
    <span>
      Total Stock: {{ aggregates.stock.sum }}
    </span>
  </ng-template>
  </kendo-grid-column>        
  <kendo-grid-column field="date" title="Date" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold" >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.date | date: 'dd/MM/yyyy'}} 
    </span>
  </ng-template>
</kendo-grid-column>   
<kendo-grid-column field="expiryDate" title="Expiry Date" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold" >
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.expiryDate | date: 'dd/MM/yyyy'}} 
    </span>
  </ng-template>
</kendo-grid-column>  
  <kendo-grid-column field="format" title="Unit of Measure" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold" >   
</kendo-grid-column>            
  <kendo-grid-column field="product.id" title="Product" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold" visibility="false">  
  <ng-template kendoGridGroupHeaderTemplate let-group="group">
    <span class="font-weight-bold">
      {{ProductsMap[group.value][0].product.category.categoryName}}  {{ProductsMap[group.value][0].product.type?.typeName}} 
       {{ProductsMap[group.value][0].product.varietal?.varietalName}}  {{ProductsMap[group.value][0].product.format}}
    </span>
  </ng-template>        
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
        {{dataItem.product.category.categoryName}} {{dataItem.product.type?.typeName}}  {{dataItem.product.varietal?.varietalName}}  {{dataItem.product.format}}
    </span>
  </ng-template>
  </kendo-grid-column>     
  <kendo-grid-column field="grade.grade" title="Grade" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>                         
  <kendo-grid-column field="grower.growerName" title="Grower" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>                                             
  <kendo-grid-column field="region" title="Region" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="paddock" title="Paddock" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="siteName" title="Site" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="zoneName" title="Zone" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="productRipeness?.ripeness" title="Ripeness" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.productRipeness?.ripeness}}
    </span>
  </ng-template>  
  </kendo-grid-column>
  <kendo-grid-column field="productSize?.size" title="Size" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.productSize?.size}}
    </span>
  </ng-template>  
  </kendo-grid-column>
  <kendo-grid-column field="productUom?.uom" title="UoM" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold"> 
  <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
    <span>
{{dataItem.productUom?.uom}}
    </span>
  </ng-template>  
  </kendo-grid-column>
  <kendo-grid-column field="unitPrice" title="Unit Price" format="{0:c2}" class='k-text-center font-weight-bold' [width]="220"
  headerClass="k-text-center font-weight-bold">          
  </kendo-grid-column>
  <kendo-grid-column field="stockFormatted" title="Quantity" class='k-text-center font-weight-bold' [sticky]="true" [width]="150"
  headerClass="k-text-center font-weight-bold">     
  </kendo-grid-column>     

  
<kendo-grid-column field="data" title="" class='k-text-center font-weight-bold' [sticky]="true" [filterable]="false"
headerClass="k-text-center font-weight-bold" [width]="100">
<ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
  <button mat-raised-button type="button" matTooltip="Transfer Batch Site" [matTooltipPosition]="'above'"
     class="btn btn-primary btn-link btn-sm btn-just-icon" (click)="openTransferDialog(dataItem)"
    >
    <i class="material-icons">swap_horiz</i>
  </button>
  
</ng-template>
</kendo-grid-column>

</kendo-grid>