<div class="main-content">
  <div class="container-fluid">

    <div class="row">
      <div class="col-lg-12 col-md-12">
        <div class="card">
          <div class="card-header card-header-tabs card-header-success d-flex" *ngIf="showGroups">
            <div class="nav-tabs-navigation">
              <div class="nav-tabs-wrapper">
                <span class="nav-tabs-title">Pricing Groups</span>

              </div>
            </div>

<!--            <button mat-button color="white" (click)="openmarketupdatesdialog(0)" mat-raised-button
              matTooltip="Share market update" [matTooltipPosition]="'above'"
              class="nav-tabs-title nav-link btn-primary btn-sm ml-auto p-2">
              <i class="material-icons">hearing</i>Public Market Update</button>-->
          </div>
          <div class="card-body">
            
                <div class="row">
                  <div class="card-body">          
                    <button mat-raised-button [disabled]="!featurePermission.create" matTooltip="Creat New Group" [matTooltipPosition]="'above'"
                    class="btn btn-primary btn-round" (click)="opendialog()">
                    <i class="material-icons">note_add</i> Create New Group
  
                  </button>
                  </div>
                </div>
            <div class="tab-content">
              <div class="tab-pane active" id="profile" *ngIf="showGroups">
                <mat-accordion class="example-headers-align" >
                  <mat-expansion-panel
                    *ngFor="let marketingGroup of supplierGroupsViewModel.marketingGroups ; let GroupIndex=index"
                    [expanded]="step === GroupIndex" (opened)="setStep(GroupIndex)"
                    [class]="getclass(marketingGroup.isActive)">
                    <mat-expansion-panel-header>
                      <mat-panel-title>

                        <button mat-raised-button [matTooltip]="marketingGroup.groupDiscription"
                          [matTooltipPosition]="'above'" class="btn btn-success btn-link btn-sm">
                          <i class="material-icons">groups</i><b> {{marketingGroup.groupName}}</b>
                          <span>- {{marketingGroup.groupDiscription}}</span>

                        </button>


                      </mat-panel-title>
                      <mat-panel-description>
                        <!-- {{PromotionGroup.groupDiscription}}   -->
                        <!-- <mat-icon>account_circle</mat-icon> -->
                      </mat-panel-description>
                    </mat-expansion-panel-header>
                    <div class="d-flex flex-wrap">
                      <mat-card class="example-card"
                        *ngFor="let member of marketingGroup.marketingGroupMembers ; let memberIndex=index">
                        <!-- <mat-card-header class="customer-cardheader">
                          <mat-card-title></mat-card-title>
                        </mat-card-header> -->
                        <mat-card-content>
                          {{member.customer.businessName}}
                        </mat-card-content>
                      </mat-card>

                    </div>

                    <mat-action-row class="d-flex row text-center">
                      
                      <button mat-button color="warn" [disabled]="!customerPermission.read" (click)="openMembersdialog(marketingGroup)" mat-raised-button
                        matTooltip="Add customers to group" [matTooltipPosition]="'above'"
                        class="btn btn-primary btn-link btn-sm">
                        <i class="material-icons">group_add</i> Manage Members</button>

                      <button mat-button color="warn" [disabled]="!inventoryPermission.read" (click)="openCategoriesdialog(marketingGroup)" mat-raised-button
                        matTooltip="Select product categories where discount is applied" [matTooltipPosition]="'above'"
                        class="btn btn-primary btn-link btn-sm">
                        <i class="material-icons">group_add</i> MANAGE PRODUCT CATEGORIES</button>

                      <button mat-button color="warn" [disabled]="!featurePermission.update" (click)="opendialog(true,marketingGroup)" mat-raised-button
                        matTooltip="Modify group settings" [matTooltipPosition]="'above'"
                        class="btn btn-warning btn-link btn-sm">
                        <i class="material-icons">admin_panel_settings</i> Settings</button>

                      <button mat-button color="warn" [disabled]="!featurePermission.delete" (click)="deleteGroup(marketingGroup)" mat-raised-button
                        matTooltip="Delete Marketing Group" [matTooltipPosition]="'above'"
                        class="btn btn-warning btn-link btn-sm">
                        <i class="material-icons">delete</i> Delete</button>

                      <button mat-button color="warn" *ngIf="GroupIndex!=0" (click)="prevStep()" mat-raised-button
                        matTooltip="Previous group" [matTooltipPosition]="'above'"
                        class="btn btn-success btn-link btn-sm">
                        <i class="material-icons">skip_previous</i>Previous</button>
                      <button mat-button color="primary"
                        *ngIf="GroupIndex < (supplierGroupsViewModel?.marketingGroups.length - 1)" (click)="nextStep()"
                        mat-raised-button matTooltip="Next group" [matTooltipPosition]="'above'"
                        class="btn btn-success btn-link btn-sm">
                        Next<i class="material-icons">skip_next </i></button>
                      <button mat-button color="primary"
                        *ngIf="GroupIndex == (supplierGroupsViewModel?.marketingGroups.length -1)" (click)="nextStep()"
                        mat-raised-button matTooltip="Close all" [matTooltipPosition]="'above'"
                        class="btn btn-success btn-link btn-sm">
                        End<i class="material-icons">eject</i></button>

                    </mat-action-row>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>
              <div *ngIf="! showGroups">
                <img src="/assets/img/403.png">
              </div>
              <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
                <p style="color: white"> Loading ... </p>
              </ngx-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
