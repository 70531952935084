import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProductService } from 'app/shared/services/product.service';
import { NgxSpinnerService } from "ngx-spinner";
import {  DataBindingDirective } from "@progress/kendo-angular-grid";
import { DetailsDialog } from './details/details.dialog';
import { JoinDialog } from './join/join.dialog';
import { UnderApproveDialog } from './under-approve/under-approve.dialog';


@Component({
  selector: 'app-workspace',
  templateUrl: './workspace.dialog.html',
  styleUrls: ['./workspace.dialog.css']
})
export class WorkspaceDialog implements OnInit {
  public opened = true;
  @ViewChild(DataBindingDirective) dataBinding: DataBindingDirective;

  constructor(public dialogRef: MatDialogRef<WorkspaceDialog>,private spinner: NgxSpinnerService, 
    @Inject(MAT_DIALOG_DATA) public dialogdata: any,public dialog: MatDialog,
    private ProductService: ProductService
  ) {

  }

  ngOnInit(): void {

  }



  public close(status: string): void {
    this.opened = false;
  }
  openSellerDetails()
  {
    this.dialog.open(DetailsDialog, { data: { hasProfile: this.dialogdata.hasProfile } }).afterClosed().subscribe(result => {
      if(result.data=="underApprove")
      {
        this.dialogRef.close();
        this.dialog.open(UnderApproveDialog, { disableClose: true });
      }
      
    });
  }
  openJoinDialog()
  {
    this.dialog.open(JoinDialog);
  }
}
