import { ChatAdapter, IChatGroupAdapter, User, Group, Message, ChatParticipantStatus, ParticipantResponse, ParticipantMetadata, ChatParticipantType, IChatParticipant, PagedHistoryChatAdapter } from 'ng-chat';
import { Observable, of } from 'rxjs';
import * as signalR from "@microsoft/signalr";
import { SupplierTrustedNetworksViewModel } from 'app/shared/models/promotions/marketing.model';
import { interval, Subscription } from 'rxjs';
import { MarketingService } from 'app/shared/services/marketing.service';
import { environment } from 'environments/environment';
import { ChatService } from 'app/shared/services/chat.service';
import { SellerService } from 'app/shared/services/Seller.Service';
import { AuthorizeService } from 'app/shared/services/authorize.service';

export class SignalRPaginatedAdapter extends PagedHistoryChatAdapter{
  public userId: string;
  private hubConnection: signalR.HubConnection
  public static serverBaseUrl: string = environment.apibaseUrl; 
  public static Buyers: IChatParticipant[] = [];
  public  BuyersLastMessages : string[] = [];
  supplierTrustedNetworksViewModel: SupplierTrustedNetworksViewModel;
  SupplierId: number;
  subscription: Subscription;
  private lastReceivedMessageId = 0;
  private source = interval(5000);
  
  constructor(private marketingService: MarketingService, private chatService: ChatService, private sellerservice: SellerService, private AuthService: AuthorizeService) {
    super();
    this.initializeConnection();
  }

  private initializeConnection(): void {
    this.sellerservice.getSeller().subscribe(data => {
      this.SupplierId = data.supplierId;
      this.userId = data.identityGuid;
      this.inithub();
    });
  }

  private inithub() {
    this.setupHub();
    this.subscription = this.source.subscribe(val => this.setupHub());  
  }

  private setupHub()
  {
    if (this.hubConnection && this.hubConnection.state == signalR.HubConnectionState.Connected) 
    {
      return;
    }
    this.hubConnection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information) 
      .withUrl(`${SignalRPaginatedAdapter.serverBaseUrl}/chat`, {
        //accessTokenFactory: () => {
        //  return "dummy token for now";//return this.AuthService.getToken();
       // }
      })
      .withAutomaticReconnect()
      .build();
      
    this.hubConnection
      .start()
      .then(() => {        
        this.initializeListeners();
        //this.joinRoom();
      })
      .catch();
  }

  private initializeListeners(): void {
    this.hubConnection.onreconnected(() => {
      //TODO refresh messages inc ase any of them are lost during reconnection
    });
    this.hubConnection.onclose(() => {
      this.setupHub();
    });
    this.hubConnection.on("SellerMessageReceived", (message) => {
      // to make sure that view model is correct means to Id is SupplierId and from is buyerId, and to solve repeated messages  
      if(message.toId === this.SupplierId && message.messageId != this.lastReceivedMessageId)
      {        
        this.lastReceivedMessageId = message.messageId;
        this.chatService.GetChatHistoryForBuyerByPage(message.fromId,1,30).subscribe(allMessages => {
          var lastMessageIndex = allMessages.length - 2;
          if(this.BuyersLastMessages[message.participant.id]) {
              lastMessageIndex =  allMessages.findIndex(object => {
              return object.messageId === this.BuyersLastMessages[message.participant.id];
            });
          } 
          var newMessages = allMessages.slice(lastMessageIndex + 1);
          newMessages = newMessages.filter(x => x.fromId != this.SupplierId);
          this.BuyersLastMessages[message.participant.id] = message.messageId;
          newMessages.forEach(newmessage => console.log('received message to append to chat ', JSON.stringify(newmessage)));       
          newMessages.forEach(newmessage => this.onMessageReceived(newmessage.participant, newmessage));          
        });
        // this.BuyersLastMessages.forEach((element, index) => {
        //   console.log(index,element)
        // });
        
      }      
    });
    this.hubConnection.on("friendsListChanged", (participantsResponse: any) => {
      // Updated Particpant list 
    });
    this.supplierTrustedNetworksViewModel = new SupplierTrustedNetworksViewModel();
    this.getSupplierNetworkforSupplier();
  }

  joinRoom(): void {
    if (this.hubConnection && this.hubConnection.state == signalR.HubConnectionState.Connected) {
      this.hubConnection.send("join", this.userId);
    }
  }

  getSupplierNetworkforSupplier(): void {
    this.chatService.GetChatContacts().subscribe((data) => {
      this.supplierTrustedNetworksViewModel = data
      SignalRPaginatedAdapter.Buyers = [];
      
      let approvedBuyers = this.supplierTrustedNetworksViewModel.supplierTrustedNetworks.filter(x =>
        (x.status == 'approved' || x.status == 'pending') && x.isActive);

      approvedBuyers.map(trustedBuyer => {
        SignalRPaginatedAdapter.Buyers.push({
          avatar: trustedBuyer.buyer.profileThubmnail,
          displayName: trustedBuyer.buyer.businessName,
          id: trustedBuyer.buyerId,
          participantType: ChatParticipantType.User,
          status: ChatParticipantStatus.Online,          
        });        
      });

      //debugger;
      // this.listFriends();
      this.listFriends().subscribe(response => {
        this.onFriendsListChanged(response);
      });
      //this.reloadSuppliersNetwork(this.supplierTrustedNetworksViewModel);
    });

  }

  listFriends(): Observable<ParticipantResponse[]> {
    return of(SignalRPaginatedAdapter.Buyers.map(user => {
      let participantResponse = new ParticipantResponse();
      participantResponse.participant = user;
      participantResponse.metadata = {
        totalUnreadMessages: this.supplierTrustedNetworksViewModel.supplierTrustedNetworks.filter(x => x.buyerId == user.id)[0].unreadMessagesCount,
      }
      return participantResponse;
    }));
  }

  getMessageHistory(destinataryId: any): Observable<Message[]> {
    // This could be an API call to your web application that would go to the database
    // and retrieve a N amount of history messages between the users.
    //this.chatService.GetChatHistory(1);
    let buyerID = destinataryId;
    var messages = this.chatService.GetChatHistoryForBuyer(buyerID);
    messages.subscribe(messages => {
      this.BuyersLastMessages[buyerID] = messages[messages.length - 1].messageId;
    });
    return messages;
  }

  getMessageHistoryByPage(destinataryId: any, size: number, page: number): Observable<Message[]> {
    let buyerID = destinataryId;
    var messages = this.chatService.GetChatHistoryForBuyerByPage(buyerID,page,size);
    messages.subscribe(messages => {
      this.BuyersLastMessages[buyerID] = messages[messages.length - 1].messageId;
    });
    return messages;
  }  

   async sendMessage(message: Message): Promise<Observable<Message[]>> {    
      let newMessage = { ...message }
      newMessage.fromId = this.SupplierId;
      await this.chatService.SendMessage(newMessage).subscribe(result => {
      });
      return of([]);
  }
}
