import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {
  MatSnackBar, MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { OrderService } from '../../../shared/services/order.service';
import { EventEmitterService } from 'app/shared/services/event-emitter.service';
import { CustomerInOrder, CustomerLineItemDetails, OrderUpdateRequest, PendingOrdersbyBuyer, PendingOrdersbyProduct, PlainProductInOrders, ProductInOrder, ProductLineItemDetails, UpdateType } from 'app/shared/models/order/PlainProductInOrders.Model';
import { SellerViewModel } from 'app/shared/models/seller';
import { SellerService } from 'app/shared/services/Seller.Service';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'app/shared/services/user.service';
import { PermissionsConstants } from 'app/shared/permissions.constants';
import { FeaturePermission } from 'app/shared/models/user/featurePermission.model';
import { GridDataResult, PageChangeEvent, RowArgs, SelectableSettings } from '@progress/kendo-angular-grid';
import { aggregateBy, AggregateDescriptor, AggregateResult, groupBy, GroupDescriptor, GroupResult } from '@progress/kendo-data-query';



@Component({
  selector: 'app-product-orders',
  templateUrl: './product-orders.component.html',
  styleUrls: ['./product-orders.component.css']
})
export class ProductOrdersComponent implements OnInit {
  public productGridView: GridDataResult;
  public loading: boolean = false;
  public pageSize: number = 10;
  public skip = 0;
  public currentPage:number = 1;
  public productGroupRes:PlainProductInOrders[] | GroupResult[];
  public total: AggregateResult;
  public aggregates: AggregateDescriptor[] = [
    { field: "orderItemTotalPrice", aggregate: "sum" },
    { field: "approvedQuantity", aggregate: "sum" },
    { field: "stock", aggregate: "sum" }
  ];
 public productGroups: GroupDescriptor[] = [{ field: "productInOrder.category", aggregates: this.aggregates }];
  PendingOrdersbyProduct: PendingOrdersbyProduct[];
  BuyerOrderindex: number = -1;
  ProductsInOrderindex: number = -1;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';
  SupplierId: number;
  approvedUnits: number;
  showApprovals: boolean = true;
  featurePermission: FeaturePermission = new FeaturePermission();
  
  constructor(private sellerservice: SellerService,

    private eventEmitterService: EventEmitterService, private OrderService: OrderService, private snackbar: MatSnackBar
    , private spinner: NgxSpinnerService,private userService: UserService, private sellerService: SellerService

  ) {
  }
  
 loadPermissions(seller: SellerViewModel) {   
  this.userService.getUserPermissionsForSeller(seller.id).subscribe(userPermissions => {  
  this.featurePermission = userPermissions.filter(x => x.feature == PermissionsConstants.ORDERS )[0];
  this.showApprovals =this.featurePermission.read;   
  });
   
}
  ngOnInit(): void {
    //this.spinner.show();
    this.loading = true;
    this.initOrders()

  }

  async initOrders() {    
    this.sellerservice.getSeller().subscribe(data => {
      this.loadPermissions(data);
      this.SupplierId = data.supplierId;
      this.getBuyerOrders();
      // this.imageSource = this.Seller.supplier.profileImageUrl_Small;
    });


  }

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000);
    }

    return value;
  }
  getBuyerOrders(): void {
    this.OrderService.GetPendingOrdersGroupbyBuyer(this.currentPage,this.pageSize).subscribe((data) => {
      data.items.forEach(s=>{
        s.orderItemTotalPrice=s.lineItemInOrder.orderItemTotalPrice;
        s.approvedQuantity=s.lineItemInOrder.approvedQuantity;
        s.stock=s.productInOrder.stock;
      });
      this.total= aggregateBy(data.items, this.aggregates);
        this.productGroupRes=groupBy(data.items, this.productGroups);
        this.productGridView= {
          data: this.productGroupRes,
          total: data.totalItems,
        };
      this.loading = false;
    });
  }
  // getProductsInOrder(): void {
  //   this.OrderService.GetPendingOrdersGroupbyProduct().subscribe((data) => {
  //     this.PendingOrdersbyProduct = data
  //     this.PendingOrdersbyProduct.forEach(x => x.buyersLineItemDetails.forEach(x => x.lineItemInOrder.updatedQuantity = x.lineItemInOrder.approvedQuantity));
  //     this.PendingOrdersbyProduct.forEach(x => x.buyersLineItemDetails.forEach(x => x.lineItemInOrder.updatedSoldPrice = x.lineItemInOrder.soldPrice));

  //   });
  // }


  confirmBuyer(index: number) {

    this.snackbar.open('All Order for Shop :{ ' + + ' } is confirmed..', 'close', {
      duration: 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

  modelChanged(event: any) {
  }

  confirmProduct() {
    this.snackbar.open('All Order for Product :{ ' + + ' } is confirmed..', 'close', {
      duration: 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }


  ConfirmAllProductsforCustomer(Customer: CustomerInOrder) {
    let _updateType = UpdateType.ConfirmAllProductsforCustomer;
    let orderUpdateRequest = new OrderUpdateRequest();
    console.log('ConfirmAllProductsforCustomer and customer Id is ', Customer.customerId)
    orderUpdateRequest.customerId = Customer.customerId;
    orderUpdateRequest.supplierId = this.SupplierId;
     this.UpdateOrder(_updateType, orderUpdateRequest);

    this.snackbar.open('All Order for Buyer :{ ' + Customer.customerName + ' } is confirmed..', 'close', {
      duration: 2000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
    });
    
  }

  ConfirmCertainProductInAllOrders(Product: ProductInOrder) {
    let _updateType = UpdateType.ConfirmCertainProductInAllOrders;
    let orderUpdateRequest = new OrderUpdateRequest();
    orderUpdateRequest.productId = Product.productId;
    console.log('ConfirmCertainProductInAllOrders productId: ' + orderUpdateRequest.productId);
    orderUpdateRequest.supplierId = this.SupplierId;
    this.UpdateOrder(_updateType, orderUpdateRequest);


    this.snackbar.open('All Order for Product :{ ' + Product.category + ' } is confirmed..', 'close', {
      duration: 1000,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }  

  ChangeRequestedQuantity(orderItem: ProductLineItemDetails, approvedUnits: number) {      
    orderItem.lineItemInOrder.approvedQuantity = approvedUnits;      
    orderItem.lineItemInOrder.approvedQuantityUpdated = true;
    this.updateOrderItem(orderItem);
  }

  setUnitPrice(orderItem: ProductLineItemDetails, unitPrice: number) {  
    orderItem.lineItemInOrder.soldPrice = unitPrice;    
    orderItem.lineItemInOrder.soldPriceUpdated = true;
    this.updateOrderItem(orderItem);
  }

  updateOrderItem(orderItem: ProductLineItemDetails){    
    let orderUpdateRequest = new OrderUpdateRequest();
    orderUpdateRequest.lineItemId = orderItem.lineItemInOrder.lineitemId;
    orderUpdateRequest.orderId = orderItem.lineItemInOrder.orderId;
    orderUpdateRequest.qunatity = orderItem.lineItemInOrder.approvedQuantity;
    orderUpdateRequest.supplierId = orderItem.productInOrder.supplierId;
    orderUpdateRequest.unitPrice = orderItem.lineItemInOrder.soldPrice;
    orderUpdateRequest.supplierId = this.SupplierId;

    //TODO: Refactor double requests to backend
    if(orderItem.lineItemInOrder.approvedQuantityUpdated){
      let _updateType = UpdateType.UpdateQuantity;
      this.UpdateOrder(_updateType, orderUpdateRequest);
    }
    if(orderItem.lineItemInOrder.soldPriceUpdated){
      let _updateType = UpdateType.SetUnitPrice;
      this.UpdateOrder(_updateType, orderUpdateRequest);
    }

    this.snackbar.open('Order confirmed..', 'close', {
      duration: 500,
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition
    });
  }

  updateProductOrder(Confirm: boolean, orderItem: CustomerLineItemDetails): void {

    let _updateType = UpdateType.Reject;
    let orderUpdateRequest = new OrderUpdateRequest();
    orderUpdateRequest.customerId = orderItem.customerInOrder.customerId;
    orderUpdateRequest.lineItemId = orderItem.lineItemInOrder.lineitemId;
    orderUpdateRequest.orderId = orderItem.lineItemInOrder.orderId;
    orderUpdateRequest.qunatity = orderItem.lineItemInOrder.approvedQuantity;
    orderUpdateRequest.supplierId = this.SupplierId;
    if (Confirm) {
      _updateType = UpdateType.Approve;
      // let ChangeRequestedQuantity = orderItem.lineItemInOrder.approvedQuantity != orderItem.lineItemInOrder.requestedQuantiy;
      // if (ChangeRequestedQuantity) {
      //   _updateType = UpdateType.UpdateQuantity;

      // }
    }
     this.UpdateOrder(_updateType, orderUpdateRequest);

    if (Confirm) {

      this.snackbar.open('Order confirmed..', 'close', {
        duration: 500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    }
    else {

      this.snackbar.open('Order rejected..', 'close', {
        duration: 500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });

    }

  }

   UpdateOrder(Type: UpdateType, orderUpdateRequest: OrderUpdateRequest) {
    
    switch (+Type) {
      case UpdateType.Approve:
        this.spinner.show();
        this.OrderService.ConfirmItem(orderUpdateRequest).subscribe((data) => {
          
          this.getBuyerOrders();
          this.spinner.hide();
        });
        break;

      case UpdateType.UpdateQuantity:
        this.OrderService.ChangeRequestedQuantity(orderUpdateRequest).subscribe((data) => {
          
          this.getBuyerOrders();
        });
        break;

        case UpdateType.SetUnitPrice:
        this.OrderService.SetUnitPrice(orderUpdateRequest).subscribe((data) => {
      
          this.getBuyerOrders();
        });
        break;

      case UpdateType.Reject:
        this.OrderService.RejectItem(orderUpdateRequest).subscribe((data) => {
      
          this.getBuyerOrders();
        });
        break;

      case UpdateType.ConfirmAllProductsforCustomer:
        this.spinner.show();
        this.OrderService.ConfirmAllProductForCustomer(orderUpdateRequest).subscribe((data) => {
          this.getBuyerOrders();
          this.spinner.hide();
        },(error)=>alert(error));
        break;

      case UpdateType.ConfirmCertainProductInAllOrders:
        this.spinner.show();
        this.OrderService.ConfirmProductInAllOrders(orderUpdateRequest).subscribe((data) => {
          this.getBuyerOrders();
          this.spinner.hide();
        });
        break;

      default:
        break;
    }

  }

  lessThanOeEq(Object: number, comparer: number): boolean {

    return Object <= comparer
  }
  lessThan(Object: number, comparer: number): boolean {

    return Object < comparer;
  }

  validateConfirmAllforProduct() {
    return false;
  }

  validateConfirmAllforBuyer(Items: ProductLineItemDetails[]) {

    let found = Items.find(x => x.lineItemInOrder.approvedQuantity > x.productInOrder.stock);
    if (found) {
      return true;
    }
    else { return false }
  }
  updateBuyerOrder(Confirm: boolean, orderItem: ProductLineItemDetails): void {


    let _updateType = UpdateType.Reject;

    let orderUpdateRequest = new OrderUpdateRequest();

    orderUpdateRequest.lineItemId = orderItem.lineItemInOrder.lineitemId;
    orderUpdateRequest.orderId = orderItem.lineItemInOrder.orderId;

    orderUpdateRequest.qunatity = orderItem.lineItemInOrder.approvedQuantity;
    orderUpdateRequest.supplierId = orderItem.productInOrder.supplierId;
    if (Confirm) {
      _updateType = UpdateType.Approve;

      let ChangeRequestedQuantity = orderItem.lineItemInOrder.approvedQuantity != orderItem.lineItemInOrder.requestedQuantiy;
      if (ChangeRequestedQuantity) {
        _updateType = UpdateType.Approve;

      }
    }

    this.UpdateOrder(_updateType, orderUpdateRequest);



    if (Confirm) {

      this.snackbar.open('Order confirmed..', 'close', {
        duration: 500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });
    }
    else {

      this.snackbar.open('Order rejected..', 'close', {
        duration: 500,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      });

    }



  }

  public pageChange(event: PageChangeEvent): void {
    this.currentPage = (Number( event.skip) + this.pageSize) / this.pageSize;
    this.skip=event.skip;
    this.getBuyerOrders();
  }
  getGrid()
  {
    this.getBuyerOrders();
  }
 

}
