import { Directive, Input } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[maxPrice][formControlName],[maxPrice][formControl],[maxPrice][ngModel]',
  providers: [{provide: NG_VALIDATORS, useExisting: MaxPriceDirective, multi: true}]
})
export class MaxPriceDirective implements Validator {
  @Input()
  maxPrice: number;
  @Input()
  price: number;
  
  validate(c: FormControl): {[key: string]: any} {
      let v = c.value;
      return ( this.price * c.value > this.maxPrice )? {"maxPrice": true} : null;
  }
} 