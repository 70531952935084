<div class="main-content">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <div class="card-header card-header-tabs card-header-primary">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <span class="nav-tabs-title">Orders:</span>
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="customer-orders" routerLinkActive="active">
                                            <i class="material-icons">person</i> Customers
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    <li class="nav-item">
                                        <a class="nav-link" routerLink="product-orders" routerLinkActive="active">
                                            <i class="material-icons">eco</i> Products
                                            <div class="ripple-container"></div>
                                        </a>
                                    </li>
                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="tab-content" *ngIf="showApprovals">
                            <router-outlet></router-outlet>
                        </div>

                        <div *ngIf="PendingOrdersbyBuyer?.length === 0">No records found</div>
                        <div *ngIf="!showApprovals">
                            <img src="/assets/img/403.png">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
</ngx-spinner>