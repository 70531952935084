<div class="netsuite-dialog">
  <h2>NetSuite Settings</h2>
  <form id="netsuite-form" [formGroup]="netsuiteSettingsForm" #netsuiteForm="ngForm">
    <div class="row">
      <div class="form-group">
        <label for="account-id">Account ID:</label>
        <input type="text" id="account-id" name="account-id" required formControlName="accountId">
        <div *ngIf="netsuiteSettingsForm.get('accountId').invalid && (netsuiteSettingsForm.get('accountId').dirty || netsuiteSettingsForm.get('accountId').touched)" class="error-message">
          Account ID is required.
        </div>
      </div>     
      <div class="form-group">
        <label for="api-url">API URL:</label>
        <input type="text" id="api-url" name="api-url" required formControlName="apiUrl">
        <div *ngIf="netsuiteSettingsForm.get('apiUrl').invalid && (netsuiteSettingsForm.get('apiUrl').dirty || netsuiteSettingsForm.get('apiUrl').touched)" class="error-message">
          API URL is required.
        </div>
      </div>
    </div> 
    <div class="row">
      <div class="form-group">
        <label for="consumer-key">Consumer Key:</label>
        <input type="text" id="consumer-key" name="consumer-key" required formControlName="consumerKey">
        <div *ngIf="netsuiteSettingsForm.get('consumerKey').invalid && (netsuiteSettingsForm.get('consumerKey').dirty || netsuiteSettingsForm.get('consumerKey').touched)" class="error-message">
          Consumer Key is required.
        </div>
      </div>
      <div class="form-group">
        <label for="consumer-secret">Consumer Secret:</label>
        <input type="text" id="consumer-secret" name="consumer-secret" required formControlName="consumerSecret">
        <div *ngIf="netsuiteSettingsForm.get('consumerSecret').invalid && (netsuiteSettingsForm.get('consumerSecret').dirty || netsuiteSettingsForm.get('consumerSecret').touched)" class="error-message">
          Consumer Secret is required.
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group">
        <label for="token-id">Token ID:</label>
        <input type="text" id="token-id" name="token-id" required formControlName="tokenId">
        <div *ngIf="netsuiteSettingsForm.get('tokenId').invalid && (netsuiteSettingsForm.get('tokenId').dirty || netsuiteSettingsForm.get('tokenId').touched)" class="error-message">
          Token ID is required.
        </div>
      </div>
      <div class="form-group">
        <label for="token-secret">Token Secret:</label>
        <input type="text" id="token-secret" name="token-secret" required formControlName="tokenSecret">
        <div *ngIf="netsuiteSettingsForm.get('tokenSecret').invalid && (netsuiteSettingsForm.get('tokenSecret').dirty || netsuiteSettingsForm.get('tokenSecret').touched)" class="error-message">
          Token Secret is required.
        </div>
      </div>
    </div>
    <button type="submit" id="save-button" [disabled]="netsuiteForm.invalid" (click)="saveForm()">Save</button>
  </form>
</div>